Munio.behaviors.autogenerate = {
  selector: '[data-autogenerate]',
  attach: function (context) {
    $(this.selector, context).each(function () {
      const $this = $(this)
      const $input = $($this.data('target'))

      $this.on('click', function () {
        $this.find('i').addClass('fa-spin')
        $.getJSON($this.data('autogenerate'), function (data) {
          $input.val(data.value)
          $input.trigger('change')
          $this.find('i').removeClass('fa-spin')
        })
      })
    })
  },
}
