<template>
  <div class="input-age flex">
    <div>
      <Textfield
        floating-label
        maxlength="4"
        placeholder="YYYY"
        v-model="year"
        :label="trans('Year')"
        :error="!isYearValid"
        @change="onYearChanged"
      />
    </div>
    <div class="px-8">
      <Textfield
        floating-label
        maxlength="2"
        placeholder="MM"
        v-model="month"
        :label="trans('Month')"
        :error="!isMonthValid"
        @change="onMonthChanged"
      />
    </div>
    <div>
      <Textfield
        floating-label
        maxlength="2"
        placeholder="DD"
        v-model="day"
        :label="trans('Day')"
        :error="!isDayValid"
        @change="onDayChanged"
      />
    </div>
  </div>
</template>

<script>
import Textfield from './mdl/Textfield.vue'
import { format, isValid, parseDate, toDateString } from '@/munio/utils/date.js'

export default {
  components: {
    Textfield,
  },
  props: {
    defaultValue: String,
  },
  data() {
    return {
      isDirty: false,
      year: undefined,
      month: undefined,
      day: undefined,
    }
  },

  model: {
    prop: 'defaultValue',
    event: 'change',
  },

  mounted() {
    this.setDefaultValue(this.defaultValue)
  },

  watch: {
    defaultValue(value) {
      this.setDefaultValue(value)
    },
  },

  computed: {
    isYearValid() {
      if (!this.isDirty) {
        return true
      }

      if (isNaN(this.year)) {
        return false
      }

      const now = new Date()
      now.setFullYear(now.getFullYear() - 10)
      if (this.year > now.getFullYear()) {
        return false
      }

      now.setFullYear(now.getFullYear() - 110)
      if (this.year < now.getFullYear()) {
        return false
      }

      return isValid(new Date(this.year, 0, 1))
    },
    isMonthValid() {
      if (!this.isDirty) {
        return true
      }

      if (isNaN(this.month)) {
        return false
      }

      const parsedMonth = new Date(this.currentYear, this.month - 1, 1).getMonth() + 1
      const inputMonth = Number(this.month)

      return parsedMonth === inputMonth
    },
    isDayValid() {
      if (!this.isDirty) {
        return true
      }

      if (isNaN(this.month)) {
        return false
      }

      return new Date(this.currentYear, 0, this.day).getDate() === Number(this.day)
    },
    date() {
      return parseDate([this.parseFullYear(this.year), this.month || '0', this.day || '0'].join('-'))
    },
    value() {
      return toDateString(this.date)
    },
    currentYear() {
      return new Date().getFullYear()
    },
  },
  methods: {
    setDefaultValue(value) {
      if (value) {
        const defaultValue = parseDate(value)

        if (defaultValue) {
          this.year = format(defaultValue, 'yyyy')
          this.month = format(defaultValue, 'MM')
          this.day = format(defaultValue, 'dd')
          this.inputChanged()
        }
      }
    },
    onDayChanged() {
      this.day = this.filterNumbers(this.day, 2)
      this.inputChanged()
    },
    onMonthChanged() {
      this.month = this.filterNumbers(this.month, 2)
      this.inputChanged()
    },
    onYearChanged() {
      this.year = this.parseFullYear(this.year)
      this.inputChanged()
    },
    inputChanged() {
      this.$emit('change', this.isYearValid && this.isMonthValid && this.isDayValid ? this.value : undefined)
    },
    parseFullYear(value) {
      const year = this.filterNumbers(value)

      if (!year) {
        return ''
      }

      let y = parseInt(year)

      if (year.length === 2) {
        const cy = parseInt((this.currentYear + '').slice(-2))

        y = y + (y > cy ? 1900 : 2000)
      }

      return y
    },
    filterNumbers(value, pad = false) {
      if (!this.isDirty) {
        this.isDirty = true
      }

      if (!value) {
        return value
      }

      value = (value + '').replace(/[^0-9]/, '')

      if (pad && value.length && value.length < pad) {
        value = new Array(pad - value.length + 1).join('0') + value
      }
      return value
    },
  },
}
</script>
