import { CollectionResponse } from '@/munio/api/data/common'
import { GateUserResourceDto } from '@/munio/api/data/GateUserResource.dto'

export type SearchUsersOptions = {
  query: string
  company?: number
  exclude: number[]
}
export default class Search {
  constructor(private gateId: number) {}

  get params() {
    return { gate: this.gateId }
  }

  users(options: SearchUsersOptions) {
    return window.Munio.$http.get<CollectionResponse<GateUserResourceDto>>(
      Munio.route('api.web.access.gate.search.users', this.params),
      window.Munio.urlParams(window.cleanup(options)),
    )
  }
}
