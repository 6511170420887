import { ResourceResponse } from '@/munio/api/data/common'
import { AccountResourceDto } from '@/munio/api/data/AccountResourceDto'
import { AccountState } from '@/munio/definitions'

class Accounts {
  get basePath() {
    return '/api/munio/accounts'
  }

  validate(data: any) {
    return window.Munio.$http.post(this.basePath, Object.assign({}, data, { validate: true }))
  }

  create(
    companyId: number,
    subdomain: string,
    name: string,
    state: AccountState.Active | AccountState.Trial,
    expiresAt?: string,
    features?: string[],
  ) {
    return window.Munio.$http.post<ResourceResponse<AccountResourceDto>>(this.basePath, {
      company: {
        id: companyId,
      },
      subdomain,
      name,
      expires_at: expiresAt,
      features,
      state,
    })
  }
}

export default Accounts
