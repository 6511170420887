<template>
  <div class="panel-languages flex items-center justify-end leading-none ml-auto">
    <input v-for="lang of languagesSelected" :key="lang.id" type="hidden" :name="name + '[]'" :value="lang.id" />

    <div class="flex items-center gap-x-4 mr-6">
      <div>{{ trans('Language') }}:</div>

      <m-select
        class="languages-list w-64"
        :options="languagesSelected"
        v-model="selection.language"
        :disabled="languagesSelected.length === 1"
        @input="setLanguage"
      />
    </div>

    <div
      v-if="showMenu"
      class="languages-dropdown dropdown"
      :class="{ open: selection.dropdown }"
      v-click-outside="hideDropdown"
    >
      <button
        type="button"
        class="languages-add btn btn-lg btn-primary btn-icon"
        @click="toggleDropdown"
        rel="tooltip"
        :title="trans('Select language')"
      >
        <i class="material-icons">language</i>
      </button>

      <div class="dropdown-menu left">
        <m-select
          multiple
          display-only
          :options="languagesOptions"
          v-model="selection.selected"
          @deselect="deselectLanguage"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LanguageSelector',
  inheritAttrs: false,

  props: {
    name: { type: String, default: 'languages' },
    onlySiteLanguages: Boolean,
    selected: Array,
  },

  computed: {
    showMenu() {
      return !(this.onlySiteLanguages && this.selected.length === 0)
    },

    languages() {
      return Munio.languages(true, false)
    },

    languagesAvailable() {
      return this.onlySiteLanguages ? this.languages.filter((language) => language.translated) : this.languages
    },

    languagesSelected() {
      let languages = this.languagesAvailable

      if (this.showMenu) {
        languages = languages.filter((language) => this.selection.selected.includes(language.key))
      }

      return languages.map((l) => ({
        id: l.key,
        label: l.name,
      }))
    },

    languagesOptions() {
      const isSingleLanguage = this.selection.selected.length === 1

      return this.languagesAvailable.map((l) => ({
        id: l.key,
        label: l.name,
        isDisabled: isSingleLanguage && this.selection.language === l.key,
      }))
    },

    isSingle() {
      return this.selection.selected.length <= 1
    },
  },

  data() {
    const currentLanguage = Munio.config.i18n.key

    return {
      selection: {
        language: currentLanguage,
        dropdown: false,
        selected: this.selected,
      },
    }
  },

  methods: {
    toggleDropdown() {
      this.selection.dropdown = !this.selection.dropdown
    },
    hideDropdown() {
      this.selection.dropdown = false
    },
    deselectLanguage({ id: language }) {
      if (language === this.selection.language) {
        this.$nextTick(() => {
          this.setLanguage(this.languagesSelected[0].id)
        })
      }
    },
    getLanguage(language) {
      return this.languages.find((l) => l.key === language)
    },
    setLanguage(language) {
      this.selection.language = language

      this.$inputs.each((i, el) => {
        el.classList.toggle('hidden', el.dataset.language !== language)
      })
    },
    async removeLanguage(language) {
      if (this.isSingle) {
        return
      }
      this.selection.selected = this.selection.selected.filter((l) => l !== language)
      this.selection.language = this.selection.selected[0] || Munio.config.i18n.key
    },
  },

  mounted() {
    this.$inputs = $(this.$el).parents('.panel:first').find('[data-language]')
  },

  destroy() {
    delete this.$inputs
  },
}
</script>
