import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import { createPinia } from 'pinia'

export default class Wrapper {
  selector = ''
  data = {}
  _app
  _tabs
  _store
  _pinia
  _router

  constructor(selector, data = {}) {
    this.selector = selector
    this.data = data

    this.mount()
  }

  get name() {
    return 'Root'
  }

  get root() {
    return this._app
  }

  get app() {
    return this._app.$refs.root
  }

  get tabs() {
    return this._tabs.$refs.root
  }

  async mount() {
    const appComponent = await this.component()
    const tabsComponent = await this.componentTabs()
    const store = await this.store()
    const router = await this.router()
    const state = (await this.state()) || {}

    if (store) {
      const initialState = {
        ...store.state,
        ...this.data,
        ...state,
      }

      this._store = new Vuex.Store({
        ...addNamespace(store),
        state: initialState,
      })
    } else {
      this._pinia = createPinia()
    }

    if (router) {
      this._router = router instanceof VueRouter ? router : new VueRouter(router)

      // makes router available as this.$router in actions and mutations
      if (this._store) {
        this._store.$router = this._router
      }
    }

    if (appComponent) {
      this._app = this.mountComponent(this.selector, appComponent.default || appComponent)
    }

    if (tabsComponent) {
      this._tabs = this.mountComponent('.header-tabs > .mdl-tabs__tab-bar', tabsComponent.default || tabsComponent)
    }

    return this._app
  }

  mountComponent(selector, component) {
    const options = this.options({
      el: selector,
      name: this.name,
      data: () => Object.assign({}, this.data),
      provide: () => Object.assign({}, this.data),
      render: function (h) {
        return h(component, { props: this.$data, ref: 'root' })
      },
    })

    const mixins = this.mixins()

    if (mixins) options.mixins = mixins
    if (this._store) options.store = this._store
    if (this._pinia) options.pinia = this._pinia
    if (this._router) options.router = this._router

    return new Vue(options)
  }

  async components() {
    const app = await this.component()
    const name = app.name || 'Unknown'

    return [
      {
        el: this.selector,
        name,
        app,
      },
    ]
  }

  component() {
    return 'div'
  }

  componentTabs() {
    return null
  }

  store() {
    return null
  }

  state() {
    return null
  }

  router() {
    return null
  }

  mixins() {
    return null
  }

  options(opts) {
    return opts
  }
}

/**
 * Add namespace to all sub modules.
 */
export function addNamespace(store) {
  if (typeof store.namespaced === 'undefined') {
    store.namespaced = true
  }

  for (const key in store.modules) {
    addNamespace(store.modules[key])
  }

  return store
}
