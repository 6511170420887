import '@/libs/jquery.autosize.input.js'

Munio.behaviors.autosizeInput = {
  selector: '[data-autosize]',
  attach: function (context, config) {
    $(this.selector, context).each(function (i, el) {
      const $el = $(el)

      $el
        .autosizeInput(
          $.extend(
            {},
            {
              space: 6,
            },
            $el.data('autosize'),
          ),
        )
        .trigger('change')
    })
  },
}
