<template>
  <time :datetime="dateFormatted" :class="{ 'is-vertical': vertical }">
    <span class="time__date">{{ strDate }}</span>
    <span v-if="showTime" class="time__time">
      <Icon name="schedule" small />
      {{ strTime }}
    </span>
  </time>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { parseDate, format as formatDate, toDateTimeString, toDateString, isValid } from '@/munio/utils/date.js'
import Icon from '@component/Icon.vue'

const props = defineProps({
  value: {
    type: [String, Date],
    required: true,
    validator: (value: string) => isValid(value),
  },
  time: {
    type: Boolean,
    default: false,
  },
  vertical: {
    type: Boolean,
    default: false,
  },
  short: {
    type: Boolean,
    default: false,
  },
  zone: {
    type: Boolean,
    default: false,
  },
})

const date = ref<Date>()
const dateFormat = computed(() => 'HH:mm' + (props.short ? '' : ':ss') + (props.zone ? ' (XXX)' : ''))
const showTime = computed(() => props.time || props.short || props.zone)
const dateFormatted = computed(() => (showTime.value ? toDateTimeString(date.value) : toDateString(date.value)))
const strDate = computed(() => toDateString(date.value))
const strTime = computed(() => formatDate(date.value, dateFormat.value))

onMounted(() => {
  date.value = parseDate(props.value)
})
</script>
