/**
 * Make tab link active when clicked.
 * Bootstrap takes care of the rest.
 */
Munio.behaviors.paneltabs = {
  selector: '.panel-tabs',
  attach: function (context, config) {
    $(this.selector, context).each(function (i) {
      let $this = $(this)
      $('a', $this).on('click', function (event) {
        $('a', $this).removeClass('active')
        $(this).addClass('active')
      })
    })
  },
}
