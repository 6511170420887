<template>
  <section>
    <div class="mdl-list mdl-list--cards">
      <div class="mdl-list__card-heading">
        <div v-if="titleHidden" class="spacer"></div>
        <h3 v-else>{{ trans('Certificates') }}</h3>

        <mdl-button raised :disabled="isLoading || !hasAddableCertificates" @click="showAddCertificate = true">
          {{ trans('Add') }}
        </mdl-button>
      </div>

      <mdl-list-item v-if="!hasCertificates" type="card" :expandable="false">
        <template v-slot:title>
          <em>{{ trans('No entries found') }}</em>
        </template>
      </mdl-list-item>

      <mdl-list-item v-else v-for="certificate in certificates" type="card" :key="certificate.id">
        <template v-slot:title>
          {{ certificate.name }}
          <small class="opaque ml-3" v-if="identifierEnabled">{{ certificate.identifier }}</small>
        </template>
        <template v-if="certificate.isExpired" v-slot:primary-content>
          <span class="label label-danger ml-3">{{ trans('Expired') }}</span>
        </template>
        <template v-slot:actions>
          <mdl-button icon @click="removeCertificate(certificate.id)">
            <i class="material-icons">delete</i>
          </mdl-button>
        </template>
      </mdl-list-item>
    </div>

    <certificate-add-modal
      :show="showAddCertificate"
      :addable="addable"
      :born-at="bornAt"
      @hide="showAddCertificate = false"
      @add="addCertificate"
    />

    <mdl-progressbar v-if="isLoading" indeterminate />
  </section>
</template>

<script>
import CertificateAddModal from './CertificateAddModal.vue'

export default {
  components: {
    CertificateAddModal,
  },

  props: {
    identifierEnabled: { type: Boolean },
    titleHidden: { type: Boolean },
    bornAt: { type: [String, undefined] },
    namespace: { type: String },
  },

  data() {
    return {
      showAddCertificate: false,
    }
  },

  mounted() {
    this.$store.dispatch('settings/load', 'certificates')
  },

  computed: {
    state() {
      return this.namespace ? this.$store.state[this.namespace] : this.$store.state
    },
    isLoading() {
      return this.state.certificates.loading
    },
    certificates() {
      return this.state.certificates.list
    },
    addable() {
      return this.state.certificates.addable
    },
    hasCertificates() {
      return this.certificates && this.certificates.length > 0
    },
    hasAddableCertificates() {
      return this.addable && this.addable.length > 0
    },
    actionNamespace() {
      return this.namespace ? `${this.namespace}/` : ''
    },
  },

  methods: {
    addCertificate(toAdd) {
      this.$store.dispatch(`${this.actionNamespace}addCertificate`, toAdd)
      this.showAddCertificate = false
    },
    removeCertificate(id) {
      this.$store.dispatch(`${this.actionNamespace}removeCertificate`, id)
    },
  },
}
</script>
