import DynamicImage from '@/munio/api/DynamicImage.js'

export default class {
  group

  constructor(group) {
    this.group = group
  }

  image(id, extension) {
    return new DynamicImage(this.group, id, extension)
  }
}
