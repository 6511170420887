<template>
  <div>
    <mdl-progressbar :indeterminate="true" v-if="fetching" />
    <div class="data-card flex-col">
      <div class="data-card__info">
        <div class="data-card__info-icon">
          <icon name="event" />
        </div>
        <div class="data-card__info-element">
          <div class="data-card__info-label">{{ trans('When') }}</div>
          <div v-for="(day, index) in session.days" :key="index" class="data-card__info-text">
            <strong v-if="currentDay === day" v-html="day.timespan" />
            <span v-else v-html="day.timespan" />
          </div>
        </div>
      </div>

      <div v-if="!session.isWebinar" class="data-card__info">
        <div class="data-card__info-icon">
          <icon name="place" />
        </div>
        <div class="data-card__info-element">
          <div class="data-card__info-label">{{ trans('Where') }}</div>
          <div class="data-card__info-text">
            <strong>{{ location.name }}</strong>
          </div>
          <div class="data-card__info-text whitespace-pre-wrap">{{ location.addressBlock }}</div>
          <div class="data-card__info-text">
            <a :href="location.url" target="_blank">
              {{ trans('Show on map') }}
              <i class="fa fa-external-link" />
            </a>
          </div>
        </div>
      </div>

      <div class="data-card__info">
        <div class="data-card__info-icon">
          <icon name="language" />
        </div>
        <div class="data-card__info-element">
          <div class="data-card__info-label">{{ trans('Language') }}</div>
          <div class="data-card__info-text">{{ language }}</div>
        </div>
      </div>

      <div v-if="session.webinar" class="data-card__info">
        <div class="data-card__info-icon">
          <img v-if="session.webinar.isTeams" src="./assets/teams-icon.svg" type="image/svg+xml" />

          <icon v-else name="open_in_new" />
        </div>
        <div class="data-card__info-element">
          <div class="data-card__info-label">{{ trans('Webinar link') }}</div>
          <a class="data-card__info-text break-all" :href="session.webinar.url" target="_blank">
            {{ trans('Click here to join the webinar') }}
          </a>
        </div>
      </div>
    </div>

    <slot></slot>

    <div class="modal-footer flex justify-end flex-grow">
      <mdl-button danger :disabled="fetching" v-confirm="unenroll">
        {{ trans('Unenroll') }}
      </mdl-button>
      <template v-if="session.isWebinar">
        <mdl-button v-if="currentDay" :href="session.webinar.url" raised primary target="_blank">
          {{ trans('Join') }}
        </mdl-button>
      </template>
    </div>
  </div>
</template>

<script>
import Confirm from '@/munio/vue/directives/confirm.js'

export default {
  directives: {
    Confirm,
  },

  props: {
    session: Object,
  },

  data() {
    return {
      fetching: false,
    }
  },

  computed: {
    location() {
      return this.session.location
    },
    language() {
      return Munio.language(this.session.language).name
    },
    currentDay() {
      if (!this.session.dayCurrent) {
        return null
      }

      return this.session.days.find((day) => day.id === this.session.dayCurrent)
    },
    nextDay() {
      if (!this.session.dayNext) {
        return null
      }

      return this.session.days.find((day) => day.id === this.session.dayNext)
    },
  },

  methods: {
    async unenroll() {
      this.fetching = true
      await Munio.api.currentUser.sessions.unenroll(this.session.id)
      this.$emit('unenrolled')
      this.fetching = false
    },
  },
}
</script>
