import Wrapper from '@/munio/vue/Wrapper.js'

Munio.ThemeColorCustomizer = class extends Wrapper {
  constructor(selector, data = {}) {
    const $el = document.querySelector(selector)

    super(selector, {
      id: $el.id,
      name: $el.name,
      value: $el.value,
      ...data,
    })
  }

  component() {
    return import('./ThemeColorCustomizer.vue')
  }
}
