import { UserBasicResourceDto } from './data/UserBasicResource.dto'

class Auth {
  get basePath() {
    return '/api/auth'
  }

  checkUsername(username: string, gate: number) {
    type CheckUsernameResponse = {
      username: string
      type: 'user' | 'invite'
      result: UserBasicResourceDto
    }

    return window.Munio.$http.get<CheckUsernameResponse>(Munio.route('api.web.auth.check.username', { username, gate }))
  }

  checkInvite(invite: string) {
    invite = encodeURIComponent(invite)

    return window.Munio.$http.get(`${this.basePath}/check/invite/${invite}`)
  }

  checkEmailAvailable(email: string) {
    email = encodeURIComponent(email)

    return window.Munio.$http.get(`${this.basePath}/check/email/${email}`)
  }

  validateProfile(email: string, firstname: string, lastname: string) {
    return window.Munio.$http.post(`${this.basePath}/validate/profile`, {
      email,
      firstname,
      lastname,
    })
  }

  login(username: string, password: string, options = {}) {
    return window.Munio.$http.post(`${this.basePath}/login`, {
      ...options,
      username,
      password,
    })
  }

  recover(username: string) {
    username = encodeURIComponent(username)

    return window.Munio.$http.post(`${this.basePath}/recover/${username}`)
  }

  reset(token: string, password: string) {
    return window.Munio.$http.post(`${this.basePath}/reset/${token}`, { password })
  }

  register(options: UserRegistration = {}) {
    options.language = window.Munio.config.i18n.key
    return window.Munio.$http.post(`${this.basePath}/register`, options)
  }

  forget() {
    return window.Munio.$http.delete(Munio.route('api.web.auth.forget'))
  }
}

export default Auth

type UserRegistration = {
  name?: string
  email?: string
  password?: string
  language?: string
  company?: string
  private?: boolean
  newsletter?: boolean
  invite?: boolean
}
