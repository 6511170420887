<template>
  <div class="list">
    <div class="list__actions" v-if="searchable || $slots.actions">
      <!-- search field -->
      <div v-if="searchable" class="form-inline">
        <input v-if="searchable" type="search" v-model="searchValue" :placeholder="searchPlaceholder" @input="search" />
        <button type="button" class="btn btn-icon btn-primary" @click="forceSearch">
          <i class="fa fa-search"></i>
        </button>
      </div>

      <div class="spacer"></div>

      <template>
        <slot name="actions"></slot>
      </template>
    </div>

    <!-- empty message -->
    <p v-if="isEmpty" class="list__empty">{{ emptyMessage }}</p>

    <!-- loading indicator -->
    <div v-if="loading" class="list__progress">
      <mdl-progressbar indeterminate />
    </div>

    <!-- the list -->
    <div class="list_content" :class="listClass">
      <template v-if="!isEmpty">
        <slot name="item" v-for="(item, index) in items" :item="item" :index="index"></slot>
      </template>
      <slot></slot>
    </div>

    <div class="list__footer" v-if="pagination || $slots.footer">
      <list-pagination v-if="pagination" :data="pagination" :loading="loading" @change="paginate"></list-pagination>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import ListPagination from '../Pagination.vue'

const MIN_SEARCH_LENGTH = 3

export default {
  props: {
    query: Object,
    data: Object,
    loading: Boolean,
    searchable: { type: Boolean, default: false },
    type: { type: String, default: 'card' },
    emptyMessage: { type: String, default: null },
    searchPlaceholder: { type: String, default: trans('Search') + '…' },
    addLabel: { type: String, default: trans('Add') },
    listClass: [String, Object, Array],
  },

  components: {
    ListPagination,
  },

  data() {
    let initialSearchValue = (this.query ? this.query.search : '') || ''

    return {
      didSearch: initialSearchValue.length > 0,
      searchValue: initialSearchValue,
      currentPage: 1,
    }
  },

  computed: {
    items() {
      return (this.data || {}).data
    },
    pagination() {
      return this.data && this.data.meta ? this.data.meta.pagination || null : null
    },
    isEmpty() {
      if (!this.items) return false

      const emptyArray = Array.isArray(this.items) && this.items.length === 0
      const emptyObject = Object.keys(this.items).length === 0

      return emptyArray || emptyObject
    },
    searchQuery() {
      if (!isNaN(this.searchValue)) {
        return this.searchValue
      }

      return this.searchValue.length >= MIN_SEARCH_LENGTH ? this.searchValue : null
    },
    queryParams() {
      let query = {
        page: this.currentPage,
        search: this.searchQuery,
      }

      return { ...this.query, ...query }
    },
  },

  methods: {
    forceSearch() {
      this.didSearch = true
      this.search()
    },

    search() {
      clearTimeout(this._searchTimout)
      this._searchTimout = setTimeout(() => {
        this._searchTimout = null
        this.currentPage = 1

        // search if query is longer than minimum length
        if (this.searchQuery) {
          this.emit('search')
          this.didSearch = true

          // reset search if search input is empty
        } else if (this.searchValue.length === 0 && this.didSearch) {
          this.emit('search')
          this.didSearch = false
        }
      }, 300)
    },

    paginate(page) {
      if (!this.loading && this.currentPage !== page) {
        this.currentPage = page

        // clear search input if the value
        // is invalid before paginating
        if (!this.searchQuery) {
          this.searchValue = ''
        }

        this.emit('paginate')
      }
    },

    emit(type) {
      this.$emit(type, this.queryParams)
    },
  },
}
</script>
