<template>
  <MdlTextfield
    class="min-w-[175px]"
    ref="textfield"
    v-model="displayValue"
    v-bind="props"
    v-on="listeners"
    @blur="onBlur"
    @focus="onFocus"
    :align="align"
  >
    <input v-if="name" type="hidden" :name="name" :value="parsedValue" />
    <div
      v-if="parsedValue !== null && discount"
      class="flex items-center gap-1 absolute top-[calc(50%-10px)]"
      :class="{ 'right-0': align === 'left', 'left-0': align === 'right', 'flex-row-reverse': align === 'right' }"
    >
      <div class="flex gap-1 bg-green-200 text-black px-2 py-1 rounded leading-[12px] text-[12px] text-nowrap">
        <span>-{{ format(discount, currency) }}</span>
        <span v-if="percent">({{ percent }}%)</span>
      </div>
      <MdlButton
        v-if="inputOriginal && !display && !readonly"
        dense
        small
        icon="replay"
        @click="displayValue = inputOriginal"
      />
    </div>
    <slot />
  </MdlTextfield>
</template>

<script>
import { computed, ref, nextTick, watch } from 'vue'
import { parse, format } from '@/munio/currency'
import { useDiscount } from '@/munio/vue/composables/useDiscount'
import MdlTextfield, { Props } from './Textfield.vue'
import MdlButton from './Button.vue'

const locale = window.Munio.config.i18n.locale

export default {
  inheritAttrs: true,

  components: { MdlButton, MdlTextfield },

  props: {
    ...Props,
    currency: { type: String, required: true },
    original: { type: [String, Number] },
    value: { type: [String, Number] },
    align: { type: String, default: 'right' },
    nullable: Boolean,
  },

  setup(props, { emit, listeners }) {
    const propsWithoutName = computed(() => {
      const { name, ...propsWithoutName } = props
      return propsWithoutName
    })

    const textfield = ref()
    const focused = ref(false)
    const inputOriginal = computed(() => stringified(props.original))
    const inputValue = ref(stringified(props.value))
    const parsedOriginal = ref(null)
    const parsedValue = ref(props.nullable && inputValue.value === '' ? null : 0)
    const { input: inputEvent, blur: blurEvent, focus: focusEvent, ...events } = listeners
    const { discount, percent } = useDiscount(inputValue, inputOriginal)
    const displayValue = computed({
      get() {
        if (focused.value || (props.nullable && inputValue.value === '')) {
          return inputValue.value
        }

        return format(props.value, props.currency)
      },

      set(value) {
        inputValue.value = value
        parsedValue.value = props.nullable && value === '' ? null : parse(value)
        emit('input', parsedValue.value)
      },
    })

    function onFocus(e) {
      if (props.readonly || props.display) {
        e.preventDefault()
        return
      }

      focused.value = true
      listeners.focus?.(e)
      nextTick(() => {
        textfield.value.$refs.input.select()
      })
    }

    function onBlur(e) {
      focused.value = false
      listeners.blur?.(e)
    }

    function stringified(value) {
      return [null, undefined].includes(value) ? '' : String(value)
    }

    watch(
      () => props.value,
      (value) => {
        if (!focused.value) {
          inputValue.value = stringified(value)
        }
      },
    )

    return {
      props: propsWithoutName,
      textfield,
      focused,
      inputOriginal,
      inputValue,
      parsedOriginal,
      parsedValue,
      displayValue,
      discount,
      percent,
      listeners: events,
      onFocus,
      onBlur,
      format: (value) =>
        parse(value).toLocaleString(locale, {
          roundingPriority: 'lessPrecision',
          minimumFractionDigits: 2,
          minimumSignificantDigits: 2,
        }),
    }
  },
}
</script>
