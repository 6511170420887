import Wrapper from '@/munio/react/Wrapper.jsx'
import store from './stores/index.js'

Munio.CoursePlayer = class extends Wrapper {
  reducer() {
    return store
  }

  state() {
    return {
      userId: this.$data.userId || 0,
      mainAttemptId: this.$data.attemptId || 0,
      preview: this.$data.isPreview || false,
      backUrl: this.$data.backUrl || '',
      selectLanguage: this.$data.selectLanguage || false,
    }
  }

  async component() {
    const App = (await import('./App.jsx')).default

    return <App />
  }
}
