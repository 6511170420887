<template>
  <div class="mdl-filter__form" @click="$emit('activate', $event)">
    {{ trans('No') }}
    <mdl-switch :name="inputName" :checked="checked" right />
    {{ trans('Yes') }}
  </div>
</template>

<script>
export default {
  name: 'SwitchFilter',
  inheritAttrs: false,

  props: {
    inputName: { type: String, default: () => 'value' },
    label: { type: String },
    disabled: { type: Boolean },
    value: { type: [Boolean, String] },
  },

  emits: ['activate'],

  computed: {
    checked() {
      if (typeof this.value === 'boolean') {
        return this.value
      }

      return this.value === 'true' || this.value === '1'
    },
  },
}
</script>
