<template>
  <li class="company-selector" v-click-outside="hide">
    <form
      ref="form"
      method="post"
      :action="route('lms.context')"
      :class="['dropdown', { open: dropdown }]"
      @keydown.esc="hide"
      @keydown.stop
    >
      <csrf />
      <input type="hidden" name="company_id" :value="company.id" />

      <a class="btn" @click="toggle">
        <icon name="business" />
        {{ company.name }}
      </a>
      <mdl-button icon="business" outlined @click="toggle" />

      <b-menu v-if="dropdown" right class="dropdown-menu left w-[32rem]" @keydown="type">
        <b-menu-item v-if="companies.length > 10">
          <mdl-textfield
            ref="search"
            tabindex="1"
            autofocus
            v-model="search.query"
            :placeholder="trans('Search')"
            @keydown.stop
            @keydown.esc="hide"
            @keydown.down="onSearchArrowDown"
            @keydown.enter.prevent="onSearchSubmit(false)"
          />
        </b-menu-item>

        <b-menu-item
          v-if="companiesOptions.length"
          ref="companies"
          class="max-h-50vh overflow-y-scroll overflow-x-hidden"
        >
          <template #content>
            <template v-if="search.active">
              <a>
                <mdl-spinner small />
              </a>
            </template>

            <template v-else>
              <a
                v-for="company of companiesOptions"
                :key="company.id"
                tabindex="1"
                @keydown.enter="select(company)"
                @keydown.up="onDropdownNavigateUp"
                @keydown.down="onDropdownNavigateDown"
                @click="select(company)"
                class="flex justify-between items-baseline gap-x-4"
              >
                <span class="flex-shrink overflow-hidden text-ellipsis whitespace-nowrap">{{ company.name }}</span>
                <small class="whitespace-nowrap overflow-x-visible">
                  <span v-if="company.orgnr" class="opaque">{{ company.orgnr }}</span>
                  {{ flag(company.country) }}
                </small>
              </a>
            </template>
          </template>
        </b-menu-item>
      </b-menu>
    </form>
  </li>
</template>

<script>
import { focusableNodes } from '@/munio/utils/index.js'

const debounce = window.debounce

export default {
  data() {
    return {
      companies: Munio.config.companies,
      company: Munio.config.company,
      search: {
        query: '',
        results: [],
        active: false,
        debounce: debounce(this.onSearchSubmit, 500),
      },
      dropdown: false,
    }
  },

  computed: {
    companiesFiltered() {
      if (!this.search.query) {
        return this.companies
      }

      const keywords = this.search.query
        .split(' ')
        .filter((v) => v)
        .map((v) => v.toLocaleLowerCase())

      return this.companies.filter((c) => {
        const matches = keywords.filter((keyword) => {
          if (keyword == c.id || keyword == c.orgnr) {
            return true
          }

          return (
            c.name.toLocaleLowerCase().includes(keyword) ||
            c.nameLegal?.toLocaleLowerCase().includes(keyword) ||
            c.nameShort?.toLocaleLowerCase().includes(keyword)
          )
        })

        return matches.length === keywords.length
      })
    },

    companiesOptions() {
      if (!this.search.query) {
        return this.companies
      }

      if (!this.search.active && this.search.results.length) {
        return this.search.results
      }

      return this.companiesFiltered
    },
  },

  methods: {
    toggle() {
      this.dropdown = !this.dropdown
      this.$nextTick(() => {
        if (this.dropdown && this.$refs.search) {
          this.$refs.search.focus()
        }
      })
    },
    hide() {
      this.dropdown = false
    },
    select(company) {
      this.company = company
      this.hide()
      this.$nextTick(() => {
        this.$refs.form.submit()
      })
    },
    type(e) {
      if (e.key.length === 1) {
        this.$nextTick(() => {
          this.$refs.search.focus()
        })
      }
    },
    async onSearchSubmit(debounce = false) {
      console.log('onSearchSubmit', {
        debounce,
        query: this.search.query,
        results: this.search.results.length,
        options: this.companiesOptions.length,
      })

      if (this.search.query.length < 3) {
        return
      }

      if (debounce && this.companiesFiltered.length) {
        return
      }

      if (can('munio')) {
        try {
          this.search.active = true
          const { data } = await Munio.api.companies.search(this.search.query, undefined, undefined, true)
          this.search.results = data
        } catch (error) {
          console.error(error)
        } finally {
          this.search.active = false
        }

        return
      }

      const firstCompany = this.companiesOptions[0]

      if (firstCompany) {
        this.select(firstCompany)
      }
    },
    onSearchArrowDown(e) {
      if (!this.$refs.companies) {
        return
      }

      const nodes = focusableNodes(this.$refs.companies.$el)

      if (nodes.length) {
        e.preventDefault()
        nodes[0].focus()
      }
    },
    onDropdownNavigateUp(e) {
      if (!this.$refs.companies) {
        return
      }

      const nodes = focusableNodes(this.$refs.companies.$el)
      const index = nodes.indexOf(e.target)

      if (index === 0) {
        e.stopPropagation()
        this.$nextTick(() => {
          this.$refs.search.focus()
        })
      } else if (index > 0) {
        e.preventDefault()
        nodes[index - 1].focus()
      }
    },
    onDropdownNavigateDown(e) {
      const nodes = focusableNodes(this.$refs.companies.$el)
      const index = nodes.indexOf(e.target)

      if (nodes[index + 1]) {
        e.preventDefault()
        nodes[index + 1].focus()
      }
    },
  },
  watch: {
    'search.query'() {
      this.search.results = []
      this.search.debounce(true)
    },
  },
}
</script>
