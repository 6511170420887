import { loadStripe, Appearance, Stripe, StripeElementLocale } from '@stripe/stripe-js'

type StripeOptions = {
  disableAdvancedFraudDetection?: boolean
  stripeAccount?: string
  apiVersion?: string
  locale?: StripeElementLocale
}

export async function useStripe(publicKey: string, options?: StripeOptions): Promise<Stripe> {
  return (await loadStripe(publicKey, {
    stripeAccount: options?.stripeAccount,
    apiVersion: options?.apiVersion,
    locale: options?.locale,
  })) as Stripe
}

const style = getComputedStyle(document.body)

export const appearance: Appearance = {
  theme: 'stripe',
  labels: 'floating',
  variables: {
    colorPrimary: style.getPropertyValue('--m-color-primary'),
    colorDanger: style.getPropertyValue('--m-color-danger'),
    colorText: style.getPropertyValue('--m-color-text'),
    borderRadius: style.getPropertyValue('--m-border-radius'),
    fontFamily: style.getPropertyValue('--m-font-family-sans-serif'),
    fontSizeBase: style.getPropertyValue('--m-font-size-base'),
    fontWeightNormal: style.getPropertyValue('--m-font-weight-normal'),
    fontLineHeight: style.getPropertyValue('--m-line-height'),
  },
  rules: {
    '.Tab': {},
    '.Tab:hover': {},
    '.Tab--selected': {},
    '.Input': {},
    '.Input--invalid': {},
  },
}

export function isStripeLocale(locale: string): locale is StripeElementLocale {
  return validLocales.includes(locale)
}

const validLocales = [
  'auto',
  'ar',
  'bg',
  'cs',
  'da',
  'de',
  'el',
  'en',
  'en-AU',
  'en-CA',
  'en-NZ',
  'en-GB',
  'es',
  'es-ES',
  'es-419',
  'et',
  'fi',
  'fil',
  'fr',
  'fr-CA',
  'fr-FR',
  'he',
  'hu',
  'hr',
  'id',
  'it',
  'it-IT',
  'ja',
  'ko',
  'lt',
  'lv',
  'ms',
  'mt',
  'nb',
  'nl',
  'no',
  'pl',
  'pt',
  'pt-BR',
  'ro',
  'ru',
  'sk',
  'sl',
  'sv',
  'th',
  'tr',
  'vi',
  'zh',
  'zh-HK',
  'zh-TW',
]
