class CompanyCourseSessionParticipants {
  companyCourseId
  sessionId

  constructor(companyCourseId, sessionId) {
    this.companyCourseId = companyCourseId
    this.sessionId = sessionId
  }

  route(name, params = {}) {
    return Munio.route(
      name,
      Object.assign(
        {
          companyCourse: this.companyCourseId,
          session: this.sessionId,
        },
        params,
      ),
    )
  }

  get basePath() {
    return this.route('api.web.admin.company.course.session.participants')
  }
}

export default CompanyCourseSessionParticipants
