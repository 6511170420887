import Wrapper from '@/munio/vue/Wrapper.js'

Munio.InviteEditor = class extends Wrapper {
  get name() {
    return 'InviteEditor'
  }

  component() {
    return import('./App.vue')
  }
}

Munio.InviteEditor.SendModal = class extends Wrapper {
  constructor(selector, inviteId) {
    super(selector, { inviteId })
  }

  get name() {
    return 'InviteSendModal'
  }

  component() {
    return import('./SendModal.vue')
  }
}
