<template>
  <span class="inline-block items-baseline text-black/50 hover:text-black cursor-pointer ml-1" @click="onCopy">
    <Icon :name="copied ? 'done' : 'content_copy'" small />
  </span>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useClipboard } from '@vueuse/core'
import Icon from './Icon.vue'

const props = defineProps<{
  text: string | number | object
}>()

const clipboard = useClipboard()
const copied = ref(false)

function stringify(value: string | number | object) {
  return typeof value === 'object' ? JSON.stringify(value, null, 2) : String(value)
}

function onCopy() {
  clipboard.copy(stringify(props.text))
  copied.value = true
  setTimeout(() => (copied.value = false), 1500)
}
</script>
