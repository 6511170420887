export function stateIcon(state: string) {
  const states = {
    queued: 'pending',
    pending: 'pending',
    //running: 'play_arrow',
    cancelled: 'block',
    success: 'done',
    failed: 'error_outline',
  }

  return states[state]
}

export function stateColor(state: string) {
  return {
    'mdl-color--blue-50 mdl-color-text--blue-900': state === 'queued',
    'mdl-color--blue-400': state === 'running',
    'mdl-color--success': state === 'success',
    'mdl-color--danger': state === 'failed',
  }
}

export function stateLabelClasses(state: string) {
  return {
    'mdl-label--success': state === 'success',
    'mdl-label--danger': state === 'failed',
    'mdl-label--default': state === 'pending',
  }
}

export function statusLabelClasses(status: number) {
  if (status < 200) {
    return 'mdl-label--default'
  }
  if (status < 300) {
    return 'mdl-label--success'
  }
  if (status < 400) {
    return 'mdl-label--info'
  }
  if (status < 500) {
    return 'mdl-label--warning'
  }
  return 'mdl-label--danger'
}
