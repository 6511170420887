import * as actions from '../actions/index.js'

const initialState = { ready: false }

let ticks = {}

//
// Reducers
//

const nodes = (state = {}, action) => {
  switch (action.type) {
    case actions.NODE_PLAY:
      let newState = Object.assign({}, state)

      Object.keys(state).forEach((nodeId) => {
        newState = Object.assign({}, newState, { [action.id]: node(newState[action.id], action) })
      })

      return newState

    case actions.NODE_PLAYBACK:
    case actions.VIDEO_TICK:
      return Object.assign({}, state, { [action.id]: node(state[action.id], action) })

    case actions.ATTEMPT_UPDATE:
      delete sortedNodes[action.attempt.id]

      action.attempt.nodes.forEach((n) => {
        const oldNode = state[n.id] || initialState
        const newNode = Object.assign({}, oldNode, n)

        state = Object.assign({}, state, { [n.id]: node(newNode, action) })
      })

      return state

    default:
      return state
  }
}

const node = (state = initialState, action) => {
  switch (action.type) {
    case actions.NODE_PLAY:
      return Object.assign({}, state, { isCurrent: action.id === state.id })

    case actions.NODE_PLAYBACK:
      return Object.assign({}, state, { playback: action.playback, ready: true })

    case actions.VIDEO_TICK:
      let tick = {
        playback: Object.assign({}, state.playback, {
          metadata: action.metadata,
        }),
      }

      ticks[action.id] = tick

      return Object.assign({}, state, tick)

    case actions.ATTEMPT_UPDATE:
      return Object.assign({}, { ready: false }, state, ticks[state.id])

    default:
      return state
  }
}

export default nodes

//
// Selectors
//

let sortedNodes = {}

export const getNodesForAttempt = (state, attemptId) => {
  if (sortedNodes[attemptId]) {
    return sortedNodes[attemptId]
  }

  let nodes = []

  Object.keys(state.nodes).forEach((k) => {
    let node = state.nodes[k]

    if (node.attemptId === attemptId) {
      nodes.push(node)
    }
  })

  let result = (sortedNodes[attemptId] = nodes.sort((a, b) => {
    if (a.sortOrder < b.sortOrder) return -1
    else if (a.sortOrder > b.sortOrder) return 1
    else return 0
  }))

  return result
}

export const getNode = (state, nodeId) => {
  return state.nodes[nodeId]
}

export const getNodeApi = (state) => {
  return Munio.api.attempt(state.currentAttemptId).node(state.currentNodeId)
}

export const getScormItem = (state, id) => {
  const manifest = state.scorm.model.manifest

  const itemFinder = function (items, id) {
    for (let item of items) {
      if (item.identifier === id) {
        return item
      }

      if (item.items) {
        const subitem = itemFinder(item.items, id)

        if (subitem) {
          return subitem
        }
      }
    }

    return null
  }

  const item = itemFinder(manifest.organization.items, id)

  if (!item) {
    throw new Error('Unknown item identifier')
  }

  return item
}

export const getScormConnection = (state, id) => {
  const nodeApi = getNodeApi(state)

  if (id) {
    return nodeApi.scorm(id)
  }

  return nodeApi.scorm(state.scorm.model.item.identifier)
}
