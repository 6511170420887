<template>
  <span class="flex flex-1 items-center" :class="{ 'text-right flex-row-reverse': right }" :data-user-id="user.id">
    <img
      v-if="showAvatar && image"
      :src="image"
      alt=""
      class="avatar"
      :class="{ 'ml-4': right, 'mr-4': !right }"
      v-tooltip="tooltip"
    />
    <icon
      v-else-if="showAvatar"
      :name="icon"
      class="md-36 md-dark md-inactive"
      :class="{ 'ml-4': right, 'mr-4': !right }"
    />
    <span class="flex flex-col">
      <span>
        <strong class="text-base" :class="{ 'ml-2': right, 'mr-2': !right }">{{ name }}</strong>
        <span v-if="showCompany && showContact && company" class="text-sm" :class="{ 'ml-2': right, 'mr-2': !right }">
          {{ company.name }}
        </span>
      </span>
      <span v-if="showContact || company" class="text-sm" :class="{ 'text-right': right }">
        <span v-if="showContact && user.email" :class="{ 'ml-2': right, 'mr-2': !right }">
          <icon v-if="showIcon" name="email" small />
          {{ user.email }}
        </span>
        <span v-if="showContact && user.mobile" :class="{ 'ml-2': right, 'mr-2': !right }">
          <icon v-if="showIcon" name="phone" small />
          {{ formatPhone(user.mobile) }}
        </span>
        <span v-if="!showContact && company" :class="{ 'ml-2': right, 'mr-2': !right }">{{ company.name }}</span>
      </span>
    </span>
  </span>
</template>

<script>
import { userFullname, formatPhone } from '@/munio/utils/index.js'

export default {
  props: {
    user: { type: Object, required: true },
    contact: { type: Boolean },
    icon: { type: String, default: 'account_circle' },
    withoutAvatar: { type: Boolean },
    withoutIcons: { type: Boolean },
    right: { type: Boolean },
    showCompany: { type: Boolean, default: true },
  },
  computed: {
    image() {
      if (!this.user.avatar) {
        return undefined
      }

      if (typeof this.user.avatar === 'string') {
        return this.user.avatar
      }

      return this.user.avatar.url
    },
    name() {
      return userFullname(this.user)
    },
    company() {
      return this.user.company || this.user.employer
    },
    showAvatar() {
      return !this.withoutAvatar
    },
    showContact() {
      return this.contact && this.user.email !== this.name
    },
    showIcon() {
      return !this.withoutIcons
    },
    tooltip() {
      if (can('munio') && this.user.id) {
        return `User ID: ${this.user.id}`
      }

      return undefined
    },
  },
  methods: {
    formatPhone,
  },
}
</script>
