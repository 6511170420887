import Wrapper from '@/munio/vue/Wrapper.js'

Munio.CourseCreator = class extends Wrapper {
  get name() {
    return 'CourseCreator'
  }

  component() {
    return import('./Creator/index.vue')
  }
}

Munio.CourseEditor = class extends Wrapper {
  get name() {
    return 'CourseEditor'
  }

  component() {
    return import('./Editor/index.vue')
  }
}
