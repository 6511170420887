import flatpickr from 'flatpickr'

Munio.behaviors.dateinput = {
  selector: '.input-date',
  attach: function (context, config) {
    $(this.selector, context).each(function (i, el) {
      const options = $(el).data()

      if (options.altField) {
        const $target = $(options.altField, context)

        if (!$target.length) {
          throw new Error('The alternate input field ' + options.altField + ' could not be found')
        }

        options.onChange = function (selectedDates, dateStr, instance) {
          $target.val(dateStr)
        }
      }

      flatpickr(el, options)
    })
  },
}
