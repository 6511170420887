import { toDateString } from '@/munio/utils/date.js'

export default class UserCourse {
  constructor(parent, courseId) {
    this.parent = parent
    this.courseId = courseId
  }

  get params() {
    return {
      ...this.parent.params,
      course: this.courseId,
    }
  }

  get() {
    return Munio.$http.get(Munio.route('api.web.access.zone.user.course', this.params))
  }

  document(file) {
    const form = new FormData()

    form.append('document', file, file.name)

    return Munio.$http.post(Munio.route('api.web.access.zone.user.course.document', this.params), form, {
      'content-type': 'multipart/form-data',
    })
  }

  setDate(from, to) {
    return Munio.$http.post(Munio.route('api.web.access.zone.user.course.setdate', this.params), {
      from: toDateString(from),
      to: toDateString(to),
    })
  }

  restart() {
    return Munio.$http.post(Munio.route('api.web.access.zone.user.course.restart', this.params))
  }

  cancel() {
    return Munio.$http.post(Munio.route('api.web.access.zone.user.course.cancel', this.params))
  }

  approve() {
    return Munio.$http.post(Munio.route('api.web.access.zone.user.course.approve', this.params))
  }

  reject() {
    return Munio.$http.post(Munio.route('api.web.access.zone.user.course.reject', this.params))
  }
}
