<template>
  <div class="mdl-progress-container" :style="containerStyle">
    <div :class="barClasses" :style="barStyle" data-upgraded="MaterialProgress">
      <div class="progressbar bar bar1" :style="{ width: progressPercent + '%' }"></div>
      <div class="bufferbar bar bar2" :style="{ width: bufferPercent + '%' }"></div>
      <div class="auxbar bar bar3" :style="{ width: auxPercent + '%' }"></div>
    </div>
    <div v-if="status" class="mdl-progress__status">{{ statusLabel }}</div>
  </div>
</template>

<script>
export default {
  props: {
    indeterminate: { type: Boolean },
    progress: { type: Number, default: 0 },
    buffer: { type: Number, default: 100 },
    width: { type: [String, Number] },
    height: { type: [String, Number] },
    status: { type: [String, Number, Boolean], default: null },
    success: { type: Boolean, default: null },
    large: { type: Boolean },
  },

  computed: {
    containerStyle() {
      if (!this.width) {
        return undefined
      }
      return { width: `${this.width}px` }
    },

    barStyle() {
      if (!this.height) {
        return undefined
      }
      return { height: `${this.height}px` }
    },

    progressPercent() {
      if (this.success === true) {
        return 100
      }

      if (this.indeterminate && this.success === false) {
        return 100
      }

      return this.normalizeProgress(this.progress)
    },

    bufferPercent() {
      if (this.indeterminate) {
        return 100
      }

      return this.normalizeProgress(this.buffer)
    },

    auxPercent() {
      return 100 - this.bufferPercent
    },

    progressIndeterminate() {
      return this.indeterminate && this.success === null
    },

    barClasses() {
      return {
        'mdl-progress': true,
        'mdl-js-progress': true,
        'mdl-progress__indeterminate': this.progressIndeterminate,
        'mdl-progress--success': this.success === true,
        'mdl-progress--failed': this.success === false,
        'mdl-progress--large': this.large,
        'is-upgraded': true,
      }
    },

    statusLabel() {
      if (typeof this.status === 'boolean') {
        return `${this.progressPercent}%`
      }

      return this.status
    },
  },

  methods: {
    normalizeProgress(progress) {
      if (progress > 100) {
        progress = 100
      }

      return Math.round(progress)
    },
  },
}
</script>
