<template>
  <div>
    <mdl-progressbar :indeterminate="true" v-if="fetching" />
    <div class="flex items-center p-8" style="border-top: 1px solid #eaeaea">
      <div
        class="avatar flex items-center justify-center mdl-color--default mdl-color-text--default-contrast"
        style="margin-right: 16px"
      >
        <icon name="event" />
      </div>
      <div>
        <strong>{{ session.from.datetimeName }}</strong>
        <p>
          <span>
            <icon class="small opaque" name="language" />
            {{ language }}
          </span>
          <span class="ml-4">
            <icon class="small opaque" name="timelapse" />
            {{ session.duration.human }}
          </span>
        </p>
      </div>
      <mdl-button raised :disabled="fetching || !canEnroll" v-confirm="enroll" style="margin-left: auto">
        {{ trans('Enroll') }}
      </mdl-button>
    </div>
  </div>
</template>

<script>
import Confirm from '@/munio/vue/directives/confirm.js'

export default {
  props: {
    session: Object,
    canEnroll: { type: Boolean, default: true },
  },

  data() {
    return {
      fetching: false,
    }
  },

  computed: {
    language() {
      return Munio.language(this.session.language).name
    },
  },

  methods: {
    async enroll() {
      this.fetching = true
      await Munio.api.currentUser.sessions.enroll(this.session.id)
      this.$emit('enrolled', this.session)
      this.fetching = false
    },
  },

  directives: {
    Confirm,
  },
}
</script>
