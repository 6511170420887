<template>
  <div class="mdl-paginator-container">
    <nav class="mdl-paginator" v-if="pagination.rowsTotal > 0">
      <div class="mdl-paginator__rows">{{ rowsText }}</div>
      <div class="mdl-paginator__actions">
        <mdl-button
          icon
          primary
          rel="prev"
          :disabled="cannotClickPrev"
          @click="$emit(emitEvent, 1)"
          class="mdl-paginator__button-first"
        >
          <i class="material-icons">first_page</i>
        </mdl-button>
        <mdl-button
          icon
          primary
          rel="prev"
          :disabled="cannotClickPrev"
          @click="$emit(emitEvent, pagination.pageCurrent - 1)"
          class="mdl-paginator__button-prev"
        >
          <i class="material-icons">navigate_before</i>
        </mdl-button>
        <mdl-button
          icon
          primary
          rel="next"
          :disabled="cannotClickNext"
          @click="$emit(emitEvent, pagination.pageCurrent + 1)"
          class="mdl-paginator__button-next"
        >
          <i class="material-icons">navigate_next</i>
        </mdl-button>
        <mdl-button
          icon
          primary
          rel="last"
          :disabled="cannotClickNext"
          @click="$emit(emitEvent, pagination.pageLast)"
          class="mdl-paginator__button-last"
        >
          <i class="material-icons">last_page</i>
        </mdl-button>
      </div>
      <mdl-progressbar v-if="loading" indeterminate />
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Object, required: false },
    rowsFrom: { type: Number, required: false, default: 0 },
    rowsTo: { type: Number, required: false, default: 0 },
    rowsTotal: { type: Number, required: false, default: 0 },
    pageRows: { type: Number, required: false, default: 15 },
    pageCurrent: { type: Number, required: false, default: 1 },
    pageLast: { type: Number, required: false, default: 1 },
    loading: { type: Boolean, required: false },
  },

  emits: ['change', 'goto'],

  computed: {
    pagination() {
      return {
        rowsFrom: this.value?.from || this.rowsFrom,
        rowsTo: this.value?.to || this.rowsTo,
        rowsTotal: this.value?.total || this.rowsTotal,
        pageRows: this.value?.per_page || this.pageRows,
        pageCurrent: this.value?.current_page || this.pageCurrent,
        pageLast: this.value?.last_page || this.pageLast,
      }
    },

    emitEvent() {
      return this.$listeners.hasOwnProperty('change') ? 'change' : 'goto'
    },

    cannotClickPrev() {
      return this.loading || this.pagination.pageCurrent <= 1
    },

    cannotClickNext() {
      return this.loading || this.pagination.pageCurrent >= this.pagination.pageLast
    },

    rowsText() {
      return trans(':num of :total', {
        num:
          this.pagination.pageLast === 1
            ? `${this.pagination.rowsTo}`
            : `${this.pagination.rowsFrom}-${this.pagination.rowsTo}`,
        total: this.pagination.rowsTotal,
      })
    },
  },
}
</script>
