import { CollectionResponse } from './data/common'
import { UserBasicResourceDto } from './data/UserBasicResource.dto'

const { $http, route, urlParams } = window.Munio

class Users {
  index(options?: URLSearchParams | object) {
    return $http.get<CollectionResponse<UserBasicResourceDto>>(route('api.web.admin.users'), urlParams(options))
  }
}

export default Users
