import IpRules from './IpRules'
import UserMerge from './UserMerge'

export default class MunioApi {
  get ipRules() {
    return new IpRules()
  }

  get userMerge() {
    return new UserMerge()
  }
}
