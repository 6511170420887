import { AccountResourceDto, Features } from '@/munio/api/data/AccountResourceDto'
import { UserBasicResourceDto, ZoneBasicResourceDto } from '@/munio/api/data'
import { CollectionResponse, ResourceResponse } from '@/munio/api/data/common'

export type UserRole = {
  id: number
  role: string
  user: UserBasicResourceDto
}

export type AccountTheme = {
  colorBg: string
  colorText: string
}

export type UpdatableAccount = {
  name: string
  expiresAt: string
  features: string[]
}

class Account {
  accountId: number
  constructor(accountId: number) {
    this.accountId = accountId
  }

  get params() {
    return { id: this.accountId }
  }

  get() {
    return window.Munio.$http.get<ResourceResponse<AccountResourceDto>>(
      Munio.route('api.web.munio.account.show', this.params),
    )
  }

  update(name: string, state: string, expiresAt: string | undefined, features: Features) {
    return window.Munio.$http.put(Munio.route('api.web.munio.account.update', this.params), {
      name,
      state: state,
      expiresAt: expiresAt,
      features,
    })
  }

  updateAppearance(theme: AccountTheme) {
    return window.Munio.$http.post(Munio.route('api.web.munio.account.update.appearance', this.params), {
      theme: theme,
    })
  }

  delete() {
    return window.Munio.$http.delete(Munio.route('api.web.munio.account.delete', this.params), {})
  }

  get gates() {
    return new Gates(this.params)
  }

  get roles() {
    return new Roles(this.accountId)
  }
}

class Gates {
  params: { id: number }

  constructor(params: { id: number }) {
    this.params = params
  }

  get() {
    return window.Munio.$http.get<CollectionResponse<ZoneBasicResourceDto>>(
      Munio.route('api.web.munio.account.gates', this.params),
    )
  }

  create(name: string) {
    return window.Munio.$http.post(Munio.route('api.web.munio.account.gate.create', this.params), { name: name })
  }

  delete(id: number) {
    return window.Munio.$http.delete(Munio.route('api.web.munio.account.gate.delete', { ...this.params, gate: id }))
  }
}

class Roles {
  accountId: number

  constructor(accountId: number) {
    this.accountId = accountId
  }

  get() {
    return window.Munio.$http.get<CollectionResponse<UserRole>>(
      Munio.route('api.web.munio.account.users', { id: this.accountId }),
    )
  }

  create(user: any, role: string) {
    return window.Munio.$http.post<ResourceResponse<UserRole>>(
      Munio.route('api.web.munio.account.users.create', { id: this.accountId }),
      {
        user,
        role,
      },
    )
  }

  delete(userId: number) {
    return window.Munio.$http.delete<CollectionResponse<UserRole>>(
      Munio.route('api.web.munio.account.users.delete', { id: this.accountId, user: userId }),
      {},
    )
  }
}

export default Account
