<template>
  <div>
    <div class="flex items-center gap-x-2 w-full">
      <!-- Firstname -->
      <mdl-textfield
        required
        class="flex-grow"
        autocomplete="new-password"
        ref="firstname"
        :error="firstnameError"
        error-focus
        :floatingLabel="trans('Firstname')"
        v-model="registration.firstname"
      />

      <!-- Lastname -->
      <mdl-textfield
        required
        class="flex-grow"
        autocomplete="new-password"
        ref="lastname"
        :error="lastnameError"
        error-focus
        :floatingLabel="trans('Lastname')"
        v-model="registration.lastname"
      />
    </div>
    <!-- Email -->
    <mdl-textfield
      required
      class="block"
      autocomplete="new-password"
      :floatingLabel="trans('E-mail')"
      :error="emailError"
      error-focus
      v-model="registration.email"
      @blur="checkEmailAvailable"
    />
    <!-- Repeat email -->
    <mdl-textfield
      v-if="registration.email"
      class="block"
      autocomplete="new-password"
      :floatingLabel="trans('Repeat e-mail')"
      :error="repeatEmailError"
      error-focus
      v-model="registration.emailRepeat"
      @blur="validateRepeatEmail"
    />
    <!-- Password -->
    <mdl-textfield-password
      ref="password"
      required
      class="block"
      autocomplete="new-password"
      :floatingLabel="trans('Password')"
      :error="passwordError"
      error-focus
      v-model="registration.password"
      @error="(error) => (passwordError = error)"
    />
  </div>
</template>

<script>
export default {
  inject: ['registration'],

  data() {
    return {
      firstnameError: null,
      lastnameError: null,
      emailError: null,
      passwordError: null,
      repeatEmailError: null,
      isAvailableEmail: false,
    }
  },

  computed: {
    isInvited() {
      return this.$store.state.userInvited || this.$store.state.user?.isInvited
    },

    userInvite() {
      if (this.$store.state.userInvite) {
        return this.$store.state.userInvite.uuid
      }

      return null
    },
  },

  methods: {
    validateName() {
      this.firstnameError = !this.registration.firstname ? trans('Please enter your firstname') : null
      this.lastnameError = !this.registration.lastname ? trans('Please enter your lastname') : null
    },

    validateEmail() {
      const { email } = this.registration
      this.isAvailableEmail = false
      this.emailError = null

      if (!email) {
        this.emailError = trans('validation.custom.email.required')
      } else if (!this.validEmail(email)) {
        this.emailError = trans('validation.custom.email.email')
      }
    },

    validateRepeatEmail() {
      const { email, emailRepeat } = this.registration

      this.repeatEmailError = email !== emailRepeat ? trans('validation.custom.email.confirmed') : null
    },

    async checkEmailAvailable(e) {
      this.validateEmail()

      if (this.emailError) {
        return false
      }

      const { email } = this.registration

      this.$store.commit('loading', true)

      let user = null

      try {
        let response = await Munio.api.auth.checkEmailAvailable(email)

        if (response.status === 200) {
          user = response.data.data
          let validate = false
          if (!this.registration.firstname) {
            this.registration.firstname = user.firstname
            validate = true
          }
          if (!this.registration.lastname) {
            this.registration.lastname = user.lastname
            validate = true
          }
          validate && this.validateName()
        }

        this.isAvailableEmail = true
      } catch (err) {
        if (err.response && err.response.status === 422) {
          this.emailError =
            err.response.data.error?.message?.email?.[0] ||
            err.response.data.error?.message?.email ||
            err.response.data.errors?.email?.[0] ||
            err.response.data.message
        } else {
          this.emailError = trans('This e-mail is already registered. Please log in instead.')
        }
        this.isAvailableEmail = false
      }

      this.$store.commit('loading', false)
    },

    validEmail(email) {
      return /@/.test(email) // dont do email validation in javascript
    },

    async validate() {
      this.validateName()
      this.validateEmail()
      this.validateRepeatEmail()

      await this.checkEmailAvailable()

      return (
        this.isAvailableEmail &&
        !this.firstnameError &&
        !this.lastnameError &&
        !this.emailError &&
        !this.repeatEmailError &&
        !this.passwordError
      )
    },
  },

  mounted() {
    if (this.isInvited) {
      this.$refs.password.focus()
    } else {
      this.$refs.firstname.focus()
    }
  },
}
</script>
