<template>
  <div class="mb-8">
    <Card layout="horizontal" :title="certificate.name" class="flex">
      <MdlTextfield
        required
        class="mt-5"
        type="number"
        placeholder="012345"
        floating-label
        :disabled="loading"
        :label="trans('Certificate number')"
        v-model="identifier"
      />
      <small class="mdl-color-text--grey-600">{{ trans('Enter date of birth') }}</small>
      <div class="flex justify-between">
        <DateOfBirth class="mt-2" v-model="bornAt" />
        <MdlButton class="self-end" primary raised :disabled="isSubmitDisabled" @click="addCertificate">
          {{ trans('Add') }}
        </MdlButton>
      </div>
    </Card>
    <MdlProgressBar v-if="loading" indeterminate />
  </div>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue'
import { parseDate } from '@/munio/utils/date.js'
import Card from '@/munio/vue/components/Card.vue'
import DateOfBirth from '@/munio/vue/components/DateOfBirth.vue'
import MdlButton from '@/munio/vue/components/mdl/Button.vue'
import MdlTextfield from '@/munio/vue/components/mdl/Textfield.vue'
import MdlProgressBar from '@/munio/vue/components/mdl/ProgressBar.vue'

export default defineComponent({
  components: {
    Card,
    DateOfBirth,
    MdlButton,
    MdlTextfield,
    MdlProgressBar,
  },

  props: {
    item: Object,
    loading: Boolean,
  },

  model: {
    prop: 'item',
    event: 'change',
  },

  setup(props, { emit }) {
    const identifier = ref(props.item.identifier || '')
    const bornAt = ref(props.item.bornAt || '')
    const certificate = computed(() => ({
      ...props.item,
      identifier: identifier.value,
      bornAt: bornAt.value,
    }))

    const isSubmitDisabled = computed(() => {
      const validDate = bornAt.value && parseDate(bornAt.value)
      const validIdentifier = identifier.value.length >= 4
      return props.loading || !validDate || !validIdentifier
    })

    function addCertificate() {
      if (isSubmitDisabled.value) {
        return
      }

      emit('add', certificate.value)
    }

    watch(certificate, (value) => {
      emit('change', value)
    })

    return {
      identifier,
      bornAt,
      certificate,
      isSubmitDisabled,
      addCertificate,
    }
  },
})
</script>
