<template>
  <div
    data-upgraded="MaterialSnackbar"
    class="mdl-snackbar mdl-js-snackbar"
    :class="{ 'mdl-snackbar--active': display, 'has-action': hasAction }"
    :aria-hidden="display ? undefined : 'true'"
    :data-type="type"
    @mouseover="onMouseOver"
    @mouseout="onMouseOut"
  >
    <div class="mdl-snackbar__text" v-html="message" />

    <a v-if="typeof actionHandler === 'string'" :href="actionHandler" class="mdl-snackbar__action">{{ actionText }}</a>

    <button v-else class="mdl-snackbar__action" :class="{ 'is-active': actionHandler }" type="button" @click="onAction">
      {{ actionText }}
    </button>

    <div ref="timer" class="mdl-snackbar__timer" :class="{ 'is-active': timer }" />
  </div>
</template>

<script>
import { Timer, cssTimeToMilliseconds } from '@/munio/utils/index.js'

const ANIMATION_LENGTH = 250
const TIMEOUT_MAX = 10000
const TIMEOUT_MIN = 4000

export default {
  name: 'Snackbar',

  data() {
    return {
      animationLength: ANIMATION_LENGTH,
      display: false,
      active: null,
      timer: null,
      toasts: [],
    }
  },

  computed: {
    type() {
      return this.active?.type
    },

    message() {
      return this.active?.message || ''
    },

    hasAction() {
      const handler = this.actionHandler
      return handler !== null && handler !== undefined
    },

    actionHandler() {
      return this.active?.actionHandler
    },

    actionText() {
      return this.active?.actionText
    },

    dismissable() {
      return this.active?.dismissable
    },

    timeout() {
      return this.active?.timeout
    },
  },

  methods: {
    isBlocking(toast) {
      if (!toast || toast.dismissable) {
        return false
      }

      return toast && [null, false].includes(toast.timeout)
    },

    onMouseOver(e) {
      if (this.timer) {
        this.timer.pause()
        this.$refs.timer.style.animationPlayState = 'paused'
      }
    },

    onMouseOut(e) {
      if (this.timer) {
        this.timer.resume()
        this.$refs.timer.style.animationPlayState = 'running'
      }
    },

    onAction(e) {
      this.hide()
      const callback = this.actionHandler

      if (typeof callback === 'function') {
        callback(e)
      }
    },

    checkQueue() {
      if (this.toasts.length > 0) {
        this.show(this.toasts.shift())
      }
    },

    queue(toast) {
      let queued

      if (toast.id && (queued = this.toasts.find((queued) => queued.id === toast.id))) {
        Object.assign(queued, toast)
      } else {
        this.toasts.push(toast)
      }

      this.toasts.sort((a, b) => {
        const ab = this.isBlocking(a)
        const bb = this.isBlocking(b)

        if (ab && !bb) return 1
        if (!ab && bb) return -1
        return 0
      })
    },

    prepare(toast) {
      let {
        message,
        type = null,
        dismissable = null,
        timeout = null,
        actionHandler = null,
        actionText = null,
        id = null,
      } = toast

      if (timeout === true) {
        timeout = TIMEOUT_MIN
      }

      if (typeof timeout === 'number') {
        if (timeout > TIMEOUT_MAX) timeout = TIMEOUT_MAX
        if (timeout < TIMEOUT_MIN) timeout = TIMEOUT_MIN
      } else if (timeout === null && dismissable !== null) {
        timeout = dismissable ? TIMEOUT_MAX : TIMEOUT_MIN
      } else if (timeout === null) {
        timeout = TIMEOUT_MIN
      }

      if (dismissable && !actionHandler) {
        actionHandler = () => this.hide()
        actionText = trans('Close')
      }

      if (typeof actionHandler === 'string' && !actionText) {
        actionText = trans('Redirect')
      }

      return {
        id,
        type,
        message,
        actionHandler,
        actionText,
        dismissable,
        timeout,
      }
    },

    render(toast) {
      this.display = true
      this.active = toast

      if (toast.timeout) {
        this.timer = new Timer(this.hide, toast.timeout)
        this.$refs.timer.style.animationDuration = toast.timeout + 'ms'
      }
    },

    show(toast) {
      toast = this.prepare(toast)

      if (this.active) {
        if (toast.id && this.active.id === toast.id) {
          return this.render(toast)
        }

        if (this.isBlocking(this.active)) {
          this.toasts.unshift(this.active)
          this.toasts.unshift(toast)
          return this.hide()
        } else {
          return this.queue(toast)
        }
      }

      this.render(toast)
    },

    hide(id = null) {
      if (id !== null) {
        const isActive = this.active && this.active.id === id

        if (!isActive) {
          const queueIndex = this.toasts.find((toast) => toast.id === id)

          if (queueIndex >= 0) {
            this.toasts.splice(queueIndex, 1)
          }
          return
        }
      }

      this.display = false
      setTimeout(() => {
        this.active = null
        this.timer && this.timer.end()
        this.timer = null
        this.checkQueue()
      }, this.animationLength)
    },
  },

  mounted() {
    this.animationLength = cssTimeToMilliseconds(getComputedStyle(this.$el).transitionDuration) || this.animationLength

    if (Munio.toasts) {
      setTimeout(() => {
        Munio.toasts.forEach((toast) => this.show(toast))
      }, 500)
    }
  },
}
</script>
