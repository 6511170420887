export default {
  state: {
    loaded: false,
    zones: [],
    certificates: [],
    requirements: [],
    owner: null,
  },

  mutations: {
    setRequirements(state, payload) {
      state.requirements = payload
    },

    setZones(state, payload) {
      const userZones = payload.filter((z) => z.user.isConnected)
      state.loaded = true
      state.zones = payload
      state.certificates = userZones.reduce(
        (a, b) =>
          a.concat(
            b.certificates.map((c) => {
              return {
                ...c,
                identifier: undefined,
                bornAt: undefined,
              }
            }),
          ),
        [],
      )
    },

    setOwner(state, payload) {
      state.owner = payload
    },

    setZoneLoadingState(state, payload) {
      const zone = state.zones.find((g) => g.id === payload.id)
      if (!zone) return

      zone.loading = payload.state
    },

    setRequirementSession(state, payload) {
      const req = state.requirements.find((r) => r.id === payload.id)
      if (!req) return

      req.session = payload.session
    },
  },
}
