export default class {
  group
  id
  extension

  constructor(group, id, extension) {
    this.group = group
    this.id = id
    this.extension = extension
  }

  get params() {
    return {
      group: this.group,
      id: this.id,
      extension: this.extension,
    }
  }

  update(image) {
    const formData = new FormData()
    formData.append('image', image)
    return Munio.$http.post(Munio.route('dynimage.update', this.params), formData)
  }

  delete() {
    return Munio.$http.delete(Munio.route('dynimage.remove', this.params))
  }
}
