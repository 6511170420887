import { render, unmountComponentAtNode } from 'react-dom'
import Thunk from 'redux-thunk'
import $ from 'jquery'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'

/**
 * All our React apps should extend this one for easy instantiation.
 * Apps only need to override 'state', 'reducer' and 'component'
 */
export default class Wrapper {
  $el = {}
  $data = {}
  container = null
  app = null

  constructor(selector, props = {}) {
    this.container = contain(selector)
    this.$el = $(selector)
    this.$data = Object.assign({}, this.$el.data(), props)

    this.mount()
  }

  store() {
    const rootReducer = this.reducer()

    if (!rootReducer) {
      return false
    }

    let devtools = (f) => f

    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
      devtools = window.__REDUX_DEVTOOLS_EXTENSION__()
    } else if (window.devToolsExtension) {
      devtools = window.devToolsExtension()
    }

    const middleware = [Thunk]

    if (isDevMode) {
      // Add logger middleware
      middleware.push((store) => (next) => (action) => {
        const { server, type, ...payload } = action

        console.groupCollapsed(server ? 'Server Action:' : 'Action:', type)
        console.debug(payload)
        console.groupEnd()

        return next(action)
      })
    }

    return createStore(rootReducer, this.state() || {}, compose(applyMiddleware(...middleware), devtools))
  }

  async mount() {
    const store = await this.store()
    let app = await this.component()

    if (store) {
      app = <Provider store={store}>{app}</Provider>
    }

    render(app, this.container)
  }

  unmount() {
    this.destroy()
  }

  state() {
    return {}
  }

  reducer() {
    return null
  }

  component() {
    return <div />
  }

  destroy() {
    unmountComponentAtNode(this.container)
  }
}
