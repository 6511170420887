/**
 * Vue auto initializer
 */
Munio.behaviors.vue = {
  selector: '[v-cloak]',
  attach: function (context, config) {
    $(this.selector, context).each((i, el) => {
      const data = $(el).data('vue') || {}
      Munio.Vue({ el, data })
    })
  },
}
