import { IpRuleData } from '@/munio/api/data'

const { $http, route } = window.Munio

export default class IpRules {
  get() {
    return $http.get<IpRuleData[]>(route('api.web.munio.iprules'))
  }

  save(rule: IpRuleData) {
    return $http.post<IpRuleData>(route('api.web.munio.iprules.save'), rule)
  }

  delete(rule: IpRuleData) {
    return $http.delete(route('api.web.munio.iprules.delete', { rule: rule.id }))
  }
}
