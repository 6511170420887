<template>
  <label :class="classNames">
    <input
      type="checkbox"
      class="mdl-switch__input"
      v-on="$listeners"
      v-bind="attrs"
      :checked="checked"
      :disabled="disabled"
      @change="$emit('toggle', $event.target.checked)"
    />
    <input v-if="attrs.name && !checked && !disabled" type="hidden" :name="attrs.name" value="0" />
    <span class="mdl-switch__label">
      <slot></slot>
    </span>
  </label>
</template>

<script>
export default {
  inheritAttrs: false,

  model: {
    prop: 'checked',
    event: 'toggle',
  },

  props: {
    checked: { type: Boolean },
    disabled: { type: Boolean },
    ripple: { type: Boolean },
    right: { type: Boolean },
  },

  computed: {
    attrs() {
      const { disabled, checked, ...attrs } = this.$attrs

      return attrs
    },
    classNames() {
      return {
        'mdl-switch mdl-js-switch': true,
        'mdl-switch--right': this.right,
        'mdl-js-ripple-effect': this.ripple,
        'is-upgraded': true,
        'is-disabled': this.disabled,
      }
    },
  },

  mounted() {
    componentHandler.upgradeElement(this.$el)
    this.updateElement()
  },

  updated() {
    this.updateElement()
  },

  beforeDestroy() {
    componentHandler.downgradeElements([this.$el])
  },

  methods: {
    updateElement() {
      this.$el.MaterialSwitch[this.checked ? 'on' : 'off']()
      this.$el.MaterialSwitch[this.disabled ? 'disable' : 'enable']()
    },
  },
}
</script>
