import Backbone from '@/libs/backbone.js'
import { bindAll, template, defaults, isString, isUndefined } from 'lodash'

Munio.behaviors.modal = {
  selector: '[data-toggle="munio.modal"], [data-submit="munio.confirm"]',
  attach: function (context) {
    // Show a modal with remote content
    // or just a title and message.
    $('[data-toggle="munio.modal"]', context).each(function (i, el) {
      let $el = $(el)

      $el.on('click', function (event) {
        event.preventDefault()
        Munio.Modal.show({
          size: $el.data('size') || null,
          target: $el.data('target') || null,
          remote: $el.data('remote') || $el.attr('href') || null,
          lock: $el.data('lock') || null,
          title: $el.data('title') || '',
          content: $el.data('content') || '',
        })
      })
    })

    // Show a modal when a form
    // is submitted to confirm.
    $('[data-submit="munio.confirm"]', context).each(function (i, el) {
      let $el = $(el)

      $el.on('submit', async function (event) {
        event.preventDefault()

        if (await Munio.confirm($el.data('title'), $el.data('content'))) {
          $el.off('submit').submit()
        }
      })
    })
  },
}

/**
 * Munio modal
 */
;(function (window, Munio) {
  'use strict'

  let ModalModel = Backbone.Model.extend({
    defaults: {
      title: '',
      content: '',
      size: 'medium',
      type: 'default',
      target: null,
      remote: null,
      loading: false,
      lock: false,
      processing: false,
      data: null,
      submit: false,
      confirmed: false,
      callbackCancel: function () {},
      callbackConfirm: function (data) {},
    },
  })

  let ModalView = Backbone.View.extend({
    model: null,
    template: null,

    $content: null,
    $target: null,
    $loading: null,
    $form: null,
    $cancel: null,
    $confirm: null,

    initialize: function (options) {
      bindAll(
        this,
        'show',
        'hide',
        'onHiding',
        'onHidden',
        'onShow',
        'onShown',
        'handleCancel',
        'handleConfirm',
        'onSubmitClick',
        'onFormSubmit',
        'onExternalLoaded',
      )

      this.template = template(this.$('#modal-template').html())

      this.model = new ModalModel()

      this.model.bind('change:loading', this.setLoading, this)
      this.model.bind('change:size', this.setSize, this)
      this.model.bind('change:type', this.setType, this)
      this.model.bind('change:processing', this.setProcessing, this)

      this.$content = this.$('.modal-content')

      this.$el.on('hide.bs.modal', this.onHiding)
      this.$el.on('hidden.bs.modal', this.onHidden)
      this.$el.on('show.bs.modal', this.onShow)
      this.$el.on('shown.bs.modal', this.onShown)
      this.$el.on('loaded.bs.modal', this.onExternalLoaded)
    },

    show: function (options) {
      options = defaults(options, this.model.defaults)

      if (!options.remote) {
        this.$content.html(this.template(options))
        this.$cancel = $('.modal-cancel, .close', this.$content)
        this.$confirm = $('.modal-confirm', this.$content)
        this.$cancel.on('click', this.handleCancel)
        this.$confirm.on('click', this.handleConfirm)

        Munio.attachBehaviors(this.$content)
      } else {
        // show a progress bar if loading remote content
        this.$progress = $(`
          <div class="mdl-progress-container p-8">
            <div class="mdl-progress mdl-js-progress mdl-progress__indeterminate"></div>
          </div>
        `)
        this.$content.html(this.$progress)
      }

      if (options.target) {
        this.$target = $(options.target)
      }

      this.model.set(options)

      this.$el.modal({
        backdrop: options.lock === true ? 'static' : true,
        keyboard: !options.lock,
        remote: options.remote,
      })
    },

    onShow: function (event) {
      if (this.$progress) {
        componentHandler.upgradeElement(this.$progress.find('.mdl-progress').get(0))
      }
    },

    /**
     * Attach behaviors to loaded content and add a
     * submit handler if it contains a form. Focus
     * the first form element.
     */
    onExternalLoaded: function (event) {
      Munio.attachBehaviors(this.$content)
      let $form = this.$content.find('form')
      if ($form.length) {
        this.model.set({
          form: true,
        })
        this.$form = $form
        this.$form.submit($form.data('noajax') ? null : this.onFormSubmit)
        this.$form.find(':submit').on('click', $form.data('noajax') ? null : this.onSubmitClick)
        this.$cancel = $('[data-dismiss="modal"], .close', this.$content)
        this.$confirm = $('button[type="submit"]', this.$content)
      }
    },

    /**
     * Just hide the modal.
     */
    hide: function () {
      this.$el.modal('hide')
    },

    onHiding: function (event) {
      if (this.model.get('processing') === true) {
        if (confirm(this.$form.data('cancelUploadMessage') || '')) {
          return
        }
        event.preventDefault()
        event.stopPropagation()
      }
    },

    /**
     * Run the cancel callback if the modal is not
     * confirmed or run the confirm callback with
     * data results. Then reset the model and
     * remove all elements in the modal content.
     * And remove bs.modal data to make sure new
     * remote content is loaded next time the modal
     * is opening.
     */
    onHidden: function (event) {
      let data = this.model.get('data')

      if (!this.model.get('confirmed') && this.model.get('callbackCancel')) {
        this.model.get('callbackCancel').call(this)
      } else {
        this.model.get('callbackConfirm').call(this, data)
      }

      if (data) {
        // Set target content if target is defined.
        if (this.$target && this.$target.length && isString(data.content)) {
          this.$target.html(data.content)
          Munio.attachBehaviors(this.$target)
        }
      }

      // Cleanup.
      this.model.clear().set(this.model.defaults)
      this.$form = null
      this.$cancel = null
      this.$confirm = null
      this.$target = null
      this.$progress = null
      this.$content.empty()
      this.$el.removeData('bs.modal')
    },

    onShown: function (event) {},

    /**
     * Set the size of the modal.
     */
    setSize: function (model, size) {
      this.$el.find('.modal-dialog').toggleClass('modal-sm', size == 'small')
      this.$el.find('.modal-dialog').toggleClass('modal-md', size == 'medium')
      this.$el.find('.modal-dialog').toggleClass('modal-lg', size == 'large')
      this.$el.find('.modal-dialog').toggleClass('modal-fullscreen', size == 'fullscreen')
    },

    /**
     * Set modal type.
     */
    setType: function (model, type) {
      this.$el.find('.modal-dialog').toggleClass('modal-default', type == 'default')
      this.$el.find('.modal-dialog').toggleClass('modal-success', type == 'success')
      this.$el.find('.modal-dialog').toggleClass('modal-warning', type == 'warning')
      this.$el.find('.modal-dialog').toggleClass('modal-danger', type == 'danger')
      this.$el.find('.modal-dialog').toggleClass('modal-info', type == 'info')
    },

    /**
     * Set the size of the modal.
     */
    setProcessing: function (model, processing) {
      if (this.$confirm) this.$confirm.toggleClass('processing', processing === true)
    },

    /**
     * Hide the modal on cancel. The Bootstrap hide
     * event will take care of the rest.
     */
    handleCancel: function (event) {
      this.hide()
    },

    handleConfirm: function (event) {
      if (this.model.get('processing')) {
        return
      }
      if (this.model.get('form')) {
        this.onFormSubmit(event)
        return
      }
      // this.model.get('callbackConfirm').call(this);
      this.model.set({
        confirmed: true,
        processing: false,
      })
      this.hide()
    },

    onSubmitClick: function (event) {
      let $form = this.$content.find('form:visible')
      let $button = $(event.currentTarget)
      let name = $button.attr('name')
      let value = $button.attr('value')

      // Remove old button values.
      $form.find('.button-pressed').remove()

      // Add clicked button value.
      $form.append('<input class="button-pressed" type="hidden" name="' + name + '" value="' + value + '">')
    },

    onFormSubmit: function (event) {
      event.preventDefault()

      if (this.model.get('processing')) {
        return
      }

      this.model.set({
        processing: true,
      })

      let $form = this.$content.find('form:visible')

      this.$confirm.addClass('processing')

      $.post($form.attr('action'), $form.serialize()).fail(function (data) {
        console.log(data)
      })
    },
  })

  Munio.Modal = new ModalView({
    el: '#modal',
  })
})(window, window.Munio)
