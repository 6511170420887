import $ from 'jquery'

Munio.behaviors.checkboxAutofill = {
  selector: '[data-autofill][data-autofill-target]',
  attach: function (context) {
    $(this.selector, context).each(function () {
      const $source = $($(this).data('autofill'), this)
      const $target = $($(this).data('autofillTarget'), this)
      const value = $(this).data('autofill-value')
      let oldValue = $target.val()

      $source.change(function () {
        if (this.checked) {
          oldValue = $target.val()

          $target.val(value).prop('disabled', true).trigger('change')
        } else {
          $target.val(oldValue).prop('disabled', false).trigger('change')
        }
      })
    })
  },
}
