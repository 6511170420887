export default class {
  gateId
  gateUserId

  constructor(gateId, gateUserId) {
    this.gateId = gateId
    this.gateUserId = gateUserId
  }

  get params() {
    return {
      gate: this.gateId,
      user: this.gateUserId,
    }
  }

  status() {
    return Munio.$http.get(Munio.route('api.web.access.gate.user.status', this.params))
  }

  setInternal(value) {
    return Munio.$http.put(
      Munio.route('api.web.access.gate.user.internal', { ...this.params, internal: value ? 1 : 0 }),
    )
  }

  setCompany(company) {
    return Munio.$http.put(Munio.route('api.web.access.gate.user.company', this.params), company)
  }

  setResponsible(responsibleId) {
    return Munio.$http.put(
      Munio.route('api.web.access.gate.user.responsible', { ...this.params, responsible: responsibleId }),
    )
  }

  unsetResponsible() {
    return Munio.$http.delete(Munio.route('api.web.access.gate.user.responsible.unset', this.params))
  }

  setDefaultCard(cardId) {
    return Munio.$http.put(Munio.route('api.web.access.gate.user.card', { ...this.params, card: cardId }))
  }
}
