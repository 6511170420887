class CurrentUserAccess {
  get basePath() {
    return '/api/user/access'
  }

  get() {
    return Munio.$http.get(this.basePath)
  }

  company(companyId) {
    return Munio.$http.get(`${this.basePath}/company/${companyId}`)
  }

  register(companyId, zoneId) {
    return Munio.$http.post(`${this.basePath}/company/${companyId}/${zoneId}`)
  }

  unregister(companyId, zoneId) {
    return Munio.$http.delete(`${this.basePath}/company/${companyId}/${zoneId}`)
  }

  syncRegistrations(companyId, zoneIds) {
    return Munio.$http.post(Munio.route('api.web.user.access.enrollment', { company: companyId }), { zoneIds })
  }

  browseCompanies(query = null) {
    return Munio.$http.get(`${this.basePath}/browse`, query ? { q: query } : undefined)
  }

  browseZones(companyId) {
    return Munio.$http.get(`${this.basePath}/browse/${companyId}`)
  }

  checkout(requirementId) {
    return Munio.$http.post(`${this.basePath}/checkout/${requirementId}`)
  }

  purchase(requirementId, invoiceDetails) {
    let { email, reference, address } = invoiceDetails
    return Munio.$http.post(`${this.basePath}/checkout/${requirementId}/confirm`, { email, reference, address })
  }
}

export default CurrentUserAccess
