import { computed, ref, Ref, isRef } from 'vue'
import { amount, parse } from '@/munio/currency'

type PriceType = Ref<string | number | null> | string | number | null

export function useDiscount(inputPrice?: PriceType, inputPriceFull?: PriceType) {
  const price = isRef(inputPrice) ? inputPrice : ref(inputPrice)
  const priceFull = isRef(inputPriceFull) ? inputPriceFull : ref(inputPriceFull)

  const discount = computed(() => {
    if (price.value === null || price.value === undefined || !priceFull.value) {
      return 0
    }

    const priceFullAmount = amount(priceFull.value)
    const priceAmount = amount(price.value)

    if (priceAmount.lessThan(priceFullAmount)) {
      return priceFullAmount.subtract(priceAmount).toUnit()
    }

    return 0
  })

  const percent = computed(() => {
    if (price.value === null || price.value === undefined || !priceFull.value) {
      return 0
    }

    if (discount.value) {
      return Math.round(amount(discount.value).divide(parse(priceFull.value), 'HALF_UP').multiply(100).toUnit())
    }

    return 0
  })

  return {
    price: price,
    priceFull: priceFull,
    discount,
    percent,
  }
}
