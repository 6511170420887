import { AnswerValue, State } from '@/munio/definitions/quiz.state'

const { route, $http } = window.Munio

export default class AttemptQuiz {
  constructor(
    public attemptId: number,
    public nodeId: number,
  ) {}

  get params() {
    return {
      attempt: this.attemptId,
      node: this.nodeId,
    }
  }

  restart() {
    return $http.post<State>(route('api.web.attempt.node.quiz.restart', this.params))
  }

  answer(question: string, answer: AnswerValue, duration: number) {
    return $http.post<State>(route('api.web.attempt.node.quiz.answer', this.params), {
      question,
      answer,
      duration,
    })
  }

  timer(duration: number) {
    const form = new FormData()
    form.set('duration', String(duration))

    navigator.sendBeacon(route('api.web.attempt.node.quiz.timer', this.params), form)
  }
}
