<template>
  <div class="panel">
    <div class="panel__header--collapsed">
      {{ title }}
    </div>
    <div class="panel__content" :class="{ 'sticky top-0': sticky }">
      <div class="panel__header">
        <span>{{ title }}</span>
        <mdl-button v-if="canClose" icon="close" small @click="$emit('close', $event)" />
      </div>

      <div class="panel__body" v-bind="$attrs">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  name: 'Panel',

  props: {
    title: { type: String },
    sticky: { type: Boolean },
  },

  computed: {
    canClose() {
      return this.$listeners.hasOwnProperty('close')
    },
  },
}
</script>
