import { toDateString } from '@/munio/utils/date.js'

class UserCourse {
  userId
  courseId

  constructor(userId, courseId) {
    this.userId = userId
    this.courseId = courseId
  }

  get params() {
    return { user: this.userId, course: this.courseId }
  }

  get() {
    return Munio.$http.get(Munio.route('api.web.admin.user.course', this.params))
  }

  document(file) {
    const form = new FormData()

    form.append('document', file, file.name)

    const url = Munio.route('api.web.admin.user.course.document', this.params)
    return Munio.$http.post(url, form, {
      'content-type': 'multipart/form-data',
    })
  }

  setDate(from, to) {
    const url = Munio.route('api.web.admin.user.course.setdate', this.params)
    return Munio.$http.post(url, {
      from: toDateString(from),
      to: toDateString(to),
    })
  }

  restart() {
    return Munio.$http.post(Munio.route('api.web.admin.user.course.restart', this.params))
  }

  cancel() {
    return Munio.$http.post(Munio.route('api.web.admin.user.course.cancel', this.params))
  }

  approve() {
    return Munio.$http.post(Munio.route('api.web.admin.user.course.approve', this.params))
  }

  reject() {
    return Munio.$http.post(Munio.route('api.web.admin.user.course.reject', this.params))
  }

  enroll(companyCourseId) {
    return Munio.$http.put(
      Munio.route('api.web.admin.user.course.enroll', { ...this.params, company_course: companyCourseId }),
    )
  }

  unenroll(companyCourseId) {
    return Munio.$http.delete(
      Munio.route('api.web.admin.user.course.unenroll', { ...this.params, company_course: companyCourseId }),
    )
  }
}

export default UserCourse
