import Backbone from '@/libs/backbone.js'

Munio.behaviors.permits = {
  selector: '#permission-roles',
  attach: function () {
    const Permits = Backbone.View.extend({
      DEVELOPER: 'developer',
      SYSTEM: 'system',
      $permits: null,
      events: {
        'change input': 'onChange',
      },

      initialize: function () {
        this.$permits = this.$('input').not('input[value="' + this.DEVELOPER + '"]')
      },

      onChange: function (event) {
        if (event.target.value == this.DEVELOPER) {
          this.$permits.prop('disabled', event.target.checked).prop('checked', event.target.checked)
        }

        if (event.target.value == this.SYSTEM) {
          this.$permits
            .not('input[value="' + this.SYSTEM + '"]')
            .prop('disabled', event.target.checked)
            .prop('checked', event.target.checked)
        }
      },
    })

    Munio.AdminUserPermits = new Permits({ el: this.selector })
  },
}
