export default class {
  constructor(gateId) {
    this.gateId = gateId
  }

  get params() {
    return { gate: this.gateId }
  }

  get(options) {
    return Munio.$http.get(Munio.route('api.web.access.gate.links', this.params), Munio.urlParams(options))
  }

  create(data) {
    return Munio.$http.post(Munio.route('api.web.access.gate.links', this.params), data)
  }
}
