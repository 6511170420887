import Wrapper from '@/munio/react/Wrapper.jsx'
import store from './store.js'

Munio.CertificateImport = class extends Wrapper {
  reducer() {
    return store
  }

  async component() {
    const App = (await import('./App.jsx')).default

    return <App />
  }
}
