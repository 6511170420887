export function openTermsAndConditions() {
  return Munio.route('lms.shop.terms').toString()
}

export function goBackToCart() {
  window.location.href = Munio.route('lms.shop.cart').toString()
}

export function getActiveStep(uuid: string) {
  return window.Munio.storage.get(`cart.${uuid}.step`)
}

export function getStepsNumber(uuid: string) {
  return window.Munio.storage.get(`cart.${uuid}.steps`)
}

export function saveActiveStep(uuid: string, step: string) {
  window.Munio.storage.set(`cart.${uuid}.step`, step)
}

export function saveStepsNumber(uuid: string, steps: number) {
  window.Munio.storage.set(`cart.${uuid}.steps`, steps)
}

export function clearLocalStorage(uuid: string) {
  window.Munio.storage.remove(`cart.${uuid}.step`)
  window.Munio.storage.remove(`cart.${uuid}.steps`)
}
