<template>
  <CourseCheckout @close="emit('close')" />
</template>

<script setup lang="ts">
import CourseCheckout from '@/munio/vue/CourseLauncher/Content/CourseCheckout.vue'
import { AuthenticatedCart } from '@/munio/api/ShopCheckout'
import { provide } from 'vue'

const props = defineProps<{
  cart: AuthenticatedCart
}>()

const emit = defineEmits<{
  (e: 'close'): void
}>()

provide('cart', props.cart)
provide('context', 'access')
</script>
