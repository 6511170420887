import Wrapper from '@/munio/vue/Wrapper.js'

Munio.UserMergeManager = class extends Wrapper {
  constructor(selection) {
    super(selection)
  }

  component() {
    return import('./App.vue')
  }
}
