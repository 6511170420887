import Wrapper from '@/munio/vue/Wrapper.js'

Munio.GateCompanyManager = class extends Wrapper {
  constructor(selector, gateId) {
    super(selector, {
      gateId,
    })
  }

  component() {
    return import('./App.vue')
  }
}
