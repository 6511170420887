<template>
  <component class="card mdl-shadow--2dp" :href="href" :is="tag" :class="cardClasses">
    <div class="card__thumbnail flex-shrink-0" :class="thumbnailClasses" v-if="showThumbnail" :style="thumbnailStyles">
      <slot name="thumbnail">
        <div class="card__thumbnail--initials" v-if="!thumbnail && initials">{{ initials }}</div>
      </slot>
    </div>

    <div class="card__content">
      <div class="card__content-label" v-if="label">
        {{ label }}
      </div>
      <div class="card__content-upper">
        <div class="card__content-suptitle">{{ suptitle }}</div>
        <div class="card__content-title">
          <slot name="title">{{ title }}</slot>
          <slot name="title-suffix">
            <small class="ml-4" v-if="titleSuffix">{{ titleSuffix }}</small>
          </slot>
        </div>

        <div class="card__content-box" v-if="$slots['default']">
          <slot />
        </div>
      </div>
      <div class="card__content-lower">
        <div class="card__footer" v-if="$slots['footer']"><slot name="footer" /></div>
        <div class="card__actions" v-if="$slots['actions']"><slot name="actions" /></div>
      </div>
    </div>
  </component>
</template>

<script>
/*
 *  TODO:
 *  - Add slot for notifications (fabs)
 *  - Style slot for actions (space-between)
 */

export default {
  props: {
    alignment: {
      type: String,
      enum: ['center', 'left'],
      default: 'left',
    },
    href: String,
    suptitle: String,
    title: String,
    titleSuffix: String,
    label: String,
    thumbnail: String,
    thumbnailShow: Boolean,
    thumbnailCover: {
      type: Boolean,
      default: true,
    },
    thumbnailSize: Number,
    initials: String,
    layout: {
      type: String,
      enum: ['vertical', 'horizontal'],
      default: 'vertical',
    },
  },
  computed: {
    tag() {
      return this.href ? 'a' : 'div'
    },
    thumbnailStyles() {
      return {
        backgroundImage: this.thumbnail ? `url(${this.thumbnail})` : null,
        width: this.thumbnailSize && this.layout === 'horizontal' ? this.thumbnailSize + 'px' : null,
        height: this.thumbnailSize && this.layout === 'vertical' ? this.thumbnailSize + 'px' : null,
      }
    },
    cardClasses() {
      return [`card--layout-${this.layout}`, `card--alignment-${this.alignment}`]
    },
    thumbnailClasses() {
      return [`card__thumbnail--size-${this.thumbnailCover ? 'cover' : 'contain'}`]
    },
    showThumbnail() {
      return this.thumbnail || this.initials || this.thumbnailShow
    },
  },
}
</script>
