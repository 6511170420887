class Entrypoints {
  constructor(private gateId: number) {}

  get params() {
    return { gate: this.gateId }
  }

  get(all: boolean = false) {
    return window.Munio.$http.get(
      Munio.route('api.web.access.gate.entrypoints', { ...this.params, all: all ? 'true' : undefined }),
    )
  }

  update(entrypointId: number, active: boolean) {
    return window.Munio.$http.put(
      Munio.route('api.web.access.gate.entrypoint.update', { ...this.params, entrypoint: entrypointId }),
      { active },
    )
  }
}

export default Entrypoints
