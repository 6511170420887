<template>
  <div ref="spinner" :class="classes"></div>
</template>

<script>
export default {
  props: {
    active: { type: Boolean, default: true },
    multicolor: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
  },

  computed: {
    classes() {
      return {
        'mdl-spinner': true,
        'mdl-js-spinner': true,
        'mdl-spinner--single-color': !this.multicolor,
        'mdl-spinner--small': this.small,
        'is-active': this.active,
        'is-upgraded': true,
      }
    },
  },

  mounted() {
    componentHandler.upgradeElement(this.$refs.spinner)
  },

  beforeDestroy() {
    componentHandler.downgradeElements(this.$refs.spinner)
  },
}
</script>
