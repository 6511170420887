<template>
  <div>
    <div v-for="attachment in attachments" :key="attachment.url" class="flex items-center py-4">
      <icon class="small opaque mr-4" :name="attachment.isLink ? 'link' : 'attachment'" />
      <a :href="attachment.url" target="_blank" rel="tooltip" :title="getTooltip(attachment)">
        {{ attachment.title || attachment.name }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Attachments',

  props: {
    attachments: { type: Array, required: true },
  },

  mounted() {
    Munio.attachBehaviors(this.$el)
  },

  methods: {
    getTooltip(attachment) {
      if (attachment.isLink) {
        return undefined
      }

      return trans('File size') + `: ${attachment.sizeHuman}`
    },
  },
}
</script>
