import Wrapper from '@/munio/vue/Wrapper.js'
import Filters from '@/munio/vue/components/Toolbar/Filters.vue'
import CSV from '@/munio/vue/components/Toolbar/CSV.vue'

Munio.Toolbar = {
  Filters: class extends Wrapper {
    constructor(selector, options) {
      super(selector, { options })
    }

    get name() {
      return 'LegacyFilters'
    }

    component() {
      return Filters
    }
  },

  CSV: class extends Wrapper {
    constructor(selector, baseUrl, options) {
      super(selector, { loading: false, csv: options.csv, baseUrl })

      Munio.Events.$on('csv-export', async (settings) => {
        try {
          const url = baseUrl || window.location.protocol + '//' + window.location.hostname + window.location.pathname
          const params = Munio.urlParamsString(window.location.search)
          this.root.loading = true
          // this.app.$props.loading = true
          await Munio.downloadCSV(url, { ...settings }, params)
        } catch (err) {
          console.error(err)
        } finally {
          this.root.loading = false
        }
      })
    }

    get name() {
      return 'LegacyCSV'
    }

    component() {
      return CSV
    }
  },
}
