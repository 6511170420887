const state = {
  loading: false,
  filters: Munio.state.filters,
  meta: null,
  roles: [],
  role: null,
  roleTab: null,
  roleEdit: {},
  courses: [],
}

function getDefaultRoleStruct(role, activeTab = 'settings') {
  return {
    ...role,
    users: {
      list: [],
      filters: null,
      meta: null,
      loaded: false,
    },
    query: '',
    activeTab,
  }
}

const actions = {
  create: async function ({ commit }, title) {
    try {
      commit('SET_LOADING', true)
      const { data } = await Munio.api.roles.create(title)
      commit('ADD_CREATED_ROLE', data.data)
    } catch (err) {
      console.error(err)
    } finally {
      commit('SET_LOADING', false)
    }
  },

  loadRoles: async function ({ commit }, queryString = null) {
    try {
      commit('SET_LOADING', true)
      const { data } = await Munio.api.roles.get(queryString)
      commit('SET_ROLES_RESPONSE', data)
    } catch (err) {
      console.error(err)
    } finally {
      commit('SET_LOADING', false)
    }
  },

  loadRole: async function ({ commit }, roleId) {
    try {
      commit('SET_LOADING', roleId)
      const { data } = await Munio.api.role(roleId).get()
      commit('UPDATE_ROLE', data.data)
    } catch (err) {
      console.error(err)
    } finally {
      commit('SET_LOADING', false)
    }
  },

  loadCourses: async function ({ state, commit }) {
    try {
      commit('SET_LOADING', 'courses')
      const { data } = await window.Munio.api.courses.get()
      commit('SET_COURSES_RESPONSE', data)
    } catch (err) {
      console.error(err)
    } finally {
      commit('SET_LOADING', false)
    }
  },

  addCourses: async function ({ state, commit }, courseIds) {
    const roleId = state.role.id

    try {
      commit('SET_LOADING', 'courses')
      const { data } = await Munio.api.role(roleId).courses.add(courseIds)
      commit('UPDATE_ROLE', data.data)
    } finally {
      commit('SET_LOADING', false)
    }
  },

  removeCourses: async function ({ state, commit }, courseIds) {
    const roleId = state.role.id

    try {
      commit('SET_LOADING', 'courses')
      const { data } = await Munio.api.role(roleId).courses.remove(courseIds)
      commit('UPDATE_ROLE', data.data)
    } finally {
      commit('SET_LOADING', false)
    }
  },

  loadUsers: async function ({ state, commit }, query) {
    const roleId = state.role.id

    try {
      commit('SET_LOADING', roleId)
      const { data } = await Munio.api.role(roleId).users.get(query)
      commit('SET_USERS_QUERY', query)
      commit('SET_USERS_RESPONSE', data)
    } catch (err) {
      console.error(err)
    } finally {
      commit('SET_LOADING', false)
    }
  },

  addUsers: async function ({ state, commit }, users) {
    const roleId = state.role.id

    try {
      commit('SET_LOADING', roleId)
      const {
        data: { role, ...data },
      } = await Munio.api.role(roleId).users.add(users)
      commit('UPDATE_ROLE', role)
      commit('SET_USERS_RESPONSE', data)
    } catch (err) {
      console.error(err)
    } finally {
      commit('SET_LOADING', false)
    }
  },

  removeUsers: async function ({ state, commit }, users) {
    const roleId = state.role.id

    try {
      commit('SET_LOADING', roleId)
      const {
        data: { role, ...data },
      } = await Munio.api.role(roleId).users.remove(users)
      commit('UPDATE_ROLE', role)
      commit('SET_USERS_RESPONSE', data)
    } catch (err) {
      console.error(err)
    } finally {
      commit('SET_LOADING', false)
    }
  },

  saveSettings: async function ({ state, commit }, form) {
    const roleId = state.role.id

    try {
      commit('SET_LOADING', roleId)
      const { data } = await Munio.api.role(roleId).update(form)
      commit('UPDATE_ROLE', data.data)
    } catch (err) {
      console.error(err)
    } finally {
      commit('SET_LOADING', false)
    }
  },

  delete: async function ({ state, commit }) {
    const roleId = state.role.id

    try {
      commit('SET_LOADING', roleId)
      await Munio.api.role(roleId).delete()
      commit('REMOVE_ROLE', roleId)
    } catch (err) {
      console.error(err)
    } finally {
      commit('SET_LOADING', false)
    }
  },
}

const mutations = {
  SET_LOADING: function (state, value) {
    state.loading = value
  },

  SET_ROLE: function (state, role) {
    state.role = role
    if (role) {
      state.role.activeTab = state.role.activeTab ?? 'users'
    }
  },

  SET_ROLE_TAB: function (state, tab) {
    state.role.activeTab = tab
  },

  ADD_CREATED_ROLE: function (state, role) {
    const newRole = getDefaultRoleStruct(role, 'settings')

    state.roles = [newRole, ...state.roles]
    state.role = newRole
  },

  UPDATE_ROLE: function (state, role) {
    const roleIndex = state.roles.findIndex((r) => r.id === role.id)
    const prevRole = state.roles[roleIndex]

    const updatedRole = {
      ...role,
      users: {
        ...prevRole.users,
        // force a user index reload next time the users tab is selected
        loaded: prevRole.users.loaded && prevRole.updatedAt === role.updatedAt,
      },
      query: prevRole.query,
      activeTab: prevRole.activeTab,
    }

    state.role = updatedRole
    state.roles = [...state.roles.slice(0, roleIndex), updatedRole, ...state.roles.slice(roleIndex + 1)]
  },

  REMOVE_ROLE: function (state, roleId) {
    const roleIndex = state.roles.findIndex((r) => r.id === roleId)
    state.role = null
    state.roles = [...state.roles.slice(0, roleIndex), ...state.roles.slice(roleIndex + 1)]
  },

  SET_ROLES_RESPONSE: function (state, { data, filters, meta }) {
    state.roles = data.map((role) => getDefaultRoleStruct(role, 'users'))
    state.filters = filters
    state.meta = meta
  },

  SET_COURSES_RESPONSE: function (state, { data }) {
    state.courses = data.map((companyCourse) => companyCourse.course)
  },

  SET_USERS_QUERY: function (state, query) {
    state.role.query = Munio.urlParamsString(query)
  },

  SET_USERS_RESPONSE: function (state, { data, filters, meta }) {
    state.role.users = {
      list: data,
      filters,
      meta,
      loaded: true,
    }
  },
}

export default {
  state,
  actions,
  mutations,
}
