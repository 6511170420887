export default class Requirements {
  constructor(zoneId) {
    this.zoneId = zoneId
  }

  get params() {
    return { zone: this.zoneId }
  }

  get() {
    return Munio.$http.get(Munio.route('api.web.access.zone.requirements', this.params))
  }

  addCourse(course) {
    return Munio.$http.post(Munio.route('api.web.access.zone.requirements.course.add', { ...this.params, course }))
  }

  addCourseModule(course, module) {
    return Munio.$http.post(
      Munio.route('api.web.access.zone.requirements.course.module.add', { ...this.params, course, object: module }),
    )
  }

  removeCourse(course) {
    return Munio.$http.delete(Munio.route('api.web.access.zone.requirements.course.remove', { ...this.params, course }))
  }

  removeCourseModule(course, module) {
    return Munio.$http.delete(
      Munio.route('api.web.access.zone.requirements.course.module.remove', { ...this.params, course, object: module }),
    )
  }
}
