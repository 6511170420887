export default class Entrypoints {
  constructor(zoneId) {
    this.zoneId = zoneId
  }

  get params() {
    return { zone: this.zoneId }
  }

  get() {
    return Munio.$http.get(Munio.route('api.web.access.zone.entrypoints', this.params))
  }

  set(entrypointIds) {
    return Munio.$http.put(Munio.route('api.web.access.zone.entrypoints.set', this.params), {
      entrypoints: entrypointIds,
    })
  }
}
