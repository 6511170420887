export default {
  PROCESSING(state, value) {
    state.processing = value ? value?.id || true : false
  },

  RESET(state, data) {
    if (!data) {
      state.initialized = true
      return
    }

    const {
      data: { relationships, selectableCompanies, relationshipSelected },
      permissions: { canChangeCompany, canEdit },
    } = data
    const employerRelationshipIndex = relationships?.findIndex((relationship) => relationship.isEmployer) || 0

    state.initialized = true
    state.canChangeCompany = canChangeCompany
    state.canEdit = canEdit

    if (employerRelationshipIndex >= 0) {
      state.relationships = [
        relationships[employerRelationshipIndex],
        ...relationships.slice(0, employerRelationshipIndex),
        ...relationships.slice(employerRelationshipIndex + 1),
      ]
    } else {
      state.relationships = relationships
    }

    state.selectableCompanies = selectableCompanies

    if (!state.selected || relationshipSelected) {
      state.selected = relationshipSelected || relationships[employerRelationshipIndex]?.id || null
    }
  },

  TOGGLE_RELATIONSHIP(state, relationship) {
    state.selected = state.selected !== relationship.id ? relationship.id : null
  },
}
