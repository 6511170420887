import { cloneDeep } from 'lodash'
import { mapArray } from '@/munio/utils/index.js'

export function sortZones(zones) {
  return [...zones].sort((a, b) =>
    (a.company.name + a.name).localeCompare(b.company.name + b.name, Munio.config.i18n.language),
  )
}

export function groupZonesByCompany(zones) {
  const map = {}

  return zones.reduce(function (acc, zone) {
    let index = map[zone.company.id]

    if (index === undefined) {
      index =
        acc.push({
          ...zone.company,
          zones: [],
        }) - 1

      map[zone.company.id] = index
    }

    acc[index].zones.push(zone)

    return acc
  }, [])
}

const state = {
  gateId: null,
  loading: false,
  paginating: false,
  processing: false,
  creating: false,
  selected: null,
  links: {
    data: [],
    filters: {},
    gate: {},
  },
  zones: {
    data: [],
    map: {},
  },
}

const actions = {
  async init({ commit, dispatch }, gateId) {
    commit('SET_GATE', gateId)
  },

  async paginate({ commit, dispatch }, page) {
    const queryString = Munio.urlParams()
    queryString.set('page', page)
    try {
      commit('SET_PAGINATING', true)
      dispatch('fetchAll', queryString)
    } catch (err) {
      // ignore
    } finally {
      commit('SET_PAGINATING', false)
    }
  },

  async fetchAll({ state, commit }, queryString) {
    try {
      commit('SET_LOADING', true)
      const { data } = await Munio.api.access.gate(state.gateId).links.get(queryString)
      commit('SET_LINKS', data)
    } catch (err) {
      console.error(err.stack)
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async fetchZones({ state, commit }) {
    try {
      commit('SET_LOADING', 'zones')
      const { data } = await Munio.api.access.gate(state.gateId).zones()
      commit('SET_ZONES', data)
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async save({ state, commit, getters, dispatch }, { id, link }) {
    try {
      const model = getters.getLink(id)
      const gateId = model?.gate?.id || state.gateId
      commit('SET_PROCESSING', id)
      if (id === 'create') {
        const { data } = await Munio.api.access.gate(gateId).links.create(link)
        commit('INSERT_LINK', data.data)
      } else {
        const { data } = await Munio.api.access.gate(gateId).link(id).update(link)
        commit('UPDATE_LINK', data.data)
      }
    } finally {
      commit('SET_PROCESSING', false)
    }
  },

  async delete({ state, commit, getters }, id) {
    if (await Munio.confirm()) {
      try {
        const model = getters.getLink(id)
        const gateId = model.gate?.id || state.gateId
        commit('SET_PROCESSING', id)
        await Munio.api.access.gate(gateId).link(id).delete()
        commit('DELETE_LINK', id)
      } finally {
        commit('SET_PROCESSING', false)
      }
    }
  },
}

const mutations = {
  SET_GATE(state, gateId) {
    state.gateId = gateId
  },
  SET_LOADING(state, value) {
    state.loading = value
  },
  SET_PAGINATING(state, value) {
    state.paginating = value
  },
  SET_PROCESSING(state, value) {
    state.processing = value
  },
  SET_CREATING(state, value) {
    state.creating = value
    state.selected = null
  },
  SET_LINKS(state, data) {
    state.links = data
  },
  SET_ZONES(state, data) {
    state.zones = data
    state.zonesMap = mapArray(data?.data || [], (zone) => zone.id)
  },
  UPDATE_LINK(state, data) {
    const index = state.links.data.findIndex((link) => link.id === data.id)
    const before = state.links.data.slice(0, index)
    const after = state.links.data.slice(index + 1)

    state.links.data = [...before, data, ...after]
  },
  INSERT_LINK(state, data) {
    state.links.data = [data, ...state.links.data]
    state.creating = false
  },
  DELETE_LINK(state, id) {
    state.links.data = state.links.data.filter((link) => link.id !== id)
  },
  TOGGLE_LINK(state, id) {
    state.selected = state.selected === id ? null : id
  },
}

const getters = {
  getLink: (state) => (id) => {
    return state.links.data.find((link) => link.id === id)
  },

  getForm: (state) => (link) => {
    const form = {
      slug: '',
      title: {},
      message: {},
      responsible: null,
      zones: [],
      zonesRemoved: [],
    }

    if (link) {
      Object.assign(
        form,
        cloneDeep({
          slug: link.slug,
          title: link.titleI18n,
          message: link.messageI18n,
          responsible: link.responsible?.id,
          zones: link.zones.map((zone) => zone.id),
        }),
      )
    }

    return form
  },

  getZone: (state) => (linkZones, zoneId) => {
    let zone = (linkZones || []).find((z) => z.id === zoneId)

    if (!zone && state.zonesMap[zoneId] !== undefined) {
      zone = state.zones.data[state.zonesMap[zoneId]]
    }

    return zone
  },

  sortZones: () => sortZones,
  groupZonesByCompany: () => groupZonesByCompany,
}

export default {
  state,
  actions,
  mutations,
  getters,
}
