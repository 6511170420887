import Vue from 'vue'
import Vuex from 'vuex'
import { PiniaVuePlugin } from 'pinia'
import VueRouter from 'vue-router'
import VueOutsideEvents from 'vue-outside-events'
import '@/munio/vue/CourseBuilder/assets/course-styles.css'

// Directives
import Autofocus from './directives/autofocus.js'
import Tooltip from './directives/tooltip.js'

// Configuration
Vue.config.devtools = true

// Plugins
Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VueOutsideEvents)
Vue.use(PiniaVuePlugin)

// Directives
Vue.directive('autofocus', Autofocus)
Vue.directive('tooltip', Tooltip)

// Filters

Vue.mixin({
  methods: {
    can: window.can,
    implode: window.implode,
    route: Munio.route,
    trans: window.trans,
    trans_choice: window.trans_choice,
    flag: Munio.flag,
    pendo(label) {
      return `${window.Munio.config.route.name}.${label}`
    },
    fullname(user) {
      if (user.firstname || user.lastname) {
        return implode(' ', [user.firstname, user.lastname])
      }

      return user.email
    },
  },
})

Munio.Vue = function (options = {}) {
  options.data = Object.assign(
    {},
    {
      config: this.config,
      debug: this.config.debug,
      user: this.config.user,
      company: this.config.company,
      account: this.config.account,
      ...this.state,
    },
    options.data,
  )

  return new Vue(options)
}

window.Vue = Vue

export default Vue
