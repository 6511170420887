import Wrapper from '@/munio/vue/Wrapper.js'
import Snackbar from './Snackbar.vue'

Munio.Notifications = new (class extends Wrapper {
  get name() {
    return 'Notifications'
  }

  component() {
    return Snackbar
  }

  show(toast) {
    this.app.show(toast)
  }

  error(message, options) {
    this.app.show({
      ...options,
      message,
      type: 'danger',
    })
  }

  hide(id = null) {
    this.app.hide(id)
  }
})('#notifications')
