import { combineReducers } from 'redux'
import { forEach, find } from 'lodash'
import * as actions from '../actions/index.js'
import attempts, { getAttempt } from './attempt.js'
import nodes from './node.js'

const INITIAL_AUTH_STATE = {
  selected: false,
  state: null,
}

const preview = (state = false, action) => state

const backUrl = (state = '', action) => state

const selectLanguage = (state = false, action) => {
  switch (action.type) {
    case actions.ATTEMPT_UPDATE:
      if (action.main && action.attempt.language === null) {
        return true
      }

      return state

    case actions.LANGUAGE_SELECT:
    case actions.LANGUAGE_UPDATED:
      return false

    default:
      return state
  }
}

const showSidebar = (state = false, action) => {
  switch (action.type) {
    case actions.SIDEBAR_TOGGLE:
      return !state

    case actions.NODE_PLAY:
    case actions.SCORM_PLAY:
      return false

    default:
      return state
  }
}

const initialized = (state = false, action) => {
  switch (action.type) {
    case actions.LANGUAGE_SELECT:
      return false

    case actions.LANGUAGE_UPDATED:
    case actions.ATTEMPT_INITIALIZED:
      return true

    default:
      return state
  }
}

const acceptedViewMode = (state = false, action) => {
  switch (action.type) {
    case actions.ACCEPT_VIEWMODE:
      return true

    default:
      return state
  }
}

const connection = (state = { previous: '', current: '' }, action) => {
  switch (action.type) {
    case actions.CONNECTION_STATE:
      return action.state

    default:
      return state
  }
}

const conditions = (state = [], action) => {
  switch (action.type) {
    case actions.ATTEMPT_UPDATE:
      return action.main ? action.attempt.conditions : state

    case actions.CONDITIONS_SUBMIT:
      let newConditions = []

      forEach(state, (condition) => {
        if (condition.hasOwnProperty('error')) {
          if (condition.type === action.key) {
            delete condition['error']
          }
        }

        newConditions.push(condition)
      })

      return newConditions

    case actions.CONDITIONS_UPDATE:
      if (action.value === 'skip') {
        return state
      }

      return state.map((condition) => {
        return condition.type === action.key ? { type: action.key, value: action.value } : condition
      })

    case actions.CONDITIONS_FAILED:
      if (action.error) {
        return state.map((condition) => {
          return condition.type === action.key ? { ...condition, error: action.error } : condition
        })
      }

      return state

    default:
      return state
  }
}

const conditionsSkipped = (state = [], action) => {
  switch (action.type) {
    case actions.CONDITIONS_UPDATE:
      if (action.value === 'skip') {
        return [...state, action.key]
      }

      return state

    default:
      return state
  }
}

const authentication = (state = null, action) => {
  switch (action.type) {
    case actions.CONDITIONS_SUBMIT:
      if (action.key === 'auth') {
        return Object.assign({}, INITIAL_AUTH_STATE, state, { selected: true })
      }
      return state

    case actions.CONDITIONS_UPDATE:
      return action.key === 'auth' ? Object.assign({}, INITIAL_AUTH_STATE, state, { state: action.value }) : state

    case actions.AUTH_UPDATE:
      return Object.assign({}, INITIAL_AUTH_STATE, state, { state: { request: action.auth } })

    case actions.ATTEMPT_UPDATE:
      if (action.main) {
        const authCondition = find(action.attempt.conditions, { type: 'auth' })

        if (authCondition) {
          return Object.assign({}, INITIAL_AUTH_STATE, state, {
            selected: authCondition.value.request !== undefined,
            state: authCondition.value,
          })
        } else {
          return null
        }
      }

      return state

    default:
      return state
  }
}

const processing = (state = false, action) => {
  switch (action.type) {
    case actions.CONDITIONS_SUBMIT:
      return true

    case actions.ATTEMPT_UPDATE:
    case actions.CONDITIONS_UPDATE:
    case actions.CONDITIONS_FAILED:
      return false

    default:
      return state
  }
}

const viewDiploma = (state = 0, action) => {
  switch (action.type) {
    case actions.DIPLOMA_SHOW:
      return action.id

    case actions.NODE_PLAY:
      return 0

    default:
      return state
  }
}

const userId = (state = 0, action) => state

const user = (state = {}, action) => {
  switch (action.type) {
    case actions.ATTEMPT_UPDATE:
      return action.attempt.user

    default:
      return state
  }
}

const mainAttemptId = (state = 0, action) => state

const currentAttemptId = (state = 0, action) => {
  switch (action.type) {
    case actions.ATTEMPT_PLAY:
      return action.attempt.id

    case actions.NODE_PLAY:
      return action.attemptId

    default:
      return state
  }
}

const currentNodeId = (state = 0, action) => {
  switch (action.type) {
    case actions.ATTEMPT_PLAY:
      return action.attempt.currentNodeId || state

    case actions.NODE_PLAY:
      return action.id

    case actions.DIPLOMA_SHOW:
      return 0

    default:
      return state
  }
}

const scormItem = (state = null, action) => {
  switch (action.type) {
    case actions.SCORM_PLAY:
      return action.item

    case actions.SCORM_EXIT:
    case actions.SCORM_FINISH:
    case actions.NODE_PLAY:
      return null

    case actions.NODE_PLAYBACK:
      if (action.playback.type === 'scorm') {
        return action.playback.model.item
      }
      return null
  }

  return state
}

const scormReady = (state = false, action) => {
  switch (action.type) {
    case actions.SCORM_READY:
      return action.state

    case actions.NODE_PLAY:
    case actions.SCORM_PLAY:
      return false
  }

  return state
}

const scormIndex = (state = false, action) => {
  switch (action.type) {
    case actions.SCORM_EXIT:
    case actions.SCORM_FINISH:
      return true

    case actions.NODE_PLAY:
    case actions.SCORM_PLAY:
      return false

    case actions.NODE_PLAYBACK:
      if (action.playback.type === 'scorm') {
        return action.playback.model.manifest.items.length > 1
      }
      return false
  }

  return state
}

const scormModel = (state = null, action) => {
  switch (action.type) {
    case actions.SCORM_UPDATE:
      return action.data

    case actions.NODE_PLAYBACK:
      if (action.playback.type === 'scorm') {
        return action.playback.model
      }
      return null
  }

  return state
}

export default combineReducers({
  initialized,
  acceptedViewMode,
  showSidebar,
  connection,
  authentication,
  conditions,
  conditionsSkipped,
  preview,
  backUrl,
  selectLanguage,
  processing,
  viewDiploma,
  userId,
  user,
  mainAttemptId,
  currentAttemptId,
  currentNodeId,
  attempts,
  nodes,
  scorm: combineReducers({
    ready: scormReady,
    index: scormIndex,
    item: scormItem,
    model: scormModel,
  }),
})

//
// Selectors
//

export function getDefaultLanguage(state) {
  return getAttempt(state, state.mainAttemptId).language
}

export function getLanguages(state) {
  return getAttempt(state, state.mainAttemptId).languages
}

export function isAuthenticating(state) {
  const auth = state.authentication

  if (!auth) {
    return false
  }

  if (!auth.state) {
    return false
  }

  if (auth.state.request?.status === 'confirm') {
    return true
  }

  return auth.selected
}
