<template>
  <div class="mdl-filter__form" @click="$emit('activate', $event)">
    <div
      v-for="option of options"
      :key="option.value"
      class="mdl-filter__option"
      :class="{ 'is-disabled': option.disabled }"
    >
      <mdl-checkbox :name="inputName" :value="String(option.value)" :disabled="option.disabled" v-model="selected">
        <span class="flex">
          <img v-if="option.image" :src="option.image" :alt="trans('Avatar')" />
          <span class="flex flex-col">
            <small v-if="option.identifier" class="opaque">{{ option.identifier }}</small>
            <span>
              <i v-if="option.icon" class="material-icons small">{{ option.icon }}</i>
              {{ option.label }}
            </span>
            <span v-if="option.description" class="mdl-checkbox__description">
              {{ option.description }}
            </span>
          </span>
        </span>
      </mdl-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckboxFilter',
  inheritAttrs: false,

  props: {
    inputName: { type: [String], default: () => 'value' },
    disabled: { type: Boolean },
    value: { type: Array },
    options: { type: Array },
  },

  emits: ['activate'],

  data() {
    return {
      selected: this.value.map((v) => String(v)),
    }
  },
}
</script>
