import { isValid as dateIsValid } from 'date-fns/isValid'
import { parse as dateParse } from 'date-fns/parse'
import { parseISO as dateParseISO } from 'date-fns/parseISO'
import { parseJSON as dateParseJSON } from 'date-fns/parseJSON'
import { format as dateFormat } from 'date-fns/format'
import { differenceInCalendarDays as dateDifferenceInCalendarDays } from 'date-fns/differenceInCalendarDays'
import { differenceInCalendarWeeks as dateDifferenceInCalendarWeeks } from 'date-fns/differenceInCalendarWeeks'
import { differenceInCalendarYears as dateDifferenceInCalendarYears } from 'date-fns/differenceInCalendarYears'
import { differenceInYears as dateDifferenceInYears } from 'date-fns/differenceInYears'
import { addDays as dateAddDays } from 'date-fns/addDays'
import flatpickr from 'flatpickr'
import 'flatpickr/dist/l10n/default.js'
import 'flatpickr/dist/l10n/cs.js'
import 'flatpickr/dist/l10n/da.js'
import 'flatpickr/dist/l10n/de.js'
import 'flatpickr/dist/l10n/et.js'
import 'flatpickr/dist/l10n/es.js'
import 'flatpickr/dist/l10n/fi.js'
import 'flatpickr/dist/l10n/fr.js'
import 'flatpickr/dist/l10n/is.js'
import 'flatpickr/dist/l10n/it.js'
import 'flatpickr/dist/l10n/lv.js'
import 'flatpickr/dist/l10n/lt.js'
import 'flatpickr/dist/l10n/hu.js'
import 'flatpickr/dist/l10n/no.js'
import 'flatpickr/dist/l10n/pl.js'
import 'flatpickr/dist/l10n/ru.js'
import 'flatpickr/dist/l10n/ro.js'
import 'flatpickr/dist/l10n/sv.js'
import 'flatpickr/dist/l10n/tr.js'
import 'flatpickr/dist/l10n/uk.js'
import {
  cs,
  da,
  de,
  et,
  enGB as en,
  es,
  fi,
  fr,
  frCA as fr_CA,
  is,
  it,
  lv,
  lt,
  hu,
  nb,
  pl,
  ru,
  ro,
  sv,
  tr,
  uk,
} from 'date-fns/locale'

const dateFnsLocales = {
  cs,
  da,
  de,
  en,
  es,
  es_US: es,
  et,
  fi,
  fr,
  fr_CA,
  hu,
  is,
  it,
  lt,
  lv,
  no: nb,
  pl,
  ro,
  ru,
  sv,
  tr,
  uk,
}

const options = {
  locale: dateFnsLocales.en,
  weekStartsOn: 1,
}

/**
 * @param Object{key: string, language: string, locale: string} language
 */
export function setLocale(language) {
  let dateFnsLocale = dateFnsLocales.en
  let flatpickrLocale = flatpickr.l10ns.default

  const candidates = [language.key, language.language, language.locale]

  candidates.forEach((locale) => {
    if (dateFnsLocales[locale]) {
      dateFnsLocale = dateFnsLocales[locale]
    }

    if (flatpickr.l10ns[locale]) {
      flatpickrLocale = flatpickr.l10ns[locale]
    }
  })

  // dateFns
  options.locale = dateFnsLocale

  // flatpickr
  flatpickr.localize(Object.assign({}, flatpickrLocale))
}

export function parseDate(value, format = null) {
  if (value instanceof Date && isNaN(value)) {
    return value
  }

  if (format !== null) {
    return dateParse(value, format)
  }

  let date = dateParseISO(value instanceof Date ? value.toISOString() : value)

  if (!dateIsValid(date)) {
    date = dateParseJSON(value)
  }

  return date
}

export function parseTime(time, date = new Date()) {
  if (!time) return null

  const { groups } = /(?<hour>2[0-3]|[0-1]?[0-9])[^0-9]?(?<minute>[0-5]?[0-9]?)/.exec(time)
  if (!groups) return null
  let { hour, minute } = groups
  if (!hour) return null

  if (String(hour).length === 1) hour = '0' + hour
  if (!minute) minute = '00'
  if (String(minute).length === 1) minute = minute + '0'

  const dateTime = `${toDateString(date)}T${hour}:${minute}`

  return parseDate(dateTime)
}

export function daysDifference(from, to = new Date()) {
  return Math.abs(dateDifferenceInCalendarDays(parseDate(from), parseDate(to)))
}

export function daysBetween(from, to = new Date()) {
  return daysDifference(from, to) - 1
}

export function daysInRange(from, to = new Date()) {
  return daysDifference(from, to) + 1
}

export function addDays(date, days) {
  return dateAddDays(parseDate(date), days)
}

export function addDay(date) {
  return addDays(date, 1)
}

export function weeksInRange(from, to = new Date()) {
  return Math.abs(dateDifferenceInCalendarWeeks(parseDate(from), parseDate(to))) + 1
}

export function yearsDifference(from, to = new Date()) {
  return Math.abs(dateDifferenceInCalendarYears(parseDate(from), parseDate(to)))
}

export function age(date, when = new Date()) {
  return Math.abs(dateDifferenceInYears(parseDate(date), parseDate(when)))
}

export function format(date, format) {
  if (!date) {
    return null
  }

  return dateFormat(parseDate(date), format, options)
}

export function toDateTimeString(date) {
  if (!date) {
    return null
  }

  return dateFormat(parseDate(date), 'yyyy-MM-dd HH:mm:ss')
}

export function toDateString(date) {
  if (!date) {
    return null
  }

  return dateFormat(parseDate(date), 'yyyy-MM-dd')
}

export function toTimeString(date, seconds = true) {
  if (!date) {
    return null
  }

  return dateFormat(parseDate(date), 'HH:mm' + (seconds ? ':ss' : ''))
}

export function isValid(value) {
  if (!value) {
    return false
  }

  if (!(value instanceof Date)) {
    value = parseDate(value)
  }

  return dateIsValid(value)
}

Munio.Date = {
  parse: parseDate,
  daysDifference,
  daysBetween,
  daysInRange,
  addDays,
  addDay,
  weeksInRange,
  yearsDifference,
  age,
  format,
  toDateTimeString,
  toDateString,
  toTimeString,
  isValid,
}
