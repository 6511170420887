<template>
  <div class="mdl-search">
    <!-- Searchbar -->
    <mdl-searchbar
      v-model="input.search"
      :searching="searching"
      :disabled="disabled"
      :minLength="minSearchLength"
      searchOnClick
      @submit="onSearchSubmit"
    >
      <template #search>
        <CountrySelector
          local
          id="company-country-select"
          :value="input.country"
          :disabled="disabled"
          @input="onCountrySelect"
          rel="tooltip"
          :title="trans('Select country')"
        />
      </template>
    </mdl-searchbar>

    <!-- Result set -->
    <template>
      <div v-if="data.length > 0" class="mdl-searchbar__results">
        <Company
          v-for="company of data"
          :key="`${company.name}${company.orgnr}${company.id}`"
          selectable
          :selected="isCompanySelected(company)"
          :disabled="!companySelectable(company)"
          :company="company"
          :country="input.country"
          @click="onCompanySelect(company)"
        />
      </div>
      <div v-else-if="searched || emptyMessage" class="mdl-searchbar__results flex flex-col items-center py-8">
        <div v-if="searched">
          <div class="flex-inline opaque">{{ trans('No results found') }}</div>

          <mdl-button v-if="countryModel.company.create" raised @click="gotoCreateScreen" class="mt-4">
            {{ trans('Create new') }}
          </mdl-button>
        </div>
        <div v-else>
          <div v-if="emptyMessage" class="flex-inline opaque">
            {{ emptyMessage }}
          </div>
        </div>
      </div>
    </template>

    <div class="action-bar">
      <slot name="actions" />

      <mdl-paginator
        v-if="data.length && total > rows"
        :rows-from="offset + 1"
        :rows-to="offset + data.length"
        :rows-total="total"
        :page-rows="rows"
        :page-current="page"
        :page-last="pages"
        @goto="onPaginate"
      />

      <div v-if="showCreateOption" class="flex-grow">
        <mdl-details :summary="trans('Create new')" v-model="create" />
        <mdl-button v-if="false" icon raised @click.stop="gotoCreateScreen">
          <i class="material-icons">add</i>
        </mdl-button>
      </div>
    </div>

    <!-- Create new company screen -->
    <template v-if="canCreate && create">
      <div class="mdl-searchbar__add">
        <mdl-textfield
          :floating-label="trans('Country')"
          :value="`${countryModel.flag} ${countryModel.i18n}`"
          readonly
        />
        <mdl-textfield
          v-autofocus
          :error="
            input.name.length < minSearchLength
              ? trans('Please enter :num or more characters', { num: minSearchLength })
              : undefined
          "
          :floating-label="trans('Name')"
          v-model="input.name"
          @input="onInput"
          required
          :minlength="minSearchLength"
        />
        <mdl-textfield :floating-label="trans('Org.nr.')" v-model="input.orgnr" @input="onInput" />
      </div>
    </template>
  </div>
</template>

<script>
import Company from './Company.vue'
import CountrySelector from './CountrySelector.vue'

export default {
  name: 'CompanySearch',

  components: {
    Company,
    CountrySelector,
  },

  props: {
    country: { type: String, default: Munio.config.company.country },
    value: Object,
    disabled: Boolean,
    context: Boolean,
    emptyMessage: String,
    initialResult: { type: Array, required: false, default: () => [] }, // fallback results, can be result of last search
    except: { type: Array, default: () => [] },
  },

  data() {
    return {
      page: 1,
      rows: 5,
      minSearchLength: 2,
      searchResult: this.getInitialResult(),
      selected: this.getInitialSelected(),
      searched: false,
      searching: false,
      create: false,
      input: this.getDefaultInput(),
    }
  },

  watch: {
    create(value) {
      if (value) {
        this.gotoCreateScreen()
      } else {
        this.onInput()
      }
    },
  },

  computed: {
    countryModel() {
      return Munio.country(this.input.country)
    },

    canCreate() {
      return this.countryModel.company.create
    },

    showCreateOption() {
      if (!this.canCreate) {
        return false
      }

      if (!this.data.length) {
        return false
      }

      return this.page >= this.pages
    },

    offset() {
      return this.rows * (this.page - 1)
    },

    pages() {
      if (!this.searchResult.length) {
        return 1
      }

      return Math.ceil(this.searchResult.length / this.rows)
    },

    data() {
      return this.searchResult?.slice(this.offset, this.offset + this.rows)
    },

    total() {
      return this.searchResult.length
    },
  },

  methods: {
    isCompanySelected(company) {
      if (this.create) {
        return false
      }

      return this.selected && this.selected === company
    },

    isValueInInitialResult() {
      return this.value && this.initialResult.find((c) => c.id === this.value.id || c === this.value)
    },

    getInitialResult() {
      if (this.value && !this.isValueInInitialResult()) {
        return [this.value, ...this.initialResult]
      }

      if (this.initialResult) {
        return this.initialResult
      }

      return [this.value].filter((v) => !!v)
    },

    getInitialSelected() {
      if (this.value) {
        return this.value
      }

      return null
    },

    companySelectable(company) {
      if (company.id) {
        return !this.except.includes(company.id)
      }

      return true
    },

    getAddress(address) {
      const line1 = address.line1
      const line2 = address.line2
      const line3 = [address.postcode, address.city].filter((v) => v).join(' ')

      return [line1, line2, line3].filter((v) => v).join(', ')
    },

    getDefaultInput() {
      const country = this.value ? this.value.country : this.country

      return {
        search: this.input ? this.input.search : '',
        country: this.input ? this.input.country : country,
        name: this.input ? this.input.name : '',
        orgnr: '',
      }
    },

    async onSearchSubmit(value) {
      this.input.search = value
      this.searchResult = []
      this.page = 1
      this.create = false
      this.selected = null

      if (this.input.search.length >= this.minSearchLength) {
        this.searching = true
        this.$emit('search', this.input.search)
        const { data } = await Munio.api.companies.search(this.input.search, this.input.country, this.context)
        this.searching = false
        this.searched = true
        this.searchResult = data.data
        this.input = this.getDefaultInput()
        this.$emit('searched', data.data)
      }
    },

    onCountrySelect(country) {
      this.input.country = country
    },

    onCompanySelect(company) {
      this.selected = company ?? null
      this.create = false
      this.onInput()
    },

    gotoCreateScreen() {
      this.create = true
      this.input.name = this.input.name || this.input.search
      this.$emit('input', null)
      this.onInput()
    },

    onInput() {
      if (this.create) {
        const payload =
          this.input.name.length >= this.minSearchLength
            ? {
                name: this.input.name,
                orgnr: this.input.orgnr,
                country: this.input.country,
              }
            : null

        this.$emit('input', payload)
      } else if (this.selected) {
        this.$emit('input', this.selected)
      } else {
        this.$emit('input', null)
      }
    },

    onPaginate(page) {
      this.input = this.getDefaultInput()
      this.onInput()
      this.page = page
    },

    getFormGroupClasses(field) {
      const error = this.hasError(field)
      const message = this.getError(field)

      return [
        'form-group',
        {
          'has-error': !!error,
          'has-feedback': !!message,
        },
      ]
    },

    hasError(field) {
      return this.form.errors.has(field)
    },

    getError(field) {
      return this.form.errors.get(field)
    },
  },
}
</script>
