Munio.behaviors.keepalive = {
  selector: 'body[data-keepalive]',
  attach: function (context, config) {
    const url = Munio.config.keepalive.url
    const interval = Munio.config.keepalive.interval * 1000
    const keepalive = $(this.selector).data('keepalive')

    if (keepalive) {
      setInterval(() => Munio.$http.get(url), interval)
    }
  },
}
