export type Review = {
  approve: boolean
  comment?: string
}

class Requisition {
  constructor(
    private gateId: number,
    private requisitionId: number,
  ) {}

  get params() {
    return {
      gate: this.gateId,
      requisition: this.requisitionId,
    }
  }

  review(review: Review) {
    const comment = review.comment === undefined || review.comment === '' ? null : review.comment
    return window.Munio.$http.put(window.Munio.route('api.web.access.gate.requisition.review', this.params), {
      ...review,
      comment,
    })
  }
}

export default Requisition
