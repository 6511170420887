<template>
  <div>
    <h4 class="mdl-subheader">{{ trans('Select employer') }}</h4>

    <company-search
      class="mt-4"
      :name-label="trans('Company')"
      v-model="registration.company"
      @input="errorMessage = null"
      @search="errorMessage = null"
    />

    <div v-if="errorMessage" class="mdl-color-text--danger">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
const data = {
  errorMessage: null,
}

export default {
  inject: ['registration'],

  data: () => data,

  computed: {
    employer() {
      return this.$store.state.employer
    },
  },

  watch: {
    employer(employer) {
      // go to next page if user applied an invitation
      // with an employer while on the employer page
      if (employer) {
        this.errorMessage = null
        this.$parent.next()
      }
    },
  },

  methods: {
    validate() {
      let isValid = true
      let { company } = this.registration
      this.errorMessage = null

      if (this.invite) return true

      if (!company) {
        this.errorMessage = trans('You must select an employer')
        return false
      }

      if (!company.name) {
        this.errorMessage = trans('Company name is required')
        isValid = false
      }

      // the country selecter use company.company to hold
      // the orgnr for norwegian companies, while all other
      // countries use company.orgnr.....
      if (company.country === 'no') {
        if (!company.orgnr) {
          this.errorMessage = trans('Organization number is required')
          isValid = false
        }
      }

      return isValid
    },
  },
}
</script>
