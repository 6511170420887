import Wrapper from '@/munio/vue/Wrapper.js'

Munio.IntegrationNotification = class extends Wrapper {
  constructor(selection, integration) {
    super(selection, {
      integration,
    })

    Munio.Events.$on('integration:updated', (integration) => {
      this.root.$data.integration = integration
    })
  }

  get name() {
    return 'IntegrationNotification'
  }

  component() {
    return import('./App.vue')
  }
}
