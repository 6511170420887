import Router from 'vue-router'
import Wrapper from '@/munio/vue/Wrapper.js'
import store from './store.js'
import routes from './routes.js'

Munio.Auth = class extends Wrapper {
  store() {
    return store
  }

  component() {
    return import('./Auth.vue')
  }

  async router() {
    const router = new Router({
      base: `/${Munio.config.i18n.routeKey}`,
      mode: 'history',
      routes,
    })

    router.beforeEach((to, from, next) => {
      if (to.meta && to.meta.title) {
        document.title = to.meta.title + ' - Munio LMS'
      }

      return next()
    })

    return router
  }
}
