import CompanyCourseSessionUsers from './CompanyCourseSessionUsers.js'
import CompanyCourseSessionParticipants from './CompanyCourseSessionParticipants.js'

class CompanyCourseSession {
  companyCourseId
  sessionId

  constructor(companyCourseId, sessionId) {
    this.companyCourseId = companyCourseId
    this.sessionId = sessionId
  }

  route(name, params = {}) {
    return Munio.route(
      name,
      Object.assign(
        {
          companyCourse: this.companyCourseId,
          session: this.sessionId,
        },
        params,
      ),
    )
  }

  get basePath() {
    return this.route('api.web.admin.company.course.session')
  }

  get(options) {
    return Munio.$http.get(this.basePath, Munio.urlParams(options))
  }

  close() {
    return Munio.$http.post(this.route('api.web.admin.company.course.session.close'))
  }

  open() {
    return Munio.$http.post(this.route('api.web.admin.company.course.session.open'))
  }

  toggle() {
    return Munio.$http.post(this.route('api.web.admin.company.course.session.toggle'))
  }

  get users() {
    return new CompanyCourseSessionUsers(this.companyCourseId, this.sessionId)
  }

  get participants() {
    return new CompanyCourseSessionParticipants(this.companyCourseId, this.sessionId)
  }

  move(participants, session) {
    return Munio.$http.post(this.route('api.web.admin.company.course.session.move'), { participants, session })
  }
}

export default CompanyCourseSession
