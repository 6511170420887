export default class {
  constructor(gateId, linkId) {
    this.gateId = gateId
    this.linkId = linkId
  }

  get params() {
    return { gate: this.gateId, link: this.linkId }
  }

  update(data) {
    return Munio.$http.post(Munio.route('api.web.access.gate.link.update', this.params), data)
  }

  delete() {
    return Munio.$http.delete(Munio.route('api.web.access.gate.link.delete', this.params))
  }
}
