<template>
  <Certificates :born-at="user.bornAt" />
</template>

<style scoped></style>

<script>
import Certificates from '@/munio/vue/components/Certificates/index.vue'

const Root = {
  name: 'UserCertificates',
  components: {
    Certificates,
  },

  props: {
    user: { type: Object, required: true },
  },
  mounted() {
    this.$store.dispatch('load')
  },
}

export default Root
</script>
