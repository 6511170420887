<template>
  <div class="mdl-button-group">
    <div v-click-outside="hideDropdown" v-focus-outside="hideDropdown">
      <MdlButton
        :class="extraClasses"
        :loading="loading"
        :primary="primary"
        :raise="raised"
        :opaque="opaque"
        :disabled="disabled"
        @click="toggleDropdown"
      >
        <slot name="button" />
      </MdlButton>

      <div class="dropdown" :class="{ open: isDropdownVisible }">
        <Menu right>
          <slot name="menu" />
        </Menu>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import MdlButton from '@/munio/vue/components/mdl/Button.vue'
import Icon from '@/munio/vue/components/Icon.vue'
import Menu from '@/munio/vue/components/Menu.vue'
import { reactive, ref } from 'vue'

withDefaults(
  defineProps<{
    primary?: boolean
    raised?: boolean
    opaque?: boolean
    disabled?: boolean
    loading?: boolean
    extraClasses?: 'string'
  }>(),
  {
    primary: false,
    raised: false,
    opaque: true,
  },
)

const isDropdownVisible = ref(false)

function hideDropdown(): void {
  isDropdownVisible.value = false
}

function toggleDropdown(): void {
  isDropdownVisible.value = !isDropdownVisible.value
}
</script>
