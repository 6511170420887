<template>
  <div class="course-nodes" :data-subcourse="isSubcourse">
    <slot name="before" />

    <node v-for="node in nodes" :key="node.id" :node="node" :parent="parent">
      <node-collection
        slot="after"
        v-if="node.subcourse && node.subcourse.nodes.length > 1"
        :nodes="node.subcourse.nodes"
        :parent="node"
      />
    </node>
    <node-completion v-if="completable && !isSubcourse" :attempt="attempt" :diploma="diploma" />

    <slot name="after" />
  </div>
</template>

<script>
import { isEmpty } from 'lodash'

import Node from './Node.vue'
import NodeCompletion from './NodeCompletion.vue'

export default {
  name: 'NodeCollection',

  components: {
    NodeCompletion,
    Node,
  },

  props: {
    nodes: Array,
    attempt: Object,
    diploma: Object,
    parent: Object,
    completable: Boolean,
  },

  computed: {
    isSubcourse() {
      return !isEmpty(this.parent)
    },
  },
}
</script>
