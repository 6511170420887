import CompanyCourseEnrollment from './CompanyCourseEnrollment.js'
import CompanyCourseSession from './CompanyCourseSession.js'
import SessionRequests from './SessionRequests.js'

class CompanyCourse {
  id

  constructor(id) {
    this.id = id
  }

  route(name, params = {}) {
    return Munio.route(
      name,
      Object.assign(
        {
          companyCourse: this.id,
        },
        params,
      ),
    )
  }

  get basePath() {
    return this.route('api.web.admin.company.course')
  }

  get() {
    return Munio.$http.get(this.basePath)
  }

  users(options) {
    return Munio.$http.get(this.route('api.web.admin.company.course.users'), Munio.urlParams(options))
  }

  sessions(options) {
    return Munio.$http.get(this.route('api.web.admin.company.course.sessions'), Munio.urlParams(options))
  }

  enroll(users, comment, session, company, reservations) {
    return Munio.$http.post(
      this.route('api.web.admin.company.course.enrollments.enroll'),
      cleanup({ users, comment, session, company, reservations }),
    )
  }

  enrollment(userId) {
    return new CompanyCourseEnrollment(this.id, userId)
  }

  createSession(session) {
    return Munio.$http.post(this.route('api.web.admin.company.course.session.create'), session)
  }

  session(sessionId) {
    return new CompanyCourseSession(this.id, sessionId)
  }

  get sessionRequests() {
    return new SessionRequests(this.id)
  }
}

export default CompanyCourse
