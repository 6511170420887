import { CollectionResponse } from '@/munio/api/data/common'
import { GateResponsibleResourceDto } from '@/munio/api/data/GateResponsibleResource.dto'
import { ZoneUserResourceDto } from '@/munio/api/data/ZoneUserResource.dto'

export type UsersGetResponse = CollectionResponse<ZoneUserResourceDto> & {
  addableCertificates: unknown[] // todo
  permissions: {
    enroll: boolean
    requisition: boolean
  }
  meta: {
    period: {
      unit: string
      unitMaxExternal: string
      unitMaxInternal: string
    }
    responsiblesEnabled: boolean
    responsiblesScoped: boolean
    responsibles: GateResponsibleResourceDto[]
  }
}

export default class Users {
  constructor(private zoneId: number) {}

  get params() {
    return { zone: this.zoneId }
  }

  get(options: any) {
    return window.Munio.$http.get<UsersGetResponse>(
      Munio.route('api.web.access.zone.users', this.params),
      Munio.urlParams(options),
    )
  }
}
