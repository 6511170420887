import { CollectionResponse } from '@/munio/api/data/common'
import { CompanyBasicResourceDto } from '@/munio/api/data/CompanyBasicResource.dto'

export default class Companies {
  constructor(private gateId: number) {}

  get params() {
    return { gate: this.gateId }
  }

  update(id: number, company: CompanyBasicResourceDto) {
    return window.Munio.$http.put<CollectionResponse<CompanyBasicResourceDto>>(
      Munio.route('api.web.access.gate.companies.update', { company: id, ...this.params }),
      window.Munio.urlParams(window.cleanup(company)),
    )
  }
}
