import Wrapper from '@/munio/vue/Wrapper.js'

Munio.CompanyCourses = class extends Wrapper {
  constructor(selector, companyId, accountId) {
    super(selector, { companyId, accountId })
  }

  component() {
    return import('./App.vue')
  }
}
