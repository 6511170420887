import Wrapper from '@/munio/vue/Wrapper.js'

Munio.ZoneRequisitions = class extends Wrapper {
  constructor(selector, gateId) {
    super(selector, { gateId })
  }

  get name() {
    return 'ZoneRequisitions'
  }

  component() {
    return import('./App.vue')
  }
}
