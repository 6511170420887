class Departments {
  get(department) {
    return Munio.$http.get(Munio.route('api.web.admin.department', { department }))
  }

  root() {
    return Munio.$http.patch(Munio.route('api.web.admin.department.root'))
  }

  update(department, name) {
    return Munio.$http.post(Munio.route('api.web.admin.department.update', { department }), { name })
  }

  create(department, name, country) {
    return Munio.$http.post(Munio.route('api.web.admin.department.create', { department }), { name, country })
  }

  add(department, company) {
    return Munio.$http.post(Munio.route('api.web.admin.department.add', { department }), cleanup({ ...company }))
  }

  moveTo(department, parentId, force = false) {
    return Munio.$http.post(Munio.route('api.web.admin.department.move', { department }), { parentId, force })
  }

  detach(department) {
    return Munio.$http.post(Munio.route('api.web.admin.department.detach', { department }))
  }
}

export default Departments
