class Roles {
  get(options) {
    return Munio.$http.get(Munio.route('api.web.admin.roles'), Munio.urlParams(options))
  }

  create(name) {
    return Munio.$http.post(Munio.route('api.web.admin.role.create'), { name })
  }
}

export default Roles
