import { CollectionResponse } from '@/munio/api/data/common'
import { CourseResourceDto } from '@/munio/api/data/CourseResource.dto'

const { $http, route, urlParams } = window.Munio

export default class CoursesManager {
  async get(options: URLSearchParams) {
    return $http.get<CollectionResponse<CourseResourceDto>>(route('api.web.munio.courses'), urlParams(options))
  }
}
