import { watch, ref, Ref } from 'vue'

/**
 * @param {Ref<HTMLElement>} wrapper
 * @param {Ref<Boolean>} isActive
 */
export default (wrapper: Ref<HTMLElement>, isActive = ref(true)) => {
  const cursor = ref()

  watch(cursor, (v) => {
    wrapper.value.style.cursor = v
  })

  watch(
    [isActive, wrapper],
    ([isActive, el]) => {
      isActive && el && init(el)
      !isActive && el && disable(el)
    },
    {
      immediate: true,
    },
  )

  let startX: number
  let startScrollX: number

  const init = (el: HTMLElement) => {
    el.addEventListener('mousedown', start)
    cursor.value = 'grab'
  }

  const disable = (el: HTMLElement) => {
    el.removeEventListener('mousedown', start)
    cursor.value = 'initial'
  }

  const start = (e: MouseEvent) => {
    if (e.button !== 0) return // not main button

    startX = e.clientX
    startScrollX = wrapper.value.scrollLeft

    window.addEventListener('mousemove', move)
    window.addEventListener('mouseup', end)
  }

  const move = (e: MouseEvent) => {
    e.preventDefault()
    cursor.value = 'grabbing'
    const deltaX = e.x - startX
    if (wrapper.value) {
      wrapper.value.scroll(startScrollX - deltaX, 0)
    }
  }

  const end = () => {
    cursor.value = 'grab'
    window.removeEventListener('mousemove', move)
    window.removeEventListener('mouseup', end)
  }
}
