import './extensions.js'
import './globals.js'
import './dependencies.js'

// core
import './core.js'
import './currency'
import './storage.js'
import './http'
import './api/index'
import './vue/index.js'
import './vue/components/index.js'
import './behaviors/index.js'
import './zendesk.js'
import { setLocale } from './utils/date.js'
import $ from 'jquery'

export const { Munio } = window

setLocale(Munio.config.i18n)

/**
 * Tabs deep linking
 */
const url = document.location.toString()
if (url.match('#')) {
  $('a[href="#' + url.split('#')[1] + '"]').trigger('click')
}

/**
 * Mobile sidebar
 */
const $body = $('body')
const openClass = 'sidebar-out'

$('#navigation-toggle').on('click', function () {
  if ($body.hasClass(openClass)) {
    $body.removeClass(openClass)
  } else {
    $body.addClass(openClass)
  }
})

$('.page-left-backdrop').on('click', function (event: any) {
  if ($body.hasClass(openClass)) {
    $body.removeClass(openClass)
    event.preventDefault()
  }
})

/**
 * Currency decimal input
 */
$('input.input-currency-decimal').on('blur', function (e: any) {
  const $el = $(this)
  const value = String($el.val())
  const maxLength = e.currentTarget.maxLength

  $el.val(value + Array(maxLength + 1 - value.length).join('0'))
})
