<template>
  <modal ref="modal" :title="title" :show="show" @hide="onHide" @hidden="onHidden" :loading="loading || saving">
    <div class="modal-body pt-0 pb-4">
      <company-search v-if="show" v-model="value" :country="country" context />
    </div>

    <template v-slot:actions>
      <mdl-button
        :disabled="loading || saving || !value"
        primary
        :raised="!!value"
        @click="submit"
        :data-qa-id="pendo('selectCompanyModal.select')"
      >
        {{ submitLabel }}
      </mdl-button>
      <mdl-button :disabled="loading || saving" @click="onHide" :data-qa-id="pendo('selectCompanyModal.cancel')">
        {{ trans('Cancel') }}
      </mdl-button>
    </template>
  </modal>
</template>

<script>
export default {
  props: {
    show: Boolean,
    title: { type: String, default: () => trans('Select company') },
    submitLabel: { type: String, default: () => trans('Select') },
    company: Object,
    loading: Boolean,
    onSubmit: Function,
    country: { type: String, default: Munio.config.company.country },
  },

  model: {
    prop: 'company',
    event: 'select',
  },

  data() {
    return {
      value: null,
      saving: false,
    }
  },

  methods: {
    onHide() {
      this.$emit('hide')
    },

    onHidden() {
      this.value = null
    },

    async submit() {
      try {
        this.saving = true

        if (this.onSubmit) {
          return this.onSubmit(this.value)
        } else {
          if (this.value && !this.value.id) {
            const { data } = await Munio.api.companies.add(this.value)
            this.value = data.data
          }

          this.$emit('select', this.value)
          this.$refs.modal.hideModal()
        }
      } finally {
        this.saving = false
      }
    },
  },

  watch: {
    company: {
      handler(value) {
        if (value) {
          this.value = value
        }
      },
      immediate: true,
    },
    show: {
      handler(value) {
        if (value && this.company) {
          this.value = this.company
        }
      },
      immediate: true,
    },
  },
}
</script>
