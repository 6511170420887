const setTooltip = function (el, value, modifiers) {
  let placement = 'top'
  let trigger
  const triggers = ['focus', 'hover', 'click'].filter((t) => modifiers[t])

  if (modifiers.bottom) placement = 'bottom'
  if (modifiers.left) placement = 'left'
  if (modifiers.right) placement = 'right'
  if (modifiers.auto) placement = 'auto'

  if (triggers.length) {
    trigger = triggers.join(' ')
  }

  $(el).tooltip({
    container: '#modal-container',
    title: value,
    html: modifiers.html,
    placement,
    trigger,
  })
}

export default {
  bind(el, { value, modifiers }) {
    setTooltip(el, value, modifiers)
  },

  unbind(el) {
    $(el).tooltip('destroy')
  },

  update(el, { value, modifiers }) {
    const $el = $(el)

    $el.attr('title', value).tooltip('fixTitle')

    const data = $el.data('bs.tooltip')

    if (modifiers.live) {
      // update live without flickering (but it doesn't reposition)
      if (data.$tip) {
        if (data.options.html) {
          data.$tip.find('.tooltip-inner').html(value)
        } else {
          data.$tip.find('.tooltip-inner').text(value)
        }
      }
    } else {
      if (data.inState.hover || data.inState.focus || data.inState.click) {
        $el.tooltip('show')
      }
    }
  },
}
