<template>
  <Modal
    :show="show"
    :dismissable="false"
    :title="trans('Verify mobile number')"
    @shown="onModalShown"
    @hidden="onModalHidden"
  >
    <template slot="body">
      <OtpInput
        v-if="show || contentShown"
        :shown="contentShown"
        :codeExpirationDate="codeExpirationDate"
        :success="success"
        :attempts="attempts"
        :hasExceededMaxCodeAttempts="hasExceededMaxCodeAttempts"
        :retryCodeTimeout="retryCodeTimeout"
        @submitCode="submitCode"
        @resendCode="resendCode"
        class="py-8"
      />
    </template>

    <template slot="actions">
      <MdlButton @click="close">{{ trans(success ? 'Close' : 'Cancel') }}</MdlButton>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { trans } from '@/munio/i18n/index.js'

import Modal from '@component/Modal.vue'
import MdlButton from '@/munio/vue/components/mdl/Button.vue'
import OtpInput from '@component/MobileVerification/OtpInput.vue'

defineProps<{
  show: boolean
  codeExpirationDate: string
  success?: boolean
  attempts?: number
  hasExceededMaxCodeAttempts: boolean
  retryCodeTimeout: number
}>()

const contentShown = ref(false)

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'submitCode', code: string): void
  (e: 'resendCode'): void
}>()

function close(): void {
  emit('close')
}

function submitCode(event: any) {
  emit('submitCode', event)
}

async function resendCode() {
  emit('resendCode')
}

function onModalShown() {
  contentShown.value = true
}

function onModalHidden() {
  contentShown.value = false
}
</script>
