<template>
  <div style="position: relative" class="">
    <ProgressBar v-if="loading" :indeterminate="true" />
    <div v-else-if="error" class="alert alert-danger mb-0">{{ error }}</div>

    <ProductSessions v-if="isIlt" :product="product" :cart="cart" :error.sync="error" :loading.sync="loading" />

    <div v-else class="product-price-group p-8 sm:px-12">
      <div class="flex flex-wrap flex-col sm:flex-row justify-between">
        <div class="h4 flex items-center">
          {{ trans('License') }}
        </div>
        <div class="flex flex-col sm:flex-row items-start sm:items-center gap-4">
          <div class="flex items-center text-right w-full justify-between gap-x-4">
            <Quantity class="product-quantity" v-model="quantity" :min="1" :max="cart.maxQuantity" />
            <ProductPrice
              :price="product.price.default"
              :original="product.price.original"
              :member-price="product.price.membership"
              :membership="cart.isMembershipCustomer"
              :membership-enabled="cart.isMembershipEnabled"
            />
          </div>
          <button
            class="mdl-button mdl-button--raised mdl-button--colored w-full sm:w-fit"
            :data-qa-id="pendo(`buyProduct.${product.id}`)"
            :disabled="!cart.canAddProduct || loading"
            @click="addToCart(false)"
          >
            {{ trans('Buy') }}
          </button>
        </div>
      </div>
    </div>

    <div v-if="product.volumeDiscounts.length" id="price-info-regular">
      <VolumeDiscounts class="px-12 py-8" :discounts="product.volumeDiscounts" />
    </div>

    <template v-if="product.price.unlimited || product.price.membershipUnlimited">
      <div class="product-price-group px-8 sm:px-12 py-8">
        <div class="flex flex-col sm:flex-row justify-between sm:gap-x-8 sm:items-center">
          <div class="h4 flex flex-grow items-center justify-between">
            <div>
              {{ trans('Unlimited license') }}
            </div>
            <div class="text-right">
              <ProductPrice
                :price="product.price.unlimited"
                :original="product.price.unlimited"
                :member-price="product.price.membershipUnlimited"
                :membership="cart.isMembershipCustomer"
                :membership-enabled="cart.isMembershipEnabled"
              />
            </div>
          </div>
          <button
            class="mdl-button mdl-button--raised w-full sm:w-fit"
            :disabled="!cart.canAddUnlimitedProduct || loading"
            :data-qa-id="pendo(`buyProductUnlimited.${product.id}`)"
            @click="addToCart(true)"
          >
            {{ trans('Buy') }}
          </button>
        </div>
      </div>

      <div id="price-info-unlimited">
        <div class="px-12 py-8 flex items-center gap-2">
          <img src="./assets/unlimited_license.svg" class="h-36px" />
          <p>
            {{
              trans(
                'This license enables unlimited usage for your organization. The minimum subscription length is :months months.',
                { months: 12 },
              )
            }}
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Quantity from '../Quantity.vue'
import ProductPrice from './ProductPrice.vue'
import ProgressBar from '../mdl/ProgressBar.vue'
import ProductSessions from './ProductSessions.vue'
import VolumeDiscounts from './VolumeDiscounts.vue'

let clearError

export default {
  components: {
    Quantity,
    ProgressBar,
    ProductPrice,
    ProductSessions,
    VolumeDiscounts,
  },

  props: {
    product: { type: Object, required: true },
    cart: { type: Object, required: true },
  },

  data: () => ({
    quantity: 1,
    loading: false,
    error: null,
  }),

  computed: {
    account() {
      return Munio.config.account
    },
    isIlt() {
      return this.product.course && this.product.course.isIlt
    },
  },

  watch: {
    error(e) {
      if (e) {
        clearTimeout(clearError)
        clearError = setTimeout(() => {
          this.error = ''
        }, 5000)
      }
    },
  },

  methods: {
    increment() {
      this.quantity = Math.min(this.quantity + 1, this.cart.maxQuantity)
    },
    decrement() {
      this.quantity = Math.max(this.quantity - 1, 1)
    },
    async addToCart(unlimited = false) {
      this.error = null
      this.loading = true

      try {
        await Munio.api.shop().cart.add({
          productId: this.product.id,
          quantity: unlimited ? 1 : this.quantity,
          unlimited,
        })
      } catch (err) {
        this.error = err.message
      }
      this.loading = false
    },
  },
}
</script>
