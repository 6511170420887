<template>
  <label :class="classNames">
    <input
      ref="input"
      type="checkbox"
      class="mdl-checkbox__input"
      v-on="listeners"
      v-bind="$attrs"
      :readonly="readonly"
      :disabled="disabled"
      :value="value"
      :checked="isChecked"
      @change="onChange"
    />
    <span v-if="$slots.default || label" class="mdl-checkbox__label" :class="labelClass">
      {{ label }}
      <slot></slot>
      <span v-if="description" class="mdl-checkbox__description">{{ description }}</span>
    </span>
    <span class="mdl-checkbox__box-outline"><span class="mdl-checkbox__tick-outline"></span></span>
  </label>
</template>

<script>
export default {
  inheritAttrs: false,

  model: {
    prop: 'checked',
    event: 'change',
  },

  props: {
    value: { default: null },
    label: { type: String },
    labelClass: { type: [String, Object, Array] },
    description: { type: String },
    indeterminate: { type: Boolean },
    disabled: { type: Boolean },
    readonly: { type: Boolean },
    checked: { type: [Boolean, Array], default: false },
  },

  data() {
    return {
      isChecked: this.getChecked(this.checked),
    }
  },

  computed: {
    listeners() {
      const { change, ...listeners } = this.$listeners
      return listeners
    },
    classNames() {
      return {
        'mdl-checkbox': true,
        'is-indeterminate': this.indeterminate,
        'is-checked': this.isChecked,
        'is-disabled': this.disabled,
        'is-upgraded': true,
        'w-auto': true,
      }
    },
  },

  methods: {
    getChecked(checked) {
      if (Array.isArray(checked)) {
        return checked.includes(this.value)
      }
      return checked
    },

    emitChange(checked) {
      let newValue = checked

      if (Array.isArray(this.checked)) {
        newValue = [...this.checked]

        if (checked) {
          newValue.push(this.value)
          newValue = newValue.filter((value, index, arr) => arr.indexOf(value) === index)
        } else {
          newValue.splice(newValue.indexOf(this.value), 1)
        }
      }

      this.isChecked = checked
      this.$emit('change', newValue)
    },

    onChange(event) {
      if (this.readonly) {
        event.preventDefault()
        return
      }

      this.emitChange(event.target.checked)
    },
  },

  watch: {
    checked: {
      immediate: true,
      handler(value) {
        this.isChecked = this.getChecked(value)
      },
    },
  },

  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.$refs.input && this.$refs.input.checked !== this.isChecked) {
          this.emitChange(this.$refs.input.checked)
        }
      }, 50)
    })
  },
}
</script>
