<template>
  <modal :title="trans('Add certificate')" :show="show" @hide="onModalClose">
    <div class="modal-body pt-4 pb-4">
      <m-select
        class="mb-8"
        :options="options"
        :placeholder="trans('Select certificate type')"
        v-model="toAdd.typeId"
      />

      <template v-if="toAdd.typeId">
        <Textfield
          ref="identifier"
          required
          class="mb-3"
          :pattern="type?.identifierPattern || '\d{4,}'"
          :floating-label="trans('Certificate number')"
          v-model="toAdd.identifier"
          @validate="toAdd.identifierValid = $event"
        />
        <small class="mdl-color-text--grey-600">{{ trans('Enter date of birth') }}</small>
        <DateOfBirth class="mt-3" @change="onDateOfBirthChange" :default-value="bornAt" />
      </template>
    </div>

    <template v-slot:footer>
      <mdl-button data-dismiss="modal">
        {{ trans('Cancel') }}
      </mdl-button>

      <mdl-button primary raised :disabled="!canAdd" @click="addCertificate">
        {{ trans('Add') }}
      </mdl-button>
    </template>
  </modal>
</template>

<script>
import Textfield from '@/munio/vue/components/mdl/Textfield.vue'
import DateOfBirth from '@/munio/vue/components/DateOfBirth.vue'

export default {
  components: {
    Textfield,
    DateOfBirth,
  },

  props: {
    show: { type: Boolean },
    identifierEnabled: { type: Boolean },
    addable: { type: Array, default: () => [] },
    bornAt: { type: [String, undefined] },
  },
  data() {
    return {
      toAdd: {
        identifier: undefined,
        typeId: undefined,
        bornAt: undefined,
        identifierValid: false,
      },
    }
  },
  computed: {
    options() {
      return this.$props.addable.map((c) => {
        return { id: c.id, label: c.name }
      })
    },
    type() {
      if (!this.toAdd.typeId) {
        return undefined
      }

      return this.addable.find((c) => c.id === this.toAdd.typeId)
    },
    canAdd() {
      if (!this.toAdd.identifier || !this.toAdd.typeId || !this.toAdd.bornAt) {
        return false
      }

      return this.toAdd.identifierValid
    },
  },
  methods: {
    onDateOfBirthChange(date) {
      this.toAdd.bornAt = date
    },
    onModalClose() {
      this.$emit('hide')
    },
    addCertificate() {
      if (this.toAdd.typeId) {
        this.$emit('add', this.toAdd)
        this.toAdd.typeId = undefined
      }
    },
  },
}
</script>
