import Wrapper from '@/munio/vue/Wrapper.js'
import CourseEnrollmentIltStore from './CourseEnrollmentIltStore.js'

Munio.CourseEnrollment = class extends Wrapper {
  constructor(selector, id) {
    super(selector, { id })
  }

  component() {
    return import('./CourseEnrollment.vue')
  }

  open() {
    this.app.open()
  }

  close() {
    this.app.close()
  }
}

Munio.CourseEnrollmentInfo = class extends Wrapper {
  constructor(selector, id) {
    super(selector, { id })
  }

  component() {
    return import('./CourseEnrollmentInfo.vue')
  }

  open(userId) {
    this.app.open(userId)
  }
}

Munio.CourseEnrollmentIlt = class extends Wrapper {
  constructor(selector, id) {
    super(selector, { id })
  }

  store() {
    return CourseEnrollmentIltStore
  }

  component() {
    return import('./CourseEnrollmentIlt.vue')
  }
}
