import './behavior.autogenerate.js'
import './behavior.autosizeinput.js'
import './behavior.capslockWarning.js'
import './behavior.checkboxAutofill.js'
import './behavior.colorpicker.js'
import './behavior.companySelector.js'
import './behavior.counters.js'
import './behavior.dateinput.js'
import './behavior.disabledlink.js'
import './behavior.disableTarget.js'
import './behavior.headertabs.js'
import './behavior.keepalive.js'
import './behavior.misc.js'
import './behavior.modal.js'
import './behavior.panelLanguageSelector.js'
import './behavior.paneltabs.js'
import './behavior.permissions.js'
import './behavior.quantity.js'
import './behavior.radioOrOther.js'
import './behavior.select.js'
import './behavior.tableSelect.js'
import './behavior.toggleElements.js'
import './behavior.tooltips.js'
import './behavior.vue.js'

Munio.attachBehaviors(document)
