import cookies from 'js-cookie'
import classNames from 'classnames'
import { trans, transChoice } from './i18n/index.js'
import $ from '@/libs/jquery.js'

window.$ = window.jQuery = $
window.classNames = classNames
window.trans = trans
window.trans_choice = transChoice
window.isDevMode = import.meta.env.DEV
window.isProduction = Munio.config.environment === 'production'

//
// Console shim
//
window.console =
  window.console ||
  (function () {
    const c = {}
    c.log =
      c.warn =
      c.debug =
      c.info =
      c.error =
      c.time =
      c.dir =
      c.profile =
      c.clear =
      c.exception =
      c.trace =
      c.assert =
        function () {}
    return c
  })()

//
// Check if user has ability
//
window.can = (ability, level = 'company') => {
  if (!Munio.config.user.permissions) {
    return false
  }

  ability = ('' + ability).toLowerCase()
  return !!(Munio.config.user.permissions[level]?.abilities[ability] ?? false)
}

//
// Output CSRF token
//
window.csrf_token = function () {
  return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
}

//
// Output encrypted CSRF token
//
window.xsrf_token = function () {
  return cookies.get('XSRF-TOKEN')
}

window.uniqid = function (prefix, length) {
  let uniqe = ''
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < (length || 32); i++) {
    uniqe += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return (prefix || '') + uniqe
}

window.isNode = (o) => {
  return typeof Node === 'object'
    ? o instanceof Node
    : o && typeof o === 'object' && typeof o.nodeType === 'number' && typeof o.nodeName === 'string'
}

window.contain = (selector) => {
  return window.isNode(selector) ? selector : document.querySelector(selector)
}

window.cleanup = function (obj) {
  if (obj instanceof URLSearchParams) {
    return obj
  }

  if (Array.isArray(obj)) {
    return obj.filter((v) => {
      if (v === null || v === undefined) {
        return false
      }

      return true
    })
  }

  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== null && obj[key] !== undefined) {
      acc[key] = obj[key]
    }

    return acc
  }, {})
}

window.debounce = function (func, wait, immediate) {
  let timeout
  return function () {
    const context = this
    const args = arguments
    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

window.implode = function (glue, values) {
  return values.filter((value) => value).join(glue)
}

window.extractDispositionFilename = function (disposition, fallback = '') {
  disposition = disposition + ''
  const matches = disposition.match(/filename[^;=\n]*=(UTF-8(['"]*))?(.*)/)

  if (matches !== null && matches[3]) {
    return decodeURI(matches[3].replace(/['"]/g, ''))
  }

  return fallback
}

window.blockNavigate = function (e) {
  // Cancel the event
  e.preventDefault() // If you prevent default behavior in Mozilla Firefox prompt will always be shown
  // Chrome requires returnValue to be set
  e.returnValue = ''
  return 'Download in progress. Are you sure you want to leave?'
}

window.downloadFile = function (data, filename, mime, bom) {
  // @author https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
  const url = window.URL || window.webkitURL
  const blobData = typeof bom !== 'undefined' ? [bom, data] : [data]
  const blob = new Blob(blobData, { type: mime || 'application/octet-stream' })

  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(blob, filename)
  } else {
    const blobURL = url.createObjectURL(blob)
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', filename)

    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }

    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
    url.revokeObjectURL(blobURL)
  }
}
