<template>
  <TreeSelect
    ref="select"
    v-bind="$attrs"
    v-on="$listeners"
    :tab-index="tabindex !== undefined ? Number(tabindex) : undefined"
    :clear-all-text="clearAllText"
    :clear-value-text="clearValueText"
    :limit-text="limitText"
    :loading-text="loadingText"
    :no-children-text="noChildrenText"
    :no-options-text="noOptionsText"
    :no-results-text="noResultsText"
    :placeholder="placeholder"
    :retry-text="retryText"
    :retry-title="retryTitle"
    :search-prompt-text="searchPromptText"
    :searchable="searchable"
    :clearable="clearable"
    :multiple="multiple"
    :required="required"
    :options="options"
    :always-open="alwaysOpen || displayOnly"
    :default-expand-level="defaultExpandLevel"
    :class="[{ 'display-only': displayOnly }]"
    :append-to-body="appendToBody"
    :disable-fuzzy-matching="disableFuzzyMatching"
    :auto-focus="autofocus"
    @open="onOpen"
    @select="onSelect"
  >
    <template v-if="$scopedSlots['option-label']" v-slot:option-label="slotProps">
      <slot name="option-label" v-bind="slotProps" />
    </template>
    <template v-if="$scopedSlots['value-label']" v-slot:value-label="slotProps">
      <slot name="value-label" v-bind="slotProps" />
    </template>
    <template v-if="$slots['before-list']" v-slot:before-list>
      <slot name="before-list" />
    </template>
    <template v-if="$slots['after-list']" v-slot:after-list>
      <slot name="after-list" />
    </template>
  </TreeSelect>
</template>

<script>
// This is a wrapper for vue-treeselect
// https://vue-treeselect.js.org/#api
import TreeSelect from '@riophae/vue-treeselect'

export default {
  inheritAttrs: false,

  components: {
    TreeSelect,
  },

  props: {
    clearAllText: {
      type: String,
      default: trans('Clear all'),
    },
    clearValueText: {
      type: String,
      default: trans('Clear'),
    },
    limitText: {
      type: Function,
      default: function limitTextDefault(count) {
        return `and `.concat(count, ' more')
      },
    },
    loadingText: {
      type: String,
      default: trans('Loading'),
    },
    noChildrenText: {
      type: String,
      default: trans('No options'),
    },
    noOptionsText: {
      type: String,
      default: trans('No options'),
    },
    noResultsText: {
      type: String,
      default: trans('No entries found'),
    },
    placeholder: {
      type: String,
      default: trans('Select'),
    },
    retryText: {
      type: String,
      default: `${trans('Retry')}?`,
    },
    retryTitle: {
      type: String,
      default: trans('Retry'),
    },
    searchPromptText: {
      type: String,
      default: trans('Search'),
    },
    searchable: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
    },
    multiple: {
      type: Boolean,
    },
    required: {
      type: Boolean,
    },
    tabindex: {
      type: [String, Number],
    },
    autofocus: {
      type: Boolean,
    },
    options: {
      type: Array,
    },
    alwaysOpen: {
      type: Boolean,
    },
    displayOnly: {
      type: Boolean,
    },
    appendToBody: {
      type: Boolean,
    },
    defaultExpandLevel: {
      type: Number,
      default: 0,
    },
    disableFuzzyMatching: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      toggleTarget: null,
    }
  },

  methods: {
    onOpen(instanceId) {
      if (this.displayOnly) {
        return
      }

      this.$nextTick(() => {
        const $select = this.$refs.select
        const container = this.appendToBody ? $select.$refs.portal.portalTarget.$refs.menu.$el : $select.$refs.menu.$el
        const menu = container.querySelector('.vue-treeselect__menu')
        const selected = container.querySelector('.vue-treeselect__option--selected')

        if (selected) {
          const position = selected.offsetTop - menu.offsetHeight / 2 + selected.offsetHeight / 2
          menu.scrollTo({ top: position, behavior: 'instant' })
        }
      })
    },

    onSelect(option) {
      const currentTarget = this.toggleTarget
      const nextTarget = option.toggleTarget && document.querySelector(option.toggleTarget)
      let classList

      if (currentTarget && nextTarget !== currentTarget) {
        classList = currentTarget.classList

        if (classList.contains('collapse') || classList.contains('fade')) {
          classList.remove('in')
        } else if (classList.contains('was-hidden')) {
          classList.replace('was-hidden', 'hidden')
        } else {
          $(currentTarget).hide()
        }
      }

      if (nextTarget) {
        this.toggleTarget = nextTarget
        classList = nextTarget.classList

        if (classList.contains('collapse') || classList.contains('fade')) {
          classList.add('in')
        } else if (classList.contains('hidden')) {
          classList.replace('hidden', 'was-hidden')
        } else {
          $(nextTarget).show()
        }
      }
    },
  },
}
</script>
