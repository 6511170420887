export default {
  async fetch({ state: { userId }, commit }) {
    try {
      const { data } = await Munio.api.user(userId).relationships.get()
      commit('RESET', data)
    } catch (e) {
      commit('RESET', null)
    }
  },

  async updateRelationship({ state: { userId }, commit }, { relationship, relationshipId, departmentId, reference }) {
    try {
      commit('PROCESSING', relationship)

      const { data } = await Munio.api.user(userId).relationship(relationship.id).update({
        relationshipId,
        departmentId,
        reference,
      })

      commit('RESET', data)
    } finally {
      commit('PROCESSING', null)
    }
  },

  async removeRelationship({ state: { userId }, commit }, relationship) {
    try {
      commit('PROCESSING', relationship)

      const { data } = await Munio.api.user(userId).relationship(relationship.id).remove()

      commit('RESET', data)
    } finally {
      commit('PROCESSING', null)
    }
  },

  async restoreRelationship({ state: { userId }, commit }, relationship) {
    try {
      commit('PROCESSING', relationship)

      const { data } = await Munio.api.user(userId).relationship(relationship.id).restore()

      commit('RESET', data)
    } finally {
      commit('PROCESSING', null)
    }
  },

  async setEmployer({ state: { userId }, commit }, relationship) {
    try {
      commit('PROCESSING', relationship)

      const { data } = await Munio.api.user(userId).relationship(relationship.id).employ()

      commit('RESET', data)
    } finally {
      commit('PROCESSING', null)
    }
  },

  async addCompany({ state: { userId }, commit }, company) {
    try {
      commit('PROCESSING', true)
      const { data } = await Munio.api.user(userId).relationships.maintain(company)
      commit('RESET', data)
    } finally {
      commit('PROCESSING', null)
    }
  },
}
