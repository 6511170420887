<template>
  <div class="mdl-textfield__wrapper">
    <div :class="classes" data-upgraded="MaterialTextfield">
      <textarea
        v-if="multiline"
        ref="input"
        class="mdl-textfield__input"
        :class="inputClass"
        :id="id"
        :name="name"
        :value="input"
        :rows="numeric(inputRows)"
        :minlength="numeric(minlength)"
        :maxlength="numeric(maxlength)"
        :disabled="disabled"
        :readonly="disabled || readonly || display"
        :placeholder="placeholder"
        v-bind="attrs"
        v-on="listeners"
      />
      <input
        v-else
        ref="input"
        class="mdl-textfield__input"
        :class="inputClass"
        :min="numeric(min)"
        :max="numeric(max)"
        :minlength="numeric(minlength)"
        :maxlength="numeric(maxlength)"
        :id="id"
        :type="type"
        :name="name"
        :value="input"
        :disabled="disabled"
        :readonly="disabled || readonly || display"
        :required="required"
        :placeholder="placeholder"
        :pattern="pattern"
        v-bind="attrs"
        v-on="listeners"
      />
      <slot name="label">
        <label class="mdl-textfield__label" :class="labelClass" :for="id">
          <template v-if="displayLabel">
            {{ displayLabel }}
            <span v-if="required && !input">{{ requiredLabel }}</span>
            <i v-else-if="invalid" class="material-icons">error_outline</i>
          </template>
        </label>
      </slot>
      <div v-if="loading" class="mdl-textfield__loader">
        <mdl-spinner small />
      </div>
      <div v-if="$slots.default" class="mdl-textfield__suffix">
        <slot />
      </div>
    </div>
    <div v-if="hasFooter" class="mdl-textfield__footer">
      <label class="mdl-textfield__error">
        <template v-if="hasError">{{ error }}</template>
      </label>
      <div v-if="maxlength" class="mdl-textfield__counter">
        <span>{{ input.length }}</span>
        /
        <span>{{ maxlength }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import isNaN from 'lodash/isNaN'

export const Props = {
  id: String,
  type: {
    type: String,
    validator: (value) => !['radio', 'checkbox'].includes((value + '').toLowerCase()),
  },
  name: String,
  value: { type: [String, Number], default: '' },
  min: { type: [String, Number], default: '' },
  max: { type: [String, Number], default: '' },
  minlength: { type: [String, Number], default: '' },
  maxlength: { type: [String, Number], default: '' },
  placeholder: { type: [String, Number], default: '' },
  disabled: Boolean,
  readonly: Boolean,
  display: Boolean,
  required: Boolean,
  requiredLabel: { type: String, default: '*' },
  label: String,
  labelClass: String,
  inputClass: String,
  floatingLabel: { type: [Boolean, String], default: false },
  error: { type: [Boolean, String] },
  errorFocus: { type: Boolean },
  rows: { type: [Number, String] },
  maxrows: { type: [Number, String] },
  expandable: Boolean,
  mono: Boolean,
  compact: Boolean,
  autoselect: Boolean,
  loading: Boolean,
  align: {
    type: String,
    validator: (value) => ['left', 'center', 'right'].includes(value),
    default: 'left',
  },
  pattern: String,
}

export default {
  inheritAttrs: false,

  props: Props,

  data() {
    return {
      input: '',
      dirty: false,
      modified: false,
      focused: false,
      valid: true,
    }
  },

  computed: {
    attrs() {
      const { error, mono, center, floatingLabel, label, labelClass, ...attrs } = this.$attrs
      return attrs
    },

    invalid() {
      if (this.required && !this.modified) {
        return false
      }

      return !this.valid
    },

    listeners() {
      const events = {
        input: this.onInput,
        focus: this.onFocus,
        blur: this.onBlur,
        reset: this.onReset,
        keydown: this.onKeydown,
      }

      for (const event in this.$listeners) {
        if (event === 'input') continue

        const handler = this.$listeners[event]

        if (events.hasOwnProperty(event)) {
          events[event] = [events[event], handler]
        } else {
          events[event] = handler
        }
      }

      return events
    },

    displayLabel() {
      if (this.floatingLabel === true) {
        return this.label
      }

      return this.label || this.floatingLabel
    },

    classes() {
      return {
        'mdl-textfield': true,
        'mdl-js-textfield': true,
        'mdl-textfield--floating-label': !!this.floatingLabel,
        'mdl-textfield--monotype': this.mono,
        'mdl-textfield--compact': this.compact,
        'mdl-textfield--display': this.display,
        'mdl-textfield--center': this.align === 'center',
        'mdl-textfield--right': this.align === 'right',
        'mdl-textfield--error-focus': this.errorFocus,
        'has-placeholder': !!this.placeholder,
        'is-dirty': this.dirty,
        'is-invalid': this.invalid,
        'is-required': this.required,
        'is-disabled': this.disabled,
        'is-readonly': this.readonly || this.display,
        'is-focused': this.focused,
        'is-inline': this.$attrs.size,
        'is-upgraded': true,
      }
    },

    hasFooter() {
      return !!this.maxlength || this.hasError
    },

    hasError() {
      return typeof this.error === 'string'
    },

    inputRows() {
      if (this.expandable) {
        const input = this.focused ? this.input : this.input.trim()
        let rows = (input.match(/\n/g) || []).length + 1

        if (this.rows) {
          const minRows = parseInt(this.rows)
          if (rows < minRows) {
            rows = minRows
          }
        }

        return rows >= 1 ? rows : 1
      }

      return this.rows || this.maxrows
    },

    multiline() {
      return this.expandable || this.maxrows > 1 || this.rows > 1
    },
  },

  methods: {
    numeric(value) {
      const result = parseFloat(value, 10)

      return isNaN(result) ? undefined : result
    },

    focus() {
      this.$refs.input.focus()
      this.$refs.input.select()
    },

    blur() {
      this.$refs.input.blur()
    },

    isFocused() {
      return this.$refs.input === document.activeElement
    },

    onInput(e) {
      this.modified = true
      this.input = e.target.value
      this.update()
      this.$emit('input', this.input)
    },

    onFocus(e) {
      if (this.autoselect) {
        e.target.select()
      }
      this.update()
      this.focused = true
    },

    onBlur() {
      this.update()
      this.focused = false
    },

    onReset(e) {
      this.update()
    },

    onKeydown(e) {
      if (this.maxrows > 1 && e.key === 'Enter') {
        if (e.target.value.split('\n').length >= this.maxrows) {
          e.preventDefault()
        }
      }
      this.update()
    },

    checkValidity() {
      if (this.error) {
        return false
      }

      return this.$refs.input?.validity.valid ?? true
    },

    checkDirty() {
      return this.input.length > 0
    },

    update() {
      this.valid = this.checkValidity()
      this.dirty = this.checkDirty()
      this.$emit('validate', this.valid)
    },
  },

  mounted() {
    this.update()

    this.$watch(
      'value',
      (value, oldValue) => {
        if (value === null || value === undefined) {
          value = ''
        }

        if (value !== oldValue) {
          this.input = this.$refs.input.value = String(value)
          this.update()
        }
      },
      { immediate: true },
    )

    this.$watch(
      'error',
      (value, oldValue) => {
        this.update()
      },
      { immediate: true },
    )
  },
}
</script>

<style scoped>
input[type='number'],
input[type='url'] {
  background: transparent !important;
}

textarea {
  background: transparent !important;
}
</style>
