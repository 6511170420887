import Vue from 'vue'
import Wrapper from './Wrapper.vue'

class CourseLauncher {
  root = null

  open({ id, title, subtitle, image }, selfEnroll = false) {
    if (this.root === null) {
      let el = document.createElement('div')

      document.body.appendChild(el)

      this.root = new Vue({ el, render: (h) => h(Wrapper, { ref: 'wrapper' }) })
    }

    let img = new Image()
    img.src = image

    this.root.$refs.wrapper.show({ id, title, subtitle, image }, selfEnroll)
  }
}

Munio.CourseLauncher = new CourseLauncher()
