Munio.behaviors.radioOrOther = {
  selector: '.radio-or-other',
  attach: function (context, config) {
    $(this.selector, context).each(function (i, el) {
      let checked = null
      let other = null

      let $radios = $('input[type="radio"]', el)
      let $other = $('.radio-other', el)

      $radios.on('change', function (event) {
        if (!other) other = $other.val()
        $other.get(0).value = ''
      })

      $other.on('focus', function (event) {
        if (other) this.value = other
        $radios.each(function (i, el) {
          if (el.checked) checked = i
        })
        $radios.prop('checked', false)
      })

      $other.on('change', function (event) {
        other = this.value
      })

      $other.on('blur', function (event) {
        if (this.value == '' && checked) {
          $radios[checked].checked = true
          checked = null
        }
      })
    })
  },
}
