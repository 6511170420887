import LanguageSelector from '@/munio/vue/components/LanguageSelector/index.vue'

/**
 * Language selector
 */
Munio.behaviors.panelLanguagesSelector = {
  selector: '.panel-languages',
  attach: function (context, config) {
    $(this.selector, context).each(function (i, el) {
      Munio.Vue({
        el,
        render: function (createElement) {
          return createElement(LanguageSelector, {
            props: JSON.parse(el.dataset.options || '{}') || {
              onlySiteLanguages: false,
              selected: [],
              delete: false,
            },
          })
        },
      })
    })
  },
}
