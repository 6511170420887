<template>
  <ProgressBar v-if="isLoading" :indeterminate="true" />
  <div v-else-if="error" class="alert alert-danger">{{ error }}</div>
  <div v-else>
    <template v-if="withHeader">
      <div
        class="coursemodal__header px-8 py-12 flex flex-col justify-end"
        :style="`background-image: url(${product.images.shop});`"
      >
        <div v-if="hasBadges" class="coursemodal__badges">
          <div v-for="badge in product.course.badges" :key="badge" class="course-badge">
            <img :src="`/img/badges/${badge}.svg`" alt="Badge" />
          </div>
        </div>

        <div class="opaque">{{ product.identifier }}</div>
        <h3>{{ product.title }}</h3>
      </div>

      <button class="coursemodal__cancel" data-dismiss="modal">
        <span class="material-icons">close</span>
      </button>
    </template>

    <ProductPrices :product="product" :cart="cart" />

    <div v-if="product.url.brochure || product.url.demo" class="product-actions m-12">
      <a v-if="product.url.brochure" class="mdl-button mdl-button--raised" :href="product.url.brochure" target="_blank">
        <i class="material-icons small mr-4">picture_as_pdf</i>
        {{ trans('Brochure') }}
      </a>
      <a v-if="product.url.demo" class="mdl-button mdl-button--raised" :href="product.url.demo" target="_blank">
        <i class="material-icons small mr-4">play_arrow</i>
        {{ trans('Demo') }}
      </a>
    </div>

    <div class="product-description large m-8 sm:m-12" v-html="product.description" />

    <mdl-details v-if="product.content" class="product-content mx-12 my-8" :summary="trans('Content')">
      <div v-html="product.content"></div>
    </mdl-details>

    <ProductDetails :product="product" :categories="productCategories" @category="(e) => $emit('goToCategory', e)" />
  </div>
</template>

<script>
import ProgressBar from '../mdl/ProgressBar.vue'
import ProductPrices from './ProductPrices.vue'
import ProductDetails from './ProductDetails.vue'

export default {
  components: {
    ProgressBar,
    ProductPrices,
    ProductDetails,
  },

  props: {
    id: { type: [String, Number], required: true },
    withHeader: { type: Boolean, default: true },
    categories: { type: Array, default: () => [] },
  },

  data() {
    return {
      isLoading: true,
      product: null,
      cart: null,
      error: null,
    }
  },

  mounted() {
    this.fetchData()
  },

  computed: {
    hasBadges() {
      if (!this.product) {
        return false
      }

      return this.product.course?.badges?.length > 0
    },

    productCategories() {
      return this.categories.filter((category) => this.product.categories.includes(category.id))
    },
  },

  methods: {
    async fetchData() {
      this.isLoading = true

      try {
        const api = Munio.api.shop().product(this.id)
        const {
          data: { data, cart },
        } = await api.get()

        this.product = data
        this.product._api = api
        this.cart = cart
      } catch (err) {
        this.error = err.message
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
