class UserRelationship {
  userId
  companyUserId

  constructor(userId, companyUserId) {
    this.userId = userId
    this.companyUserId = companyUserId
  }

  get basePath() {
    return `/api/users/${this.userId}/relationships/${this.companyUserId}`
  }

  remove() {
    return Munio.$http.post(`${this.basePath}/remove`)
  }

  restore() {
    return Munio.$http.post(`${this.basePath}/restore`)
  }

  update({ relationshipId, departmentId, reference }) {
    return Munio.$http.post(`${this.basePath}/update`, {
      relationship_id: relationshipId,
      department_id: departmentId,
      reference,
    })
  }

  employ() {
    return Munio.$http.post(`${this.basePath}/employ`)
  }
}

export default UserRelationship
