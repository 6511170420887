import * as actions from '../actions/index.js'

const initialState = { ready: false, loading: false }

//
// Reducers
//

const attempts = (state = {}, action) => {
  switch (action.type) {
    case actions.ATTEMPT_LOAD:
    case actions.ATTEMPT_UPDATE:
      return Object.assign({}, state, { [action.id]: attempt(state[action.id], action) })

    case actions.NODE_PLAY:
      return Object.assign({}, state, { [action.attemptId]: attempt(state[action.attemptId], action) })

    default:
      return state
  }
}

const attempt = (state = initialState, action) => {
  switch (action.type) {
    case actions.ATTEMPT_LOAD:
      return Object.assign({}, state, { loading: true })

    case actions.ATTEMPT_UPDATE:
      return Object.assign({}, initialState, action.attempt, {
        ready: true,
        loading: false,
      })

    case actions.NODE_PLAY:
      return Object.assign({}, state, { currentNodeId: action.id })

    default:
      return state
  }
}

export default attempts

//
// Selectors
//

export const getAttempt = (state, id) => {
  return state.attempts[id] || initialState
}

export const getLanguage = (state) => {
  return getAttempt(state, state.mainAttemptId).language
}
