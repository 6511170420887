import $ from 'jquery'
import { forEach } from 'lodash'

//
// Patch touch events
//

forEach(['touchstart', 'touchmove', 'wheel'], (event) => {
  $.event.special[event] = {
    setup: function (_, ns, handle) {
      this.addEventListener(event, handle, { passive: true })
    }
  }
})

export default $
