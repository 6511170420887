<template>
  <div id="course-modals">
    <course-launcher-modal
      v-for="{ id, title, subtitle, image, context, show, selfEnroll } in modalsArray"
      :key="id"
      :show="show"
      :id="id"
      :title="title"
      :subtitle="subtitle"
      :image="image"
      :context="context"
      :selfEnroll="selfEnroll"
      @cancel="hide(id)"
    />
  </div>
</template>

<script>
import CourseLauncherModal from './CourseLauncherModal.vue'

export default {
  components: {
    CourseLauncherModal,
  },

  data() {
    return {
      modals: {},
    }
  },

  computed: {
    modalsArray() {
      const arr = []
      for (const id of Object.keys(this.modals)) {
        arr.push(this.modals[id])
      }
      return arr
    },
  },

  methods: {
    show({ id }, selfEnroll) {
      const modal = { ...arguments[0], selfEnroll, show: true }
      let target = this.modals[id]

      if (!target) {
        this.modals = { ...this.modals, [id]: modal }

        target = this.modals[id]
      } else {
        target.show = true
        this.modals = { ...this.modals, [id]: target }
      }
    },

    hide(id) {
      const modal = this.modals[id]

      if (!modal) {
        return
      }

      modal.show = false

      this.modals = { ...this.modals, [id]: modal }
    },
  },
}
</script>
