import ShopAdmin from './ShopAdmin'
import ShopCart from './ShopCart'
import ShopProduct from './ShopProduct.js'
import ShopCheckout from './ShopCheckout'
import ShopCustomer from './ShopCustomer.js'

class Shop {
  slug

  constructor(slug, context) {
    this.slug = slug
    this.context = context
  }

  get basePath() {
    return `/api/shop/${this.slug}`
  }

  salespeople() {
    return Munio.$http.get(this.basePath + '/salespeople')
  }

  products(options) {
    return Munio.$http.get(this.basePath + '/product', options)
  }

  product(id) {
    return new ShopProduct(this.basePath, id)
  }

  customer(id) {
    return new ShopCustomer(this.basePath, id)
  }

  get cart() {
    return new ShopCart(this.slug, this.context)
  }

  get checkout() {
    return new ShopCheckout(this.slug, this.context)
  }

  region(region) {
    window.Munio.$http.post(Munio.route('api.web.shop.region.change', { shop: this.slug }), {
      region,
    })
  }

  get admin() {
    return new ShopAdmin(this.slug)
  }
}

export default Shop
