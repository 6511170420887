<template>
  <div>
    <template v-if="selected.length > 0">
      <div class="p-8 pt-0">
        <h4 class="mdl-subheader m-0 mb-4">
          {{ trans('Selected') }}<small class="label label-default ml-2">{{ selected.length }}</small>
        </h4>
        <div class="mdl-chips max-h-25vh overflow-y-auto">
          <mdl-chip
            v-for="course of coursesSelected"
            :key="course.id"
            :image="course.image.avatar"
            deletable
            @delete="onRemove(course.id)"
          >
            {{ course.title }}
          </mdl-chip>
        </div>
        <slot name="selection" />
      </div>
    </template>

    <mdl-searchbar v-if="coursesAvailable.length >= 5" class="px-8 mb-8" v-model="search" />

    <template v-if="coursesAvailable.length">
      <mdl-tabs v-if="groups.length > 1" v-model="selectedTab">
        <mdl-tab
          v-for="group of groups"
          :key="group.type"
          :id="group.type"
          :label="group.label"
          :disabled="search !== '' || !group.courses.length"
        />
      </mdl-tabs>

      <div v-if="search && !coursesAvailable.length" class="modal-body py-8 opaque">
        {{ trans('No results') }}
      </div>

      <div v-else-if="coursesFiltered.length" class="mdl-list max-h-50vh overflow-y-auto">
        <mdl-list-item
          v-for="course in coursesFiltered"
          :key="course.id"
          type="card"
          :thumbnail="course.image.avatar"
          :title="course.title"
          :overline="course.identifier"
        >
          <template v-slot:actions>
            <mdl-button icon="add" primary @click="onAdd(course.id)" />
          </template>
        </mdl-list-item>
      </div>

      <div v-else class="modal-body py-8 opaque">
        {{ trans('All courses have been selected') }}
      </div>
    </template>

    <div v-else slot="body">
      {{ emptyText }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    courses: { type: Array, required: true },
    except: { type: Array, default: () => [] },
    selected: { type: Array, default: () => [] },
    emptyText: {
      type: String,
      default: () => trans('The user is already enrolled to all accessable courses.'),
    },
  },

  model: {
    prop: 'selected',
    event: 'change',
  },

  data() {
    return {
      search: '',
      currentTab: null,
    }
  },

  computed: {
    selectedTab: {
      get() {
        if (this.search) {
          return false
        }
        return this.currentTab
      },
      set(value) {
        this.currentTab = value
      },
    },

    groups() {
      return Munio.config.courseGroups.reduce((groups, { type, label }) => {
        const courses = this.courses.filter((course) => course.typeGroup === type)

        if (courses.length) {
          groups.push({
            type,
            label,
            courses,
          })
        }

        return groups
      }, [])
    },

    hasTabs() {
      return this.groups.length > 1
    },

    coursesAvailable() {
      if (!this.except.length) {
        return this.courses
      }

      return this.courses.filter((course) => !this.except.includes(course.id))
    },

    coursesFiltered() {
      const courses = this.coursesAvailable.filter((course) => !this.selected.includes(course.id))

      if (this.search) {
        return courses.filter((course) => this.matchesSearch(course))
      }

      if (this.selectedTab) {
        return courses.filter((course) => course.typeGroup === this.selectedTab)
      }

      return courses
    },

    coursesSelected() {
      return this.selected.map((courseId) => this.courses.find((course) => course.id === courseId))
    },
  },

  methods: {
    matchesSearch(course) {
      const cmp = (str) => {
        return (
          String(str ?? '')
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) !== -1
        )
      }

      return cmp(course.title) || cmp(course.identifier) || String(course.id) === this.search
    },

    onRemove(courseId) {
      const i = this.selected.indexOf(courseId)

      if (i < 0) {
        return
      }

      const selected = [...this.selected.slice(0, i), ...this.selected.slice(i + 1)]
      this.$emit('change', selected)
    },

    onAdd(courseId) {
      if (this.selected.indexOf(courseId) >= 0) {
        return
      }

      const selected = [...this.selected, courseId]
      this.$emit('change', selected)
    },
  },
}
</script>
