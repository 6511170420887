const state = {
  certificates: {
    list: [],
    addable: [],
    loading: true,
  },
}

const actions = {
  load(store) {
    store.dispatch('fetchCertificates')
    store.dispatch('fetchAddable')
  },

  async fetchCertificates(store) {
    const response = await Munio.api.currentUser.certificates.get()
    store.commit('UPDATE_CERTIFICATES', response)
    store.commit('LOADING_CERTIFICATES', false)
  },

  async fetchAddable(store) {
    const response = await Munio.api.currentUser.certificates.addable()
    store.commit('UPDATE_ADDABLE', response)
  },

  async addCertificate(store, { typeId, identifier, bornAt }) {
    const copy = {
      certificates: [...store.state.certificates.list],
      addable: [...store.state.certificates.addable],
    }
    const update = {
      certificates: [
        ...copy.certificates,
        { ...copy.addable.find((c) => Number(c.id) === Number(typeId)), identifier },
      ],
      addable: copy.addable.filter((c) => Number(c.id) !== Number(typeId)),
    }
    try {
      store.commit('LOADING_CERTIFICATES', true)
      store.commit('UPDATE_CERTIFICATES', { data: { data: update.certificates } })
      store.commit('UPDATE_ADDABLE', { data: { data: update.addable } })
      await Munio.api.currentUser.certificates.add(typeId, identifier, bornAt)
    } catch (err) {
      store.commit('UPDATE_CERTIFICATES', { data: { data: copy.certificates } })
      store.commit('UPDATE_ADDABLE', { data: { data: copy.addable } })
      const msg = err.response?.data?.error?.message
      Munio.Flash.error(trans('Unable to add certificate'), trans(msg))
    } finally {
      store.commit('LOADING_CERTIFICATES', false)
    }
  },

  async removeCertificate(store, id) {
    const copy = {
      certificates: [...store.state.certificates.list],
    }
    const update = {
      certificates: copy.certificates.filter((c) => Number(c.id) !== Number(id)),
    }
    try {
      store.commit('LOADING_CERTIFICATES', true)
      store.commit('UPDATE_CERTIFICATES', { data: { data: update.certificates } })
      await Munio.api.currentUser.certificates.delete(id)
      store.dispatch('fetchAddable')
    } catch (err) {
      store.commit('UPDATE_CERTIFICATES', { data: { data: copy.certificates } })
      const msg = err.response?.data?.error?.message
      Munio.Flash.error(trans('Unable to remove certificate'), trans(msg))
    } finally {
      store.commit('LOADING_CERTIFICATES', false)
    }
  },
}

const mutations = {
  LOADING_CERTIFICATES(state, loading = true) {
    state.certificates.loading = loading
  },
  UPDATE_CERTIFICATES(state, response) {
    const {
      data: { data },
    } = response
    state.certificates.list = data
  },
  UPDATE_ADDABLE(state, response) {
    const {
      data: { data },
    } = response
    state.certificates.addable = data
  },
}

export default {
  state,
  actions,
  mutations,
}
