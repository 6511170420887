import Action from './Action.js'

class Integrations {
  constructor(id) {
    this.id = id
  }

  get params() {
    return { integration: this.id }
  }

  get() {
    return Munio.$http.get(Munio.route('api.web.integration', this.params))
  }

  changeMode(mode) {
    return Munio.$http.put(Munio.route('api.web.integration.mode', { ...this.params, mode }))
  }

  actionsTrigger(action) {
    return Munio.$http.post(Munio.route('api.web.integration.actions.trigger'), { action })
  }

  actions(options) {
    return Munio.$http.get(Munio.route('api.web.integration.actions', this.params), Munio.urlParams(options))
  }

  action(ulid) {
    return new Action(this.id, ulid)
  }
}

export default Integrations
