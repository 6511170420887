import {
  CollectionResponse,
  ResourceResponse,
  UserBasicResourceDto,
  UserMergeResourceDto,
  UserProfileMap,
} from '../data'

const { $http } = window.Munio

class UserMerge {
  constructor() {}

  index(query: string | number | object) {
    return $http.get<CollectionResponse<UserMergeResourceDto>>(
      Munio.route('api.web.munio.merge.users'),
      Munio.urlParams(query),
    )
  }

  get(id: number) {
    return $http.get<ResourceResponse<UserMergeResourceDto>>(
      Munio.route('api.web.munio.merge.users.show', { merge: id }),
    )
  }

  create(data: { candidates: number[] }) {
    return $http.post<ResourceResponse<UserMergeResourceDto>>(Munio.route('api.web.munio.merge.users.create'), data)
  }

  update(id: number, data: MergeUpdateData) {
    return $http.patch<ResourceResponse<UserMergeResourceDto>>(
      Munio.route('api.web.munio.merge.users.update', { merge: id }),
      data,
    )
  }

  execute(id: number) {
    return $http.post<undefined>(Munio.route('api.web.munio.merge.users.execute', { merge: id }))
  }

  delete(id: number) {
    return $http.delete<undefined>(Munio.route('api.web.munio.merge.users.delete', { merge: id }))
  }

  deleteCandidate(id: number) {
    return $http.delete<ResourceResponse<UserMergeResourceDto> | undefined>(
      Munio.route('api.web.munio.merge.users.deleteCandidate', { user: id }),
    )
  }
}

export default UserMerge

type MergeUpdateData = {
  masterUserId: number
  supportTickets: string[]
  profileMap: {
    name?: number
    employer?: number
    email?: number
    mobile?: number
    born?: number
  }
}
