<template>
  <li v-if="content || $slots.content"><slot /><slot name="content"></slot></li>

  <li v-else-if="header" :class="classes" @click="handleClick">
    <slot></slot>
  </li>

  <li v-else-if="divide" @click.stop="handleClick" role="separator" class="divider"></li>

  <li v-else-if="href" :class="classes">
    <a :href="href" @click="handleClick"><slot></slot></a>
  </li>

  <li v-else @click="handleClick" :class="classes">
    <a v-if="disabled" aria-disabled="true" style="pointer-events: none" @click.prevent.stop><slot></slot></a>
    <a v-else href><slot></slot></a>
  </li>
</template>

<script>
export default {
  props: {
    header: { type: Boolean, default: false },
    content: { type: Boolean, default: false },
    divide: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    href: { type: String, default: null },
  },

  computed: {
    classes() {
      return [
        this.$attrs.class,
        {
          disabled: this.disabled,
          'dropdown-header': this.header,
        },
      ]
    },

    isClickable() {
      return this.$listeners.click !== undefined
    },
  },

  methods: {
    handleClick(event) {
      if (this.disabled) {
        event.stopPropagation()
        event.preventDefault()
        return
      }

      if (!this.href) {
        event.preventDefault()
      }

      this.$emit('click', event)
    },
  },
}
</script>
