class Salesorders {
  create(shop, company) {
    return Munio.$http.post(Munio.route('api.web.salesorders.create'), {
      shop,
      company,
    })
  }
}

export default Salesorders
