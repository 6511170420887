<template>
  <div v-if="discounts.length">
    <img src="./assets/discount.svg" class="h-24px" />

    {{ trans('We offer volume discounts for customers ordering multiple licenses.') }}
    <ul>
      <li
        v-for="discount in discounts"
        :key="discount.id"
        v-html="
          trans(`:from+ licenses: <strong>:percent%</strong> discount`, {
            from: discount.from,
            percent: discount.percent * 100,
          })
        "
      />
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    discounts: { type: Array, default: () => [] },
  },

  computed: {
    account() {
      return Munio.config.account
    },
  },
}
</script>
