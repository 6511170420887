<template>
  <modal :show="show" @close="$emit('close', false)" :loading="loading" :title="trans('Add course')">
    <course-selector v-if="show && courses.length" v-model="coursesSelected" :courses="courses" :except="except" />
    <template #actions>
      <mdl-button raised primary :disabled="!coursesSelected.length" @click="onSubmit">
        {{ trans('Add') }}
      </mdl-button>
      <mdl-button @click="$emit('close', false)">
        {{ trans('Cancel') }}
      </mdl-button>
    </template>
  </modal>
</template>

<script>
export default {
  props: {
    show: Boolean,
    courses: { type: Array, required: true },
    except: { type: Array, default: () => [] },
    selected: { type: Array, default: () => [] },
    loading: Boolean,
  },

  model: {
    prop: 'show',
    event: 'close',
  },

  data() {
    return {
      coursesSelected: [],
    }
  },

  watch: {
    show: {
      immediate: true,
      handler(value) {
        if (value) {
          this.coursesSelected = this.selected
        }
      },
    },
  },

  methods: {
    async onSubmit() {
      this.$emit('select', this.coursesSelected)
      this.$emit('close', false)
    },
  },
}
</script>
