import Wrapper from '@/munio/vue/Wrapper.js'

Munio.QuizPlayer = class extends Wrapper {
  constructor(selector, { attemptId, nodeId, language, model, playback }) {
    super(selector, {
      attemptId,
      nodeId,
      language,
      model,
      playback,
    })
  }

  get name() {
    return 'QuizPlayer'
  }

  component() {
    return import('./Quiz.vue')
  }
}
