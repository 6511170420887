<template>
  <div>
    <h3 class="mt-0 flex flex-col">
      <span>{{ zone.name }}</span>
      <small v-if="gate.id != zone.id">{{ gate.name.default }}</small>
    </h3>

    <p v-if="image"><img :src="image.header" class="rounded" /></p>

    <p>{{ trans('You have been invited as an administrator.') }}</p>

    <slot />
  </div>
</template>

<script>
export default {
  props: {
    invite: { type: Object, required: true },
  },

  computed: {
    zone() {
      return this.invite.element.zone
    },

    gate() {
      return this.zone.gate
    },

    image() {
      if (this.zone.image?.exists) {
        return this.zone.image
      }

      if (this.gate.image?.exists) {
        return this.gate.image
      }

      return null
    },
  },
}
</script>
