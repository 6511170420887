class Charts {
  get basePath() {
    return '/api/munio/charts'
  }

  sales() {
    return Munio.$http.get(`${this.basePath}/sales`)
  }

  storage() {
    return Munio.$http.get(`${this.basePath}/storage`)
  }
}

export default Charts
