<template>
  <mdl-paginator
    :rows-from="rowsFrom"
    :rows-to="rowsTo"
    :rows-total="rowsTotal"
    :page-rows="pageRows"
    :page-current="pageCurrent"
    :page-last="pageLast"
    :loading="loading"
    @goto="goto"
  ></mdl-paginator>
</template>

<script>
export default {
  props: {
    data: Object,
    loading: Boolean,
  },

  computed: {
    pageRows() {
      return this.data.perPage
    },

    pageCurrent() {
      return this.data.currentPage || 1
    },

    pageLast() {
      return this.data.totalPages || 1
    },

    offset() {
      return (this.pageCurrent - 1) * this.pageRows
    },

    rowsFrom() {
      return this.offset + 1
    },

    rowsTo() {
      return this.offset + this.data.count
    },

    rowsTotal() {
      return this.data.total
    },
  },

  methods: {
    goto(page) {
      if (page < 1) return
      if (page > this.pageLast) return

      this.$emit('change', page)
    },
  },
}
</script>
