<template>
  <div>
    <MdlButton :disabled="isProcessing" :data-qa-id="pendo('back')" @click="emit('close')">
      <Icon name="arrow_back" />
      {{ trans('Back') }}
    </MdlButton>

    <Stepper v-model="store.step" :steps="store.steps" :editable="!isProcessing" @activate="onChangeStep" />
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'

import { useShopCheckoutStore } from '@/munio/vue/ShopCheckout/store'
import { saveActiveStep } from '@/munio/vue/ShopCheckout/utils'
import { trans } from '@/munio/i18n/index.js'

import Stepper from '@/munio/vue/ShopCheckout/Stepper/Stepper.vue'
import MdlButton from '@component/mdl/Button.vue'
import Icon from '@component/Icon.vue'

const emit = defineEmits<{
  (e: 'close'): void
}>()

const store = useShopCheckoutStore()
const isProcessing = computed(() => store.loading === 'processing')
const onChangeStep = (step: string) => {
  saveActiveStep(store.cart.uuid, step)
}

watch(
  () => store.paymentProcessed,
  (newV) => {
    if (newV) {
      emit('close')
    }
  },
)
</script>
