import { defineStore } from 'pinia'

function sortActions(actions) {
  actions.sort((a, b) => {
    if (a.createdAt > b.createdAt) return -1
    if (a.createdAt < b.createdAt) return 1
    return 0
  })

  return actions
}

const useStore = defineStore('integrationActions', {
  state: () => ({
    channel: null,
    connection: {
      previous: null,
      current: null,
    },
    live: false,
    actions: {
      list: [],
      added: [],
      pending: [],
      selected: null,
      loading: false,
      paginating: false,
    },
  }),

  getters: {
    actionsShown(state) {
      return sortActions([...state.actions.list, ...state.actions.added])
    },
  },

  actions: {
    set_connection(connection) {
      this.connection = connection
    },

    set_live(live) {
      this.live = live

      if (live) {
        this.actions.list = []
        this.actions.pending = []
      }
    },

    set_loading(value) {
      this.actions.loading = value
    },

    set_paginating(value) {
      this.actions.paginating = value
    },

    set_actions(actions) {
      this.actions.list = actions
      this.actions.pending = []
      this.actions.selected = null
    },

    set_added(actions) {
      actions.forEach((action) => {
        const existingAction = this.actions.list.find((a) => a.ulid === action.ulid)

        if (existingAction) {
          Object.assign(existingAction, action)
        } else {
          this.actions.list = [action, ...this.actions.list]
        }
      })

      sortActions(this.actions.list)
    },

    set_selected(value) {
      this.actions.selected = this.actions.selected === value ? null : value
    },

    connect(channel) {
      this.channel = channel
    },

    disconnect() {
      this.channel?.unbind()
      this.channel = null
    },

    store_action(action) {
      const actions = [...this.actions.list, ...this.actions.pending]

      if (action.parent?.ulid) {
        const parentAction = actions.find((a) => a.ulid === action.parent.ulid)

        this.actions.pending = [action, ...this.actions.pending]

        if (parentAction) {
          Object.assign(parentAction, { modified: true })
          return
        }
      }

      const existingAction = actions.find((a) => a.ulid === action.ulid)

      if (existingAction) {
        Object.assign(existingAction, action)
      } else {
        if (this.live) {
          this.actions.list = [action, ...this.actions.list]
          sortActions(this.actions.list)
        } else {
          this.actions.pending = [action, ...this.actions.pending]
        }
      }
    },

    onMount({ actions, channel, live }) {
      this.set_live(live)
      this.set_actions(actions)

      if (channel) {
        Munio.Websocket().connection.bind('state_change', (connection) => {
          this.set_connection(connection)
        })

        channel = Munio.Websocket().subscribe(channel)
        channel.bind('action', (action) => this.store_action({ ...action, modified: true }))
        this.connect(channel)
      }
    },

    onUnmount() {
      if (this.channel) {
        Munio.Websocket().unsubscribe(this.channel.name)
        this.disconnect()
      }
    },

    async loadAction({ action, page = null }) {
      try {
        this.set_loading(action.ulid)

        if (page !== null) {
          this.set_paginating(action.ulid)
        }

        const {
          data: { data: events, meta, action: model },
        } = await Munio.api.integration(action.integration.id).action(action.ulid).events(page)

        this.store_action({
          ...Object.assign({}, model, { events, eventsMeta: meta }),
          modifed: false,
        })
      } catch (err) {
        console.error(err)
      } finally {
        this.set_loading(false)
        this.set_paginating(false)
      }
    },

    async toggleAction(action) {
      this.set_selected(action.ulid)

      if (this.actions.selected) {
        this.loadAction({ action })
      }
    },
  },
})

export default useStore
