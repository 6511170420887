<template>
  <div v-if="filter" :class="['mdl-filter', `mdl-filter--${type}`, { 'is-active': isEditing }]">
    <input
      v-for="input of values"
      :key="`${input.name}.${input.value}`"
      type="hidden"
      :name="input.name"
      :value="input.value"
    />

    <mdl-chip
      ref="chip"
      tabindex="0"
      :highlight="isHighlighted"
      :deletable="isRemovable"
      @click.stop="edit"
      @delete="remove"
      @keydown.native.enter="edit"
      @keydown.native.delete="remove"
    >
      <template v-if="valueDisplay">
        <span>{{ label }}: {{ valueDisplay }}</span>
      </template>
      <template v-else>
        {{ label }}
      </template>
    </mdl-chip>

    <form
      v-if="isEditing"
      ref="input"
      autocomplete="off"
      :class="['mdl-filter__input', `mdl-filter__input--${type}`]"
      @keydown.tab="trap"
      @keydown.esc="cancel"
      @keydown.stop
      @submit.prevent
      v-click-outside="cancel"
      v-keydown-outside="keydown"
    >
      <input type="hidden" name="name" :value="name" />

      <div class="mdl-filter__input-header">
        {{ label }}
      </div>

      <component
        class="mdl-filter__input-body"
        :class="{
          [`mdl-filter__input-body--${type}`]: true,
          'is-disabled': hasExtraInput && !allowMultipleInput && extraActive,
        }"
        :is="formComponent(type)"
        v-bind="filter"
        :value="value"
        @activate="setExtraActive(false)"
        @keydown.enter="apply"
        @submit.stop="apply"
      />

      <hr v-if="hasExtraInput" class="mdl-divider my-4" />

      <component
        v-if="hasExtraInput"
        class="mdl-filter__input-body mdl-filter__input-body--extra"
        :class="{
          [`mdl-filter__input-body--${extraInput.type}`]: true,
          'is-disabled': !allowMultipleInput && !extraActive,
        }"
        :autofocus="false"
        input-name="extraValue"
        :is="formComponent(extraInput.type)"
        :label="extraInput.label"
        v-bind="extraInput"
        :value="extraValue"
        @activate="setExtraActive(true)"
        @keydown.enter="apply"
        @submit.stop="apply"
      />

      <div class="mdl-filter__input-actions">
        <mdl-button @click.stop="apply" primary>{{ trans('OK') }}</mdl-button>
        <mdl-button @click.stop="cancel">{{ trans('Cancel') }}</mdl-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { focusTrap } from '@/munio/utils/index.js'
import { values, valueDisplay, typeForms, valueDefault, filterValue } from './helpers.js'

export default {
  name: 'MdlFilter',

  props: {
    name: { type: String, required: true },
  },

  data() {
    return {
      extraActive: false,
    }
  },

  mounted() {
    if (this.extraInput?.isActive) {
      this.extraActive = true
    }
  },

  computed: {
    ...mapState({
      form: 'form',
    }),
    ...mapGetters({
      getFilter: 'getFilter',
      getFilterValue: 'getFilterValue',
      isFilterDirty: 'isFilterDirty',
    }),
    isDirty() {
      return this.isFilterDirty(this.name)
    },
    extraInput() {
      return this.filter.extraInput
    },
    hasExtraInput() {
      return this.extraInput !== undefined && this.extraInput !== null
    },
    allowMultipleInput() {
      return this.hasExtraInput && this.extraInput.allowMultipleInput
    },
    filter() {
      return this.getFilter(this.name)
    },
    isHighlighted() {
      return this.isEditing || this.filter.isFlag
    },
    isEditing() {
      return this.form.filter === this.name
    },
    isEditable() {
      return typeForms[this.type] !== undefined
    },
    isRemovable() {
      return [null, undefined].includes(this.filter.valueDefault)
    },
    type() {
      return this.filter.type
    },
    label() {
      return this.filter.label
    },
    options() {
      return this.filter.options
    },
    valueDefault() {
      return this.filter.valueDefault
    },
    value() {
      const value = this.getFilterValue(this.name)

      if (value.extraValue !== undefined) {
        return this.allowMultipleInput ? value.value : this.valueDefault
      }

      return value
    },
    extraValue() {
      if (!this.hasExtraInput) {
        return undefined
      }

      const value = this.getFilterValue(this.name)
      return value?.extraValue !== undefined ? value.extraValue : valueDefault(this.extraInput)
    },
    hasExtraValue() {
      const value = this.getFilterValue(this.name)

      return value !== undefined && Object.hasOwn(value, 'extraValue')
    },
    values() {
      return values({ ...this.filter, value: this.value })
    },
    valueDisplay() {
      let val = valueDisplay({ ...this.filter, value: this.value })
      let xtr
      if (this.hasExtraInput && this.hasExtraValue) {
        xtr = valueDisplay({ ...this.filter.extraInput, value: this.extraValue })
        return this.allowMultipleInput ? `${val} (${xtr})` : xtr
      }
      return val
    },
  },

  methods: {
    setExtraActive(value) {
      if (this.extraActive !== value) {
        this.extraActive = value
      }
    },

    formComponent(type) {
      const component = typeForms[type]
      if (!component) {
        throw new Error('Unknown filter type: ' + type)
      }

      return component
    },

    focus() {
      this.$nextTick(() => {
        if (this.$refs.chip) {
          this.$refs.chip.$el.focus()
        }
      })
    },

    keydown(e) {
      if (!this.isEditing) {
        return
      }

      switch (e.code) {
        case 'Escape':
          return this.cancel()
        case 'Tab':
          return this.trap(e)
      }
    },

    edit() {
      if (this.isEditable) {
        this.$store.dispatch('editFilter', this.name)
        this.$emit('edit', this.name)
      }
    },

    remove() {
      this.$store.dispatch('removeFilter', this.name)
      this.$emit('remove', this.name)
    },

    apply() {
      const value = filterValue(this.filter, this.$refs.input, this.extraActive)
      if (value !== undefined) {
        const payload = { name: this.name, value }
        this.$store.dispatch('applyFilter', payload)
        this.$emit('apply', payload)
        this.focus()
      } else {
        this.remove()
      }
    },

    cancel() {
      if (this.isEditing) {
        this.$store.dispatch('cancelFilter', this.name)
        this.$emit('cancel')
        this.focus()
      }
    },

    trap(e) {
      focusTrap(this.$refs.input, e)
    },
  },
}
</script>
