<template>
  <modal :show="isVisible" @hidden="cancel">
    <div class="p-8 flex items-center flex-col" v-if="company">
      <div class="flex justify-center mb-8">
        <div>
          <img :src="company.logoUrl" :alt="company.name" style="width: 100px" />
        </div>
      </div>
      <h4 style="margin: 0; font-weight: 400">{{ company.name }}</h4>
    </div>

    <div class="modal-header" v-if="!working">
      <h3 class="modal-title" style="font-weight: 400">{{ trans('Select zone(s)') }}</h3>
    </div>

    <template v-if="working">
      <mdl-progressbar indeterminate />
    </template>

    <div v-else>
      <list list-class="mdl-list--cards" :data="{ data: visibleZones }">
        <mdl-list-item
          :data-zone-id="item.id"
          type="card"
          slot="item"
          slot-scope="{ item }"
          class="zone"
          :depth="item.depth"
          :background="item.avatar.color"
          :initials="item.avatar.initials"
          :title="item.name"
          :expandable="false"
          @click.native="toggle(item)"
        >
          <label v-if="isSelectable(item)" slot="actions" class="input-checkbox">
            <input
              type="checkbox"
              @click.stop="toggle(item)"
              :checked="isSelected(item)"
              :disabled="isSelectedByDescendant(item)"
            />
          </label>
        </mdl-list-item>
      </list>
    </div>

    <div class="mdl-divider"></div>

    <div v-if="!working" class="modal-actions pt-8">
      <mdl-button primary :disabled="!changed.length" raised @click="confirm">
        {{ trans('Save') }}
      </mdl-button>

      <mdl-button @click="cancel">
        {{ trans('Cancel') }}
      </mdl-button>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'AccessZoneModal',

  props: {
    company: Object,
  },

  data() {
    return {
      zones: [],
      changed: [],
      working: false,
    }
  },

  watch: {
    company(value) {
      this.changed = []
      if (value) this.fetch()
    },
  },

  computed: {
    isVisible() {
      return this.company !== null
    },

    visibleZones() {
      return this.zones.filter((zone) => {
        if (zone.company.id !== this.company.id) {
          return false
        }

        return zone.isEnrollablePublic || this.hasSelectableDescendant(zone)
      })
    },

    registeredZones() {
      return this.zones.filter((zone) => zone.user.isRegistered)
    },
  },

  methods: {
    toggle(zone) {
      if (!this.isSelectable(zone)) {
        return false
      }

      zone.user.isRegistered = !zone.user.isRegistered

      const index = this.changed.indexOf(zone)

      if (index !== -1) {
        this.changed = [...this.changed.slice(0, index), ...this.changed.slice(index + 1)]
      } else {
        this.changed.push(zone)
      }
    },

    isSelectable(zone) {
      return zone.isEnrollablePublic
    },

    getZone(id) {
      return this.zones.find((z) => z.id === id)
    },

    path(zone) {
      if (!zone.parentId) return []
      let parent = zone
      let path = []

      while (parent) {
        parent = this.getZone(parent.parentId)

        if (parent) {
          path.unshift(parent.id)
        }
      }

      return path
    },

    hasSelectableDescendant(zone) {
      return this.zones.find((z) => z.isEnrollablePublic && this.path(z).includes(zone.id))
    },

    isSelectedByDescendant(zone) {
      return !!this.registeredZones.find((z) => this.path(z).includes(zone.id))
    },

    isSelected(zone) {
      if (this.isSelectedByDescendant(zone)) {
        return true
      }

      return this.registeredZones.indexOf(zone) !== -1
    },

    async fetch() {
      this.working = true
      const {
        data: { data },
      } = await Munio.api.currentUser.access.browseZones(this.company.id)
      this.zones = data.filter((zone) => zone.company.id === this.company.id)
      this.working = false
    },

    async confirm() {
      this.working = true
      await Munio.api.currentUser.access.syncRegistrations(
        this.company.id,
        this.registeredZones.map((z) => z.id),
      )
      this.working = false

      window.location.href = this.company.accessUrl
    },

    cancel() {
      this.zones = []
      this.$emit('hidden')
    },
  },
}
</script>

<style scoped lang="scss">
@import '@style/variables';

$zone-avatar-size: 40px;

.input-checkbox {
  padding: 0 1em;
  margin-left: auto;
}

input[type='checkbox'] {
  margin-right: 0 !important;
}

.zone {
  transition: background-color 100ms ease;
  box-shadow: none;

  &:hover {
    background-color: rgba($brand-primary, 0.025);
  }
}

.zone__avatar {
  width: $zone-avatar-size;
  height: $zone-avatar-size;
  border-radius: 50%;

  background-color: rgba($brand-primary, 0.3);
  color: rgba($brand-primary, 0.8);
  font-weight: 500;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 2rem;
}
</style>
