class GDPR {
  get basePath() {
    return '/api/user/gdpr'
  }

  get() {
    return Munio.$http.get(this.basePath)
  }

  request() {
    return Munio.$http.post(`${this.basePath}/request`)
  }

  download(uuid, password) {
    return Munio.$http.post(`${this.basePath}/download`, { uuid, password }, {}, { responseType: 'arraybuffer' })
  }
}

export default GDPR
