<template>
  <div v-if="!product.isUserInterested" class="-mx-12 px-12 mt-8 pb-12">
    <template v-if="isAuthenticated">
      <div v-if="!expanded" class="cursor-pointer flex flex-col sm:flex-row" @click="expanded = !expanded">
        <div class="flex items-center">
          <Icon name="event_seat" class="mdl-color-text--grey-600 mr-4" />
          <div class="flex flex-col sm:flex-row gap-2">
            {{ message }}
            <a class="mdl-color-text--primary cursor-pointer text-uppercase">{{ toggle }}</a>
          </div>
        </div>
      </div>

      <div v-if="expanded" class="mdl-card p-8">
        <p class="m-0 pb-8">{{ trans('Subscribe to receive a notification when new sessions become available.') }}</p>
        <div>
          <fieldset class="flex items-end">
            <label for="seats_quantity" class="mr-12">{{ trans('Number of seats') }}:</label>
            <Quantity id="seats_quantity" class="product-quantity" v-model="quantity" :min="1" :max="100" />
          </fieldset>
          <div class="mt-4">
            <MdlTextfield id="comment" :maxrows="4" v-model="comment" :placeholder="trans('Comment')" />
          </div>
        </div>
        <div class="flex justify-end pt-4">
          <MdlButton flat @click="expanded = false" :disabled="loading">
            {{ trans('Cancel') }}
          </MdlButton>
          <MdlButton primary outlined @click="subscribe" :disabled="!quantity || loading">
            {{ trans('Ok') }}
          </MdlButton>
        </div>
      </div>
    </template>
    <div v-else>
      {{ message }}
      <a :href="route('lms.login', { return: route('lms.shop.product', { product: this.product.id }).toString() })">
        Login
      </a>
    </div>
  </div>

  <div v-else class="mt-12 pb-12 flex items-center">
    <Icon name="event_seat" class="mdl-color-text--grey-600 mr-4" />
    <div class="flex flex-col sm:flex-row gap-2">
      {{ trans("We'll contact you when there are new sessions available") }}.
      <a
        :class="{ opaque: loading }"
        class="mdl-color-text--accent cursor-pointer text-uppercase"
        @click="unsubscribe()"
      >
        {{ trans('Unsubscribe') }}
      </a>
    </div>
  </div>
</template>

<script>
import Icon from '../Icon.vue'
import Quantity from '../Quantity.vue'
import MdlButton from '../mdl/Button.vue'
import MdlTextfield from '../mdl/Textfield.vue'

export default {
  components: {
    Icon,
    Quantity,
    MdlButton,
    MdlTextfield,
  },

  props: {
    message: { type: String, required: true },
    toggle: { type: String, default: trans('Click here') },
    product: { type: Object, required: true },
    loading: { type: Boolean },
  },

  data: () => ({
    error: null,
    hover: false,
    quantity: 1,
    comment: '',
    expanded: false,
  }),

  computed: {
    account() {
      return Munio.config.account
    },
    isAuthenticated() {
      return !!Munio.config.user.id
    },
  },

  watch: {
    expanded(bool) {
      this.$emit('expand', bool)
    },
  },

  methods: {
    async subscribe() {
      this.$emit('loading', true)
      const { product, comment, quantity } = this
      try {
        const {
          data: {
            data: { id },
          },
        } = await product._api.subscribe(comment, quantity)
        this.expanded = false
        if (id) {
          this.$set(product, 'isUserInterested', true)
        }
      } catch (err) {
        this.expanded = true
        Munio.Flash.error('Failed to subscribe', err.toString())
      }
      this.$emit('loading', false)
    },
    async unsubscribe() {
      this.$emit('loading', true)
      const { product } = this
      try {
        await product._api.unsubscribe()
        this.expanded = false
        this.$set(product, 'isUserInterested', false)
      } catch (err) {
        this.expanded = true
        Munio.Flash.error('Failed to unsubscribe', err.toString())
      }
      this.$emit('loading', false)
    },
  },
}
</script>
