import { RequisitionDto } from '../data/Requisition.dto'
import { CollectionResponse } from '@/munio/api/data/common'

export type GetRequisitionsFilters = {
  status?: 'pending' | 'approved' | 'rejected'
  page?: string
  zone?: string
  user?: string
  requestor?: string
  reviewer?: string
  'date[from]'?: string
  'date[to]'?: string
  can_approve?: boolean
  include_history?: boolean
}

export type CreateRequisitionOptions = {
  user: {
    id?: number
    firstname: string
    lastname: string
    email: string
    company: {
      id?: number
      orgnr?: string
      name: string
      country?: string
    }
  }
  zoneIds: number[]
  period: {
    to: string
    from: string
  }
  responsibleId?: number
  description?: string
}

class Requisitions {
  constructor(private gateId: number) {}

  get params() {
    return {
      gate: this.gateId,
    }
  }

  get(filters: GetRequisitionsFilters) {
    const options = filters ? window.Munio.urlParams(filters) : undefined
    return window.Munio.$http.get<CollectionResponse<RequisitionDto>>(
      window.Munio.route('api.web.access.gate.requisitions', this.params),
      options,
    )
  }

  // api.web.access.gate.requisition.create
  create(requisition: CreateRequisitionOptions) {
    const route = window.Munio.route('api.web.access.gate.requisition.create', this.params)

    type Response = {
      data: RequisitionDto
    }

    return window.Munio.$http.post<Response>(route, requisition)
  }
}

export default Requisitions
