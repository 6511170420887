<template>
  <div
    v-if="$slots.default && (active || persist)"
    class="mdl-tabs__panel"
    :class="{ 'mdl-tabs__panel-body': body, 'is-active': active }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'MdlTab',

  props: {
    id: [String, Number],
    label: String,
    badge: [String, Number],
    selected: Boolean,
    disabled: Boolean,
    persist: Boolean,
    body: Boolean,
  },

  data() {
    let $parent = this.$parent
    let $child = this

    while ($parent.$options?.name !== 'MdlTabs') {
      $child = $parent
      $parent = $parent.$parent
    }

    if ($parent?.$options?.name !== 'MdlTabs') {
      throw new Error('Invalid MdlTabs structure')
    }

    const index = $parent.$vnode.componentOptions.children.indexOf($child.$vnode)

    return {
      container: $parent,
      index,
    }
  },

  computed: {
    identifier() {
      return this.id || this.$vnode.key || this.label
    },

    active() {
      return this.identifier === this.container.currentTab
    },
  },

  watch: {
    active(value) {
      if (value) {
        this.$emit('select', this.identifier)
      }
    },
  },

  created() {
    this.container.add(this)
  },

  beforeDestroy() {
    this.container.remove(this)
  },
}
</script>
