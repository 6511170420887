class UserRelationships {
  userId

  constructor(userId) {
    this.userId = userId
  }

  get basePath() {
    return `/api/users/${this.userId}/relationships`
  }

  get() {
    return Munio.$http.get(`${this.basePath}`)
  }

  maintain(company) {
    return Munio.$http.post(`${this.basePath}/maintain`, { company })
  }
}

export default UserRelationships
