class LocationIndex {
  get basePath() {
    return `/api/locations`
  }

  get() {
    return Munio.$http.get(this.basePath)
  }

  create(placeId, name) {
    return Munio.$http.post(this.basePath, {
      place_id: placeId,
      name,
    })
  }

  search(query) {
    return Munio.$http.get('/api/locations/search', { query })
  }
}

class Location {
  locationId

  constructor(locationId) {
    this.locationId = locationId
  }

  get basePath() {
    return `/api/locations/${this.locationId}`
  }

  update(placeId, name) {
    return Munio.$http.post(`${this.basePath}`, {
      place_id: placeId,
      name,
    })
  }
}

export { Location }
export default LocationIndex
