import {
  AccountResourceDto,
  CollectionResponse,
  CourseBasicResourceDto,
  CourseResourceDto,
  ResourceResponse,
} from '@/munio/api/data'
import { OAuthClientResourceDto } from '@/munio/api/data'

const { $http, route } = window.Munio

class OAuth {
  get clients() {
    return new OAuthClients()
  }

  client(id: string) {
    return new OAuthClient(id)
  }

  scopes() {
    return $http.get(route('api.web.oauth.scopes'))
  }
}

class OAuthClients {
  get() {
    return $http.get<CollectionResponse<OAuthClientResourceDto>>(route('api.web.oauth.clients'))
  }

  store(client: Partial<OAuthClientResourceDto>) {
    return $http.post<ResourceResponse<OAuthClientResourceDto>>(route('api.web.oauth.client.create'), client)
  }
}

abstract class ClientApi {
  constructor(public readonly clientId: string) {}

  get params() {
    return { client: this.clientId }
  }
}

class OAuthClient extends ClientApi {
  update(data: Partial<OAuthClientResourceDto>) {
    return $http.put<ResourceResponse<OAuthClientResourceDto>>(route('api.web.oauth.client.update', this.params), data)
  }

  destroy() {
    return $http.delete<void>(route('api.web.oauth.client.delete', this.params))
  }

  get tokens() {
    return new OAuthClientTokens(this.clientId)
  }

  get webhooks() {
    return new OAuthClientWebhooks(this.clientId)
  }

  get accounts() {
    return new OAuthClientAccounts(this.clientId)
  }

  get courses() {
    return new OAuthClientCourses(this.clientId)
  }
}

class OAuthClientTokens extends ClientApi {
  get() {
    return $http.get(route('api.web.oauth.client.tokens', this.params))
  }

  create(data: any) {
    return $http.post(route('api.web.oauth.client.token.create', this.params), data)
  }

  destroy(id: string) {
    return $http.delete(route('api.web.oauth.client.token.delete', { ...this.params, token: id }))
  }
}

class OAuthClientWebhooks extends ClientApi {
  get() {
    return $http.get(route('api.web.oauth.client.webhooks', this.params))
  }

  set(name: string, url: string) {
    return $http.post(route('api.web.oauth.client.webhook.set', { ...this.params, name }), { url })
  }

  test(name: string) {
    return $http.post(route('api.web.oauth.client.webhook.test', { ...this.params, name }))
  }

  remove(name: string) {
    return $http.delete(route('api.web.oauth.client.webhook.remove', { ...this.params, name }))
  }
}

class OAuthClientAccounts extends ClientApi {
  get() {
    return $http.get<CollectionResponse<AccountResourceDto>>(route('api.web.oauth.client.accounts', this.params))
  }

  add(company: CompanyInput) {
    return $http.post<CollectionResponse<AccountResourceDto>>(
      route('api.web.oauth.client.account.add', this.params),
      company,
    )
  }

  remove(account: string) {
    return $http.delete<CollectionResponse<AccountResourceDto>>(
      route('api.web.oauth.client.account.remove', {
        ...this.params,
        ulid: account,
      }),
    )
  }
}

class OAuthClientCourses extends ClientApi {
  get(page?: number) {
    return $http.get<CollectionResponse<CourseBasicResourceDto>>(
      route('api.web.oauth.client.courses', this.params),
      window.Munio.urlParams({ page }),
    )
  }

  addable(options?: URLSearchParams) {
    options = Munio.urlParams(options)
    options.set('rows', '10')

    return $http.get<CollectionResponse<CourseResourceDto>>(
      route('api.web.oauth.client.courses.addable', this.params),
      options,
    )
  }

  add(courses: number[]) {
    return $http.post<CollectionResponse<CourseBasicResourceDto>>(
      route('api.web.oauth.client.course.add', this.params),
      { courses },
    )
  }

  remove(course: string) {
    return $http.delete<CollectionResponse<CourseBasicResourceDto>>(
      route('api.web.oauth.client.course.remove', {
        ...this.params,
        course,
      }),
    )
  }
}

export default OAuth

type CompanyInput = {
  id?: number
  name: string
  orgnr?: string
  country: string
}
