<template>
  <Modal v-if="value" ref="modal" size="xlarge" show :loading="loading" @hidden="close">
    <div class="modal-header flex-col items-start p-8">
      <button type="button" class="close" @click="modal.hide()">
        <Icon name="close" />
      </button>
      <h2 class="m-0 mb-4">Request</h2>

      <div class="w-full flex flex-col gap-y-4">
        <div class="flex items-center gap-x-2">
          <div class="mdl-label">
            <Icon small :name="value.url.startsWith('https://') ? 'https' : 'no_encrpytion'" />
            {{ value.url }}
          </div>
          <div v-if="value?.response" class="mdl-label" :class="statusLabelClasses(value.response.status)">
            {{ value.response.status }} {{ value.response.statusText }}
          </div>
        </div>

        <DefinitionList>
          <DefinitionItem :term="trans('Method')" :description="value.method" />
          <DefinitionItem :term="trans('Identifier')" :description="value.ulid" copy />
          <DefinitionItem :term="trans('Date')" :description="value.createdAt" />
        </DefinitionList>

        <MdlDetails v-if="request?.options?.headers" summary="Headers">
          <dl class="grid grid-cols-[auto,1fr] gap-x-4 m-0 text-sm">
            <template v-for="(value, key) in request.options.headers">
              <dt :key="key + '-key'" class="nowrap m-0">{{ key }}</dt>
              <dd :key="key + '-value'" class="monospace text-pretty text-xs m-0">
                {{ typeof value === 'string' ? value : JSON.stringify(value, null, 2) }}
              </dd>
            </template>
          </dl>
        </MdlDetails>

        <MdlDetails v-if="request?.options?.json" summary="Payload">
          <div class="relative mdl-color--white p-2 border border-solid border-gray-300 rounded-md pr-8">
            <Copy class="absolute top-2 right-2" :text="request.options.json" />
            <JsonPretty :data="request.options.json" />
          </div>
        </MdlDetails>
      </div>
    </div>

    <div v-if="response" class="bg-gray-100 p-8">
      <h2 class="mt-0">Response</h2>

      <div v-if="responseHeaders" class="flex flex-col gap-y-4">
        <MdlDetails summary="Headers">
          <dl class="grid grid-cols-[auto,1fr] gap-x-4 m-0 text-sm">
            <template v-for="(header, key) in responseHeaders">
              <dt :key="key + '-key'" class="nowrap m-0">{{ header.header }}</dt>
              <dd :key="key + '-value'" class="monospace overflow-wrap-anywhere hyphens-none text-pretty text-xs m-0">
                <span class="select-all">{{ header.value }}</span>
                <span>{{ header.options ? `; ${header.options}` : '' }}</span>
              </dd>
            </template>
          </dl>
        </MdlDetails>

        <MdlDetails :summary="trans('Preview')" open>
          <div
            v-if="response.isJson"
            class="relative mdl-color--white p-2 border border-solid border-gray-300 rounded-md pr-8"
          >
            <Copy class="absolute top-2 right-2" :text="response.body" />
            <JsonPretty :data="response.body" />
          </div>
          <pre v-else class="relative bg-white rounded-md text-xs pr-8">
            <Copy class="absolute top-2 right-2" :text="response.body" />
            {{ response.body }}
          </pre>
        </MdlDetails>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { onMounted, computed, ref, watch } from 'vue'
import JsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import Icon from '@component/Icon.vue'
import DefinitionList from '@component/DefinitionList.vue'
import DefinitionItem from '@component/DefinitionItem.vue'
import Modal from '@component/Modal.vue'
import MdlButton from '@component/mdl/Button.vue'
import MdlDivider from '@component/mdl/Divider.vue'
import MdlDetails from '@component/mdl/Details.vue'
import { ResourceResponse } from '@/munio/api/data'
import { statusLabelClasses } from './helpers'
import Copy from '@component/Copy.vue'

const { route, $http } = window.Munio
const loading = ref(false)
const modal = ref()
const request = ref<ActionRequestResourceDto | null>(null)
const response = ref<ActionResponseResourceDto | null>(null)
const responseHeaders = computed(() => {
  if (!response.value || !response.value.headers) {
    return []
  }

  return Object.entries(response.value.headers).reduce(
    (acc, [key, value]) => {
      for (const header of value) {
        const [headerValue, headerOptions] = header.split(';')
        acc.push({
          header: key,
          value: headerValue,
          options: headerOptions,
        })
      }
      return acc
    },
    [] as Array<{ header: string; value: string; options: Record<string, string> }>,
  )
})

const props = defineProps<{
  integration: number
  value?: ActionRequestBasicResourceDto
}>()

const emit = defineEmits<{
  input: (value: ActionRequestResourceDto | null) => void
}>()

function close() {
  emit('input', null)
}

type ActionRequestBasicResourceDto = {
  id: number
  ulid: string
  method: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE'
  url: string
  createdAt: string
  response?: ActionResponseBasicResourceDto
}

type ActionRequestResourceDto = ActionRequestBasicResourceDto & {
  options: {
    headers?: Record<string, string>
    json?: Object
  }
  response?: ActionResponseResourceDto
}

type ActionResponseBasicResourceDto = {
  id: number
  status: number
  statusText: string
  headers: Record<string, string[]>
  isSuccess: boolean
  isJson: boolean
}

type ActionResponseResourceDto = ActionResponseBasicResourceDto & {
  body?: string
}

onMounted(async () => {
  try {
    loading.value = true
    request.value = null
    response.value = null

    const { data } = await $http.get<ResourceResponse<ActionRequestResourceDto>>(
      route('api.web.integration.request', { integration: props.integration, request: props.value!.ulid }),
    )
    request.value = data.data
    response.value = data.data.response
  } catch (e) {
    console.error(e)
  } finally {
    loading.value = false
  }
})
</script>
