<template>
  <span class="flex flex-1 items-center" :data-course-id="course.id">
    <img v-if="avatar && image" :src="image" alt="" class="avatar mr-4" />
    <span class="flex flex-col">
      <strong>{{ title }}</strong>
      <slot>
        <div v-if="course.identifier" class="mdl-overline">{{ course.identifier }}</div>
      </slot>
    </span>
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: {
    course: { type: Object, required: true },
    avatar: { type: Boolean, default: true },
  },
  computed: {
    image() {
      if (typeof this.course.avatar === 'string') {
        return this.course.avatar
      }
      return this.course.image?.avatar
    },
    title() {
      if (typeof this.course.title === 'object') {
        return this.course.title.default
      }
      return this.course.title
    },
  },
}
</script>
