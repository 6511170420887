import '@/libs/jquery.iframe-transport.js'

Munio.behaviors.misc = {
  attach: function (context, config) {
    // Stop click propagation.
    $('.js-stop-propagation', context).click((event) => {
      event.stopPropagation()
    })

    // Remove ugly focus when clicking a button,
    // but keep the focus if the button is selected
    // with the tab key.
    $('button', context).on('mouseup mousedown', function (event) {
      $(this).blur()
    })

    // Add a processing class to submit buttons in
    // modals as default. And remove the class when
    // the modal is hidden.
    $('.modal button[type="submit"]').on('click', function (event) {
      $(this).addClass('processing')
    })
    $('.modal').on('hidden.bs.modal', function (event) {
      $(this).find('button[type="submit"]').removeClass('processing')
    })

    // Automaticly focus and select first input in
    // modals.
    $('.modal').on('shown.bs.modal', function (event) {
      $(this).find('input:not(input[type=button],input[type=submit],input[type=hidden])').first().select()
    })

    // Do not hide dropdown if theclicked
    // element is not a link.
    $('.dropdown-menu', context).on('click', function (event) {
      let $el = $(event.target)

      if ($el.is(':not(a)') && $el.parents('a').length === 0) {
        event.stopPropagation()
      }
    })

    // Show a warning if user navigates
    // away form pages before saving changes.
    $('[data-change-alert]', context).each(function (i, el) {
      let changed = false
      $(el).on('change', function (event) {
        changed = true
      })
      $(el).submit(function () {
        $(window).off('beforeunload')
      })
      $(window).bind('beforeunload', function (event) {
        if (changed) return 'Are you sure you want to leave? All changes will be lost.'
      })
    })

    /**
     * Add a focus class to input group addons when
     * the input is focused.
     */
    $('.input-group', context).each(function (i, el) {
      const $input = $('> *:not(.input-group-addon)', el)
      const $addon = $('.input-group-addon', el)

      $input.on('focus blur', function (event) {
        $addon.toggleClass('focus', this === document.activeElement)
      })
    })

    /**
     * Force one accordion to be open in a panel group.
     */
    $('.panel-group.force-open .panel-heading a', context).on('click', function (event) {
      if ($(this).parents('.panel').children('.panel-collapse').hasClass('in')) {
        event.stopPropagation()
      }
    })

    /**
     * Set a redio inside accordion title as checked when shown.
     */
    $('.panel-group', context).each(function (i, el) {
      $('.panel-collapse', el).on('show.bs.collapse shown.bs.collapse', function (event) {
        $(event.target).parent('.panel').find('.panel-title input[type="radio"]').prop('checked', true)
      })
    })

    /**
     * Private user account toggler.
     */
    $('#user-company-mode input:checkbox', context)
      .on('change', function (e) {
        const $input = $(e.target)
        const $form = $input.closest('form')

        if ($input.is(':checked')) {
          $form.addClass('private')
        } else {
          $form.removeClass('private')
        }
      })
      .trigger('change')

    /**
     * Submit to iframe.
     */
    $('form[data-submit="iframe"]', context).each(function (i, el) {
      const $form = $(el)

      $form.on('submit', function (e) {
        e.preventDefault()

        const xhr = $.ajax(this.action, {
          data: $(':input:not(:file)', this).serializeArray(),
          files: $(':file', this),
          processData: false,
          iframe: true,
        })

        xhr.done(function (data) {
          try {
            data = JSON.parse(data)
            Munio.handleJSONResponseData(data)
          } catch (e) {
            // ignore
          }

          if (data) {
            $form.trigger('iframe-response', data)
          }
        })
      })
    })

    /**
     * Simple file upload.
     */
    $('.file-upload .btn-file :file', context).on('change', function () {
      const $input = $(this)
      const numFiles = $input.get(0).files ? $input.get(0).files.length : 1
      const label = $input.val().replace(/\\/g, '/').replace(/.*\//, '')
      $input.trigger('fileselect', [numFiles, label])
    })

    $('.file-upload .btn-file :file', context).on('fileselect', function (event, numFiles, label) {
      const $input = $(this).parents('.input-group').find(':text')
      const log = numFiles > 1 ? numFiles + ' files selected' : label
      if ($input.length) {
        $input.val(log)
      }
    })
  },
}
