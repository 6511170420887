import { Translation } from '@/munio/definitions'
import { ValidityMode } from '@/munio/api/Course'

export type CreateCourseRequest = {
  ulid: string
  type: string
  languages: string[]
  identifier: string | null
  title: Translation
  description: Translation
  diplomaDescription: Translation
  ignoreDiplomaDescription: boolean
  validityMode: ValidityMode
  requireDocumentation: boolean | null
  enableDocumentation: boolean | null
  requireApproval: boolean | null
  isAdminResponsible: boolean | null
}

export type CreateCourseResponse = {
  data: {
    id: number
    course: {
      id: number
      type: string
      ulid: string
    }
  }
}

export default class {
  async create(data: CreateCourseRequest) {
    const route = window.Munio.route('api.web.admin.courses.create')
    const result = await window.Munio.$http.post<CreateCourseResponse>(route, data)
    return result.data
  }

  async get() {
    return await window.Munio.$http.get('/api/courses')
  }
}
