class Dashboard {
  companyId

  constructor(companyId) {
    this.companyId = companyId
  }

  get params() {
    return {
      company: this.companyId,
    }
  }

  users() {
    return Munio.$http.get(Munio.route('api.web.company.dashboard.users', this.params))
  }

  completions(days) {
    return Munio.$http.get(Munio.route('api.web.company.dashboard.completions', { ...this.params, ...days }))
  }
}

export default Dashboard
