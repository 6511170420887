<template>
  <div class="product-price-group">
    <template v-if="product.isSessionOpen || product.sessions.length">
      <div class="px-8 pt-8 sm:px-12">
        <template v-if="product.sessions.length">
          <div class="product-sessions" v-for="group in groups" :key="group.location.id">
            <h5 class="truncate pb-0 mb-0">{{ group.location.name }}</h5>
            <div>
              <a class="opaque" target="_blank" :href="group.location.url" :title="trans('Show location')">
                {{ group.location.address }}
              </a>
            </div>
            <div class="flex justify-between my-4" v-for="session in group.sessions" :key="session.id">
              <div>
                <div class="flex-1 flex items-center">
                  <span class="whitespace-nowrap">{{ session.from.datetimeName }}</span>
                  <span v-if="session.days.length === 1">–{{ session.days[0].to.hour }}</span>
                  <mdl-button v-if="session._dateInfo" icon v-tooltip.html.bottom="session._dateInfo">
                    <icon name="info" />
                  </mdl-button>
                  <icon name="videocam" title="Webinar" class="opaque" v-if="session.isWebinar"></icon>
                </div>

                <div class="flex items-center">
                  <icon class="small mr-4 opaque" name="people" />
                  {{ trans_choice(':num seats', session.seats.available, { num: session.seats.available }) }}
                  <icon class="small mx-4 opaque" name="language" />
                  {{ session.languageName }}
                </div>
              </div>

              <quantity
                class="product-quantity -mr-2"
                v-model="session.quantity"
                :min="0"
                :max="session.seats.available"
              />
            </div>
          </div>
        </template>

        <div class="product-sessions" v-if="product.isSessionOpen">
          <hr />

          <h5 class="mb-0">{{ trans('Open license') }}</h5>
          <div class="flex items-center">
            <span class="whitespace-nowrap mr-auto">{{ trans('Choose location and time later') }}</span>
            <quantity class="product-quantity ml-12" v-model="quantity" :min="0" :max="cart.maxQuantity" />
          </div>
        </div>
      </div>

      <div class="px-8 sm:px-12">
        <div class="w-full flex-1 my-12 order-1" v-if="!showingSessionRequest">
          <div class="flex items-center justify-end text-right w-full">
            <button
              v-if="product.volumeDiscounts.length"
              type="button"
              class="mdl-button mdl-button--icon mr-auto"
              data-toggle="collapse"
              data-target="#price-info-session"
              aria-expanded="false"
              aria-controls="price-info-session"
            >
              <i class="material-icons">help_outline</i>
            </button>
            <div class="flex justify-end flex-col sm:flex-row items-end sm:items-center gap-4 w-full">
              <ProductPrice
                :price="product.price.default"
                :original="product.price.original"
                :member-price="product.price.membership"
                :membership="cart.isMembershipCustomer"
                :membership-enabled="cart.isMembershipEnabled"
              />
              <div class="w-full sm:w-fit">
                <button
                  class="mdl-button mdl-button--raised mdl-button--colored mobile:w-full"
                  :disabled="!total || loading"
                  @click="addToCart"
                >
                  {{ trans('Buy') }}
                </button>
              </div>
            </div>
          </div>

          <div v-if="product.volumeDiscounts.length" class="collapse" id="price-info-session">
            <VolumeDiscounts :discounts="product.volumeDiscounts" />
          </div>
        </div>

        <SessionRequest
          key="session-request"
          :product="product"
          :loading="loading"
          :message="trans('Can\'t find a session that suits you?')"
          @loading="$emit('update:loading', $event)"
          @expand="(bool) => (showingSessionRequest = bool)"
        />
      </div>
    </template>

    <div class="px-8 pt-8 sm:px-12" v-else>
      {{ trans('There are no scheduled sessions at the moment.') }}

      <SessionRequest
        :product="product"
        :loading="loading"
        :message="trans('Are you interested in attending this course?')"
        :toggle="trans('Let us know')"
        @loading="$emit('update:loading', $event)"
        @expand="(bool) => (showingSessionRequest = bool)"
      />
    </div>
  </div>
</template>

<script>
import SessionRequest from './SessionRequest.vue'
import ProductPrice from './ProductPrice.vue'
import VolumeDiscounts from './VolumeDiscounts.vue'

export default {
  components: {
    SessionRequest,
    ProductPrice,
    VolumeDiscounts,
  },

  props: {
    product: { type: Object, required: true },
    cart: { type: Object, required: true },
    loading: { type: Boolean },
  },

  data() {
    return {
      quantity: 0,
      sessions: this.product.sessions.map((session) => {
        return { ...session, quantity: 0 }
      }),
      showingSessionRequest: false,
    }
  },

  computed: {
    groups() {
      return this.sessions.reduce((groups, session) => {
        const group = groups.find((g) => g.location.id === session.location.id)

        if (session.days.length > 1) {
          session._dateInfo = '<p>' + session.days.map((d) => d.from.datetime + ' – ' + d.to.hour).join('<br>') + '</p>'
        }

        if (group) {
          group.sessions.push(session)
        } else {
          groups.push({ location: session.location, sessions: [session] })
        }

        return groups
      }, [])
    },

    quantities() {
      return this.sessions.map((session) => ({
        id: session.id,
        quantity: session.quantity,
      }))
    },

    total() {
      return this.sessions.reduce((count, session) => {
        return (count += session.quantity)
      }, this.quantity)
    },
  },

  methods: {
    async addToCart() {
      if (!this.total) {
        return this.$emit('update:error', this.trans('No session seats selected…'))
      }

      this.$emit('update:error', null)
      this.$emit('update:loading', true)

      try {
        await Munio.api.shop().cart.add({
          productId: this.product.id,
          quantity: this.quantity,
          sessions: this.quantities.filter((session) => {
            return session.quantity > 0
          }),
        })
      } catch (err) {
        this.$emit('update:error', err.message)
        this.$emit('update:loading', false)
      }
    },
  },
}
</script>
