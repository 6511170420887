<template>
  <div>
    <template v-for="(company, i) of companies">
      <h4 :key="'header-' + company.id" class="mdl-subheader block" :class="{ 'mt-4': i > 0 }">{{ company.name }}</h4>
      <div :key="'users-' + company.id" class="mdl-chips">
        <mdl-chip
          v-for="user of company.users"
          :key="user.email"
          :image="avatar(user)"
          :color-bg="user.error ? 'danger' : undefined"
          :color-text="user.error ? 'danger-contrast' : undefined"
          :contact-color-bg="user.invite ? 'warning' : undefined"
          :contact-color-text="user.invite ? 'warning-contrast' : undefined"
          :highlight="isMe(user)"
          deletable
          @delete="() => !loading && $emit('remove', user)"
        >
          {{ userFullname(user) }}
        </mdl-chip>
      </div>
    </template>
  </div>
</template>

<script>
import { userFullname } from '@/munio/utils/index.js'

export default {
  name: 'UsersSelected',

  props: {
    users: Array,
    loading: Boolean,
  },

  computed: {
    companies() {
      return this.users
        .reduce((acc, user) => {
          let index = acc.findIndex((company) => company.id === (user.company ? user.company.id : 0))

          if (index === -1) {
            index = acc.push({
              ...(user.company ? user.company : { id: 0, name: trans('No employer') }),
              users: [],
            })
            index--
          }

          acc[index].users.push(user)

          return acc
        }, [])
        .sort((a, b) => {
          if (a.name > b.name) return 1
          if (a.name < b.name) return -1
          return 0
        })
    },
  },

  methods: {
    userFullname,

    avatar(user) {
      if (typeof user.avatar === 'string') {
        return user.avatar
      }

      return user.avatar?.url
    },
    isMe(user) {
      return user.id === Munio.config.user.id
    },
  },
}
</script>

<style scoped></style>
