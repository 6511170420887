<template>
  <div class="mdl-filter__form" @click="$emit('activate', $event)">
    <div>
      <mdl-textfield
        ref="from"
        readonly
        size="10"
        :class="dateFromClasses"
        :floating-label="trans('From')"
        :name="`${inputName}_from`"
        :value="inputFrom"
        v-autofocus="autofocus"
        @click="openDateFrom = true"
        @focus="openDateFrom = true"
      >
        <date-picker
          :show="openDateFrom"
          @close="openDateFrom = false"
          :min-date="min"
          :max-date="inputTo || value.to || max"
          :value="inputFrom"
          @input="onDateFromChange"
        />
        <mdl-button v-if="inputFrom" icon @click.stop="onDateFromChange(undefined)" class="top-3">
          <icon name="cancel" class="smaller" />
        </mdl-button>
      </mdl-textfield>
    </div>
    <div class="mx-4">&rarr;</div>
    <div>
      <mdl-textfield
        ref="to"
        readonly
        size="10"
        :class="dateToClasses"
        :floating-label="trans('To')"
        :name="`${inputName}_to`"
        :value="inputTo"
        @click="openDateTo = true"
        @focus="openDateTo = true"
      >
        <date-picker
          :show="openDateTo"
          @close="openDateTo = false"
          :min-date="inputFrom || value.from || min"
          :max-date="max"
          :value="inputTo"
          @input="onDateToChange"
        />
        <mdl-button v-if="inputTo" icon @click.stop="onDateToChange(undefined)" class="top-3">
          <icon name="cancel" class="smaller" />
        </mdl-button>
      </mdl-textfield>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DateRangeFilter',
  inheritAttrs: false,

  props: {
    inputName: { type: [String], default: () => 'value' },
    disabled: { type: Boolean },
    autofocus: { type: [Boolean], default: () => true },
    value: { type: [Object], default: () => ({ to: '', from: '' }) },
    min: { type: String },
    max: { type: String },
  },

  emits: ['activate'],

  data() {
    return {
      openDateFrom: false,
      openDateTo: false,
      inputFrom: this.value.from,
      inputTo: this.value.to,
    }
  },
  watch: {
    value(value) {
      if (value.from !== this.inputFrom || value.to !== this.inputTo) {
        this.inputFrom = value.from
        this.inputTo = value.to
      }
    },
  },
  computed: {
    dateFromClasses() {
      return { 'is-focused': this.openDateFrom, 'is-dirty': this.inputFrom, 'opacity-60': this.disabled }
    },
    dateToClasses() {
      return { 'is-focused': this.openDateTo, 'is-dirty': this.inputTo, 'opacity-60': this.disabled }
    },
  },

  methods: {
    onDateFromChange(value) {
      this.inputFrom = value
      this.$refs.from.blur()
      if (this.inputFrom && !this.inputTo) {
        this.$refs.to.focus()
      }
    },
    onDateToChange(value) {
      this.inputTo = value
      this.$refs.to.blur()
    },
  },
}
</script>
