import Wrapper from '@/munio/vue/Wrapper.js'
import store from './store/index.js'

Munio.OrderViewer = class extends Wrapper {
  constructor(selector, order, customer = true) {
    super(selector, { order, customer })
  }

  store() {
    return store
  }

  component() {
    return import('./App.vue')
  }
}

Munio.OrderCreator = class extends Wrapper {
  constructor(selector, shop) {
    super(selector, { shop })
  }

  component() {
    return import('./Creator.vue')
  }
}
