import { CollectionResponse } from '../data/common'
import { GateResourceDto } from '../data/GateResource.dto'
import { ZoneResourceDto } from '../data/ZoneResource.dto'
import GateCard from './GateCard.js'
import GateEntrypoints from './Gate/Entrypoints'
import GateResponsibles from './GateResponsibles.js'
import GateUser from './GateUser.js'
import GateUsers from './GateUsers'
import Link from './Link.js'
import Links from './Links.js'
import Requisition from './Requisition'
import Requisitions from './Requisitions'
import Search from './Gate/Search'
import Companies from './Gate/Companies'

class Gate {
  constructor(private id: number) {}

  get params() {
    return { gate: this.id }
  }

  get() {
    type Response = {
      data: GateResourceDto
    }

    return window.Munio.$http.get<Response>(Munio.route('api.web.access.gate', this.params))
  }

  update(data: any) {
    return window.Munio.$http.put(Munio.route('api.web.access.gate.update', { gate: this.id }), data)
  }

  user(id: number) {
    return new GateUser(this.id, id)
  }

  get users() {
    return new GateUsers(this.id)
  }

  get responsibles() {
    return new GateResponsibles(this.id)
  }

  zones() {
    type ZonesResponse = CollectionResponse<ZoneResourceDto> & {
      gate: GateResourceDto
    }

    return window.Munio.$http.get<ZonesResponse>(Munio.route('api.web.access.gate.zones', this.params))
  }

  get entrypoints() {
    return new GateEntrypoints(this.id)
  }

  cards(options: any) {
    return window.Munio.$http.get(Munio.route('api.web.access.gate.cards', this.params), Munio.urlParams(options))
  }

  card(id: number) {
    return new GateCard(this.id, id)
  }

  get links() {
    return new Links(this.id)
  }

  link(id: number) {
    return new Link(this.id, id)
  }

  get requisitions() {
    return new Requisitions(this.id)
  }

  requisition(requisitionId: number) {
    return new Requisition(this.id, requisitionId)
  }

  get search() {
    return new Search(this.id)
  }

  get companies() {
    return new Companies(this.id)
  }
}

export default Gate
