<template>
  <div :class="typeClasses">
    <div v-if="$slots.header" :class="headerClasses">
      <slot name="header" />
    </div>
    <component :is="componentType" :href="link" :class="itemClasses()" @click="onClick" role="button">
      <slot name="before" />
      <div class="mdl-list__item-content">
        <div class="mdl-list__item-depth-angle" v-if="depth"></div>
        <div class="mdl-list__item-primary-content">
          <div v-if="showAvatar" class="mdl-list__item-avatar" :class="avatarClasses">
            <slot name="avatar" />
            <circular-progress v-if="progress !== null" :progress="progress" />
            <img v-if="thumbnail" class="mdl-list__item-avatar-thumbnail" :src="thumbnail" alt="Avatar" />
            <template v-else>
              <div v-if="progress && progress < 100" class="mdl-list__item-avatar-progress">
                {{ Number(progress).toFixed(0) }}%
              </div>
              <i v-else-if="icon" class="material-icons">{{ icon }}</i>
              <div v-else-if="initials" class="mdl-list__item-avatar-initials">{{ initials }}</div>
            </template>
            <div v-if="badge" class="mdl-list__item-avatar-badge" :class="badgeClasses">
              <i v-if="typeof badge === 'string'" class="material-icons">{{ badge }}</i>
              <div v-else class="mdl-badge">{{ badge }}</div>
            </div>
          </div>

          <div class="mdl-list__item-title">
            <template v-if="overline">
              <div class="mdl-overline">{{ overline }}</div>
            </template>
            <slot v-else name="overline" />

            <slot v-if="$slots.title" name="title" />
            <template v-else-if="title">
              {{ title }}
            </template>
            <small v-if="hasSubtitle && hasDescription" class="opaque">
              <template v-if="subtitle">{{ subtitle }}</template>
              <slot v-else name="subtitle" />
            </small>
          </div>

          <template v-if="hasSubtitle && !hasDescription">
            <div class="mdl-list__item-sub-title">
              <template v-if="subtitle">{{ subtitle }}</template>
              <slot v-else name="subtitle" />
            </div>
          </template>

          <template v-if="hasDescription">
            <div class="mdl-list__item-text-body">
              <template v-if="description">{{ description }}</template>
              <slot v-else name="description" />
            </div>
          </template>

          <slot name="primary-content"></slot>
        </div>
        <div class="mdl-list__item-secondary-content">
          <div class="mdl-list__item-secondary-info" v-if="$slots['actions-info']">
            <slot name="actions-info" />
          </div>
          <div class="mdl-list__item-secondary-action">
            <slot name="actions" />
            <mdl-button
              v-if="isExpandable"
              :icon="isExpanded ? 'expand_less' : 'expand_more'"
              class="mdl-list__item-toggle"
            />
          </div>
        </div>
      </div>
      <slot name="after" />
    </component>
    <template v-if="isExpanded">
      <div :class="bodyClasses" v-if="$slots.default || $slots.body">
        <slot />
        <slot name="body" />
      </div>
      <div class="mdl-list__card-actions" v-if="$slots['card-actions']">
        <slot name="card-actions" />
      </div>
    </template>
    <div v-if="loading" class="mdl-list__card-progress">
      <mdl-progressbar indeterminate />
    </div>
  </div>
</template>

<script>
import CircularProgress from '@/munio/vue/components/CircularProgress.vue'

export default {
  props: {
    title: String,
    subtitle: String,
    description: String,
    overline: String,
    link: String,
    initials: String,
    background: { type: [String, Object, Array], default: null },
    thumbnail: String,
    progress: { type: [Number, String], default: null },
    badge: [String, Number],
    badgeClasses: { type: String, default: null },
    icon: String,
    type: { type: String, default: 'card' },
    loading: Boolean,
    bodyClass: { type: String, default: null },
    bodyInset: Boolean,
    modified: Boolean,
    expanded: Boolean,
    expandedClass: { type: String, default: null },
    expandable: { type: Boolean, default: null },
    depth: [String, Number],
    flat: Boolean,
    dense: Boolean,
  },

  components: {
    CircularProgress,
  },

  data() {
    return {
      isExpanded: this.expandable === false ? false : this.expanded,
    }
  },

  computed: {
    isClickable() {
      return this.link || this.$listeners.click !== undefined
    },

    componentType() {
      if (this.link) {
        return 'a'
      }

      return 'div'
    },

    showAvatar() {
      return this.$slots.avatar || this.initials || this.thumbnail || this.progress || this.badge || this.icon
    },

    isExpandable() {
      if (this.expandable !== null) {
        return this.expandable
      }

      if (this.$slots['card-actions'] || this.$slots.body) {
        return true
      }

      return !!this.$slots.default
    },

    hasDescription() {
      return !!(this.$slots.description || this.description)
    },

    hasSubtitle() {
      return !!(this.$slots.subtitle || this.subtitle)
    },

    typeClasses() {
      return {
        [`mdl-list__${this.type}`]: this.type !== null,
        flat: this.flat,
        'is-expanded': this.isExpanded,
        'is-loading': this.loading,
        [this.expandedClass]: this.expandedClass && this.isExpanded,
      }
    },

    headerClasses() {
      return {
        [`mdl-list__${this.type}-header`]: this.type !== null,
      }
    },

    bodyClasses() {
      return {
        [`mdl-list__${this.type}-body`]: true,
        [`mdl-list__${this.type}-body--inset`]: this.bodyInset,
        [this.bodyClass]: this.bodyClass,
      }
    },

    avatarClasses() {
      const bg =
        typeof this.background === 'string'
          ? {
              [this.background]: !!this.background,
            }
          : this.background

      return Object.assign(
        {},
        {
          'mdl-list__item-avatar--image': this.thumbnail,
        },
        bg,
      )
    },

    onClick() {
      return this.$listeners.click ?? this.toggle
    },
  },

  methods: {
    itemClasses() {
      return {
        'mdl-list__item': true,
        dense: this.dense,
        'cursor-pointer': this.isClickable,
        'mdl-list__item--two-line':
          this.$slots.subtitle || this.subtitle || this.$slots.description || this.description,
        'mdl-list__item--three-line':
          (this.$slots.subtitle || this.subtitle) && (this.$slots.description || this.description),
        ['mdl-list__item--depth-' + this.depth]: this.depth,
        'is-modified': this.modified,
      }
    },

    toggle(event) {
      const expanded = !this.isExpanded

      if (this.isExpandable && !event.defaultPrevented) {
        this.$emit('toggle', expanded)

        if (!this.$listeners.toggle) {
          this.isExpanded = expanded
        }
      }
    },
  },

  watch: {
    expanded: {
      immediate: true,
      handler(value, oldValue) {
        this.isExpanded = value
      },
    },
  },
}
</script>
