export default class Admins {
  constructor(zoneId) {
    this.zoneId = zoneId
  }

  get params() {
    return { zone: this.zoneId }
  }

  get(options) {
    return Munio.$http.get(Munio.route('api.web.access.zone.admins', this.params), Munio.urlParams(options))
  }

  add(users) {
    return Munio.$http.post(Munio.route('api.web.access.zone.admin.add', this.params), {
      users: users.filter((user) => user.id).map((user) => user.id),
      invites: users.filter((user) => !user.id),
    })
  }

  remove(userId) {
    return Munio.$http.delete(Munio.route('api.web.access.zone.admin.remove', { ...this.params, user: userId }))
  }

  update(userId, data) {
    return Munio.$http.put(Munio.route('api.web.access.zone.admin.update', { ...this.params, user: userId }), data)
  }
}
