import { ResourceResponse, CollectionResponse, ShopProductResourceDto } from '@/munio/api/data'

const { $http, route, urlParams } = window.Munio

export default class ShopAdmin {
  constructor(protected slug: string) {}

  get products() {
    return new ShopAdminProducts(this.slug)
  }
}

class ShopAdminProducts {
  constructor(protected slug: string) {}

  get params() {
    return {
      shop: this.slug,
    }
  }

  get(options?: URLSearchParams) {
    return $http.get<CollectionResponse<ShopProductResourceDto>>(
      route('api.web.shop.admin.products', this.params),
      urlParams(options),
    )
  }

  update(shopProductId: number, data: Record<string, any>) {
    const endpoint = route('api.web.shop.admin.product.update', {
      ...this.params,
      product: shopProductId,
    })

    return $http.put<ResourceResponse<ShopProductResourceDto>>(endpoint, data)
  }
}
