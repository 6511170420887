<template>
  <div class="panel-column panel-column--large">
    <div class="row" v-if="loaded">
      <div class="col-sm-12">
        <company-card :company="owner.company" :state="owner.state" class="mb-8" />

        <template v-if="hasMissingCertificates">
          <h1 class="mt-0">{{ trans('Missing certificate') }}</h1>
          <CertificateInput
            v-for="certificate of missingCertificates"
            :key="certificate.id"
            :item="certificate"
            :loading="certificate.id === loadingCertificateId"
            @add="addCertificate"
          />
        </template>
        <div class="flex flex-col">
          <template v-if="requirementsTodo.length">
            <h4 class="mt-0">{{ trans('Requirements') }}</h4>
            <mdl-progressbar
              large
              class="mb-8"
              :progress="requirementsCompletedPercentage"
              :status="requirementsCompletedLabel"
            />
            <course-card
              v-for="item of requirementsTodo"
              :key="item.id"
              :item="item"
              :disabled="hasMissingCertificates"
              @launch="onLaunch"
            />
            <hr class="mdl-divider my-8" />
          </template>

          <template v-if="requirementsAdminTodo.length">
            <h4 class="mt-0">{{ trans('Tasks for your administrator') }}</h4>
            <course-card v-for="item of requirementsAdminTodo" :key="item.id" :item="item" @launch="onLaunch" />
            <hr class="mdl-divider my-8" />
          </template>

          <template v-if="requirementsCompleted.length">
            <h4 class="mt-0">{{ trans('Completed') }}</h4>
            <course-card
              v-for="item of requirementsCompleted"
              :key="item.id"
              :item="item"
              :disabled="hasMissingCertificates"
              @launch="onLaunch"
            />
            <hr class="mdl-divider my-8" />
          </template>
        </div>

        <h4 class="mt-0">{{ trans('Your access') }}</h4>
        <div class="action-bar">
          <mdl-button primary raised @click="selectedCompany = owner.company">
            {{ trans('Manage') }}
          </mdl-button>
        </div>
      </div>

      <access-zone-modal :company="selectedCompany" @hidden="selectedCompany = null" />

      <course-launcher-modal
        v-if="renderLaunchModal"
        :show="launch !== null"
        :id="launcherId"
        :requirementId="launcherRequirementId"
        :image="launcherImage"
        :title="launcherTitle"
        :subtitle="launcherSubtitle"
        :context="launcherContext"
        @cancel="cancel"
      />
    </div>

    <div v-else>
      <mdl-progressbar indeterminate />
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import CourseLauncherModal from '@/munio/vue/CourseLauncher/CourseLauncherModal.vue'
import CompanyCard from './CompanyCard.vue'
import CourseCard from './CourseCard.vue'
import AccessZoneModal from '@/munio/vue/AccessZoneOverview/AccessZoneModal.vue'
import CertificateInput from './CertificateInput.vue'

export default {
  name: 'AccessZoneCompany',

  components: {
    CertificateInput,
    AccessZoneModal,
    CompanyCard,
    CourseCard,
    CourseLauncherModal,
  },

  props: {
    id: Number,
  },

  data() {
    return {
      showInactiveZones: false,
      selectedZone: null,
      selectedCompany: null,
      loadingCertificateId: null,
      launch: null,
    }
  },

  async created() {
    await this.fetch()
  },

  computed: {
    isLoading() {
      return this.$store.state.zones.filter((z) => z.loading).length > 0
    },

    canLaunch() {
      return this.requirements.length > 0 && !this.isLoading && !this.hasMissingCertificates
    },

    renderLaunchModal() {
      return this.canLaunch && this.launch !== null
    },

    activeZones() {
      return this.$store.state.zones.filter((z) => z.user.isConnected)
    },

    registeredZones() {
      return this.$store.state.zones.filter((z) => z.user.isRegistered)
    },

    inactiveZones() {
      return this.$store.state.zones.filter((z) => !z.user.isConnected)
    },

    requirements() {
      return this.$store.state.requirements
        .filter((r) => r.isCourse)
        .sort((a, b) => {
          if (a.object.identifier && !b.object.identifier) {
            return 1
          } else if (!a.object.identifier && b.object.identifier) {
            return -1
          }

          const aStr = (a.object.identifier ?? '') + a.object.title
          const bStr = (b.object.identifier ?? '') + b.object.title
          return aStr.localeCompare(bStr, Munio.config.i18n.language)
        })
    },

    requirementsCompleted() {
      return this.requirements.filter((r) => r.state.isValid && !r.state.expiration?.isExpiring)
    },

    requirementsCompletedPercentage() {
      return (this.requirementsCompleted.length / this.requirements.length) * 100
    },

    requirementsCompletedLabel() {
      const num = trans(':num of :total', { num: this.requirementsCompleted.length, total: this.requirements.length })
      return `${trans('Completed')}: ${num}`
    },

    requirementsTodo() {
      return this.requirements.filter((r) => !this.requirementsCompleted.includes(r) && !r.object.isAdminResponsible)
    },

    requirementsAdminTodo() {
      return this.requirements.filter((r) => !this.requirementsCompleted.includes(r) && r.object.isAdminResponsible)
    },

    missingCertificates() {
      return this.$store.state.certificates.filter((c) => !c.exists)
    },

    hasMissingCertificates() {
      return this.missingCertificates.length > 0
    },

    owner() {
      return this.$store.state.owner
    },

    loaded() {
      return this.$store.state.loaded
    },

    launcherRequirementId() {
      return this.launch?.id
    },

    launcherId() {
      return this.launch?.object.id
    },

    launcherImage() {
      return this.launch?.object.image.header
    },

    launcherTitle() {
      return this.launch?.object.title
    },

    launcherSubtitle() {
      return this.launch?.object.identifier
    },

    launcherContext() {
      return this.id
    },
  },

  methods: {
    ...mapMutations(['setZones', 'setOwner', 'setRequirements', 'setZoneLoadingState']),

    async fetch() {
      const {
        data: { data },
      } = await Munio.api.currentUser.access.company(this.id)
      this.setZones(data.zones.map((g) => Object.assign(g, { loading: false })))
      this.setOwner({ company: data.company, state: data.state })
      this.setRequirements(data.requirements)
    },

    async addZone(zone) {
      this.selectedZone = null
      this.setZoneLoadingState({ id: zone.id, state: true })

      try {
        await Munio.api.currentUser.access.register(zone.company.id, zone.id)
        await this.fetch()
      } catch (e) {
        // Error handler
      }

      this.setZoneLoadingState({ id: zone.id, state: false })
    },

    async removeZone(zone) {
      this.setZoneLoadingState({ id: zone.id, state: true })

      const redirectAfterAction = this.activeZones.length === 1

      try {
        await Munio.api.currentUser.access.unregister(zone.company.id, zone.id)
      } catch (e) {
        // Error handler?
      }

      if (redirectAfterAction) {
        window.location.href = Munio.route('lms.user.access').toString()
        return
      }

      try {
        await this.fetch()
      } catch (e) {
        // Error handler?
      }

      this.setZoneLoadingState({ id: zone.id, state: false })
    },

    onLaunch(objectId) {
      if (this.canLaunch) {
        const requirement = this.requirements.find((requirement) => requirement.object.id === objectId)

        this.launch = requirement
      }
    },

    onZoneToggle(zone) {
      this.selectedZone = zone === this.selectedZone ? null : zone
    },

    async addCertificate(certificateType) {
      try {
        this.loadingCertificateId = certificateType.id
        await Munio.api.currentUser.certificates.add(
          certificateType.id,
          certificateType.identifier,
          certificateType.bornAt,
        )
        await this.fetch()
      } catch (err) {
        const serverError = err.response?.status === 500
        const msg = err.response?.data?.error?.message
        Munio.Flash.error(trans('Unable to add certificate'), serverError ? undefined : msg)
      } finally {
        this.loadingCertificateId = null
      }
    },

    async cancel() {
      this.launch = null
      await this.fetch()
    },
  },
}
</script>
