class Action {
  constructor(integrationId, ulid) {
    this.integrationId = integrationId
    this.ulid = ulid
  }

  get params() {
    return {
      integration: this.integrationId,
      action: this.ulid,
    }
  }

  get() {
    return Munio.$http.get(Munio.route('api.web.integration.action', this.params))
  }

  events(page) {
    return Munio.$http.get(Munio.route('api.web.integration.action.events', this.params), { page })
  }

  retry() {
    return Munio.$http.post(Munio.route('api.web.integration.action.retry', this.params))
  }

  cancel() {
    return Munio.$http.post(Munio.route('api.web.integration.action.cancel', this.params))
  }
}

export default Action
