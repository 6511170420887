<template>
  <div class="color-picker flex items-center">
    <input ref="input" type="text" :name="name" :value="value" v-bind="$attrs" />
    <mdl-button @click="clearValue">{{ trans('Remove') }}</mdl-button>
  </div>
</template>

<script>
import $ from 'jquery'
import materialPalette from './material-palette.json'
import 'spectrum-colorpicker'

export default {
  name: 'ColorPicker',

  inheritAttrs: false,

  props: {
    name: { type: String, default: null },
    value: { type: String, default: '' },
    format: { type: String, default: 'rgb' },
    palette: { type: Array, default: null },
  },

  computed: {
    materialPalette() {
      const from = 0 // 2
      const to = undefined // 10
      const palette = []

      Object.keys(materialPalette).forEach((name, i) => {
        const group = materialPalette[name]

        Object.keys(group)
          .slice(from, to)
          .forEach((name, i) => {
            ;(palette[i] = palette[i] || []).push(group[name])
          })
      })

      return palette
    },
  },

  mounted() {
    $(this.$refs.input).spectrum({
      palette: this.palette || this.materialPalette,
      showPalette: true,
      showInput: true,
      allowEmpty: true,
      showButtons: false,
      showPaletteOnly: false,
      preferredFormat: this.format,
      chooseText: trans('Select'),
      cancelText: trans('Cancel'),

      change: this.onChange,
      hide: this.onChange,
      move: this.onChange,
    })
  },

  beforeDestroy() {
    clearTimeout(this.___timeout)
    $(this.$refs.input).spectrum('destroy')
  },

  methods: {
    showColorPicker() {
      $(this.$refs.input).spectrum('toggle')
    },
    clearValue() {
      this.onChange(null)
      $(this.$refs.input).spectrum('set', null)
    },
    onChange(color) {
      // emit after a timeout to prevent duplicate events caused by
      // the move event beeing triggered by clicking paletts
      clearTimeout(this.___timeout)
      this.___timeout = setTimeout(() => {
        this.$emit('input', color ? color.toString() : null)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@style/mixins';
@import '@style/variables';
@import '@style/libs/spectrum-colorpicker/spectrum.css';

// Color preview

.sp-replacer {
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  background-color: transparent;

  * {
    cursor: pointer;
  }

  // &::after {
  //   height: auto;
  //   font-size: 23px;
  //   font-family: 'Material Icons';
  //   content: "arrow_drop_down";
  // }
}

.sp-preview {
  margin: 0 8px 0 0;
  width: 32px;
  height: 32px;
  border: 1px solid $gray-lighter;
  border-radius: $border-radius-large;
  overflow: hidden;

  &-inner {
    // border-radius: $border-radius-large;
  }
}

.sp-dd {
  display: none;
}

// Color picker

.sp-container {
  border: none;
  background-color: $panel-bg;
  @include shadow-2dp();
}

.sp-palette-container {
  border-right: none;
}

.sp-picker-container {
  border-left-color: $panel-border;
}

.sp-color,
.sp-hue,
.sp-clear {
  border: none;
}

.sp-dragger {
}

.sp-palette {
  max-width: 380px;

  .sp-thumb-el {
    margin: 0;
    border: none;
    &.sp-thumb-dark {
      color: white;
    }
  }

  // add a border to white palette thumb
  .sp-thumb-el[data-color='rgb(255, 255, 255)'] {
    box-shadow: 0 0 1px 1px rgba($gray-darker, 0.3);
  }

  .sp-thumb-active .sp-thumb-inner {
    background-image: none !important;
    &::after {
      font-family: 'Material Icons';
      font-size: 16px;
      content: 'check';
    }
  }
}
</style>
