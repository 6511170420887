import RoleCourses from './RoleCourses.js'
import RoleUsers from './RoleUsers.js'

class Role {
  roleId = null

  constructor(roleId) {
    this.roleId = roleId
  }

  get params() {
    return {
      role: this.roleId,
    }
  }

  get() {
    return Munio.$http.get(Munio.route('api.web.admin.role', this.params))
  }

  update(data) {
    return Munio.$http.post(Munio.route('api.web.admin.role', this.params), data)
  }

  delete() {
    return Munio.$http.delete(Munio.route('api.web.admin.role.delete', this.params))
  }

  get users() {
    return new RoleUsers(this.roleId)
  }

  get courses() {
    return new RoleCourses(this.roleId)
  }
}

export default Role
