const FILTER = 'FILTER'
const COMPANY_COURSE = 'COMPANY_COURSE'
const SESSIONS = 'SESSIONS'
const SESSION = 'SESSION'
const TOGGLE_ENROLLMENT_MODAL = 'TOGGLE_ENROLLMENT_MODAL'
const TOGGLE_MOVE_MODAL = 'TOGGLE_MOVE_MODAL'
const SET_ENROLLMENT_MODAL_USERS = 'SET_ENROLLMENT_MODAL_USERS'
const SET_SESSION = 'SET_SESSION'
const INSERT_SESSION = 'INSERT_SESSION'
const TOGGLE_RESERVATION = 'TOGGLE_RESERVATION'
const SET_PARTICIPANTS_SELECTION = 'SET_PARTICIPANTS_SELECTION'

const enrollmentModalDefault = () => ({
  show: false,
  users: [],
})

const moveModaLDefault = () => ({
  show: false,
  sessions: [],
  selection: [],
  targetSessionId: null,
})

export default {
  state: {
    id: null,
    filter: Munio.urlParams(),
    companyCourse: null,
    permissions: {},
    sessionId: null,
    reservationId: null,
    session: null,
    sessions: {
      data: [],
      links: {},
      meta: {},
    },
    participantsSelected: [],
    enrollmentModal: enrollmentModalDefault(),
    moveModal: moveModaLDefault(),
  },

  mutations: {
    [FILTER](state, filter) {
      state.filter = Munio.urlParams(filter)
    },
    [COMPANY_COURSE](state, response) {
      state.companyCourse = response.data
      state.permissions = response.permissions
    },
    [SESSION](state, response) {
      state.session = response.data

      let found = false

      for (let index in state.sessions.data) {
        let session = state.sessions.data[index]

        if (state.session.id === session.id) {
          state.sessions.data[index] = state.session
          found = true
        }
      }

      if (!found) {
        state.sessions.data = [...state.sessions.data, state.session]
      }

      state.sessions = { ...state.sessions }
    },
    [SESSIONS](state, response) {
      state.sessions = {
        data: response.data || [],
        links: response.links || {},
        meta: response.meta || {},
      }
    },
    [TOGGLE_ENROLLMENT_MODAL](state, show) {
      if (show) {
        state.enrollmentModal = enrollmentModalDefault()
      }

      state.enrollmentModal.show = !!show
    },
    [TOGGLE_MOVE_MODAL](state, show) {
      if (show) {
        state.moveModal = moveModaLDefault()
      }

      state.moveModal.show = !!show
    },
    [SET_ENROLLMENT_MODAL_USERS](state, users) {
      state.enrollmentModal.users = users
    },
    [SET_SESSION](state, id) {
      state.sessionId = id
      state.session = null
      state.participantsSelected = []
      state.enrollmentModal = enrollmentModalDefault()
      state.moveModal = moveModaLDefault()
    },
    [INSERT_SESSION](state, response) {
      state.session = response.data
      state.sessionId = state.session.id

      state.sessions.data = [state.session, ...state.sessions.data]
      state.sessions = { ...state.sessions }
    },
    [TOGGLE_RESERVATION](state, id) {
      state.reservationId = id
    },
    [SET_PARTICIPANTS_SELECTION](state, selected) {
      state.participantsSelected = selected
    },
  },

  actions: {
    async fetch(store, filter = null) {
      if (filter) {
        store.commit(FILTER, filter)
      }

      const api = Munio.api.companyCourse(store.state.id)
      const companyCourseResponse = await api.get()
      const sessionsResponse = await api.sessions(store.state.filter)

      store.commit(COMPANY_COURSE, companyCourseResponse.data)
      store.commit(SESSIONS, sessionsResponse.data)
    },
    async fetchPage(store, page = 1) {
      const filter = Munio.urlParams(store.state.filter)
      filter.set('page', page)
      const response = await Munio.api.companyCourse(store.state.id).sessions(filter)
      store.commit(SESSIONS, response.data)
    },
    async fetchSession(store, id) {
      const response = await Munio.api.companyCourse(store.state.id).session(id).get(store.state.filter)
      store.commit(SESSION, response.data)
    },
    async fetchSessionParticipantsCSV(store, id) {
      const filter = Munio.urlParams(store.state.filter)
      const baseUrl = Munio.api.companyCourse(store.state.id).session(id).participants.basePath

      await Munio.downloadCSV(baseUrl, filter)
    },
    insertSession(store, response) {
      store.commit(INSERT_SESSION, response.data)
    },
    updateSession(store, response) {
      store.commit(SESSION, response.data)
    },
    async closeSession(store, id) {
      const response = await Munio.api.companyCourse(store.state.id).session(id).close()
      store.commit(SESSION, response.data)
    },
    async openSession(store, id) {
      const response = await Munio.api.companyCourse(store.state.id).session(id).open()
      store.commit(SESSION, response.data)
    },
    async toggleSessionState(store, id) {
      const response = await Munio.api.companyCourse(store.state.id).session(id).toggle()
      store.commit(SESSION, response.data)
    },
    async unenrollSessionUsers(store, userIds) {
      await Munio.api.companyCourse(store.state.id).session(store.state.sessionId).users.unenroll(userIds)
    },
    async approveSessionUsers(store, userIds) {
      await Munio.api.companyCourse(store.state.id).session(store.state.sessionId).users.approve(userIds)
    },
    async resetSessionUsers(store, userIds) {
      await Munio.api.companyCourse(store.state.id).session(store.state.sessionId).users.reset(userIds)
    },
    toggleSession(store, id) {
      store.commit(SET_SESSION, store.state.sessionId !== id ? id : null)
    },
    toggleReservation(store, id) {
      if (id) {
        store.commit(TOGGLE_RESERVATION, id)
        store.commit(TOGGLE_ENROLLMENT_MODAL, true)
      } else {
        store.commit(TOGGLE_ENROLLMENT_MODAL, false)
        store.commit(TOGGLE_RESERVATION, null)
      }
    },
    setEnrollmentModalUsers(store, users) {
      store.commit(SET_ENROLLMENT_MODAL_USERS, users)
    },
    async moveParticipants(store, { participants, session }) {
      const response = await Munio.api
        .companyCourse(store.state.id)
        .session(store.state.sessionId)
        .move(participants, session)
      store.commit(TOGGLE_MOVE_MODAL, false)
      store.commit(SET_PARTICIPANTS_SELECTION, [])
      await store.dispatch('fetchSession', session)
      await store.dispatch('updateSession', response)
    },
  },

  getters: {
    getSession: (state) => (id) => {
      return state.sessions.data.find((session) => session.id === id)
    },
    getParticipant: (state) => (id) => {
      return state.session.participants.find((participant) => participant.id === id)
    },
    getReservation: (state) => (id) => {
      return state.session.reservations.find((reservation) => reservation.identifier === id)
    },
  },
}
