import Wrapper from '@/munio/vue/Wrapper.js'
import App from './App.vue'
import store from './store.js'

Munio.UserCertificates = class extends Wrapper {
  constructor(selector, user) {
    super(selector, { user })
  }

  component() {
    return App
  }

  store() {
    return store
  }
}
