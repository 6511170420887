<template>
  <i :class="[iconClass, { small: small }]">{{ iconName }}</i>
</template>

<script>
export default {
  props: {
    type: { type: String, required: false, default: 'mdl' },
    name: { type: String, required: true },
    small: { type: Boolean },
  },

  computed: {
    iconClass() {
      switch (this.type) {
        case 'mdl':
          return 'material-icons'
        case 'fa':
        default:
          return `fa fa-${this.name}`
      }
    },

    iconName() {
      switch (this.type) {
        case 'mdl':
          return this.name
        case 'fa':
        default:
          return null
      }
    },
  },
}
</script>
