<template>
  <div class="mdl-file">
    <input
      type="file"
      ref="file"
      :name="name"
      :accept="accept"
      :disabled="disabled"
      :multiple="multiple"
      v-on="$listeners"
      @change="onSelect"
    />
    <mdl-button v-bind="$attrs" :outlined="outlined" :opaque="opaque" :disabled="disabled" @click="onClick">{{
      trans('Choose file')
    }}</mdl-button>
    <mdl-textfield
      readonly
      compact
      :value="filename"
      :error="error"
      :disabled="disabled"
      :placeholder="trans('No file selected')"
      @click="onClick"
    />
  </div>
</template>

<script>
export default {
  name: 'MdlFile',

  inheritAttrs: false,

  props: {
    name: { type: String, default: '' },
    outlined: { type: Boolean, default: true },
    opaque: { type: Boolean, default: true },
    accept: String,
    disabled: Boolean,
    multiple: Boolean,
    error: { type: Boolean, default: false },
  },

  data() {
    return {
      file: undefined,
    }
  },

  computed: {
    filename() {
      return this.file ? this.file.name : this.name
    },
  },

  methods: {
    onClick(e) {
      this.$refs.file.click()
    },

    onSelect(e) {
      this.file = e.target.files[0]
      this.$emit('onFileInput', this.file)
    },
  },
}
</script>
