<template>
  <form @submit.prevent="add" class="">
    <card class="mdl-shadow--8dp">
      <h4>{{ trans('Invite user') }}</h4>

      <section class="mt-8">
        <div>
          <mdl-textfield
            required
            type="email"
            tabindex="0"
            :floating-label="trans('E-mail')"
            :loading="checkingEmail"
            v-autofocus
            v-model="email"
            ref="email"
          />
        </div>

        <div class="flex">
          <mdl-textfield
            required
            minlength="2"
            tabindex="0"
            class="flex-grow"
            :readonly="!!user.id"
            :floating-label="trans('Firstname')"
            v-model="user.firstname"
            ref="firstname"
          />

          <mdl-textfield
            required
            minlength="2"
            tabindex="0"
            class="flex-grow ml-4"
            :readonly="!!user.id"
            :floating-label="trans('Lastname')"
            v-model="user.lastname"
            ref="lastname"
          />
        </div>
      </section>

      <section>
        <h4 class="mdl-subheader">{{ trans('Employer') }}</h4>

        <template v-if="changeCompany">
          <company-search v-model="companySelected">
            <template #actions>
              <mdl-button :raised="!!companySelected" @click="changeCompany = false">{{ trans('Select') }}</mdl-button>
            </template>
          </company-search>
          <div v-if="!companySelected" class="mdl-color-text--info">{{ trans('No company selected') }}</div>
        </template>

        <company
          v-else-if="companySelected && !changeCompany"
          :company="companySelected"
          :country="companySelected.country"
          class="mdl-shadow--2dp"
        >
          <mdl-button v-if="!(user && user.id)" raised class="ml-8" @click="changeCompany = true">
            {{ trans('Change') }}
          </mdl-button>
        </company>
      </section>

      <template #actions>
        <mdl-button tabindex="0" type="submit" raised :disabled="!isValid" @click.prevent="add">
          {{ trans('Add') }}
        </mdl-button>
        <mdl-button tabindex="0" @click.stop="$emit('cancel')">
          {{ trans('Cancel') }}
        </mdl-button>
      </template>
    </card>
  </form>
</template>

<script>
import debounce from 'lodash/debounce'
import Company from '@/munio/vue/components/CompanySearch/Company.vue'

export default {
  components: { Company },
  props: {
    company: {
      type: Object,
      default: () => Munio.config.company,
    },
  },
  data() {
    return {
      user: {
        id: null,
        email: '',
        firstname: '',
        lastname: '',
        company: null,
      },
      changeCompany: false,
      checkingEmail: false,
      validEmail: false,
      isValid: false,
      companySelected: null,
    }
  },
  computed: {
    email: {
      get() {
        return this.user.email
      },
      set(value) {
        this.user.email = value
        if (this.user.id) {
          this.reset(true)
        }
        this.$nextTick(() => {
          this.validEmail = !this.$refs.email.invalid
          this.debounceCheckEmail()
        })
      },
    },
  },
  mounted() {
    this.companySelected = this.company
  },
  methods: {
    add() {
      this.$nextTick(() => {
        this.validate()
        if (this.isValid) {
          this.$emit('submit', { ...this.user, company: this.companySelected })
          this.reset()
        }
      })
    },

    validate() {
      if (!this.user.id) {
        for (const field of ['email', 'firstname', 'lastname']) {
          if (!this.$refs[field] || !this.$refs[field].$refs.input.validity.valid) {
            return (this.isValid = false)
          }
        }
      }

      return (this.isValid = this.companySelected !== null)
    },

    reset(keepEmail) {
      this.isValid = false
      this.user = {
        id: null,
        email: keepEmail ? this.user.email : '',
        firstname: '',
        lastname: '',
        company: null,
      }
    },

    async checkEmail() {
      if (!this.validEmail) {
        this.validate()
        return
      }
      try {
        this.checkingEmail = true
        const {
          data: { data: user },
        } = await Munio.api.checkEmail(this.user.email)
        this.user = user
        this.companySelected = user.company
      } catch (err) {
        if (err.response?.status === 404 && this.user.id) {
          this.reset(true)
        }
      } finally {
        this.validate()
        this.checkingEmail = false
      }
    },
  },
  watch: {
    user: {
      deep: true,
      handler() {
        this.$nextTick(() => this.validate())
      },
    },
  },
  created() {
    this.debounceCheckEmail = debounce(this.checkEmail, 400)
  },
}
</script>
