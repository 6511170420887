<template>
  <div class="inline-flex gap-x-2 items-center" v-click-outside="hide">
    <code v-bind="$attrs" :class="{ 'select-all': !isHidden }">{{ isHidden ? maskedValue : value }}</code>

    <mdl-button icon @click="isHidden = !isHidden">
      <icon :name="isHidden ? 'visibility' : 'visibility_off'" />
    </mdl-button>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'

export default defineComponent({
  inheritAttrs: false,

  props: {
    value: {
      required: true,
      type: String,
    },
  },

  setup(props) {
    const isHidden = ref(true)

    return {
      isHidden,
      hide: () => (isHidden.value = true),
      maskedValue: computed(() => props.value.replace(/./g, '\u2022')),
    }
  },
})
</script>
