import UserCourse from './UserCourse.js'

export default class User {
  constructor(zoneId, userId) {
    this.zoneId = zoneId
    this.userId = userId
  }

  get params() {
    return {
      zone: this.zoneId,
      user: this.userId,
    }
  }

  get() {
    return Munio.$http.get(Munio.route('api.web.access.zone.user', this.params))
  }

  requisitions() {
    return Munio.$http.get(Munio.route('api.web.access.zone.user.requisitions', this.params))
  }

  patch(applyCurrentRequirements) {
    return Munio.$http.patch(Munio.route('api.web.access.zone.user.patch', this.params), {
      apply_current: applyCurrentRequirements,
    })
  }

  sync() {
    return Munio.$http.post(Munio.route('api.web.access.zone.user.sync', this.params))
  }

  unregister() {
    return Munio.$http.delete(Munio.route('api.web.access.zone.user.unregister', this.params))
  }

  unrequisition(requisitionId) {
    return Munio.$http.delete(
      Munio.route('api.web.access.zone.user.unrequisition', {
        ...this.params,
        requisition: requisitionId,
      }),
    )
  }

  course(courseId) {
    return new UserCourse(this, courseId)
  }
}
