<template>
  <ul :class="classNames">
    <template v-for="(step, index) in steps">
      <li
        :key="`step-${step.label}`"
        class="mdl-stepper__step"
        :class="{
          'is-done': isPassed(index),
          'is-failed': isFailed(index),
          'is-active': isActive(index),
          'is-clickable': isClickable(index),
        }"
        @click.stop="onClick(index)"
      >
        <span class="mdl-stepper__step-circle">
          <icon v-if="step.icon" :name="step.icon" />
          <icon v-else-if="isFailed(index) && isActive(index)" name="clear" />
          <icon v-else-if="isDone(index)" name="check" />
          <span v-else>{{ index + 1 }}</span>
        </span>
        <span class="mdl-stepper__step-content">
          <span class="mdl-stepper__step-label" v-html="step.label"></span>
          <span class="mdl-stepper__step-description" v-if="step.description" v-html="step.description"></span>
        </span>
      </li>
      <li v-if="layout === 'horizontal'" class="mdl-stepper__separator" :key="`separator-${step.label}`"></li>
    </template>
  </ul>
</template>

<script>
export default {
  props: {
    steps: { type: Array, required: true },
    current: { type: Number, required: true },
    layout: {
      type: String,
      enum: ['horizontal', 'horizontal-alt', 'vertical'],
      default: 'horizontal-alt',
    },
  },

  model: {
    prop: 'current',
    event: 'step',
  },

  computed: {
    classNames() {
      return {
        'mdl-stepper': true,
        'mdl-stepper--horizontal': this.layout === 'horizontal',
        'mdl-stepper--horizontal-alt': this.layout === 'horizontal-alt',
        'mdl-stepper--vertical': this.layout === 'vertical',
      }
    },
  },

  methods: {
    isPassed(index) {
      return index < this.current || this.current + 1 === this.steps.length
    },

    isActive(index) {
      return index === this.current
    },

    isClickable(index) {
      if (this.steps[index].selectable !== undefined) {
        return this.steps[index].selectable
      }

      return this.isPassed(index)
    },

    isDone(index) {
      if (this.steps[index].done !== undefined) {
        return this.steps[index].done
      }

      return this.isPassed(index)
    },

    isFailed(index) {
      if (this.steps[index].failed !== undefined) {
        return this.steps[index].failed
      }
      return false
    },

    onClick(index) {
      if (this.isClickable(index)) {
        this.$emit('step', index)
      }
    },
  },
}
</script>
