import { ShopCartResourceDto } from '@/munio/api/data'

const { $http, route } = window.Munio

class ShopCart {
  constructor(
    public slug: string,
    public context?: string,
  ) {}

  get params() {
    return {
      shop: this.slug,
      context: this.context,
    }
  }

  protected url(routeName: string, params: Record<string, any> = {}) {
    return route(routeName, Object.assign(params, this.params))
  }

  get() {
    return $http.get<ShopCartResourceDto>(this.url('api.web.shop.cart'))
  }

  clear() {
    return $http.delete<ShopCartResourceDto>(this.url('api.web.shop.cart.clear'))
  }

  add({ productId, quantity = 1, unlimited = false, sessions = [] }: AddArgs) {
    const url = this.url('api.web.shop.cart.add', { product: productId })
    return $http.post<ShopCartResourceDto>(url, { quantity, unlimited, sessions })
  }

  set({ productId, sessionId, quantity = 1, unlimited = false }: SetArgs) {
    const url = this.url('api.web.shop.cart.set', { product: productId, session: sessionId })
    return $http.put<ShopCartResourceDto>(url, { quantity, unlimited })
  }
}

type AddArgs = {
  productId: number
  quantity?: number
  unlimited?: boolean
  sessions?: SessionOption[]
}

type SetArgs = {
  productId: number
  sessionId?: number
  quantity: number
  unlimited: boolean
}

type SessionOption = {
  id: number
  quantity: number
}

export default ShopCart
