<template>
  <thead>
    <tr class="mdl-data-table__progressbar">
      <td colspan="999"><mdl-progressbar indeterminate /></td>
    </tr>
    <tr>
      <th v-if="dataTable.selectable">
        <mdl-checkbox :checked="selected" :disabled="!selectable" :indeterminate="indeterminate" @change="onToggle" />
      </th>
      <slot></slot>
    </tr>
  </thead>
</template>

<script>
export default {
  inject: ['dataTable'],

  data() {
    return {
      selected: false,
      selectable: false,
      indeterminate: false,
    }
  },

  mounted() {
    this.onSelection()
    this.dataTable.$on('rowCount', this.onSelection)
    this.dataTable.$on('selection', this.onSelection)
  },

  beforeDestroy() {
    this.dataTable.$off('rowCount', this.onSelection)
    this.dataTable.$off('selection', this.onSelection)
  },

  computed: {
    loading() {
      return this.dataTable.loading
    },
  },

  methods: {
    onSelection() {
      const { selectable, selected } = this.dataTable.getCount()

      this.selected = selected > 0 && selected === selectable
      this.selectable = selectable > 0
      this.indeterminate = selected > 0 && selected < selectable
    },

    onToggle(checked) {
      this.dataTable.toggleAll(checked)
    },
  },
}
</script>
