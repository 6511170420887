<template>
  <mdl-list-item
    :class="{ 'mb-8': isCreating }"
    :expanded="expanded || isSelected"
    :expandable="!creating"
    :loading="isProcessing"
    @toggle="toggleLink"
  >
    <template v-slot:title>
      <h3 v-if="isCreating" class="m-0">
        {{ trans('New link') }}
        <span v-if="link.responsible" class="label label-info">
          {{ link.responsible.company.name }}
        </span>
      </h3>
      <h4 v-else class="m-0">
        {{ link.title }}
        <span v-if="link.responsible" class="label label-info">
          {{ link.responsible.company.name }}
        </span>
      </h4>
    </template>

    <template v-slot:actions>
      <span v-if="link.shortUrl" class="label label-info label-outlined select-all" @click.prevent v-tooltip="title">
        {{ link.shortUrl }}
      </span>
      <small v-else-if="!isCreating" class="label label-danger label-outlined text-xl rounded-md">
        {{ trans('Missing required link slug') }}
      </small>
      <span v-else-if="form.slug" class="label label-info label-outlined select-all" @click.prevent v-tooltip="title">
        {{ route('munioaccess.link', { link: form.slug }) }}
      </span>
    </template>

    <div v-if="!isCreating" slot="subtitle" class="flex mt-2">
      <div class="mr-4">
        <span>{{ trans('ID') }}:</span>
        <strong>{{ link.id }}</strong>
      </div>
      <div class="mr-4" v-if="link.slug">
        <span>{{ trans('Slug') }}:</span>
        <strong>{{ link.slug }}</strong>
      </div>
      <div class="mr-4" :class="{ 'mdl-color-text--danger': !link.zones.length }">
        <span>{{ trans('Zones') }}:</span>
        <strong>{{ link.zones.length || '-' }}</strong>
      </div>
      <div class="mr-4">
        <span>{{ trans('Pageviews') }}:</span>
        <strong>{{ link.stats.pageviews || '-' }}</strong>
      </div>
      <div class="mr-4">
        <span>{{ trans('Registrations') }}:</span>
        <strong>{{ link.stats.registrations || '-' }}</strong>
      </div>
      <div class="mr-4" v-if="lastVisitedAt">
        <span>{{ trans('Last visited') }}:</span>
        <strong>{{ lastVisitedAt }}</strong>
      </div>
    </div>

    <section class="flex">
      <div class="flex-grow">
        <h4 class="mdl-subheader m-0">{{ trans('Title') }}</h4>
        <mdl-textfield-i18n v-model="form.title" />
      </div>
      <div class="ml-12">
        <h4 class="mdl-subheader m-0">{{ trans('URL slug') }}</h4>
        <mdl-textfield v-model="form.slug" :placeholder="trans('Optional')" maxlength="16" size="16" />
      </div>
    </section>

    <section>
      <h4 class="mdl-subheader mb-0">{{ trans('Message') }}</h4>
      <mdl-textfield-i18n v-model="form.message" expandable />
    </section>

    <section v-if="responsibleModeEnabled || link.responsible">
      <h4 class="mdl-subheader">{{ trans('Responsible company') }}</h4>
      <m-select
        clearable
        :placeholder="trans('Not provided')"
        v-model="form.responsible"
        :options="responsibles"
        class="w-96 mr-4"
      />
    </section>

    <section>
      <h3>{{ trans('Zones') }}</h3>

      <template v-if="zones.length">
        <div v-for="company of companies" :key="company.id" class="mdl-chips mt-4">
          <h4 class="mdl-subheader w-full">{{ trans(company.name) }}</h4>
          <mdl-chip
            v-for="zone of company.zones"
            :key="zone.id"
            deletable
            :delete-icon="isZoneRemoved(zone.id) ? 'add_circle_outline' : 'cancel'"
            :class="{ opaque: isZoneRemoved(zone.id) }"
            @delete="toggleZone(zone)"
            >{{ zone.name }}</mdl-chip
          >
          <mdl-button icon @click="showCompanyZoneModal(company)">
            <icon name="add" />
          </mdl-button>
        </div>
      </template>
      <div v-else>
        <em class="opaque">{{ trans('No zones added') }}</em>
      </div>
    </section>

    <template v-slot:card-actions>
      <mdl-button :disabled="!isDirty" :raised="isDirty" primary @click="save">{{ trans('Save') }}</mdl-button>
      <mdl-button :disabled="!isDirty && !isCreating" @click="cancel">{{ trans('Cancel') }}</mdl-button>
      <mdl-button raised @click="showZoneModal">{{ trans('Add zone') }}</mdl-button>
      <mdl-button v-if="!isCreating" danger class="ml-auto" icon @click="remove"><icon name="delete" /></mdl-button>
    </template>

    <add-zone-modal
      :company="zoneCompany"
      :added="selectedZoneIds"
      :loading="loading === 'zones'"
      :show="showModal"
      @hide="showModal = false"
      @add="addZones"
    />
  </mdl-list-item>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { toDateString } from '@/munio/utils/date.js'
import AddZoneModal from './AddZoneModal.vue'

export default {
  components: {
    AddZoneModal,
  },

  props: {
    link: {
      type: Object,
      default: () => ({
        company: Munio.config.company,
        title: null,
        titleI18n: {},
        message: null,
        messageI18n: {},
        responsible: null,
        zones: [],
        stats: {},
      }),
    },
    expanded: Boolean,
  },

  data() {
    return {
      form: this.getForm(),
      showModal: false,
      zoneCompany: null,
    }
  },

  computed: {
    ...mapState(['loading', 'processing', 'creating', 'selected']),
    ...mapGetters(['getZone']),

    isProcessing() {
      return this.processing === this.id
    },

    isCreating() {
      return this.creating && !this.link.id
    },

    isSelected() {
      return this.isCreating || this.selected === this.link.id
    },

    isEditable() {
      return !this.isCreating && this.isSelected
    },

    id() {
      return this.isCreating ? 'create' : this.link.id
    },

    title() {
      return this.link.title || this.link.slug
    },

    message() {
      return this.link.message
    },

    zoneIds() {
      return this.form.zones || []
    },

    selectedZoneIds() {
      return this.zoneIds.filter((zoneId) => !this.isZoneRemoved(zoneId))
    },

    zones() {
      return this.$store.getters.sortZones(this.zoneIds.map((id) => this.getZone(this.link.zones, id)))
    },

    companies() {
      return this.$store.getters.groupZonesByCompany(this.zones)
    },

    responsibleModeEnabled() {
      return this.$store.state.links.gate.responsibleMode !== 'disabled'
    },

    responsibles() {
      if (!this.responsibleModeEnabled) {
        return []
      }

      return (this.$store.state.links.gate.responsibles || []).map((responsible) => ({
        id: responsible.id,
        label: responsible.company.name,
      }))
    },

    isDirty() {
      return JSON.stringify(this.form) !== JSON.stringify(this.getForm())
    },

    lastVisitedAt() {
      const date = this.link.stats.lastVisitedAt

      if (!date) {
        return null
      }

      return toDateString(date)
    },
  },

  methods: {
    toggleLink() {
      if (this.creating) {
        return
      }

      this.$store.commit('TOGGLE_LINK', this.link.id)
    },

    getForm() {
      return this.$store.getters.getForm(this.link)
    },

    showZoneModal() {
      this.showCompanyZoneModal(null)
    },

    showCompanyZoneModal(company) {
      this.zoneCompany = company
      this.showModal = true
    },

    isZoneRemoved(zoneId) {
      return this.form.zonesRemoved.includes(zoneId)
    },

    toggleZone(zone) {
      const i = this.form.zonesRemoved.findIndex((id) => id === zone.id)

      if (i >= 0) {
        this.form.zonesRemoved = [...this.form.zonesRemoved.slice(0, i), ...this.form.zonesRemoved.slice(i + 1)]
      } else {
        this.form.zonesRemoved.push(zone.id)
      }
    },

    sortZones() {
      this.form.zones = this.zones
        .sort((a, b) => (a.company.name + a.name).localeCompare(b.company.name + b.name, Munio.config.i18n.language))
        .map((zone) => zone.id)
    },

    addZones(zoneIds) {
      this.form.zones = [...this.form.zones, ...zoneIds].filter((value, index, zones) => zones.indexOf(value) === index)
      this.form.zonesRemoved = this.form.zonesRemoved.filter((id) => !this.form.zones.includes(id))
      this.showModal = false
    },

    cancel() {
      this.form = this.getForm()

      if (this.isCreating) {
        this.$store.commit('SET_CREATING', false)
      }
    },

    save() {
      this.$store.dispatch('save', {
        id: this.id,
        link: {
          slug: this.form.slug,
          title: this.form.title,
          message: this.form.message,
          responsible: this.form.responsible,
          zones: this.selectedZoneIds,
        },
      })
    },

    remove() {
      this.$store.dispatch('delete', this.id)
    },
  },

  watch: {
    link(value) {
      this.cancel()
    },
  },
}
</script>
