<template>
  <label :class="classNames">
    <input
      type="radio"
      class="mdl-radio__button"
      v-on="listeners"
      v-bind="attrs"
      :name="name"
      :value="value"
      :disabled="disabled"
      :checked="isChecked"
      :readonly="readonly"
      @change="onChange"
    />
    <span v-if="$slots.default || label" class="mdl-radio__label">
      {{ label }}
      <slot></slot>
      <span v-if="description" class="mdl-radio__description">{{ description }}</span>
    </span>
  </label>
</template>

<script>
export default {
  inheritAttrs: false,

  model: {
    prop: 'checked',
    event: 'change',
  },

  props: {
    name: { type: String },
    label: { type: String },
    description: { type: String },
    disabled: { type: Boolean },
    value: { type: [Boolean, String, Number], default: null },
    checked: { type: [Boolean, String, Number], default: false },
    readonly: { type: Boolean },
  },

  computed: {
    attrs() {
      const { name, disabled, checked, readonly, ...attrs } = this.$attrs

      return attrs
    },

    listeners() {
      const { change, ...listeners } = this.$listeners
      return listeners
    },

    classNames() {
      return {
        'mdl-radio': true,
        'mdl-js-radio': true,
        'is-upgraded': true,
        'pointer-events-none': this.readonly,
      }
    },

    isChecked() {
      if (this.value !== null) {
        if (typeof this.value !== typeof this.checked) {
          return this.checked
        }

        return this.checked === this.value
      }

      return this.checked
    },
  },

  watch: {
    disabled() {
      this.updateElement()
    },
  },

  mounted() {
    componentHandler.upgradeElement(this.$el)
    this.updateElement()
  },

  updated() {
    this.updateElement()
  },

  beforeDestroy() {
    componentHandler.downgradeElements([this.$el])
  },

  methods: {
    onChange(event) {
      let value = event.target.checked

      if (value && this.value !== null && typeof this.checked !== 'boolean') {
        value = this.value
      }

      this.$emit('change', value)
    },

    updateElement() {
      this.$el.MaterialRadio[this.isChecked ? 'check' : 'uncheck']()
      this.$el.MaterialRadio[this.disabled ? 'disable' : 'enable']()
    },
  },
}
</script>
