<script lang="ts">
import { computed, defineComponent } from 'vue'
import { trans } from '@/munio/i18n/index.js'
import MdlButton from './Button.vue'
import MdlProgressBar from '@component/mdl/ProgressBar.vue'
import MdlTextfield, { Props } from './Textfield.vue'
import Icon from '@component/Icon.vue'

type Requirement = {
  key: string
  regex: string
  label: string
  valid: boolean
}

const rules = window.Munio.config.auth.password.rules

export default defineComponent({
  inheritAttrs: false,

  components: {
    MdlProgressBar,
    Icon,
    MdlTextfield,
  },

  props: {
    ...Props,
    value: { type: String, default: '' },
  },

  emits: ['error'],

  data() {
    return {
      focused: false,
      internalError: null,
    }
  },

  computed: {
    requirements() {
      return rules.map((rule) => ({
        key: rule.key,
        regex: rule.regex,
        label: rule.label,
        valid: new RegExp(rule.regex).test(this.value),
      }))
    },
    valid() {
      return this.requirements.every((req) => req.valid)
    },
    score() {
      let score = this.requirements.reduce((score, req) => {
        return score + (req.valid ? 1 : 0)
      }, 0)

      return Math.min(100, Math.round((score / rules.length) * 100))
    },
  },

  methods: {
    handleFocusEnter() {
      this.focused = true
      this.$emit('error', this.internalError)
    },
    handleFocusLeave() {
      this.focused = false
      this.$emit('error', this.internalError)
    },
    colorClass(requirement: Requirement) {
      return requirement.valid ? 'text-green-600 green-600' : 'text-gray-500 gray-500'
    },
  },

  watch: {
    value() {
      this.internalError = this.valid ? null : trans('Ensure the password conditions are met')
    },
  },
})
</script>

<template>
  <div class="mdl-textfield-password">
    <MdlTextfield
      :type="'password'"
      :floating-label="trans('Password')"
      v-on="$listeners"
      v-bind="{ ...$attrs, ...$props }"
      @focusin="handleFocusEnter"
      @focusout="handleFocusLeave"
    >
      <div v-if="focused" class="mdl-textfield-password--rules">
        <div>{{ trans('Strength') }}: {{ score }}%</div>
        <MdlProgressBar :progress="score" :success="valid" class="my-2" />
        <div v-for="(requirement, index) in requirements" :key="index" class="flex items-center gap-x-1">
          <Icon small :name="requirement.valid ? 'check' : 'close'" :class="colorClass(requirement)" />
          <span :class="colorClass(requirement)">
            {{ requirement.label }}
          </span>
        </div>
      </div>
    </MdlTextfield>
  </div>
</template>
