/**
 * Zendesk Livechat
 */
if (Munio.config.support && Munio.config.user?.company) {
  ;(
    window.zEmbed ||
    function (source, domain) {
      let n, o, d, i, s
      const a = []
      const r = document.createElement('iframe')
      window.zEmbed = function () {
        a.push(arguments)
      }
      window.zE = window.zE || window.zEmbed
      r.src = 'javascript:false'
      r.title = ''
      r.role = 'presentation'
      ;(r.frameElement || r).style.cssText = 'display: none'
      d = document.getElementsByTagName('script')
      d = d[d.length - 1]
      d.parentNode.insertBefore(r, d)
      i = r.contentWindow
      s = i.document
      try {
        o = s
      } catch (e) {
        n = document.domain
        r.src = 'javascript:var d=document.open();d.domain="' + n + '";void(0);'
        o = s
      }
      o.open()._l = function () {
        const o = this.createElement('script')
        n && (this.domain = n)
        o.id = 'js-iframe-async'
        o.src = source
        this.t = +new Date()
        this.zendeskHost = domain
        this.zEQueue = a
        this.body.appendChild(o)
      }
      o.write('<body onload="document._l();">')
      o.close()
    }
  )('https://assets.zendesk.com/embeddable_framework/main.js', 'munio.zendesk.com')

  window.zE(function () {
    window.zE.setLocale(window.Munio.config.i18n.locale)
    const user = window.Munio.config.user
    if (user.email) {
      window.zE.identify({
        name: user.fullname,
        email: user.email,
        organization: user.company.name,
      })
    }
  })
}
