export default {
  userId: null,
  initialized: false,
  processing: false,
  selected: null,

  relationships: [],
  selectableCompanies: [],

  canChangeCompany: false,
  canEdit: false,
}
