import settings from './settings.js'
import { mapArray, get } from '@/munio/utils/index.js'

const optionsKey = 'zone.display'
const options = Munio.storage.get(optionsKey, {})

const state = {
  gate: null,
  companyId: null,
  selectedZoneId: null,
  zones: [],
  zonesMap: {},
  entrypoints: [],
  includeZoneId: get(options.includeZoneId, false),
  includeTenants: get(options.includeTenants, true),
  includeTenantsSub: get(options.includeTenantsSub, true),
  includeEntrypoints: get(options.includeEntrypoints, false),
  loading: false,
}

const actions = {
  initialize({ dispatch, commit }) {
    commit('SET_GATE', Munio.state.gate)
    dispatch('getZones')
  },

  async createZone({ state, dispatch }, data) {
    try {
      await Munio.api.access.zone(state.gate.id).create(data)
      await dispatch('getZones')
    } catch (e) {
      console.error(e)
      Munio.Flash.error(trans('An error occured'))
    }
  },

  async getZones({ state, commit }) {
    commit('SET_LOADING', true)
    try {
      const {
        data: { data },
      } = await Munio.api.access.gate(state.gate.id).zones()
      commit('SET_ZONES', data)
    } catch (e) {
      console.error(e)
      Munio.Flash.error(trans('Failed to load zones.') + ' ' + trans('Please try again.'))
    } finally {
      commit('SET_LOADING', false)
    }
  },

  updateZone({ commit }, zone) {
    commit('SET_ZONE', zone)
  },

  setSelectedZone({ commit }, value) {
    commit('SET_SELECTED_ZONE', value)
    commit('settings/RESET')
  },
}

const mutations = {
  TOGGLE_ZONE_IDS(state, value) {
    state.includeZoneId = options.includeZoneId = value
    Munio.storage.set(optionsKey, options)
  },

  TOGGLE_TENANTS(state, value) {
    state.includeTenants = options.includeTenants = value
    Munio.storage.set(optionsKey, options)
  },

  TOGGLE_TENANTS_SUB(state, value) {
    state.includeTenantsSub = options.includeTenantsSub = value
    Munio.storage.set(optionsKey, options)
  },

  TOGGLE_ENTRYPOINTS(state, value) {
    state.includeEntrypoints = options.includeEntrypoints = value
    Munio.storage.set(optionsKey, options)
  },

  SET_SELECTED_ZONE(state, value) {
    state.selectedZoneId = value
  },

  SET_GATE(state, gate) {
    state.gate = gate
  },

  SET_INTEGRATION(state, integration) {
    state.gate.integration = integration
  },

  SET_ZONE(state, zone) {
    const index = state.zonesMap[zone.id]
    const copy = [...state.zones]
    copy[index] = zone
    state.zones = copy
    state.gate = zone.gate
  },

  SET_ZONES(state, value) {
    state.zones = value
    state.zonesMap = mapArray(state.zones, (zone) => zone.id)
    state.selectedZoneId = state.selectedZoneId || state.gate.id
    const index = state.zonesMap[state.gate.id]
    state.companyId = state.zones[index]?.company?.id
  },

  SET_LOADING(state, value) {
    state.loading = value
  },
}

const getters = {
  getZone: (state) => (id) => {
    if (!id) return null
    const index = state.zonesMap[id]
    return state.zones[index]
  },

  getCompany: (state) => (id) => {
    return state.companies[id]
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
  modules: {
    settings,
  },
}
