import Backbone from '@/libs/backbone.js'

Munio.behaviors.categoryModal = {
  attach: function (context, config) {
    const App = Backbone.View.extend({
      shops: null,

      initialize: function (options) {
        if (!this.$el.length) return

        this.shops = this.$('input[name^="shop"]')
      },
    })

    this.instance = new App({ el: $('#category-modal', context) })
  },
}
