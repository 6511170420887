class CompanyCourseSessionUsers {
  companyCourseId
  sessionId

  constructor(companyCourseId, sessionId) {
    this.companyCourseId = companyCourseId
    this.sessionId = sessionId
  }

  route(name, params = {}) {
    return Munio.route(
      name,
      Object.assign(
        {
          companyCourse: this.companyCourseId,
          session: this.sessionId,
        },
        params,
      ),
    )
  }

  approve(ids) {
    return Munio.$http.post(this.route('api.web.admin.company.course.session.users.update'), {
      users: ids,
      action: 'approve',
    })
  }

  reset(ids) {
    return Munio.$http.post(this.route('api.web.admin.company.course.session.users.update'), {
      users: ids,
      action: 'reset',
    })
  }

  unenroll(ids) {
    return Munio.$http.delete(this.route('api.web.admin.company.course.session.users.unenroll'), { users: ids })
  }
}

export default CompanyCourseSessionUsers
