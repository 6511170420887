class SessionRequests {
  companyCourseId
  constructor(companyCourseId) {
    this.companyCourseId = companyCourseId
  }

  route(name, params = {}) {
    return Munio.route(
      name,
      Object.assign(
        {
          companyCourse: this.companyCourseId,
        },
        params,
      ),
    )
  }

  index() {
    return Munio.$http.get(this.route('api.web.admin.company.course.sessions.requests'))
  }

  delete(id) {
    return Munio.$http.delete(
      this.route('api.web.admin.company.course.sessions.requests.delete', { sessionRequest: id }),
    )
  }
}

export default SessionRequests
