import { onMounted, onUnmounted, ref, Ref } from 'vue'

/**
 * Provides sticky headers for any table.
 *
 * @example
 * // Should be top level in setup method or script setup
 *
 * const thead = ref()
 * const tableWrap = ref()
 *
 * // remember to set refs for both elements in template: <thead ref="thead" ... > <div ref="tableWrap" ...>
 *
 * const {
 *   isStuck,
 *   hasScrollLeft,
 *   hasScrollRight
 * } = useTableEnhancement({ tableWrap, thead })
 *
 * return {
 *   // remember to return refs if using options api setup method
 *   thead,
 *   tableWrap,
 *   // use in template
 *   isStuck,
 *   hasScrolledX,
 *   hasScrollRight
 * }
 */
export default (tableWrap: Ref<HTMLElement | undefined>, thead: Ref<HTMLElement | undefined>) => {
  const isStuck = ref(false)
  const hasScrollLeft = ref(false)
  const hasScrollRight = ref(false)
  const observeTable = ref(false)
  let offsetTop = 0

  const observe = () => {
    if (!observeTable.value) {
      hasScrollRight.value = false
      hasScrollLeft.value = false
      isStuck.value = false

      return
    }

    const [wrap, head] = [tableWrap.value, thead.value]

    if (!(wrap && head)) {
      hasScrollRight.value = false
      hasScrollLeft.value = false
      return requestAnimationFrame(observe)
    }

    const { top: wrapTop, bottom, width } = wrap.getBoundingClientRect()
    const { scrollWidth, scrollLeft } = wrap
    const top = wrapTop + head.offsetTop
    const newOffset = Math.abs(Math.min(0, top))

    isStuck.value = newOffset > 1
    hasScrollRight.value = scrollLeft + width < scrollWidth - 1
    hasScrollLeft.value = scrollLeft > 1

    if (newOffset === offsetTop || bottom < 100) {
      return requestAnimationFrame(observe)
    }

    offsetTop = newOffset

    head.style.transform = `translateY(${offsetTop}px)`

    requestAnimationFrame(observe)
  }

  onMounted(() => {
    observeTable.value = true
    observe()
  })

  onUnmounted(() => {
    observeTable.value = false
  })

  return {
    isStuck,
    hasScrollLeft,
    hasScrollRight,
  }
}
