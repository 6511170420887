const state = {
  id: null,
  loading: false,
  processing: false,
  selected: null,
  enrolled: [],
  enrollable: [],
  modal: {
    active: null,
    loading: false,
  },
}

const mutations = {
  LOADING(state, value) {
    state.loading = value
  },

  PROCESSING(state, id) {
    state.processing = id
  },

  SET_ENROLLED(state, enrolled) {
    state.enrolled = enrolled
  },

  SET_ENROLLABLE(state, enrollable) {
    state.enrollable = enrollable
  },

  SET_COURSE(state, data) {
    const i = state.enrolled.findIndex((userCourse) => userCourse.id === data.id)

    state.enrolled = [...state.enrolled.slice(0, i), data, ...state.enrolled.slice(i + 1)]
  },

  SET_MODAL(state, { active, loading }) {
    state.modal.active = active
    state.modal.loading = loading
  },

  TOGGLE(state, id) {
    state.selected = id === state.selected ? null : id
  },
}

const getters = {
  getById: (state) => (id) => {
    return state.enrolled.find((userCourse) => userCourse.id === id)
  },

  getByCourseId: (state) => (courseId) => {
    return state.enrolled.find((userCourse) => userCourse.courseId === courseId)
  },
}

const actions = {
  async toggle({ state, commit, getters, dispatch }, id) {
    commit('TOGGLE', id)

    if (state.selected === id) {
      dispatch('fetchCourse', getters.getById(id).courseId)
    }
  },

  async fetch({ state, commit }) {
    commit('LOADING', true)

    try {
      const { data } = await Munio.api.user(state.id).courses.get()
      commit('SET_ENROLLED', data)
    } finally {
      commit('LOADING', false)
    }
  },

  async fetchCourse({ state, commit, getters }, courseId) {
    const userCourse = getters.getByCourseId(courseId)

    try {
      commit('LOADING', userCourse.id)
      const { data } = await Munio.api.user(state.id).course(courseId).get()
      commit('SET_COURSE', data)
    } finally {
      commit('LOADING', false)
    }
  },

  async fetchEnrollable({ state, commit }) {
    const { data } = await Munio.api.user(state.id).courses.enrollable()
    commit('SET_ENROLLABLE', data)
  },

  async unenroll({ state, commit, dispatch, getters }, { id, companyCourseId }) {
    if (await Munio.confirm()) {
      const courseId = getters.getById(id).courseId

      try {
        commit('PROCESSING', id)
        const { data } = await Munio.api.user(state.id).course(courseId).unenroll(companyCourseId)
        commit('SET_ENROLLED', data)
      } finally {
        commit('PROCESSING', false)
      }
    }
  },

  async restart({ state, commit, getters }, { id }) {
    if (
      await Munio.confirm(
        trans('Are you sure about this?'),
        trans('The course will be reset and the user must start over.'),
      )
    ) {
      const courseId = getters.getById(id).courseId

      try {
        commit('PROCESSING', id)
        const { data } = await Munio.api.user(state.id).course(courseId).restart()
        commit('SET_COURSE', data)
      } finally {
        commit('PROCESSING', false)
      }
    }
  },

  async uploadDocument({ state, commit, getters }, { id, file }) {
    if (!file) {
      return
    }

    const courseId = getters.getById(id).courseId

    try {
      commit('PROCESSING', id)
      const { data } = await Munio.api.user(state.id).course(courseId).document(file)
      commit('SET_COURSE', data)
    } finally {
      commit('PROCESSING', false)
    }
  },

  async setDate({ state, commit, getters }, { id, dateFrom, dateTo }) {
    if (!dateFrom && !dateTo) {
      return
    }

    const courseId = getters.getById(id).courseId

    try {
      commit('PROCESSING', id)
      const { data } = await Munio.api.user(state.id).course(courseId).setDate(dateFrom, dateTo)
      commit('SET_COURSE', data)
    } finally {
      commit('PROCESSING', false)
    }
  },

  async approve({ state, commit, getters }, { id }) {
    const courseId = getters.getById(id).courseId

    try {
      commit('PROCESSING', id)
      const { data } = await Munio.api.user(state.id).course(courseId).approve()
      commit('SET_COURSE', data)
    } finally {
      commit('PROCESSING', false)
    }
  },

  async reject({ state, commit, getters }, { id }) {
    const courseId = getters.getById(id).courseId

    try {
      commit('PROCESSING', id)
      const { data } = await Munio.api.user(state.id).course(courseId).reject()
      commit('SET_COURSE', data)
    } finally {
      commit('PROCESSING', false)
    }
  },

  enroll: async ({ state, commit, dispatch }, { courseId, companyCourseId }) => {
    try {
      const { data } = await Munio.api.user(state.id).course(courseId).enroll(companyCourseId)

      commit('SET_ENROLLED', data)
    } finally {
      commit('SET_MODAL', { active: false, loading: false })
    }
  },

  showEnrollableModal: async ({ state, commit, dispatch }) => {
    commit('SET_MODAL', { active: true, loading: true })
    commit('SET_ENROLLABLE', [])

    try {
      const { data } = await Munio.api.user(state.id).courses.enrollable()

      commit('SET_ENROLLABLE', data)
    } finally {
      commit('SET_MODAL', { active: true, loading: false })
    }
  },

  hideEnrollableModal: ({ commit }) => {
    commit('SET_MODAL', { active: false, loading: false })
  },
}

export default {
  state,
  mutations,
  getters,
  actions,
}
