class Link {
  get basePath() {
    return `/api/link`
  }

  info(url, params = {}) {
    return Munio.$http.get(`${this.basePath}/info`, { url: url.trim(), ...params })
  }
}

export default Link
