import Admins from './Zone/Admins.js'
import Certificates from './Zone/Certificates.js'
import Entrypoints from './Zone/Entrypoints.js'
import Requirements from './Zone/Requirements.js'
import Search from './Zone/Search'
import Users from './Zone/Users'
import User from './Zone/User.js'

export default class Zone {
  constructor(id) {
    this.id = id
  }

  get params() {
    return { zone: this.id }
  }

  get() {
    return Munio.$http.get(Munio.route('api.web.access.zone', this.params))
  }

  create(data) {
    return Munio.$http.post(Munio.route('api.web.access.zone.create', this.params), data)
  }

  update(data) {
    return Munio.$http.put(Munio.route('api.web.access.zone.update', this.params), data)
  }

  user(userId) {
    return new User(this.id, userId)
  }

  get users() {
    return new Users(this.id)
  }

  get search() {
    return new Search(this.id)
  }

  get requirements() {
    return new Requirements(this.id)
  }

  get certificates() {
    return new Certificates(this.id)
  }

  get entrypoints() {
    return new Entrypoints(this.id)
  }

  get admins() {
    return new Admins(this.id)
  }
}
