import Wrapper from '@/munio/vue/Wrapper.js'

Munio.CompanyDetails = class extends Wrapper {
  constructor(selector, company) {
    super(selector, { company })
  }

  component() {
    return import('./App.vue')
  }
}
