import Wrapper from '@/munio/vue/Wrapper.js'
import store from './store.js'

Munio.LocationManager = class extends Wrapper {
  component() {
    return import('./App.vue')
  }

  store() {
    return store
  }
}
