<template>
  <div class="mdl-slider__container">
    <input
      ref="input"
      type="range"
      class="mdl-slider mdl-js-slider is-upgraded"
      data-upgraded=",MaterialSlider"
      :min="min"
      :max="max"
      :step="step"
      :value="valueNumber"
      :disabled="disabled"
      v-bind="attrs"
      @input="onInput"
      v-on="listeners"
    />
    <div class="mdl-slider__background-flex">
      <div class="mdl-slider__background-lower" :style="lowerBackgroundStyle" />
      <div class="mdl-slider__background-upper" :style="upperBackgroundStyle" />
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    value: {
      type: [String, Number],
      required: false,
    },
    step: {
      type: [String, Number],
      default: 1,
    },
    min: {
      type: [String, Number],
      default: 0,
    },
    max: {
      type: [String, Number],
      required: true,
    },
    disabled: {
      required: false,
    },
  },

  computed: {
    attrs() {
      const { min, max, step, value, disabled, ...attrs } = this.$attrs
      return attrs
    },
    listeners() {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
    lowerBackgroundStyle() {
      return {
        flex: `${this.relativeValue} 1 0%`,
      }
    },
    upperBackgroundStyle() {
      return {
        flex: `${1 - this.relativeValue} 1 0%`,
      }
    },
    valueNumber() {
      return Number(this.value)
    },
    stepNumber() {
      return Number(this.step)
    },
    minNumber() {
      return Number(this.min)
    },
    maxNumber() {
      return Number(this.max)
    },
    relativeValue() {
      const val = Math.round((this.valueNumber - this.minNumber) / this.stepNumber) * this.stepNumber
      return val / (this.maxNumber - this.minNumber)
    },
  },

  methods: {
    onInput({ target: { value } }) {
      this.$emit('input', typeof this.value === 'string' ? value : Number(value))
    },
  },
}
</script>
