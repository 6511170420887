export default class {
  gateId

  constructor(gateId) {
    this.gateId = gateId
  }

  get(options) {
    return Munio.$http.get(
      Munio.route('api.web.access.gate.responsibles', { gate: this.gateId }),
      Munio.urlParams(options),
    )
  }

  add(company) {
    return Munio.$http.post(Munio.route('api.web.access.gate.responsible.add', { gate: this.gateId }), company)
  }

  update(companyId, data) {
    return Munio.$http.put(
      Munio.route('api.web.access.gate.responsible.update', { gate: this.gateId, company: companyId }),
      data,
    )
  }

  remove(companyId) {
    return Munio.$http.delete(
      Munio.route('api.web.access.gate.responsible.remove', { gate: this.gateId, company: companyId }),
    )
  }
}
