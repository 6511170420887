<template>
  <div
    class="mdl-searchbar"
    :class="{
      'is-searching': searching,
      'is-focused': focused,
      'is-dropdown': dropdown,
      'has-results': $slots.default,
    }"
    v-click-outside="onBlur"
    v-focus-outside="onBlur"
  >
    <div class="mdl-searchbar__container flex items-center gap-x-2">
      <slot name="search" />

      <mdl-textfield
        v-bind="attrs"
        v-on="listeners"
        :label="label"
        v-model="input"
        v-autofocus="autofocus ? 500 : false"
        @focus="onFocus"
        @keydown.enter.stop.prevent="onEnter"
        @keydown.esc.stop.prevent="onBlur"
      >
        <mdl-button v-if="input" class="mdl-searchbar__clear" icon="clear" @click="onClear" />
      </mdl-textfield>

      <mdl-button primary outlined :loading="searching" :disabled="isSearchDisabled" @click="onClick">
        {{ trans('Search') }}
      </mdl-button>
    </div>
    <div v-if="$slots.default" class="mdl-searchbar__results">
      <slot />
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  inheritAttrs: false,

  props: {
    searching: { type: Boolean },
    value: { type: String, default: '' },
    label: { type: String, default: trans('Type to search') },
    minLength: { type: Number, default: 3 },
    delay: { type: [Boolean, Number], default: 400 },
    autofocus: { type: Boolean, default: true },
    dropdown: { type: Boolean },
    searchOnClick: { type: Boolean },
  },

  data() {
    return {
      focused: false,
      debounceSearch: null,
    }
  },

  computed: {
    input: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)

        if (this.debounceSearch && !this.searchOnClick) {
          this.debounceSearch(value)
        }
      },
    },

    attrs() {
      const { label, value, ...attrs } = this.$attrs
      return attrs
    },

    listeners() {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
    isSearchDisabled() {
      return this.input.length < this.minLength || this.attrs.disabled || this.searching
    },
  },

  methods: {
    onBlur(event) {
      this.focused = false
    },

    onFocus(event) {
      this.focused = true
    },

    onClear(event) {
      this.input = ''
      this.$emit('clear')
      if (!this.$listeners.clear) {
        this.$emit('submit', '')
      }
    },

    onEnter(event) {
      if (this.isSearchDisabled) {
        return
      }

      this.submit(event.target.value)
    },

    onClick(event) {
      this.submit(this.value)
    },

    submit(value) {
      if (this.minLength && value.length < this.minLength) {
        return
      }

      this.debounceSearch?.cancel()
      this.$emit('submit', value)
    },
  },

  created() {
    if (this.delay) {
      this.debounceSearch = debounce(this.submit, this.delay === true ? 400 : this.delay)
    }
  },

  mounted() {
    if (this.minLength === 0) {
      this.$nextTick(() => {
        this.submit(this.value)
      })
    }
  },
}
</script>
