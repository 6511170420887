<template>
  <details class="mdl-details" :open="isOpen">
    <summary ref="summary" @click.prevent="onToggle">{{ summary }}</summary>
    <div ref="content" :class="{ flip: flip && isOpen }"><slot /></div>
  </details>
</template>

<script>
export default {
  props: {
    open: Boolean,
    summary: { type: String, required: true },
    flip: Boolean,
  },

  model: {
    prop: 'open',
    event: 'toggle',
  },

  data() {
    return {
      isOpen: this.open,
    }
  },

  methods: {
    onToggle() {
      this.isOpen = !this.isOpen
      this.$emit('toggle', this.isOpen)
    },
  },

  watch: {
    open(value) {
      this.isOpen = value
    },
  },
}
</script>
