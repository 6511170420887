class RoleUsers {
  roleId = null

  constructor(roleId) {
    this.roleId = roleId
  }

  get params() {
    return {
      role: this.roleId,
    }
  }

  get basePath() {
    return Munio.route('api.web.admin.role.users', this.params)
  }

  get(options, csv = null) {
    if (csv) {
      Munio.downloadCSV(this.basePath, csv, Munio.urlParams(options))
    } else {
      return Munio.$http.get(this.basePath, Munio.urlParams(options))
    }
  }

  search(query) {
    return Munio.$http.get(Munio.route('api.web.admin.role.users.search', this.params), { search: query })
  }

  add(users) {
    return Munio.$http.post(Munio.route('api.web.admin.role.users.add', this.params), { users })
  }

  remove(users) {
    return Munio.$http.delete(Munio.route('api.web.admin.role.users.remove', this.params), { users })
  }
}

export default RoleUsers
