import Wrapper from '@/munio/vue/Wrapper.js'
import store from './store.js'

Munio.GateCardManager = class extends Wrapper {
  constructor(selector, gateId, integrationId) {
    super(selector, { gateId, integrationId })
  }

  component() {
    return import('./App.vue')
  }

  store() {
    return store
  }
}
