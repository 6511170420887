import { ResourceResponse } from '@/munio/api/data/common'
import { CourseResourceDto } from '@/munio/api/data/CourseResource.dto'

export type Configuration = {
  classification?: number
  categories?: number[]
  regions?: number[]
}

const { $http, route } = window.Munio

export default class CourseManager {
  constructor(private id: number) {
    this.id = id
  }

  async update(configuration: Configuration) {
    return $http.put<ResourceResponse<CourseResourceDto>>(
      route('api.web.munio.course.update', {
        id: this.id,
      }),
      configuration,
    )
  }
}
