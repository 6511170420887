<template>
  <list list-class="mdl-list" v-bind="{ ...$props, ...$attrs }" v-on="$listeners">
    {{ expandedIndex }}
    <mdl-list-item
      slot="item"
      slot-scope="props"
      :expanded="expandedIndex === props.index"
      @expand="expandedIndex = props.index"
    ></mdl-list-item>
    <slot name="actions" slot="actions"></slot>
    <slot></slot>
    <slot name="footer" slot="footer"></slot>
  </list>
</template>

<script>
import List from '@/munio/vue/components/list/List.vue'

export default {
  inheritAttrs: false,

  props: List.props,

  data() {
    return {
      expandedIndex: null,
    }
  },
}
</script>
