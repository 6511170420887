import Vue, { computed, ref, reactive } from 'vue'

Munio.Counters = new Vue({
  name: 'Counters',

  setup() {
    const refs = {}
    const ulid = ref(Munio.config.counters.ulid)
    const list = reactive(Munio.config.counters.list ?? {})

    function get(key) {
      return computed(() => {
        let sum = 0

        for (const [counterKey, value] of Object.entries(list)) {
          if (counterKey.startsWith(key)) {
            sum += value
          }
        }

        return sum
      })
    }

    function count(key) {
      if (refs[key]) {
        return refs[key]
      }

      return (refs[key] = computed(() => {
        const result = get(key)

        if (result.value > 99) {
          return '99+'
        }

        if (!result.value) {
          return undefined
        }

        return String(result.value)
      }))
    }

    /**
     * @param {string|null} newUlid
     * @returns {Promise<void>}
     */
    async function reload(newUlid = null) {
      if (newUlid && newUlid === ulid.value) {
        return
      }

      const {
        data: { ulid: currentUlid, list: currentList },
      } = await Munio.api.currentUser.counters()
      ulid.value = currentUlid
      Object.assign(list, currentList)
    }

    return {
      ulid,
      list,
      get,
      count,
      reload,
    }
  },
})
