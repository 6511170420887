import { CollectionResponse } from '@/munio/api/data/common'
import { ApprovalResourceDto } from '@/munio/api/data/ApprovalResource.dto'

class Approvals {
  get(options?: URLSearchParams) {
    return window.Munio.$http.get<CollectionResponse<ApprovalResourceDto>>(
      window.Munio.route('api.web.admin.approvals'),
      window.Munio.urlParams(options),
    )
  }
}

export default Approvals
