class ShopProduct {
  constructor(shopPath, id) {
    this.shopPath = shopPath
    this.id = id
  }

  get basePath() {
    return `${this.shopPath}/product/${this.id}`
  }

  get() {
    return Munio.$http.get(this.basePath)
  }

  subscribe(comment, quantity) {
    return Munio.$http.post(`${this.basePath}/subscribe`, cleanup({ comment, quantity }))
  }

  unsubscribe() {
    return Munio.$http.delete(`${this.basePath}/unsubscribe`)
  }
}

export default ShopProduct
