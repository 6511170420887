class RoleCourses {
  roleId = null

  constructor(roleId) {
    this.roleId = roleId
  }

  get params() {
    return {
      role: this.roleId,
    }
  }

  get() {
    return Munio.$http.get(Munio.route('api.web.admin.role.courses', this.params))
  }

  add(courseIds) {
    return Munio.$http.post(Munio.route('api.web.admin.role.courses.add', this.params), { courses: courseIds })
  }

  remove(courseIds) {
    return Munio.$http.delete(Munio.route('api.web.admin.role.courses.remove', this.params), { courses: courseIds })
  }
}

export default RoleCourses
