<template>
  <div>
    <toolbar v-model="filters" :loading="loading === true">
      <mdl-button raised primary @click="onNew">{{ trans('New link') }}</mdl-button>
    </toolbar>

    <mdl-list>
      <access-link v-if="creating" key="creating" expanded />
      <access-link v-for="link of links.data" :key="link.uuid" :link="link" />

      <div class="action-bar">
        <mdl-paginator
          :rows-from="meta.from"
          :rows-to="meta.to"
          :rows-total="meta.total"
          :page-rows="meta.per_page"
          :page-current="meta.current_page"
          :page-last="meta.last_page"
          :loading="paginating"
          @goto="onPaginate"
        />
      </div>
    </mdl-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AccessLink from './AccessLink.vue'

export default {
  name: 'AccessLinkManager',

  components: {
    AccessLink,
  },

  props: {
    gateId: { type: Number, required: true },
  },

  computed: {
    ...mapState({
      loading: (state) => state.loading,
      paginating: (state) => state.paginating,
      creating: (state) => state.creating,
      links: (state) => state.links || {},
    }),

    filters: {
      get() {
        return Munio.state.filters
      },
      async set({ queryString }) {
        this.$store.dispatch('fetchAll', queryString)
      },
    },

    meta() {
      return this.links.meta || {}
    },
  },

  methods: {
    onNew() {
      this.$store.commit('SET_CREATING', true)
    },
    onPaginate(page) {
      this.$store.dispatch('paginate', page)
    },
  },

  mounted() {
    this.$store.dispatch('init', this.gateId)
  },
}
</script>
