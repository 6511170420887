import UserRelationships from './UserRelationships.js'
import UserRelationship from './UserRelationship.js'
import UserCourses from './UserCourses.js'
import UserCourse from './UserCourse.js'

class User {
  userId

  constructor(userId) {
    this.userId = userId
  }

  get basePath() {
    return `/api/users/${this.userId}`
  }

  get courses() {
    return new UserCourses(this.userId)
  }

  impersonate(url = null) {
    const options = {}

    if (url) {
      options.url = url
    }

    return Munio.$http.post(`${this.basePath}/impersonate`, options)
  }

  addCertificate(toAdd) {
    return Munio.$http.post(`${this.basePath}/certificate`, toAdd)
  }

  course(courseId) {
    return new UserCourse(this.userId, courseId)
  }

  get relationships() {
    return new UserRelationships(this.userId)
  }

  relationship(companyUserId) {
    return new UserRelationship(this.userId, companyUserId)
  }

  merge(deleteUserIds) {
    return Munio.$http.post(Munio.route('api.web.admin.user.duplicate.merge'), { userId: this.userId, deleteUserIds })
  }
}

export default User
