import Wrapper from '@/munio/vue/Wrapper.js'

Munio.ParticipantSelector = class extends Wrapper {
  constructor(selector, company, courseItems, back, next) {
    super(selector, { company, courseItems, back, next })
  }

  component() {
    return import('./App.vue')
  }
}
