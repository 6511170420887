<template>
  <div class="mdl-filter__form" @click="$emit('activate', $event)">
    <mdl-textfield v-autofocus="autofocus" :name="inputName" :value="value" v-on="$listeners" />
  </div>
</template>

<script>
export default {
  name: 'InputForm',
  inheritAttrs: false,

  props: {
    inputName: { type: [String], default: () => 'value' },
    disabled: { type: Boolean },
    autofocus: { type: [Boolean], default: () => true },
    value: { type: [Object, String, Number] },
  },

  emits: ['activate'],
}
</script>
