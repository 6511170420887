<template>
  <tr :class="classes" :data-valign="valign">
    <td v-if="dataTable.selectable">
      <mdl-checkbox :name="name" :value="value" :checked="selected" :disabled="disabled" @change="onToggle" />
    </td>
    <slot></slot>
  </tr>
</template>

<script>
export default {
  inject: ['dataTable'],

  props: {
    selectable: { type: Boolean, default: true },
    modified: { type: Boolean },
    name: { type: String, default: undefined },
    value: { type: [String, Number, Boolean], default: undefined },
    valign: { type: String },
  },

  data() {
    return {
      selected: this.dataTable.isRowSelected(this.value),
    }
  },

  computed: {
    disabled() {
      return !this.selectable
    },
    classes() {
      return {
        'is-selected': this.selected,
        'is-modified': this.modified,
      }
    },
  },

  mounted() {
    this.dataTable.addRow(this)
    this.dataTable.$on('selection', this.onSelection)
  },

  beforeDestroy() {
    this.dataTable.removeRow(this)
    this.dataTable.$off('selection', this.onSelection)
  },

  methods: {
    onSelection() {
      const selected = this.dataTable.isRowSelected(this.value)

      if (selected !== this.selected) {
        this.selected = selected
        this.$emit('toggle', this.selected, this.value)
      }
    },

    onToggle(checked) {
      if (this.selectable) {
        this.dataTable.toggleRow(checked, this.value)
      }
    },
  },
}
</script>
