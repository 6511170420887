import { ShopCartResourceDto } from '@/munio/api/data/ShopCartResource.dto'
import { CollectionResponse, ResourceResponse } from '@/munio/api/data/common'
import { CompanyBasicResourceDto, UserBasicResourceDto } from '@/munio/api/data'
import { CompanyAddressDto } from '@/munio/api/data/CompanyResource.dto'
import { Request } from '@/munio/http'

const { route, $http } = window.Munio
const cleanup = window.cleanup

class ShopCart {
  constructor(
    protected shop: string,
    protected context?: string,
  ) {}

  protected route(name: string, params: object = {}) {
    return route(
      name,
      Object.assign(params, {
        shop: this.shop,
        context: this.context,
      }),
    )
  }

  get() {
    return $http.get<ResourceResponse<AuthenticatedCart>>(this.route('api.web.shop.checkout'))
  }

  updateCompany(company: Partial<CompanyBasicResourceDto>, setAsEmployer: boolean = false) {
    return $http.put<ResourceResponse<AuthenticatedCart>>(this.route('api.web.shop.checkout.update.company'), {
      company,
      set_as_employer: setAsEmployer,
    })
  }

  updateAddress(address: CompanyAddressDto) {
    return $http.put<ResourceResponse<AuthenticatedCart>>(this.route('api.web.shop.checkout.update.address'), {
      address,
    })
  }

  updateEnrollment(enrollments: ItemEnrollment[]) {
    return $http.put<ResourceResponse<AuthenticatedCart>>(this.route('api.web.shop.checkout.update.enrollment'), {
      enrollments,
    })
  }

  search(userInput: any) {
    return $http.post<CollectionResponse<UserBasicResourceDto>>(
      this.route('api.web.shop.checkout.search'),
      cleanup(userInput),
    )
  }

  confirm(options: CardConfirmOptions): Request<CardConfirmResponse>
  confirm(options: InvoiceConfirmOptions): Request<InvoiceConfirmResponse>
  confirm(options: CardConfirmOptions | InvoiceConfirmOptions) {
    return $http.post<CardConfirmResponse | InvoiceConfirmResponse>(
      this.route('api.web.shop.checkout.confirm'),
      options,
    )
  }
}

export default ShopCart

type NewUser = {
  id?: number
  firstname?: string
  lastname?: string
  email: string
  company?: CompanyBasicResourceDto
}

type ExistingUser = NewUser & {
  id: number
  email?: string
}

export type NewOrExistingUser = NewUser | ExistingUser

export type ItemEnrollment = {
  item: number
  users: Array<NewOrExistingUser>
}

export type AuthenticatedCart = ShopCartResourceDto & {
  user: UserBasicResourceDto
}

interface CardConfirmOptions {
  method: 'card'
  confirmationToken: string
}

export interface CardConfirmResponse {
  orderId: number
  redirectUrl?: string
  clientSecret: string
  status: string
}

interface InvoiceConfirmOptions {
  method: 'invoice'
  address: CompanyAddressDto
  reference: string
  email: string
}

interface InvoiceConfirmResponse {
  orderId: number
  redirectUrl: string
}
