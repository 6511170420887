<template>
  <svg id="logo" data-name="Munio" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" :class="classes">
    <polygon
      class="shape"
      points="409.6 0 307.2 0 204.8 0 102.4 0 0 0 0 102.4 0 204.8 0 307.2 0 409.6 0 512 102.4 512 102.4 409.6 102.4 307.2 102.4 204.8 102.4 102.4 204.8 102.4 307.2 102.4 409.6 102.4 409.6 204.8 409.6 307.2 409.6 409.6 409.6 512 512 512 512 409.6 512 307.2 512 204.8 512 102.4 512 0 409.6 0"
    />
    <polygon
      class="shape"
      points="204.8 307.2 204.8 409.6 204.8 512 307.2 512 307.2 409.6 307.2 307.2 307.2 204.8 204.8 204.8 204.8 307.2"
    />
  </svg>
</template>

<style lang="scss">
@import '@style/variables';

.munio-logo {
  min-width: 16px;

  .shape {
    fill: $munio-orange-warning;
  }

  &--watermark .shape {
    fill: rgba(0, 0, 0, 0.25);
  }
}
</style>

<script>
export default {
  props: {
    watermark: Boolean,
  },

  computed: {
    classes() {
      return [
        'munio-logo',
        {
          'munio-logo--watermark': this.watermark,
        },
      ]
    },
  },
}
</script>
