class CompanyCourseEnrollment {
  companyCourseId
  userId

  constructor(companyCourseId, userId) {
    this.companyCourseId = companyCourseId
    this.userId = userId
  }

  route(name, params = {}) {
    return Munio.route(
      name,
      Object.assign(
        {
          companyCourse: this.companyCourseId,
          user: this.userId,
        },
        params,
      ),
    )
  }

  get basePath() {
    return this.route('api.web.admin.company.course.enrollment')
  }

  get() {
    return Munio.$http.get(this.basePath)
  }

  unenroll() {
    return Munio.$http.delete(this.route('api.web.admin.company.course.enrollment.unenroll'))
  }
}

export default CompanyCourseEnrollment
