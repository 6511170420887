import Wrapper from '@/munio/vue/Wrapper.js'
import App from './App.vue'

Munio.UserProfileConfirm = class extends Wrapper {
  constructor(selector, profile) {
    super(selector, { profile })
  }

  component() {
    return App
  }
}
