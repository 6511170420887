import { forEach } from 'lodash'
import { getAttempt, getLanguage } from '../stores/attempt.js'
import { getNode, getNodesForAttempt, getNodeApi, getScormConnection, getScormItem } from '../stores/node.js'

export const CONNECTION_STATE = 'CONNECTION_STATE'
export const ATTEMPT_INIT = 'ATTEMPT_INIT'
export const ATTEMPT_INITIALIZED = 'ATTEMPT_INITIALIZED'
export const ATTEMPT_LOAD = 'ATTEMPT_LOAD'
export const ATTEMPT_UPDATE = 'ATTEMPT_UPDATE'
export const ATTEMPT_COMPLETED = 'ATTEMPT_COMPLETED'
export const ATTEMPT_FAILED = 'ATTEMPT_FAILED'
export const ATTEMPT_QUIT = 'ATTEMPT_QUIT'
export const ATTEMPT_UPDATED = 'ATTEMPT_UPDATED'
export const ATTEMPT_CONTINUE = 'ATTEMPT_CONTINUE'
export const ATTEMPT_PLAY = 'ATTEMPT_PLAY'
export const LANGUAGE_SELECT = 'LANGUAGE_SELECT'
export const LANGUAGE_UPDATED = 'LANGUAGE_UPDATED'
export const DIPLOMA_SHOW = 'DIPLOMA_SHOW'
export const COURSE_PLAY = 'COURSE_PLAY'
export const NODE_PLAY = 'NODE_PLAY'
export const NODE_PLAYBACK = 'NODE_PLAYBACK'
export const NODE_APPROVE = 'NODE_APPROVE'
export const ACCEPT_VIEWMODE = 'ACCEPT_VIEWMODE'
export const CONDITIONS_UPDATE = 'CONDITIONS_UPDATE'
export const CONDITIONS_SUBMIT = 'CONDITIONS_SUBMIT'
export const CONDITIONS_FAILED = 'CONDITIONS_FAILED'
export const AUTH_SELECT = 'AUTH_SELECT'
export const AUTH_UPDATE = 'AUTH_UPDATE'
export const AUTH_CONFIRM = 'AUTH_CONFIRM'
export const AUTH_REJECT = 'AUTH_REJECT'
export const VIDEO_TICK = 'VIDEO_TICK'
export const VIDEO_PROGRESS = 'VIDEO_PROGRESS'
export const SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE'
export const SCORM_PLAY = 'SCORM_PLAY'
export const SCORM_READY = 'SCORM_READY'
export const SCORM_EXIT = 'SCORM_EXIT'
export const SCORM_UPDATE = 'SCORM_UPDATE'
export const SCORM_COMMIT = 'SCORM_COMMIT'
export const SCORM_FINISH = 'SCORM_FINISH'

const $init = $.Deferred()
const $connect = $.Deferred()
const $loading = $.when($init, $connect)

let mainAttemptId = null
let autoLoad = false
let autoLoadInterval = 10 * 1000
let _channels = {}
let pushingVideoProgress = false

export const init = () => (dispatch, getState) => {
  const state = getState()
  const attemptId = state.mainAttemptId
  const userId = state.userId
  const backUrl = state.backUrl

  mainAttemptId = attemptId

  $loading.done(() => {
    dispatch({ type: ATTEMPT_INITIALIZED })
    dispatch(playAttempt(mainAttemptId, true))
  })

  dispatch(websocket())
  dispatch(subscribe(`user.${userId}`))

  setInterval(() => autoLoadAttempt(dispatch), autoLoadInterval)

  dispatch({ type: ATTEMPT_INIT, id: mainAttemptId })

  dispatch(
    loadAttempt(mainAttemptId, () => {
      const attempt = getAttempt(getState(), mainAttemptId)

      // go away if the attempt is not playable
      if (!attempt.isResumable) {
        window.location.href = backUrl
      }

      $init.resolve()
    }),
  )
}

const autoLoadAttempt = (dispatch) => {
  if (!autoLoad) return

  if (isDevMode) console.group('Autoloading')
  dispatch(loadAttempt(mainAttemptId))
  if (isDevMode) console.groupEnd()
}

export const quitAttempt = () => (dispatch, getState) => {
  dispatch({ type: ATTEMPT_QUIT })

  window.location.href = getState().backUrl
}

export const toggleSidebar = () => ({ type: SIDEBAR_TOGGLE })

export const loadAttempt = (attemptId, callback) => async (dispatch, getState) => {
  const state = getState()
  const mainLanguage = attemptId !== mainAttemptId ? getLanguage(state) : null

  dispatch(subscribe(`attempt.${attemptId}`))

  dispatch({ type: ATTEMPT_LOAD, id: attemptId })

  try {
    const response = await Munio.api.attempt(attemptId).get(mainLanguage)
    dispatch(updateAttempt(response.data, state))
  } finally {
    if (typeof callback === 'function') callback()
  }
}

const updateAttempt = (attempt, state) => {
  const { id, nodes, conditions } = attempt
  const isMain = id === mainAttemptId

  if (isMain) {
    if (isDevMode) console.info('Autoload OFF')
    autoLoad = false

    // auto reload attempt to check for changes on ILT courses
    // because these are completed outside the scope of the main attempt
    forEach(nodes, (node) => {
      if (node.object.isIlt && node.playback.type && !node.userObject.attemptId) {
        if (isDevMode) console.info('Autoload ON')
        autoLoad = true
      }
    })
  }

  // keep skipped state
  if (state.conditions && conditions) {
    const newConditions = conditions.map((c) => {
      const oc = state.conditions.find((e) => e.type === c.type)
      return c.options && c.options.repeat === true && oc && oc.value === 'skip' ? oc : c
    })
    attempt = { ...attempt, conditions: newConditions }
  }

  return { type: ATTEMPT_UPDATE, id, attempt, main: isMain }
}

const playAttempt =
  (attemptId, autoPlay = false) =>
  (dispatch, getState) => {
    const attempt = getAttempt(getState(), attemptId)

    dispatch({ type: ATTEMPT_PLAY, attempt })

    let playNodeId = attempt.isCompleted ? attempt.nodes[0].id : attempt.currentNodeId

    if (autoPlay) {
      const userObject = getNode(getState(), attempt.currentNodeId).userObject
      const subAttemptId = userObject ? userObject.attemptId : null

      if (subAttemptId) {
        playNodeId = null
        dispatch(
          loadAttempt(subAttemptId, () => {
            dispatch(playAttempt(subAttemptId))
          }),
        )
      }
    }

    if (playNodeId) {
      dispatch(playNode(playNodeId))
    }
  }

export const playNode = (nodeId) => async (dispatch, getState) => {
  const state = getState()
  const node = getNode(state, nodeId)
  const { id, attemptId, isSequenced, previous, isLocked } = node
  const previousNode = getNode(state, previous)

  if (isLocked) {
    return
  }

  if (isSequenced && previousNode && !previousNode.isDone) {
    return
  }

  await dispatch({ type: NODE_PLAY, id, attemptId })

  const { data } = await Munio.api.attempt(attemptId).node(id).get()

  dispatch({ type: NODE_PLAYBACK, id, attemptId, playback: data })
}

export const playScormActivity = (id) => async (dispatch, getState) => {
  const item = getScormItem(getState(), id)

  dispatch({ type: SCORM_PLAY, item })
}

export const updateScormActivity =
  (id, data, finish = false) =>
  async (dispatch, getState) => {
    dispatch({ type: SCORM_UPDATE, id, data, finish })
  }

export const exitScormActivity = () => (dispatch) => {
  dispatch({ type: SCORM_EXIT })
}

export const setScormReady = (ready) => (dispatch) => {
  dispatch({ type: SCORM_READY, state: ready })
}

export const navigateScormActivity = (target) => async (dispatch, getState) => {
  switch (target) {
    case 'back':
      console.info('BAAACK')
      break

    case 'next':
      console.info('NEEEXT')
      break
  }
}

/*
export const commitScormActivity = (id, metadata) => async (dispatch, getState) => {
  const response = await getNodeApi(getState()).scorm(id).commit(metadata)
  const { manifest, item, data } = response.data

  dispatch({ type: SCORM_COMMIT, manifest, item, data })
}

export const finishScormActivity = (id, metadata) => async (dispatch, getState) => {
  const response = await getNodeApi(getState()).scorm(id).finish(metadata)
  const { manifest, item, data } = response.data

  dispatch({ type: SCORM_EXIT, manifest, item, data })
}
*/

export const playNext = () => (dispatch, getState) => {
  const state = getState()
  const mainAttemptId = state.mainAttemptId
  const mainAttempt = getAttempt(state, mainAttemptId)

  if (mainAttempt.isCompleted) return

  const currentAttemptId = getNode(state, state.currentNodeId).attemptId
  const currentAttempt = getAttempt(state, currentAttemptId)
  const navigationAttempt = currentAttempt.isCompleted ? mainAttempt : currentAttempt
  const nodes = getNodesForAttempt(state.nodes, navigationAttempt.id)
  const hasIncomplete = nodes.filter((node) => !node.isDone).length > 0

  const findNextIncomplete = function (nodes, currentId = null) {
    let hasFoundCurrent = false

    return nodes.find((node) => {
      if (hasFoundCurrent || currentId === null) {
        return hasIncomplete ? !node.isDone : true
      }

      if (node.id === currentId) {
        hasFoundCurrent = true
      }
    })
  }

  const nextNode = findNextIncomplete(nodes, state.currentNodeId) || findNextIncomplete(nodes)

  dispatch(playNode(nextNode.id))
}

export const changeLanguage = (language) => (dispatch, getState) => {
  dispatch({ type: LANGUAGE_SELECT, language })

  Munio.api
    .attempt(mainAttemptId)
    .changeLanguage(language)
    .then((response) => {
      dispatch(
        loadAttempt(mainAttemptId, () => {
          dispatch(playAttempt(mainAttemptId))
          dispatch({ type: LANGUAGE_UPDATED, language })
        }),
      )
    })
}

export const playDiploma = (attemptId) => {
  return { type: DIPLOMA_SHOW, id: attemptId }
}

export const startCourse = (nodeId, callback) => (dispatch, getState) => {
  const node = getNode(getState(), nodeId)

  dispatch({ type: COURSE_PLAY, id: node.object.id })

  Munio.api
    .attempt(node.attemptId)
    .node(node.id)
    .startCourse()
    .then((response) => {
      let attempt = response.data
      dispatch(
        loadAttempt(attempt.id, () => {
          dispatch(playAttempt(attempt.id))

          if (typeof callback === 'function') callback()
        }),
      )
    })
    .catch(() => {
      if (typeof callback === 'function') callback()
    })
}

export const approve = (nodeId, callback) => (dispatch, getState) => {
  const node = getNode(getState(), nodeId)

  dispatch({ type: NODE_APPROVE, id: nodeId })

  let triggerCallback = () => typeof callback === 'function' && callback()

  Munio.api.attempt(node.attemptId).node(node.id).approve().then(triggerCallback, triggerCallback)
}

export const approveDate = (nodeId, completedAt, callback) => (dispatch, getState) => {
  const node = getNode(getState(), nodeId)

  dispatch({ type: NODE_APPROVE, id: nodeId })

  let triggerCallback = () => typeof callback === 'function' && callback()

  Munio.api.attempt(node.attemptId).node(node.id).approve(completedAt).then(triggerCallback, triggerCallback)
}

export const acceptViewMode = () => {
  return { type: ACCEPT_VIEWMODE }
}

export const updateCondition = (key, value) => (dispatch, getState) => {
  const attemptId = getState().mainAttemptId
  const conditions = { key, value }

  dispatch({ type: CONDITIONS_SUBMIT, key, value })

  Munio.api
    .attempt(attemptId)
    .updateConditions(conditions)
    .then((response) => {
      let result = response.data[key]

      dispatch({ type: CONDITIONS_UPDATE, key, value: result === true ? 'skip' : result })
      dispatch(loadAttempt(attemptId))
    })
    .catch((error) => {
      error = error.response.data ? error.response.data.error : trans('An error occured')

      dispatch({ type: CONDITIONS_FAILED, key, error })
    })
}

export const skipCondition = (key) => (dispatch, getState) => {
  const attemptId = getState().mainAttemptId
  dispatch({ type: CONDITIONS_UPDATE, key, value: 'skip' })
  dispatch(loadAttempt(attemptId))
}

export const confirmAuth = () => (dispatch) => {
  dispatch({ type: AUTH_CONFIRM })

  Munio.api
    .attempt(mainAttemptId)
    .confirmAuth()
    .then((response) => {
      dispatch(loadAttempt(mainAttemptId))
    })
}

export const rejectAuth = () => (dispatch) => {
  dispatch({ type: AUTH_REJECT })

  Munio.api
    .attempt(mainAttemptId)
    .rejectAuth()
    .then((response) => {
      dispatch(loadAttempt(mainAttemptId))
    })
}

export const videoTick =
  (nodeId, metadata, forceUpdate = false) =>
  (dispatch, getState) => {
    const node = getNode(getState(), nodeId)
    const attemptId = node.attemptId

    dispatch({ type: VIDEO_TICK, id: nodeId, metadata, forceUpdate })

    if (!forceUpdate && (node.isDone || pushingVideoProgress)) {
      return
    }

    dispatch(videoProgress(attemptId, nodeId, metadata))
  }

export const enrollToSession = (nodeId, sessionId) => (dispatch, getState) => {
  const node = getNode(getState(), nodeId)

  return Munio.api.attempt(node.attemptId).node(node.id).sessionEnroll(sessionId)
}

const videoProgress = (attemptId, nodeId, metadata) => (dispatch, getState) => {
  pushingVideoProgress = true

  Munio.api
    .attempt(attemptId)
    .node(nodeId)
    .videoProgress(metadata)
    .then((response) => {
      pushingVideoProgress = false
      dispatch({ type: VIDEO_PROGRESS, id: nodeId, node: response.data })
    })
    .catch(() => {
      pushingVideoProgress = false
    })
}

//
// Internal
//

const serverAction = (action) => (dispatch, getState) => {
  const { type, payload } = action

  dispatch({ type, server: true, ...payload })

  switch (type) {
    case AUTH_UPDATE:
      dispatch(loadAttempt(mainAttemptId))
      break

    case ATTEMPT_UPDATED:
    case ATTEMPT_COMPLETED:
      dispatch(loadAttempt(payload.id))
      break
  }
}

const websocket = () => (dispatch) => {
  let pusher = Munio.Websocket()

  const handleStateChange = (state) => {
    dispatch({ type: CONNECTION_STATE, state })

    if (state.current === 'connected') {
      $connect.resolve()
    }
  }

  handleStateChange({ current: pusher.connection.state })

  pusher.connection.bind('state_change', handleStateChange)
  pusher.connection.bind_global((event, data) => {
    if (event === 'message' && data.event === 'SERVER_ACTION') {
      dispatch(serverAction(data.data))
    }
  })
}

const subscribe = (channelName) => (dispatch) => {
  if (_channels[channelName] === undefined) {
    let channel = Munio.Websocket().subscribe('private-' + channelName)

    channel.bind('SERVER_ACTION', (action) => {
      dispatch(serverAction(action))
    })

    _channels[channelName] = channel

    if (isDevMode) console.info(`Subscribed to channel: ${channelName}`)
  }
}
