import CurrentUserAccess from './CurrentUserAccess.js'
import GDPR from './GDPR.js'
import { toDateString } from '@/munio/utils/date.js'

class CurrentUser {
  get basePath() {
    return '/api/user'
  }

  get access() {
    return new CurrentUserAccess()
  }

  get courses() {
    return new CurrentUserCourses()
  }

  get sessions() {
    return new CurrentUserSessions()
  }

  get certificates() {
    return new Certificate()
  }

  get gdpr() {
    return new GDPR()
  }

  get() {
    return Munio.$http.get(`${this.basePath}/profile`)
  }

  counters() {
    return Munio.$http.get(Munio.route('api.web.user.counters'))
  }

  validate(profile) {
    return Munio.$http.post(`${this.basePath}/profile`, profile)
  }

  update(data) {
    return Munio.$http.patch(`${this.basePath}/profile`, data)
  }

  password(currentPassword, newPassword) {
    return Munio.$http.post(Munio.route('api.web.user.profile.password'), { currentPassword, newPassword })
  }

  employ(company) {
    return Munio.$http.post(`${this.basePath}/employ`, company)
  }
}

class CurrentUserCourses {
  get basePath() {
    return '/api/user/courses'
  }

  get(id, contextCompanyId) {
    return Munio.$http.get([this.basePath, id, contextCompanyId].filter((v) => !!v).join('/'))
  }

  launch(id, contextCompanyId, restart = false) {
    let url = `${this.basePath}/${id}`

    if (contextCompanyId) {
      url += `/${contextCompanyId}`
    }

    return Munio.$http.post(url, { restart })
  }

  restart(id) {
    return Munio.$http.post(`${this.basePath}/${id}/restart`)
  }

  enroll(id) {
    return Munio.$http.post(`${this.basePath}/${id}/enroll`)
  }

  unenroll(id) {
    return Munio.$http.post(`${this.basePath}/${id}/unenroll`)
  }

  document(id, file) {
    const form = new FormData()

    form.append('document', file, file.name)

    return Munio.$http.post(`${this.basePath}/${id}/document`, form, {
      'content-type': 'multipart/form-data',
    })
  }

  setdate(id, from, to) {
    const url = Munio.route('api.web.user.course.setdate', { course: id })

    return Munio.$http.post(url, {
      from: toDateString(from),
      to: toDateString(to),
    })
  }

  approve(id) {
    return Munio.$http.post(`${this.basePath}/${id}/approve`)
  }
}

class CurrentUserSessions {
  get basePath() {
    return '/api/user/sessions'
  }

  enroll(id) {
    return Munio.$http.post(`${this.basePath}/${id}`)
  }

  unenroll(id) {
    return Munio.$http.delete(`${this.basePath}/${id}`)
  }
}

class Certificate {
  get basePath() {
    return '/api/user/certificate'
  }

  get() {
    return Munio.$http.get(`${this.basePath}`)
  }

  add(typeId, identifier, bornAt) {
    return Munio.$http.post(`${this.basePath}`, { typeId, identifier, bornAt })
  }

  delete(id) {
    return Munio.$http.delete(`${this.basePath}/${id}`)
  }

  addable() {
    return Munio.$http.get(`${this.basePath}/addable`)
  }
}

export default CurrentUser
