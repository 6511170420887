type EnrollmentData = {
  zoneIds: number[]
  responsibleId?: number
  user: {
    id?: number
    firstname: string
    lastname: string
    email: string
    company: {
      id?: number
      name: string
      country?: string
    }
  }
}

export default class GateUsers {
  constructor(private gateId: number) {}

  enroll(options: EnrollmentData) {
    const route = window.Munio.route('api.web.access.gate.user.enroll', {
      gate: this.gateId,
    })

    type Response = {
      data: {
        userId: number
      }
    }

    return window.Munio.$http.post<Response>(route, options)
  }
}
