<template>
  <div>
    <template v-if="locations.length">
      <template v-if="locations.length > 5">
        <div class="p-8">
          <m-select v-model="selectedLocationId" :options="selectLocations" :placeholder="trans('Select location')" />
          <a class="block mt-4" v-if="selectedLocation" :href="selectedLocation.location.url" target="_blank">
            <icon class="small" name="place" />
            {{ selectedLocation.location.address }}
          </a>
        </div>
        <session-node
          v-for="session in selectedLocationSessions"
          :key="session.id"
          :session="session"
          :can-enroll="canEnroll"
          @enrolled="$emit('enrolled', session)"
        />
      </template>

      <mdl-tabs v-else fixed>
        <mdl-tab v-for="(entry, index) in locations" :key="entry.location.id" :label="entry.location.name">
          <div class="p-8">
            <a :href="entry.location.url" target="_blank">
              <icon class="small" name="place" />
              {{ entry.location.address }}
            </a>
          </div>

          <session-node
            v-for="session in entry.sessions"
            :key="session.id"
            :session="session"
            :can-enroll="canEnroll"
            @enrolled="$emit('enrolled', session)"
          />
        </mdl-tab>
      </mdl-tabs>
    </template>

    <div class="text-danger text-center p-8" v-else>
      {{ trans('There are no enrollable sessions at the moment.') }}
    </div>

    <slot />
  </div>
</template>

<script>
import SessionNode from './SessionNode.vue'

export default {
  components: {
    SessionNode,
  },

  props: {
    sessions: {
      type: Array,
      default: () => [],
    },
    canEnroll: { type: Boolean, default: true },
  },

  data() {
    return {
      selectedLocationId: undefined,
    }
  },

  computed: {
    selectLocations() {
      return (
        this.locations?.map((l) => {
          return { id: l.location.id, label: l.location.name }
        }) ?? []
      )
    },
    selectedLocation() {
      if (!this.selectedLocationId) return undefined
      return this.locations?.find((l) => Number(l.location.id) === Number(this.selectedLocationId))
    },
    selectedLocationSessions() {
      return this.selectedLocation?.sessions
    },
    locations() {
      return Object.values(
        this.sessions.reduce((result, current) => {
          const locationId = current.location.id

          if (locationId in result) result[locationId].sessions.push(current)
          else {
            result[locationId] = {
              location: current.location,
              sessions: [current],
            }
          }

          return result
        }, {}),
      )
    },
  },
}
</script>
