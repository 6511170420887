<template>
  <div class="m-stepper">
    <StepItem
      v-for="(step, index) in stepper"
      :key="step.id"
      :id="step.id"
      :value="index + 1"
      :label="step.label"
      :active="isActive(step)"
      :completed="step.completed"
      :loading="step.loading"
      :show-edit="editable"
      @activate="emit('input', step.id)"
    >
      <template v-if="step.completed && !isActive(step) && step.preview" #details>
        <component v-if="step.preview" :is="step.preview" :id="step.id" :loading="step.loading" />
      </template>

      <component v-if="isActive(step)" :is="step.content" :id="step.id" :loading="step.loading" />
    </StepItem>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Step } from './Types'
import StepItem from './StepItem.vue'

const props = withDefaults(
  defineProps<{
    steps: Step[]
    value: string
    editable?: boolean
  }>(),
  { editable: true },
)

const emit = defineEmits<{
  (e: 'input', value: string): void
}>()

const stepper = ref(props.steps)
const isActive = (step: Step) => step.id === props.value
</script>
