<template>
  <div>
    <p v-if="error" class="alert alert-danger" :class="{ 'mt-8': users.length > 0 }">
      {{ trans(error) }}
    </p>

    <div class="relative">
      <transition name="fade" mode="out-in" :duration="100">
        <invite
          v-if="!isDisabled && invite"
          key="invite"
          :company="company"
          @cancel="invite = false"
          @submit="addInvite"
        />

        <mdl-searchbar
          v-else
          key="search"
          v-model="search"
          :min-length="minLength"
          :placeholder="placeholder"
          :searching="searching"
          :dropdown="!inline"
          @submit="onSearchSubmit"
        >
          <template v-if="(search || minLength === 0) && searchResults !== null">
            <p v-if="!searching && !results.length && searchResults.length" class="mdl-color--white p-4 mb-px">
              <Icon name="info" small />
              {{ trans('All users added') }}
            </p>
            <p
              v-if="!searching && !searchResults.length"
              class="mdl-color--info mdl-color-text--info-contrast p-4 mb-px"
            >
              <Icon name="info" small />
              {{ trans('No results found') }}
            </p>

            <div class="overflow-auto max-h-50vh">
              <div v-for="user in results" :key="user.id" class="mdl-searchbar__result">
                <user :user="user" />
                <mdl-button v-if="canAdd(user)" :disabled="isDisabled" icon="add" primary @click.stop="addUser(user)" />
                <mdl-button v-else disabled icon="done" />
              </div>
            </div>
            <div v-if="!disabled && canInvite" class="mdl-color--grey-100 p-8">
              <a class="is-clickable opaque" @click.stop="invite = true">
                {{ trans('Cannot find the user? Click here to invite') }}
              </a>
            </div>
          </template>
        </mdl-searchbar>
      </transition>

      <div class="flex flex-col items-start mt-8 gap-4">
        <users-selected :users="users" :loading="loading" @remove="removeUser" />

        <mdl-chip v-if="canAddMe" deletable delete-icon="add_circle_outline" @delete="addMe" @click="addMe" highlight>
          {{ trans('Add myself') }}
        </mdl-chip>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@component/Icon.vue'
import UsersSelected from './UsersSelected.vue'
import Invite from './Invite.vue'

export default {
  name: 'UserSelector',
  components: {
    Icon,
    UsersSelected,
    Invite,
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
    disableSelf: Boolean,
    single: Boolean,
    inline: Boolean,
    placeholder: {
      type: String,
      default: () => trans('Search for users'),
    },
    addedLabel: {
      type: String,
      default: () => trans('Already added'),
    },
    addUserHandler: Function,
    removeUserHandler: Function,
    searchHandler: Function,
    minLength: Number,
    canInvite: {
      type: Boolean,
      default: true,
    },
    company: Object,
  },
  data() {
    return {
      searching: false,
      search: '',
      searchResults: null,
      searchMeta: null,
      invite: false,
      error: '',
      loading: false,
    }
  },
  computed: {
    results() {
      return this.searchResults?.filter((u) => {
        return !this.users.find((_u) => u.email === _u.email || (_u.id && u.id === _u.id))
      })
    },
    isDisabled() {
      if (this.disabled) {
        return true
      }

      return this.single ? this.users.length === 1 : false
    },
    canAddMe() {
      return !this.disableSelf && this.canAdd(Munio.config.user)
    },
  },
  methods: {
    async onSearchSubmit(search) {
      if (search || this.minLength === 0) {
        try {
          this.searching = true
          this.searchResults = await this.searchHandler(this.search)
        } catch (err) {
          console.error(err)
        } finally {
          this.searching = false
        }
      } else {
        this.searchResults = null
      }
    },
    canAdd(user) {
      if (user.addable !== undefined && !user.addable) {
        return false
      }
      return !this.users.find((u) => u.email === user.email || (user.id && u.id === user.id))
    },
    addMe() {
      this.addUser(Munio.config.user)
    },
    addInvite(user) {
      this.invite = false
      this.addUser(user)
    },
    async addUser(user) {
      if (!this.canAdd(user)) {
        Munio.Notifications.show({ message: trans('User already added') })
        this.$forceUpdate()
        return
      }

      this.error = ''
      try {
        this.loading = true
        if (await this.addUserHandler(user)) {
          this.search = ''
        }
      } catch (e) {
        this.error = e.toString()
      } finally {
        this.loading = false
      }
    },
    async removeUser(user) {
      this.error = ''
      try {
        this.loading = true
        await this.removeUserHandler(user)
      } catch (e) {
        this.error = e.toString()
      }
      this.loading = false
    },
  },
}
</script>
