<template>
  <modal :title="trans('New company')" :show="show" @hide="close" :loading="saving">
    <div class="modal-body pt-0 pb-4">
      <company-search v-if="show" v-model="value" :country="country" context></company-search>
    </div>

    <template slot="actions">
      <mdl-button :disabled="saving || !value || !value.name" primary :raised="!!value" @click="submit">
        {{ trans('Add') }}
      </mdl-button>
      <mdl-button :disabled="saving" @click="close">{{ trans('Cancel') }}</mdl-button>
    </template>
  </modal>
</template>

<script>
export default {
  props: {
    show: { type: Boolean, default: false },
    onSubmit: Function,
    country: { type: String, default: Munio.config.company.country },
    redirect: { type: Boolean, default: true },
  },

  data() {
    return {
      value: null,
      saving: false,
    }
  },

  methods: {
    close() {
      this.value = null
      this.$emit('close')
    },

    async submit() {
      try {
        this.saving = true
        if (!this.onSubmit) {
          const company = await window.Munio.api.companies.add(this.value, this.redirect)
          this.$emit('submit', company)
        } else {
          this.onSubmit(this.value)
        }
        this.close()
      } finally {
        this.saving = false
      }
    },
  },
}
</script>
