<template>
  <svg :class="className" :width="size" :height="size" :viewBox="'0 0 ' + size + ' ' + size" :data-progress="progress">
    <circle :class="`${className}-bg`" :cx="offset" :cy="offset" :r="radius" :stroke-width="strokeWidth"></circle>
    <circle
      :class="`${className}-fg`"
      :cx="offset"
      :cy="offset"
      :r="radius"
      :stroke-width="strokeWidth"
      :style="{
        transformOrigin: 'center',
        transform: `rotate(${angle}deg)`,
        strokeDasharray: circumference,
        strokeDashoffset: strokeLength,
      }"
    />
    <icon v-if="icon" type="mdl" :name="icon"></icon>
    <slot></slot>
  </svg>
</template>

<script>
import Icon from './Icon.vue'

export default {
  props: {
    className: { type: String, default: 'progress-bar-circular' },
    size: { type: Number, default: 50 },
    progress: { type: Number, default: 0 },
    strokeWidth: { type: Number, default: 3 },
    angle: { type: Number, default: -90 },
    icon: String,
  },

  components: {
    Icon,
  },

  computed: {
    offset() {
      return this.size / 2
    },
    radius() {
      return this.size / 2 - this.strokeWidth / 2
    },
    circumference() {
      return this.radius * Math.PI * 2
    },
    strokeLength() {
      let circumference = this.circumference
      let percentage = this.progress
      let minPercentage = (this.strokeWidth / circumference) * 100

      if (percentage && percentage < minPercentage) {
        percentage = minPercentage
      }

      return circumference - (circumference * percentage) / 100
    },
  },
}
</script>
