<template>
  <div class="mdl-tabs is-upgraded" :class="{ 'mdl-tabs--fixed': fixed }">
    <div
      v-if="scrollBackVisible"
      ref="arrowBack"
      class="mdl-tabs__arrow mdl-tabs__arrow--back"
      @mousedown.left="startScroll(false)"
      @mouseup="stopScroll(false)"
      @touchstart.prevent="startScroll(false)"
      @touchend.prevent="stopScroll(false)"
    >
      <mdl-button icon="chevron_left" />
    </div>
    <div
      v-if="scrollForwardVisible"
      ref="arrowForward"
      class="mdl-tabs__arrow mdl-tabs__arrow--forward"
      @mousedown.left="startScroll(true)"
      @mouseup="stopScroll(true)"
      @touchstart.prevent="startScroll(true)"
      @touchend.prevent="stopScroll(true)"
    >
      <mdl-button icon="chevron_right" />
    </div>

    <div class="mdl-tabs__tab-bar" ref="tabBar">
      <button
        v-for="tab in tabs"
        :key="tab.identifier"
        type="button"
        class="mdl-tabs__tab"
        :class="classes(tab)"
        @click="select(tab)"
      >
        <span :class="{ 'mdl-badge': tab.badge }" :data-badge="tab.badge">
          {{ tab.label }}
        </span>
      </button>
    </div>

    <mdl-progressbar v-show="loading" indeterminate />

    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'MdlTabs',

  props: {
    fixed: Boolean,
    loading: Boolean,
    active: [String, Number, Boolean],
  },

  model: {
    prop: 'active',
    event: 'select',
  },

  emits: ['select'],

  data() {
    return {
      tabs: [],
      currentTab: null,
      scrolling: false,
      scrollDelay: 250,
      scrollTimer: 0,
      scrollMax: 0,
      scroll: {
        smooth: false,
        pos: 0,
      },
      drag: false,
      observer: null,
    }
  },

  computed: {
    scrollBackVisible() {
      return this.scrollMax > 0 && this.scroll.pos > 0
    },
    scrollForwardVisible() {
      return this.scrollMax > 0 && this.scroll.pos < this.scrollMax
    },
  },

  methods: {
    offsets() {
      const bar = this.$refs.tabBar
      const margin = this.$refs.arrowBack?.clientWidth || this.$refs.arrowForward?.clientWidth || 0

      return {
        margin,
        start: bar.scrollLeft,
        end: bar.scrollLeft + bar.clientWidth,
      }
    },
    handleResize() {
      const bar = this.$refs.tabBar

      if (!bar) {
        return
      }

      this.scrollMax = bar.scrollWidth - bar.clientWidth
      this.$nextTick(() => this.scrollToElement(bar.querySelector('.mdl-tabs__tab.is-active')))
    },
    scrollToElement(element) {
      if (!element) {
        return
      }

      let pos = this.scroll.pos
      const bar = this.$refs.tabBar
      const { margin, start, end } = this.offsets()
      const edgeThreshold = 25

      const tabStart = element.offsetLeft
      const tabEnd = tabStart + element.clientWidth

      if (start > tabStart - margin) {
        pos = tabStart - margin * 1.5
      } else if (end < tabEnd + margin) {
        pos = start + tabEnd - end + margin * 1.5
      }

      if (pos < edgeThreshold) pos = 0
      if (pos > this.scrollMax - edgeThreshold) pos = this.scrollMax

      this.scroll = {
        smooth: true,
        pos,
      }
    },
    getTabOnPosition(pos) {
      const bar = this.$refs.tabBar
      const tabs = Array.from(bar.querySelectorAll('.mdl-tabs__tab'))
      const offsets = this.offsets()

      return tabs.find((tab) => {
        const tabStart = tab.offsetLeft
        const tabEnd = tabStart + tab.clientWidth

        return tabStart <= pos && tabEnd >= pos
      })
    },
    startScroll(forward = false) {
      this.scrollTimer = setTimeout(() => {
        clearInterval(this.scrollTimer)
        this.scrolling = true
        this.scrollTimer = setInterval(() => {
          if (this.scrollElement(forward)) {
            this.stopScroll(forward)
          }
        }, 5)
      }, this.scrollDelay)
    },
    stopScroll(forward = false) {
      clearInterval(this.scrollTimer)
      if (this.scrolling) {
        this.scrolling = false
      } else {
        const { start, end, margin } = this.offsets()
        const tab = this.getTabOnPosition(forward ? end - margin : start + margin)
        this.scrollToElement(tab)
      }
    },
    scrollElement(forward = false) {
      const el = this.$refs.tabBar
      if (!el) return
      const direction = forward ? 1 : -1
      let pos = this.scroll.pos + direction * 5
      let done = false
      if (pos < 0) {
        pos = 0
        done = true
      } else if (pos > this.scrollMax) {
        pos = this.scrollMax
        done = true
      }
      this.scroll = {
        smooth: false,
        pos,
      }
      return done
    },
    classes(tab) {
      return {
        'is-active': tab.identifier === this.currentTab,
        'is-disabled': tab.disabled,
      }
    },
    add(tab) {
      this.$nextTick(() => {
        this.tabs.push(tab)
        this.tabs.sort((a, b) => (a.index > b.index ? 1 : -1))
        this.$nextTick(this.handleResize)
      })
    },
    remove(tab) {
      this.$nextTick(() => {
        const index = this.tabs.findIndex((t) => t.identifier === tab.identifier)
        this.tabs.splice(index, 1)

        if (tab.identifier === this.currentTab) {
          this.select(this.tabs[index > 0 ? index - 1 : 0])
        }
        this.$nextTick(this.handleResize)
      })
    },
    select(tab) {
      if (!tab || tab.disabled) {
        return
      }

      if (tab.identifier !== this.currentTab) {
        this.currentTab = tab.identifier
        this.$emit('select', tab.identifier)
        this.$nextTick(this.handleResize)
      }
    },
  },

  watch: {
    active: {
      immediate: true,
      handler(value, oldValue) {
        if (!value && !oldValue) {
          return
        }

        if (this.tabs.length) {
          const tab = this.tabs.find((tab) => tab.identifier === value) || this.tabs[0]
          this.select(tab)
        }
      },
    },
    scroll: {
      immediate: true,
      handler({ smooth, pos }) {
        const el = this.$refs.tabBar
        if (!el) return
        el.scrollTo({ left: pos, behavior: smooth ? 'smooth' : 'instant' })
      },
    },
  },

  mounted() {
    this.$nextTick(() => {
      if (this.tabs.length === 0) {
        return
      }

      for (const tab of this.tabs) {
        if (this.active === tab.identifier || tab.selected || tab.active) {
          return this.select(tab)
        }
      }

      this.select(this.tabs[0])

      const observer = new ResizeObserver(this.handleResize)
      observer.observe(this.$refs.tabBar)
      this.observer = observer
    })
  },

  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect()
    }
  },
}
</script>
