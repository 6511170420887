import Vue from 'vue'
import $ from 'jquery'
import ColorPicker from '../vue/components/ColorPicker/ColorPicker.vue'

Munio.behaviors.colorpicker = {
  selector: 'input[type="color"]',
  attach: function (context, config) {
    $(this.selector, context).each((i, el) => {
      new Vue({
        render: (h) =>
          h(ColorPicker, {
            attrs: {
              id: el.id,
              class: el.className,
            },
            props: {
              name: el.name,
              value: el.value,
            },
          }),
      }).$mount(el)
    })
  },
}
