<template>
  <div class="mdl-textfield-i18n">
    <template v-if="name">
      <input
        v-for="(value, key) of input"
        :key="`${name}-${key}`"
        type="hidden"
        :name="`${name}[${key}]`"
        :value="value"
      />
    </template>

    <mdl-textfield ref="input" v-bind="attrs" v-on="listeners" v-model="inputValue" />

    <div class="mdl-chips">
      <mdl-chip v-if="languagesActive.length === 0" :ref="`chip_${languageDefault}`" class="is-empty">
        {{ getLanguage(languageDefault).name }}
      </mdl-chip>
      <mdl-chip
        v-for="code of languagesActive"
        :key="code"
        :ref="`chip_${code}`"
        :highlight="isSelected(code)"
        deletable
        :class="['is-clickable', { 'is-empty': isEmpty(code) }]"
        delete-icon="clear"
        @focus="setLanguage(code)"
        @click="setLanguage(code)"
        @delete="delLanguage(code)"
      >
        {{ getLanguage(code).name }}
      </mdl-chip>

      <div class="inline-block dropdown">
        <mdl-button :disabled="!languagesInactive.length" icon data-toggle="dropdown">
          <i class="material-icons">add</i></mdl-button
        >
        <b-menu right>
          <b-menu-item v-for="code in languagesInactive" :key="code" @click.prevent="setLanguage(code)">{{
            getLanguage(code).name
          }}</b-menu-item>
        </b-menu>
      </div>
    </div>
  </div>
</template>

<script>
import MdlTextfield from '@/munio/vue/components/mdl/Textfield.vue'

export default {
  inheritAttrs: false,

  props: {
    ...MdlTextfield.props,
    name: String,
    value: { type: Object, default: () => ({}) },
    languages: { type: Array, default: () => Munio.config.languages.map((l) => l.key) },
    languageDefault: { type: String, default: Munio.config.i18n.key },
  },

  data() {
    return {
      input: {},
      language: this.languageDefault,
    }
  },

  computed: {
    attrs() {
      let { name, value, ...attrs } = Object.assign({}, { ...this.$props }, { ...this.$attrs })
      return attrs
    },

    listeners() {
      let { input, ...listeners } = this.$listeners
      return listeners
    },

    inputValue: {
      get() {
        return this.input[this.language] || ''
      },

      set(value) {
        this.$set(this.input, this.language, value)
        this.$emit('input', this.input)
      },
    },

    inputLanguages() {
      return Object.keys(this.input)
    },

    languagesActive() {
      return this.languages.filter((code) => this.inputLanguages.indexOf(code) !== -1)
    },

    languagesInactive() {
      return this.languages
        .filter((code) => this.inputLanguages.indexOf(code) === -1)
        .sort((a, b) => {
          return this.getLanguage(a).name.localeCompare(this.getLanguage(b).name, Munio.config.i18n.language)
        })
    },
  },

  methods: {
    isActive(code) {
      return this.input.hasOwnProperty(code)
    },

    isSelected(code) {
      return code === this.language
    },

    isDeletable(code) {
      if (!this.isSelected(code)) {
        return false
      }
      return Object.keys(this.input).length > 1
    },

    isEmpty(code) {
      return !(this.input[code] || '').length
    },

    getLanguage(code) {
      return Munio.language(code)
    },

    setLanguage(code, focus = false) {
      this.language = code
      if (this.input.hasOwnProperty(code)) {
        if (focus) {
          this.$nextTick(() => {
            this.$refs['chip_' + code][0].$el.focus()
          })
        }
      } else {
        this.$set(this.input, code, '')
        this.$nextTick(() => {
          this.$refs.input.focus()
        })
      }
    },

    async delLanguage(code) {
      if (this.input[code]) {
        if (!(await Munio.confirm())) {
          return
        }
      }
      this.$delete(this.input, code)
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.input = value

        if (Object.keys(this.input).length > 0 && this.input[this.language] === undefined) {
          for (let language of this.languages) {
            if (this.input.hasOwnProperty(language)) {
              this.language = language
              break
            }
          }
        }
      },
    },
  },
}
</script>
