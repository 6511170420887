import Wrapper from '@/munio/vue/Wrapper.js'

Munio.IntegrationActionsInspector = class extends Wrapper {
  constructor(selection, integrationId, modes) {
    super(selection, {
      integrationId,
      modes,
    })
  }

  component() {
    return import('./App.vue')
  }
}
