import PapaParse from 'papaparse'

const chunkify = function (arr, size) {
  let sets = []
  let chunks = arr.length / size
  let i = 0
  let offset = 0

  while (i < chunks) {
    offset = i * size

    sets.push(arr.slice(offset, offset + size))

    i++
  }

  return sets
}

const actions = {
  RESET: 'RESET',
  IMPORT_START: 'IMPORT_START',
  IMPORT_SUCCESS: 'IMPORT_SUCCESS',
  IMPORT_FAILED: 'IMPORT_FAILED',
  FILE_SELECT: 'FILE_SELECT',
  TYPE_SELECT: 'TYPE_SELECT',
  PARSE_FILE: 'PARSE_FILE',
  PARSE_SUCCESS: 'PARSE_SUCCESS',
  PARSE_FAILED: 'PARSE_FAILED',
  TOGGLE_HEADER: 'TOGGLE_HEADER',
  COLUMN_SELECT: 'COLUMN_SELECT',

  throwError: (error) => ({ type: actions.PARSE_FAILED, error }),

  toggleHeader: () => ({ type: actions.TOGGLE_HEADER }),

  selectFile: (file) => ({ type: actions.FILE_SELECT, file }),

  reset: () => ({ type: actions.RESET }),

  selectType: (type) => ({ type: actions.TYPE_SELECT, key: type }),

  selectColumn: (index, key) => (dispatch) => {
    dispatch({ type: actions.COLUMN_SELECT, key, index })
  },

  parse: (file) => (dispatch, getState) => {
    if (!file) {
      dispatch({ type: actions.PARSE_FAILED, error: 'Please select a file first.' })
      return
    }

    dispatch({ type: actions.PARSE_FILE })

    PapaParse.parse(file, {
      complete: (results) => {
        dispatch({ type: actions.PARSE_SUCCESS, data: results.data, meta: results.meta })
      },

      error: (error) => {
        dispatch({ type: actions.PARSE_FAILED, error: 'Well shit' })
      },
    })
  },

  import: () => (dispatch, getState) => {
    const { type, data, columns, header } = getState()
    const arrData = (header ? data.slice(1) : data).map((entry) => {
      let row = {}

      columns.forEach((key, index) => {
        row[key] = entry[index]
      })

      return row
    })

    dispatch({ type: actions.IMPORT_START })

    chunkify(arrData, 50).forEach((chunk, index) => {
      let data = { certificates: [] }

      chunk.forEach((entry, index) => {
        data.certificates.push(entry)
      })

      Munio.api.certificate
        .import(type, data)
        .then((response) => {
          dispatch({ type: actions.IMPORT_SUCCESS, chunk: index, count: chunk.length })
        })
        .catch(() => {
          dispatch({ type: actions.IMPORT_FAILED, chunk: index, count: chunk.length })
        })
    })
  },
}

export default actions
