<template>
  <hr :class="classes" :data-label="label" :aria-label="label" />
</template>

<script>
export default {
  props: {
    label: { type: String },
    inset: { type: Boolean, default: false },
  },

  computed: {
    classes: function () {
      return {
        'mdl-divider': true,
        'mdl-divider--inset': this.inset !== false,
      }
    },
  },
}
</script>
