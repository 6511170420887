import Wrapper from '@/munio/vue/Wrapper.js'

Munio.ZoneStatus = class extends Wrapper {
  constructor(selector, zoneId, gateId) {
    super(selector, { zoneId, gateId })
  }

  component() {
    return import('./App.vue')
  }
}
