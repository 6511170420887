<template>
  <div>
    <div class="course-nodes">
      <Node v-if="course.documentation.enabled" :node="documentationNode">
        <div v-if="readonly && !document">
          {{ trans('Not provided') }}
        </div>
        <div v-else-if="canUploadDocument" class="mdl-card overflow-hidden">
          <transition-group
            :name="showDocument ? 'slide-back' : 'slide-next'"
            tag="div"
            class="mdl-card__title flex-row"
          >
            <div v-if="showDocument" key="document" class="w-full flex justify-between items-start gap-2 min-h-[36px]">
              <DocumentPreview :document="documentPreview" class="mdl-color--white p-2">
                <template #actions>
                  <mdl-button
                    v-if="!readonly"
                    icon="edit"
                    :disabled="loading"
                    @click="toggleDocumentView(true)"
                    v-tooltip="trans('Upload documentation')"
                  />
                </template>
              </DocumentPreview>
            </div>

            <div v-else key="upload" class="flex flex-col w-full">
              <div class="flex justify-end">
                <mdl-button v-if="document" icon="clear" :disabled="loading" @click="toggleDocumentView(false)" />
              </div>

              <div class="flex flex-col gap-y-4 p-4">
                <div v-if="!readonly && !showCameraStream" class="flex flex-grow items-center transition-default">
                  <mdl-file
                    @change="selectDocument"
                    :accept="accept.join(',')"
                    :disabled="loading || readonly"
                    class="flex-grow mr-8"
                  />
                  <mdl-button primary raised :disabled="!file || loading || readonly" @click="uploadDocument">
                    {{ trans('Upload') }}
                  </mdl-button>
                </div>

                <div v-if="hasImageCaptureForCurrentBrowser">
                  <div v-if="!showCameraStream" class="flex flex-col gap-y-4">
                    <span class="uppercase font-bold">{{ trans('or') }}</span>
                    <mdl-button class="w-fit" outlined opaque v-if="!showCameraStream" @click="showCameraStream = true">
                      {{ trans('Take picture') }}
                    </mdl-button>
                  </div>

                  <CameraCapture
                    ref="cameraCapture"
                    v-if="showCameraStream"
                    @closeStream="showCameraStream = false"
                    @changeImage="getCameraImage"
                  />
                </div>
              </div>
            </div>
          </transition-group>
        </div>

        <div v-else-if="showDocument">
          <DocumentPreview :document="documentPreview" class="mdl-color--white p-2" />
        </div>

        <div v-else-if="!document">
          <em>{{ trans('Not provided') }}</em>
        </div>
      </Node>

      <Node :node="dateNode">
        <transition name="fade">
          <div v-if="showDateInput && !readonly" class="mdl-card">
            <div class="mdl-card__title flex-row">
              <div class="flex flex-grow items-center transition-default">
                <div class="flex">
                  <div v-if="requireCompletedAt">
                    <mdl-textfield
                      readonly
                      compact
                      :class="{ 'is-focused': openValidFrom }"
                      :placeholder="trans('Select date')"
                      :value="validFrom"
                      :disabled="loading"
                      @click="openValidFrom = true"
                    />
                    <date-picker
                      v-model="validFrom"
                      :max-date="toDateString(new Date())"
                      :show="openValidFrom"
                      :disabled="loading"
                      @close="openValidFrom = false"
                      altInputClass
                    />
                  </div>

                  <Icon v-if="userCourse.course.validityMode === 'period'" name="arrow_right_alt" class="px-4" />

                  <div v-if="requireExpiresAt">
                    <mdl-textfield
                      readonly
                      compact
                      :class="{ 'is-focused': openValidTo }"
                      :placeholder="trans('Select date')"
                      :value="validTo"
                      :disabled="loading"
                      @click="openValidTo = true"
                    />
                    <date-picker
                      v-model="validTo"
                      :show="openValidTo"
                      :disabled="loading"
                      :min-date="toDateString(new Date())"
                      @close="openValidTo = false"
                      altInputClass
                    />
                  </div>
                </div>

                <div class="spacer" />

                <mdl-button :disabled="!(hasChangedDate || hasSetDate) || loading" @click="unsetdate">
                  {{ trans('Cancel') }}
                </mdl-button>

                <mdl-button primary raised :disabled="!hasChangedDate || loading" @click="setdate">
                  {{ trans('OK') }}
                </mdl-button>
              </div>
            </div>
          </div>

          <div v-else class="flex items-center">
            <div class="flex items-center gap-x-2">
              <div v-if="requireCompletedAt">
                <strong v-if="validFrom">{{ toDateString(validFrom) }}</strong>
                <span v-else>{{ trans('Not provided') }}</span>
              </div>
              <Icon v-if="userCourse.course.validityMode === 'period'" name="arrow_right_alt" />
              <div v-if="requireExpiresAt" class="flex items-center">
                <strong v-if="validTo">{{ toDateString(validTo) }}</strong>
                <span v-else>{{ trans('Not provided') }}</span>
              </div>
              <mdl-button v-if="canEditDate && !readonly" icon="edit" @click="changeDate = true" />
            </div>
          </div>
        </transition>
      </Node>

      <Node v-if="showReviewForm" icon="save_alt">
        <mdl-button raised primary :disabled="!hasSetDate || loading" @click="approve">
          {{ trans(admin ? 'Approve' : 'Confirm') }}
        </mdl-button>
        <mdl-button v-if="onReject && admin" outlined danger :disabled="!hasSetDate || loading" @click="reject">
          {{ trans('Reject') }}
        </mdl-button>
      </Node>

      <NodeCompletion
        v-else-if="completable && userCourse.attempt?.diploma"
        :connected="progressForDate === 100"
        :attempt="userCourse.attempt"
        :diploma="userCourse.attempt?.diploma"
      />

      <Node v-else :node="approvalNode" :progress-angle="-90">
        <div v-if="approval?.reviewer" class="opaque">
          {{ toDateString(approval.reviewedAt) }}
          &mdash; {{ approval.reviewer.name }}
        </div>
      </Node>
    </div>

    <template v-if="course.attachments.length">
      <h4 class="mdl-subheader">{{ trans('Attachments') }}</h4>
      <attachments :attachments="course.attachments" />
    </template>
  </div>
</template>

<script>
import Icon from '@component/Icon.vue'
import Node from './Node.vue'
import NodeCompletion from './NodeCompletion.vue'
import Attachments from './Attachments.vue'
import DocumentPreview from '@/munio/vue/components/DocumentPreview.vue'
import CameraCapture from './CameraCapture.vue'
import { toDateString } from '@/munio/utils/date.js'
import { trans } from '@/munio/i18n/index.js'

export const filterTypes = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  pdf: 'application/pdf',
}

export default {
  name: 'Misc',

  components: {
    Attachments,
    Icon,
    Node,
    NodeCompletion,
    DocumentPreview,
    CameraCapture,
  },

  props: {
    loading: Boolean,
    admin: Boolean,
    completable: { type: Boolean, default: true },
    userCourse: { type: Object, required: true },
    onUpload: {
      type: Function,
      required: true,
      default: () => {},
    },
    onSetdate: {
      type: Function,
      required: true,
      default: () => {},
    },
    onApprove: {
      type: Function,
      required: true,
      default: () => {},
    },
    onReject: { type: Function },
  },

  data() {
    return {
      openValidFrom: null,
      openValidTo: null,
      changeDocument: false,
      changeDate: false,
      file: null,
      date: {
        from: null,
        to: null,
      },
      showWithPreview: false,
      showCameraStream: false,
      hasImageCaptureForCurrentBrowser: false,
    }
  },

  mounted() {
    if (this.approval?.isReviewed) {
      return
    }

    if (!this.document) {
      this.changeDocument = true
    }

    if (this.showDateInput && !this.changeDate) {
      this.changeDate = true
    }

    this.hasImageCaptureForCurrentBrowser = this.isImageCaptureSupported()
  },

  computed: {
    readonly() {
      if (this.admin) {
        return false
      }

      return this.userCourse.course.isAdminResponsible || false
    },

    validityMode() {
      return this.userCourse.course.validityMode || 'from'
    },

    requireCompletedAt() {
      return ['from', 'period'].includes(this.validityMode)
    },

    requireExpiresAt() {
      return ['to', 'period'].includes(this.validityMode)
    },

    course() {
      return this.userCourse.course
    },

    attempt() {
      return this.userCourse.attempt
    },

    diploma() {
      return this.userCourse.diploma
    },

    approval() {
      return this.userCourse.approval
    },

    document() {
      return this.userCourse.document
    },

    documentPreview() {
      if (!this.document) {
        return
      }

      return {
        icon: this.document.icon,
        slug: this.document.slug,
        url: {
          download: this.document.url,
          view: this.document.urlView,
        },
        file: {
          name: this.document.file.nameShort,
          size: this.document.file.size.bytes,
          exists: this.document.file.exists,
        },
      }
    },

    allowedExtensions() {
      return Object.keys(filterTypes)
    },

    accept() {
      return Object.values(filterTypes)
    },

    progressForDocumentation() {
      if (this.approval?.approvedAt !== undefined) {
        return 100
      }

      return this.document ? 100 : 0
    },

    progressForDate() {
      return this.hasSetDate ? 100 : 0
    },

    isEditable() {
      return this.canUploadDocument || this.userCourse.canUserApprove
    },

    showDocument() {
      return this.document && !this.changeDocument
    },

    documentIcon() {
      return this.document?.icon || 'insert_drive_file'
    },

    documentTitle() {
      let title = trans('Documentation')

      if (!this.course.documentation.required) {
        title += ' (' + trans('Optional') + ')'
      }

      return title
    },

    documentationNode() {
      const isCompleted = this.progressForDocumentation === 100
      let status = 'not-started'

      if (this.progressForDocumentation > 0) {
        status = isCompleted ? 'completed' : 'started'
      }

      return {
        progress: this.progressForDocumentation,
        icon: isCompleted ? 'check' : this.documentIcon,
        object: { title: this.documentTitle },
        status,
      }
    },

    completedAt() {
      return toDateString(this.approval?.completedAt)
    },

    expiresAt() {
      return toDateString(this.approval?.expiresAt)
    },

    validFrom: {
      get() {
        return this.date.from || this.completedAt
      },
      set(value) {
        this.date.from = this.toDateString(value)
      },
    },

    validTo: {
      get() {
        return this.date.to || this.expiresAt
      },
      set(value) {
        this.date.to = this.toDateString(value)
      },
    },

    dateNode() {
      const isCompleted = this.hasSetDate || this.approval?.isReviewed || false
      let title = trans('Validity period')

      if (this.validityMode === 'from') {
        title = trans('Completion date')
      } else if (this.validityMode === 'to') {
        title = trans('Expiration date')
      }

      return {
        progress: isCompleted ? 100 : this.progressForDate,
        icon: isCompleted ? 'check' : 'event',
        object: { title },
        status: isCompleted ? 'completed' : 'not-started',
      }
    },

    approvalNode() {
      const title = this.approval?.status || trans('Confirmation')
      const state = this.approval?.state || 'pending'
      let isCompleted = this.userCourse.attempt?.isCompleted
      let progress = this.userCourse.progress
      let status = isCompleted ? 'completed' : 'not-started'
      let icon = isCompleted ? 'star' : 'info'

      if (state === 'rejected') {
        isCompleted = false
        status = 'failed'
        icon = 'close'
      }

      return {
        progress: this.userCourse.progress,
        object: { title },
        status,
        icon,
      }
    },

    hasUserSubmitted() {
      if (this.admin) {
        return this.approval?.isReviewed || false
      }

      return this.approval?.isPendingApproval || false
    },

    canUploadDocument() {
      if (!this.course.documentation.enabled) {
        return false
      }

      if (this.approval?.isReviewed) {
        return false
      }

      if (!this.admin) {
        return this.userCourse.documentation?.canUpload
      }

      return this.userCourse.permissions?.document || false
    },

    showReviewForm() {
      if (this.readonly) {
        return false
      }

      if (this.approval?.isReviewed) {
        return false
      }

      if (!this.admin) {
        return !this.approval || this.approval.state === 'pending'
      }

      return true
    },

    showDateInput() {
      if (this.course.documentation.required && !this.document) {
        return false
      }

      if (this.approval?.isReviewed) {
        return false
      }

      if (this.changeDate) {
        return true
      }

      switch (this.course.validityMode) {
        case 'from':
          return !this.validFrom
        case 'to':
          return !this.validTo
        case 'period':
          return !this.validFrom || !this.validTo
      }

      return true
    },

    hasSetDate() {
      switch (this.course.validityMode) {
        case 'from':
          return !!this.completedAt
        case 'to':
          return !!this.expiresAt
        case 'period':
          return !!this.completedAt && !!this.expiresAt
      }

      return false
    },

    hasChangedDate() {
      const changedFrom = this.date.from && this.date.from !== this.completedAt
      const changedTo = this.date.to && this.date.to !== this.expiresAt

      if (!this.course.validityMode) {
        return changedFrom
      }

      switch (this.course.validityMode) {
        case 'from':
          return changedFrom
        case 'to':
          return changedTo
        case 'period':
          if (changedFrom) {
            return !!this.validTo
          }
          if (changedTo) {
            return !!this.validFrom
          }
      }

      return false
    },

    canEditDate() {
      if (this.approval?.isReviewed) {
        return false
      }

      if (this.readonly && this.approval?.isPendingApproval) {
        return false
      }

      return true
    },

    canReview() {
      if (!this.attempt?.isValid || this.approval?.isReviewed) {
        return false
      }

      if (this.admin) {
        return this.userCourse.permissions?.approve || false
      }

      return this.userCourse.canUserApprove
    },
  },

  methods: {
    toDateString,

    isImageCaptureSupported() {
      return window.ImageCapture !== undefined
    },

    selectDocument(e) {
      this.file = e.target.files[0] || null
    },

    getCameraImage(e) {
      this.file = e || null
      this.uploadDocument()
    },

    toggleDocumentView(value) {
      const camera = this.$refs.cameraCapture
      if (camera) {
        camera.emptyStream()
      }

      this.showCameraStream = false

      this.changeDocument = value
      this.file = null
    },

    async uploadDocument() {
      try {
        await this.onUpload(this.file)
        this.$nextTick(() => {
          this.changeDocument = false
        })
      } catch (e) {
        console.error(e)
      }
    },

    unsetdate() {
      this.date.to = null
      this.date.from = null
      this.$nextTick(() => {
        this.changeDate = !this.hasSetDate
      })
    },

    async setdate() {
      try {
        await this.onSetdate(this.validFrom, this.validTo)
        this.unsetdate()
      } catch (e) {
        console.error(e)
      }
    },

    async approve() {
      if (!(await Munio.confirm())) {
        return
      }

      try {
        await this.onApprove()
      } catch (e) {
        console.error(e)
      }
    },

    async reject() {
      if (!(await Munio.confirm(trans('Are you sure?'), undefined, { type: 'warning' }))) {
        return
      }

      try {
        await this.onReject()
      } catch (e) {
        console.error(e)
      }
    },
  },

  watch: {
    userCourse() {
      this.unsetdate()
    },
  },
}
</script>
