class UserInvite {
  id

  constructor(id) {
    this.id = id
  }

  get basePath() {
    return `/api/auth/invites/${this.id}`
  }

  accept() {
    return Munio.$http.post(this.basePath)
  }

  decline() {
    return Munio.$http.delete(this.basePath)
  }
}

export default UserInvite
