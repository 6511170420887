import { computed, Ref, ref } from 'vue'
import { defineStore } from 'pinia'
import { useShopCheckoutStore } from './store'
import {
  ConfirmationToken,
  Stripe,
  StripeElementLocale,
  StripeElements,
  StripeError,
  StripePaymentElement,
  StripePaymentElementChangeEvent,
} from '@stripe/stripe-js'
import { UserBasicResourceDto } from '@/munio/api/data'
import { useStripe, appearance, isStripeLocale } from './useStripe'

export const useCardStore = defineStore('shop.checkout.card', () => {
  const checkout = useShopCheckoutStore()

  const setError = (err: string | Error | StripeError) => checkout.setAlert(err, 'danger')
  const clearError = () => checkout.setAlert(undefined)
  const user = computed(() => checkout.cart.user as UserBasicResourceDto)

  let stripe: Stripe
  let elements: StripeElements
  let paymentElement: StripePaymentElement

  function getLocale(): StripeElementLocale {
    const i18n = window.Munio.config.i18n

    for (const locale of [i18n.locale, i18n.language, i18n.key]) {
      if (isStripeLocale(locale)) {
        return locale
      }
    }

    return 'auto'
  }

  async function mount(element: HTMLElement) {
    if (!checkout.cart.shop.stripeKey) {
      console.error('Stripe key is missing')
      return
    }

    stripe = (await useStripe(checkout.cart.shop.stripeKey, {
      locale: getLocale(),
    })) as Stripe

    elements = stripe.elements({
      mode: 'payment',
      loader: 'always',
      amount: checkout.cart.total.gross.cents,
      currency: checkout.cart.shop.currency.toLowerCase(),
      appearance,
    })

    paymentElement = elements.create('payment', {
      defaultValues: {
        billingDetails: {
          name: user.value.fullname,
          email: user.value.email || undefined,
          phone: user.value.mobile || undefined,
          address: {
            line1: checkout.cart.paymentAddress?.line1 || undefined,
            line2: checkout.cart.paymentAddress?.line2 || undefined,
            city: checkout.cart.paymentAddress?.city || undefined,
            postal_code: checkout.cart.paymentAddress?.postcode || undefined,
            country: checkout.cart.paymentAddress?.country || undefined,
          },
        },
      },
    })

    paymentElement.on('change', (event: StripePaymentElementChangeEvent) => {
      checkout.paymentMethodForm.card.valid = event.complete

      setError(event instanceof ErrorEvent ? event.error?.message : undefined)
    })

    paymentElement.mount(element)
  }

  function unmount() {
    paymentElement?.unmount()
  }

  async function submit(): Promise<ConfirmationToken | undefined> {
    clearError()

    try {
      const { error: submitError } = await elements.submit()

      if (submitError) {
        setError(submitError)
        return
      }

      const { error, confirmationToken } = await stripe.createConfirmationToken({
        elements,
      })

      // if the response is an error
      if (error || !confirmationToken) {
        setError(error)
        return
      }

      // if the user has passed prop redirect="if_required"
      // and the payment confirmation was successful
      // and the payment method is not forced to redirect
      // then stripe.confirmPayment resolves with a paymentIntent
      // so we sould pass it back up to the caller for consumption
      // https://stripe.com/docs/js/payment_intents/confirm_payment?type=pii#confirm_payment_intent-options-redirect
      return confirmationToken
    } catch (error) {
      setError(error as Error)
      return
    }
  }

  async function nextAction(response: { error?: string; clientSecret: string; status: string }) {
    let intentStatus = response.status

    clearError()

    if (response.error) {
      setError(response.error)
      return false
    }

    if (response.status === 'requires_action') {
      const { error, paymentIntent } = await stripe.handleNextAction({
        clientSecret: response.clientSecret,
      })

      if (error) {
        setError(error)
        return false
      }

      if (paymentIntent?.status) {
        intentStatus = paymentIntent.status
      }
    }

    return intentStatus === 'succeeded'
  }

  return {
    submit,
    nextAction,
    mount,
    unmount,
  }
})
