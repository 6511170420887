<template>
  <pre>
    {{ invite }}
  </pre>
</template>

<script>
export default {
  props: {
    invite: { type: Object, required: true },
  },
}
</script>
