import Select from '@/munio/vue/components/Select.vue'

Munio.behaviors.select = {
  selector: 'select',
  attach: function (context) {
    $(this.selector, context).each(function (i, el) {
      if ($(el).parents('[class^="flatpickr"]').length > 0) {
        return
      }

      const attrs = {
        options: Array.from(
          $.map($(el).find('option'), (option) => ({
            id: option.value,
            label: option.text,
            labelSelected: option.dataset.selection,
            toggleTarget: option.dataset.toggle,
            dataset: Object.assign({}, option.dataset),
            isDisabled: option.disabled,
            selected: option.selected,
          })).values(),
        ),
        value: el.value === '' ? undefined : el.value,
      }

      const placeholderIndex = attrs.options.findIndex((opt) => opt.id === '')

      if (placeholderIndex !== -1) {
        attrs.placeholder = attrs.options[placeholderIndex].label
        attrs.options = [...attrs.options.slice(0, placeholderIndex), ...attrs.options.slice(placeholderIndex + 1)]
        attrs.clearable = true
      }

      let key
      for (let attr of el.attributes) {
        if (!attr.name.match(/^on([a-z]+)/)) {
          if (attr.name === attr.value || (attr.value === '' && !['form', 'name', 'size'].includes(attr.name))) {
            attrs[attr.name] = true
          } else {
            attrs[attr.name] = attr.value
          }
        }
      }

      for (key of Object.keys(el.dataset)) {
        if (typeof Select.props[key]?.type() === 'boolean') {
          attrs[key] = ['true', '1', ''].includes(el.dataset[key]?.toLowerCase())
        } else {
          attrs[key] = el.dataset[key]
        }
      }

      if (attrs.required) {
        attrs.clearable = false
      }

      Munio.Vue({
        el,
        name: 'SelectBehavior',
        render: function (createElement) {
          return createElement(Select, {
            class: Array.from(el.classList).filter((cls) => cls !== 'select'),
            attrs,
          })
        },
      })
    })
  },
}
