import { extractQueryParams, valueDefault, resetSelection } from './helpers.js'
import Vue from 'vue'
import { isEqual } from 'lodash'

const state = () => ({
  // from server
  legacy: false,
  csv: false,
  filters: [],
  filtersMap: {},
  search: {
    input: '',
    active: false,
    enabled: true,
  },

  // local state
  inline: false,
  form: {
    filters: [],
    filtersShow: false,
    filter: null,
    search: '',
    searchFocus: false,
    values: {},
    modified: false,
  },
})

const mutations = {
  INIT(state, options) {
    Object.assign(state, options)

    state.inline = !!(options.inline ?? false)
    state.form.search = state.search.input = state.search.input || ''
    state.form.filters = state.filters
      .sort((a, b) => {
        if (a.position > b.position) return 1
        else if (a.position < b.position) return -1
        else return 0
      })
      .reduce((acc, filter) => {
        if (filter.isActive) {
          acc.push(filter.name)
        }
        return acc
      }, [])

    state.filters.forEach((filter, index) => {
      let value = filter.value

      if (filter.extraInput?.isActive) {
        value = filter.extraInput.allowMultipleInput
          ? {
              value: filter.value,
              extraValue: filter.extraInput.value,
            }
          : {
              extraValue: filter.extraInput.value,
            }
      }

      state.filtersMap = { ...state.filtersMap, [filter.name]: index }
      state.form.values = {
        ...state.form.values,
        [filter.name]: value,
      }
    })
  },
  RESET(state) {
    const values = {}
    const active = []

    state.filters.forEach((filter) => {
      values[filter.name] = null

      if (filter.valueDefault) {
        values[filter.name] = filter.valueDefault
        active.push(filter.name)
      }
    })

    state.form.filters = active
    state.form.search = ''
    state.form.filter = null
    state.form.values = values
  },
  INPUT(state, value) {
    state.form.search = value
  },
  SHOW_FILTERS(state, value) {
    state.form.filtersShow = !!value
  },
  ADD_FILTER(state, { name, value, clearSearch = false }) {
    if (clearSearch) {
      state.form.search = ''
    }

    if (value) {
      state.form.filters.push(name)
      state.form.values[name] = value
    } else {
      state.form.filter = name
    }
  },
  REMOVE_FILTER(state, name) {
    state.form.filters = state.form.filters.filter((filterName) => filterName !== name)
    state.form.filter = null
    Vue.set(state.form.values, name, null)
  },
  CANCEL_FILTER(state, name) {
    state.form.filter = null
  },
  EDIT_FILTER(state, name) {
    state.form.filter = name
  },
  APPLY_FILTER(state, { name, value }) {
    state.form.filter = null

    if (!state.form.filters.includes(name)) {
      state.form.filters = [...state.form.filters, name]
    }

    state.form.values = { ...state.form.values, [name]: value }
  },
  SEARCH_FOCUS(state, value) {
    state.form.searchFocus = !!value
  },
  RESTORE(state, queryString) {
    const { active, values, search } = extractQueryParams(state.filters, queryString)

    state.form.filters = active
    state.form.values = values
    state.form.search = state.search.input = search
  },
  SUBMIT(state) {
    Object.keys(state.form.values).forEach((key) => {
      state.filters[state.filtersMap[key]].value = state.form.values[key]
    })
    state.search.input = state.form.search
    state.form.filter = null
  },
}

const getters = {
  getFilter:
    ({ filters, filtersMap }) =>
    (name) => {
      return filters[filtersMap[name]]
    },

  getFilterValue:
    ({ form }, { getFilter }) =>
    (name) => {
      const filter = getFilter(name)
      let value = form.values[name] ?? filter.valueDefault ?? valueDefault(filter)

      if (filter.extraInput?.allowMultipleInput) {
        value = {
          value: value?.value ?? valueDefault(filter),
          extraValue: value?.extraValue ?? valueDefault(filter.extraInput),
        }
      }

      if (value === undefined || value === null) {
        return valueDefault(filter)
      }

      return value
    },

  getFiltersValues: ({ form }, { getFilter }) => {
    const values = {}

    form.filters.forEach((name) => {
      const filter = getFilter(name)
      const value = form.values[name]
      // only add to values if there is a custom value set
      if (value !== null && value !== filter.valueDefault) {
        values[filter.name] = value
      }
    })

    return values
  },

  getFiltersActive: ({ form, filters }, { getFilter }) => {
    const current = form.filter
    let active = form.filters

    if (current && !active.includes(current)) {
      active = [...active, current]
    }

    for (const filter of filters) {
      if (filter.valueDefault !== null && filter.valueDefault !== undefined && !active.includes(filter.name)) {
        active.push(filter.name)
      }
    }

    return active.map((name) => getFilter(name)).filter((filter) => filter.isEnabled)
  },

  getFiltersInactive: ({ filters, form }) => {
    return filters.filter((filter) => {
      if (!filter.isEnabled || filter.isFlag) {
        return false
      }

      return !form.filters.includes(filter.name)
    })
  },

  getFlagsInactive: ({ filters, form }) => {
    return filters.filter((filter) => {
      return filter.isFlag && !form.filters.includes(filter.name)
    })
  },

  isInitial: ({ filters, form }, { isFilterDirty }) => {
    if (form.search !== '') {
      return false
    }

    return filters.filter((filter) => isFilterDirty(filter.name)).length === 0
  },

  isFilterDirty:
    ({ form }, { getFilter }) =>
    (name) => {
      const filter = getFilter(name)
      const value = form.values[name] ?? null

      if (['daterange', 'period'].includes(filter.type)) {
        if (filter.valueDefault) {
          return value.from !== filter.valueDefault.from || value.to !== filter.valueDefault.to
        }

        if (value === null && filter.valueDefault === null) {
          return false
        }

        return !isEqual(value, { from: null, to: null })
      }

      return !isEqual(value, filter.valueDefault)
    },
}

const actions = {
  editFilter({ commit }, name) {
    commit('EDIT_FILTER', name)
  },
  cancelFilter({ commit }, name) {
    commit('CANCEL_FILTER', name)
  },
  applyFilter({ commit, dispatch, getters }, { name, value }) {
    commit('APPLY_FILTER', { name, value })
    if (getters.isInitial) {
      dispatch('clear')
    }
  },
  removeFilter({ commit, dispatch, getters }, name) {
    commit('REMOVE_FILTER', name)
    if (getters.isInitial) {
      dispatch('clear')
    }
  },
  clear({ state }) {
    if (!state.inline) {
      resetSelection()
    }
  },
  reset({ commit, dispatch, state }) {
    commit('RESET')
    dispatch('clear')
  },
  restore({ state, commit, dispatch, getters }, queryString) {
    commit('RESTORE', queryString)
    if (getters.isInitial) {
      dispatch('clear')
    }
  },
  submit({ state, commit, dispatch, getters }) {
    commit('SUBMIT')
    if (getters.isInitial) {
      dispatch('clear')
    }
  },
}

export default {
  state,
  mutations,
  getters,
  actions,
}
