import Zone from './Zone.js'
import Gate from './Gate'

export default class {
  zone(id) {
    return new Zone(id)
  }

  gate(id) {
    return new Gate(id)
  }
}
