<template>
  <form method="post" :action="route('lms.user.access.link.register')">
    <csrf />

    <h3>{{ trans('Select zone(s)') }}:</h3>

    <div v-for="company of companies" :key="company.id">
      <h4 class="mdl-subheader mt-8">{{ company.name }}</h4>
      <div v-for="zone of company.zones" :key="zone.id">
        <mdl-checkbox name="zones[]" :value="zone.id" v-model="selected">{{ zone.name }}</mdl-checkbox>
      </div>
    </div>

    <div class="form-actions">
      <mdl-button href="/" :disabled="submitting">{{ trans('Cancel') }}</mdl-button>
      <mdl-button
        :loading="submitting"
        :disabled="submitting || selected.length === 0"
        raised
        primary
        @click="onSubmit"
        >{{ trans('Enroll') }}</mdl-button
      >
    </div>
  </form>
</template>

<script>
import { sortZones, groupZonesByCompany } from './Manager/store.js'

export default {
  props: {
    link: { type: Object, required: true },
  },

  data() {
    return {
      submitting: false,
      selected: [],
    }
  },

  computed: {
    zones() {
      return sortZones(this.link.zones)
    },

    companies() {
      return groupZonesByCompany(this.zones)
    },
  },

  methods: {
    onSubmit(e) {
      this.submitting = true
      this.$el.submit()
    },
  },
}
</script>
