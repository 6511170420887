import CompanySelector from '@/munio/vue/CompanySelector/index.vue'

Munio.behaviors.companySelector = {
  selector: '#header .company-selector',
  attach: function (context) {
    $(this.selector, context).each(function (i, el) {
      Munio.Vue({
        el,
        name: 'CompanySelector',
        render: function (createElement) {
          return createElement(CompanySelector)
        },
      })
    })
  },
}
