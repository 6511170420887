<template>
  <div>
    <div>{{ trans("You've been invited by :company to complete this course", { company: invite.company.name }) }}:</div>

    <div class="my-8 flex content-center">
      <img :src="course.image.avatar" class="avatar" />
      <h4 class="ml-4 inline-block">
        {{ invite.element.course.title }}
        <small class="opaque font-bold" v-if="course.identifier">{{ course.identifier }}</small>
      </h4>
    </div>

    <div v-if="session" class="my-8">
      <a v-if="!session.isWebinar" :href="session.location.url" target="_blank" class="block">
        <img :src="session.location.image.large" alt="" class="rounded" />
      </a>

      <div v-if="session.isWebinar">
        <h4 class="mdl-subheader mt-8">{{ trans('Webinar') }}</h4>
        <p>{{ trans('The session will be presented live in an online webinar') }}</p>
      </div>
      <div v-else>
        <h4 class="mdl-subheader mt-8">{{ trans('Where') }}</h4>
        <div>
          <div>{{ session.location.name }}</div>
          <a :href="session.location.url" target="_blank"
            >{{ session.location.address }} <i class="material-icons small">place</i></a
          >
        </div>
      </div>
      <div>
        <h4 class="mdl-subheader mt-8">{{ trans('When') }}</h4>
        <p>
          <span v-html="session.from.html.datetime" /> –
          <span v-html="session.to.date == session.from.date ? session.to.html.hour : session.to.html.datetime" />
        </p>
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
export default {
  props: {
    invite: { type: Object, required: true },
  },

  computed: {
    course() {
      return this.invite.element.course
    },
    session() {
      return this.invite.element.session
    },
  },
}
</script>
