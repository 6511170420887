<template>
  <div class="login-profile">
    <img class="avatar mr-4" :src="avatar" />
    <h4>
      <label v-if="user" for="username">
        {{ user.name || user.fullname }}
      </label>
      <input id="username" type="text" name="username" :value="username" readonly />
    </h4>
    <MdlButton icon="clear" @click="clearUser" />
  </div>
</template>

<script>
import MdlButton from '@component/mdl/Button.vue'

export default {
  components: {
    MdlButton,
  },

  computed: {
    user() {
      if (this.$store.state.resetToken) {
        return this.$store.state.resetToken.user
      }

      return this.$store.state.user
    },

    avatar() {
      return this.user?.avatar?.url || `/cb12345678/img/avatar/avatar/missing.jpg`
    },

    username() {
      return this.$store.state.username || this.user?.email || this.user?.mobile
    },
  },

  methods: {
    clearUser() {
      this.$store.dispatch('cancelLogin')
    },
  },
}
</script>
