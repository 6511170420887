Munio.behaviors.disableTarget = {
  selector: '[data-disable-target]',
  attach: function (context, config) {
    $(this.selector, context).on('click', function (event) {
      let element = $(event.target)
      let target = $(element.data('disable-target'))
      let input

      // we didn't find a target element.
      // try to find a default element.
      if (!element.data('disable-target').length) {
        target = element.closest('.form-group')
      }

      input = target.find('input').not(element)
      input.prop('disabled', !input.is(':disabled'))
    })
  },
}
