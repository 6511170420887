import AttemptNode from './AttemptNode.js'

class Attempt {
  attemptId = null

  constructor(attemptId) {
    this.attemptId = attemptId
  }

  get basePath() {
    return `/api/attempt/${this.attemptId}`
  }

  get(language = null) {
    let url = this.basePath

    if (language) {
      url += `?language=${language}`
    }

    return Munio.$http.get(url)
  }

  changeLanguage(language) {
    return Munio.$http.post(`${this.basePath}/language`, { language })
  }

  updateConditions(conditions) {
    return Munio.$http.post(`${this.basePath}/conditions`, conditions)
  }

  rejectAuth() {
    return Munio.$http.post(`${this.basePath}/auth/reject`)
  }

  confirmAuth() {
    return Munio.$http.post(`${this.basePath}/auth/confirm`)
  }

  node(nodeId) {
    return new AttemptNode(this.attemptId, nodeId)
  }
}

export default Attempt
