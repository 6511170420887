class UserCourses {
  userId

  constructor(userId) {
    this.userId = userId
  }

  get basePath() {
    return `/api/users/${this.userId}/courses`
  }

  get() {
    return Munio.$http.get(this.basePath)
  }

  enrollable() {
    return Munio.$http.get(`${this.basePath}/enrollable`)
  }
}

export default UserCourses
