import Wrapper from '@/munio/vue/Wrapper.js'

Munio.GateEntrypointManager = class extends Wrapper {
  constructor(selector, gate, zones) {
    super(selector, { gate, zones })
  }

  get name() {
    return 'GateEntrypointManager'
  }

  component() {
    return import('./App.vue')
  }
}
