import dinero, { Dinero } from 'dinero.js'
import { Currency } from '@/munio/api/data'

const { Munio } = window
const defaultCurrency = Munio.config.currency || 'NOK'
const locale = Munio.config.i18n.locale

export function amount(value: string | number | Dinero, currency: Currency = defaultCurrency, precision = 2): Dinero {
  if (value === null || value === undefined) {
    value = 0
  }

  if (typeof value === 'object' && 'getAmount' in value) {
    return value as Dinero
  }

  value = Number(value)
  currency = (currency || defaultCurrency).toUpperCase() as Currency

  const amount: number = parseInt(parseFloat(String(value * 10 ** precision)).toFixed())

  return dinero({ amount, currency, precision }).setLocale(locale)
}

export function format(value: string | number | Dinero, currency: Currency, precision = 2) {
  currency = (currency || defaultCurrency).toUpperCase() as Currency
  return amount(value, currency, precision).toFormat()
}

export function parse(value: string | number): number {
  value = String(value)
  const match = /(?<num>.+)(?<sep>[,.\u066B\u2396·])(?<fraction>[0-9]+)$/.exec(value)
  const num = (match?.groups?.num || value).replace(/[^0-9]/g, '')
  const fraction = match?.groups?.fraction

  return parseFloat(fraction ? `${num}.${fraction}` : num) || 0
}

/**
 * Currency
 */
window.Munio.Currency = {
  default: defaultCurrency,
  amount,
  format,
  parse,
}
