<template>
  <div class="mdl-filter__form" @click="$emit('activate', $event)">
    <div
      v-for="option of options"
      :key="option.value"
      class="mdl-filter__option"
      :class="{ 'is-disabled': option.disabled }"
    >
      <mdl-radio
        :name="inputName"
        :class="optionClasses(option)"
        :value="option.value"
        :checked="String(option.value) === String(value)"
        :disabled="option.disabled"
      >
        <img v-if="option.image" :src="option.image" :alt="trans('Avatar')" />
        <span class="flex flex-col">
          <small v-if="option.identifier" class="opaque">{{ option.identifier }}</small>
          {{ option.label }}
          <span v-if="option.description" class="mdl-radio__description">
            {{ option.description }}
          </span>
        </span>
      </mdl-radio>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RadioFilter',
  inheritAttrs: false,

  props: {
    inputName: { type: [String], default: () => 'value' },
    disabled: { type: Boolean },
    value: { type: [String, Number] },
    options: { type: Array },
  },

  emits: ['activate'],

  methods: {
    optionClasses(option) {
      if (option.indent) {
        return [`indent-${option.indent}`]
      }
    },
  },
}
</script>
