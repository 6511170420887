export default class {
  gateId
  cardId

  constructor(gateId, cardId) {
    this.gateId = gateId
    this.cardId = cardId
  }

  get params() {
    return { gate: this.gateId, card: this.cardId }
  }

  get() {
    return Munio.$http.get(Munio.route('api.web.access.gate.card', this.params))
  }

  match(type, user) {
    return Munio.$http.post(Munio.route('api.web.access.gate.card.match', { ...this.params, type, user }))
  }

  search(query) {
    return Munio.$http.get(Munio.route('api.web.access.gate.card.search', { ...this.params, search: query }))
  }
}
