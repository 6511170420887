<template>
  <div v-if="canEnroll">
    <div class="modal-body flex justify-between items-center">
      <div class="flex-grow">
        <div>
          <h4 class="mdl-subheader">{{ trans('Duration') }}</h4>
          <p>{{ userCourse.duration.human }}</p>
        </div>
      </div>

      <div class="spacer"></div>

      <mdl-button raised primary :disabled="processing" @click="enroll">
        {{ trans('Enroll') }}
      </mdl-button>
    </div>

    <div class="modal-body pt-0" v-if="course.description.length">
      <h4 class="mdl-subheader">{{ trans('Description') }}</h4>
      <span class="whitespace-pre-wrap">{{ course.description }}</span>
    </div>
  </div>

  <div v-else>
    <div class="modal-body flex justify-between items-center">
      <div class="flex-grow">
        <div>
          <h4 class="mdl-subheader">{{ trans('Duration') }}</h4>
          <p>{{ userCourse.duration.human }}</p>
        </div>
      </div>

      <div class="spacer"></div>

      <div class="flex justify-end flex-grow items-baseline">
        <mdl-button v-if="canUnenroll" danger :disabled="processing" @click="unenroll">
          {{ trans('Unenroll') }}
        </mdl-button>

        <mdl-button v-if="mustPurchase" accent raised :disabled="processing" @click="purchase">
          {{ trans('Buy') }}
          <span class="opaque ml-4">{{ product.price.display }}</span>
        </mdl-button>

        <template v-else-if="userCourse.isResumable">
          <mdl-button outlined :disabled="processing" @click="restart">
            {{ trans('Restart') }}
          </mdl-button>

          <mdl-button primary raised :disabled="processing" @click="start">
            {{ trans('Resume') }}
          </mdl-button>
        </template>

        <mdl-button v-else-if="userCourse.isPlayable" primary raised :disabled="processing" @click="start">
          {{ trans('Start') }}
        </mdl-button>

        <mdl-button v-else-if="userCourse.unplayableReason" disabled raised>
          {{ userCourse.unplayableReasonText }}
        </mdl-button>
      </div>
    </div>

    <div class="modal-body pt-0" v-if="course.description.length">
      <h4 class="mdl-subheader">{{ trans('Description') }}</h4>
      <span class="whitespace-pre-wrap">{{ course.description }}</span>
    </div>

    <div class="modal-body modal-body--tinted">
      <template v-if="incompletePrerequisites.length">
        <h4 class="mdl-subheader">{{ trans('Prerequisites') }}</h4>
        <div>
          <div class="course-nodes">
            <prerequisite
              v-for="prerequisite in incompletePrerequisites"
              :key="prerequisite.courseId"
              :prerequisite="prerequisite"
            />
          </div>
        </div>

        <h4 class="mdl-subheader">{{ trans('Content') }}</h4>
      </template>

      <div>
        <node-collection
          :nodes="nodes"
          :attempt="userCourse.attempt"
          :diploma="userCourse.diploma"
          :completable="userCourse.isCompletable && !userCourse.isMunioAccessPlayback"
        />
      </div>

      <template v-if="course.attachments.length">
        <h4 class="mdl-subheader">{{ trans('Attachments') }}</h4>
        <attachments :attachments="course.attachments" />
      </template>
    </div>
  </div>
</template>

<script>
import Confirm from '@/munio/vue/directives/confirm.js'
import NodeCollection from '@/munio/vue/components/UserCourse/NodeCollection.vue'
import Attachments from '@/munio/vue/components/UserCourse/Attachments.vue'
import Prerequisite from './Prerequisite.vue'

export default {
  components: {
    Attachments,
    Prerequisite,
    NodeCollection,
  },

  directives: {
    Confirm,
  },

  props: {
    userCourse: { type: Object, required: true },
    product: { type: Object },
    processing: { type: Boolean },
  },

  methods: {
    cancel() {
      this.$emit('cancel')
    },

    start() {
      this.$emit('start', { course: this.userCourse, restart: false })
    },

    async restart() {
      if (await Munio.confirm()) {
        this.$emit('start', { course: this.userCourse, restart: true })
      }
    },

    unenroll() {
      this.$emit('unenroll', this.userCourse)
    },

    enroll() {
      this.$emit('enroll', this.userCourse)
    },

    purchase() {
      this.$emit('purchase', {
        course: this.course,
        product: this.product,
      })
    },
  },

  computed: {
    canUnenroll() {
      if (!can('MUNIO')) {
        return false
      }

      return this.userCourse.isEnrolled && this.userCourse.isUnenrollable
    },

    canEnroll() {
      return !this.userCourse.isEnrolled && this.userCourse.canSelfEnroll
    },

    course() {
      return this.userCourse.course
    },

    mustPurchase() {
      if (!this.product) {
        return false
      }

      if (this.userCourse.hasLicense) {
        return false
      }

      return !this.userCourse.isResumable && !this.userCourse.isPlayable
    },

    incompletePrerequisites() {
      return this.userCourse.prerequisites.filter((prerequisite) => !prerequisite.isCompleted)
    },

    hasMultipleModules() {
      if (!this.userCourse || !this.userCourse.nodes) {
        return false
      }

      return this.userCourse.nodes.length > 1
    },

    nodes() {
      return this.userCourse.nodes || []
    },

    descriptions() {
      if (!this.userCourse.course.description) return []
      return this.userCourse.course.description.split('\n')
    },
  },
}
</script>

<style scoped lang="scss">
.coursemodal {
  &__actions {
    .mdl-button {
      min-width: 150px;
      height: 45px;
    }
  }
}
</style>
