import { ApprovalResourceDto } from '@/munio/api/data/ApprovalResource.dto'
import { AxiosRequestHeaders } from 'axios'
import { toDateString } from '@/munio/utils/date.js'

type Response = {
  data: ApprovalResourceDto
}

class Approval {
  constructor(private approval: number) {}

  get params() {
    return {
      approval: this.approval,
    }
  }

  get() {
    return window.Munio.$http.get<Response>(window.Munio.route('api.web.admin.approval', this.params))
  }

  review(approve: boolean) {
    return window.Munio.$http.post<Response>(window.Munio.route('api.web.admin.approval.review', this.params), {
      approve,
    })
  }

  document(file: File) {
    const form = new FormData()
    form.append('document', file)

    return window.Munio.$http.post<Response>(window.Munio.route('api.web.admin.approval.document', this.params), form, {
      'Content-Type': 'multipart/form-data',
    } as AxiosRequestHeaders)
  }

  validity(from?: string, to?: string) {
    return window.Munio.$http.post<Response>(window.Munio.route('api.web.admin.approval.validity', this.params), {
      from: toDateString(from),
      to: toDateString(to),
    })
  }
}

export default Approval
