import { get } from '@/munio/utils/index.js'

export function form() {
  return {
    salesperson: undefined,
    customer: undefined,
    contact: undefined,
    payment: {
      customer: undefined,
      address: undefined,
      invoice: {
        email: undefined,
        days: undefined,
        reference: undefined,
        comment: undefined,
      },
    },
    lines: {},
    linesAdded: [],
    sessions: {},
    enableAccounting: undefined,
    enableLicensing: undefined,
  }
}

export function lineForm(form, line) {
  const input = form.lines[line?.uid] || {}

  return {
    product: get(input.product, line?.productId || undefined),
    session: get(input.session, line?.session?.id || undefined),
    price: get(input.price, line?.price?.final || 0),
    quantity: get(input.quantity, line?.quantity || 1),
    unlimited: get(input.unlimited, line?.isUnlimited || false),
    removed: get(input.removed, false),
  }
}

export function getCompanyAddress(company) {
  return (
    company.addresses?.find((address) => company.invoiceAddressId || company.addressId) ||
    company.addresses?.[0] ||
    undefined
  )
}
