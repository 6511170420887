class ShopCustomer {
  constructor(parentPath, companyId) {
    this.parentPath = parentPath
    this.companyId = companyId
  }

  get basePath() {
    return `${this.parentPath}/customer/${this.companyId}`
  }

  get(includeAddress = false) {
    return Munio.$http.get(this.basePath, { includeAddress })
  }
}

export default ShopCustomer
