import { combineReducers } from 'redux'
import actions from './actions.js'

export const types = {
  fireworks: {
    name: trans('Fireworks'),
    columns: [
      { key: 'identifier', name: trans('Certificate number') },
      { key: 'lastname', name: trans('Lastname') },
      { key: 'firstname', name: trans('Firstname') },
      { key: 'born_at', name: trans('Date of birth') },
      { key: 'expires_at', name: trans('Expires') },
    ],
  },
}

export const typesArray = Object.keys(types).map((type) => Object.assign({}, types[type], { key: type }))

export default combineReducers({
  error: (state = '', action) => {
    switch (action.type) {
      case actions.PARSE_FAILED:
        return action.error

      case actions.PARSE_SUCCESS:
        return ''

      default:
        return state
    }
  },

  file: (state = null, action) => {
    switch (action.type) {
      case actions.FILE_SELECT:
        return {
          name: action.file.name,
          type: action.file.type,
          size: action.file.size,
        }

      case actions.RESET:
        return null

      default:
        return state
    }
  },

  header: (state = true, action) => {
    switch (action.type) {
      case actions.TOGGLE_HEADER:
        return !state

      default:
        return state
    }
  },

  types: (state = typesArray, action) => state,

  type: (state = typesArray[0].key, action) => {
    switch (action.type) {
      case actions.TYPE_SELECT:
        return action.key

      default:
        return state
    }
  },

  data: (state = [], action) => {
    switch (action.type) {
      case actions.PARSE_SUCCESS:
        return action.data

      case actions.RESET:
      case actions.FILE_SELECT:
      case actions.PARSE_FILE:
        return []

      default:
        return state
    }
  },

  columns: (state = ['lastname', 'firstname', 'identifier', 'expires_at', 'born_at'], action) => {
    switch (action.type) {
      case actions.TYPE_SELECT:
        return []

      case actions.COLUMN_SELECT:
        let newState = state.slice(0)

        if (action.key == '_ignore') {
          delete newState[action.index]
        } else {
          let cur = newState.indexOf(action.key)

          if (cur > -1) {
            delete newState[cur]
          }

          newState[action.index] = action.key
        }

        return newState

      default:
        return state
    }
  },

  reviewing: (state = false, action) => {
    switch (action.type) {
      case actions.PARSE_SUCCESS:
        return true

      case actions.RESET:
        return false

      default:
        return state
    }
  },

  submitting: (state = false, action) => {
    switch (action.type) {
      case actions.IMPORT_START:
        return true

      case actions.RESET:
        return false

      default:
        return state
    }
  },

  imported: (state = 0, action) => {
    switch (action.type) {
      case actions.RESET:
      case actions.IMPORT_START:
        return 0

      case actions.IMPORT_SUCCESS:
        return state + action.count

      default:
        return state
    }
  },

  failed: (state = 0, action) => {
    switch (action.type) {
      case actions.RESET:
      case actions.IMPORT_START:
        return 0

      case actions.IMPORT_FAILED:
        return state + action.count

      default:
        return state
    }
  },

  processed: (state = 0, action) => {
    switch (action.type) {
      case actions.RESET:
      case actions.IMPORT_START:
        return 0

      case actions.IMPORT_FAILED:
      case actions.IMPORT_SUCCESS:
        return state + action.count

      default:
        return state
    }
  },
})
