import { isEmpty } from 'lodash'

const state = {
  locations: [],
  newLocationModal: false,
  editLocationModal: false,
  editLocation: null,
  response: null,
  searching: false,
  loading: false,
  error: false,
}

const mutations = {
  LOCATION_SET: (state, locations) => {
    state.locations = locations
  },
  LOCATION_NEW_MODAL_SHOW: (state) => {
    state.newLocationModal = true
    state.response = null
    state.searching = false
    state.error = false
  },
  LOCATION_NEW_MODAL_HIDE: (state) => {
    state.newLocationModal = false
    state.response = null
  },
  LOCATION_SEARCH_RESET: (state) => {
    state.response = null
    state.error = false
  },
  LOCATION_SEARCH_START: (state) => {
    state.searching = true
  },
  LOCATION_SEARCH_FOUND: (state, response) => {
    state.response = response
    state.searching = false
    state.error = false
  },
  LOCATION_SEARCH_NOT_FOUND: (state) => {
    state.response = null
    state.searching = false
    state.error = true
  },
  LOCATION_ADD: (state, location) => {
    state.locations.unshift(location)
  },
  LOCATION_EDIT_MODAL_SHOW: (state, location) => {
    state.editLocationModal = true
    state.editLocation = location
    state.response = null
    state.searching = false
    state.error = false
  },
  LOCATION_EDIT_MODAL_HIDE: (state) => {
    state.editLocationModal = false
  },
  LOCATION_UPDATE: (state, location) => {
    const index = state.locations.findIndex((l) => l.id === location.id)

    // Update locally so the user doesn't have to refresh to see the result.
    state.locations = [...state.locations.slice(0, index), location, ...state.locations.slice(index + 1)]
  },
  LOCATION_LOADING: (state, value) => {
    state.loading = value
  },
}

const actions = {
  setLocations: ({ commit }) => commit('LOCATION_SET', Munio.state.locations),
  newLocationModal: ({ commit }, { show }) =>
    show ? commit('LOCATION_NEW_MODAL_SHOW') : commit('LOCATION_NEW_MODAL_HIDE'),
  search: async ({ commit }, query) => {
    if (!query) return commit('LOCATION_SEARCH_RESET')

    commit('LOCATION_SEARCH_START')
    try {
      const { data } = await Munio.api.locations.search(query)
      commit('LOCATION_SEARCH_FOUND', data)
    } catch (e) {
      commit('LOCATION_SEARCH_NOT_FOUND')
    }
  },
  create: async ({ commit, state }, name) => {
    commit('LOCATION_LOADING', true)
    const placeId = state.response.place_id
    try {
      const { data } = await Munio.api.locations.create(placeId, name)
      commit('LOCATION_LOADING', false)
      commit('LOCATION_ADD', data)
      commit('LOCATION_NEW_MODAL_HIDE')
    } catch (e) {
      commit('LOCATION_LOADING', false)
      Munio.Flash.error()
    }
  },
  edit: ({ commit, state }, location) => {
    commit('LOCATION_EDIT_MODAL_SHOW', location)
  },
  update: async ({ commit, state }, name) => {
    commit('LOCATION_LOADING', true)
    const id = state.editLocation.id
    let placeId = null

    // If the user has made a search, there will be data in state.response.
    if (!isEmpty(state.response)) {
      placeId = state.response.place_id
    } else {
      placeId = state.editLocation.identifier
    }

    try {
      const { data } = await Munio.api.location(id).update(placeId, name)
      commit('LOCATION_LOADING', false)
      commit('LOCATION_UPDATE', data)
      commit('LOCATION_EDIT_MODAL_HIDE')
      Munio.Flash.success()
    } catch (e) {
      commit('LOCATION_LOADING', false)
      Munio.Flash.error(trans('Location update failed'))
    }
  },
}

export default {
  state,
  mutations,
  actions,
}
