import { CompanyBasicResourceDto } from './data/CompanyBasicResource.dto'
import { CompanyResourceDto } from './data/CompanyResource.dto'
import { CollectionResponse, ResourceResponse } from './data/common'

const { $http, route, urlParams } = window.Munio
const cleanup = window.cleanup

type CompanyPayload = {
  name: string
  nameShort: string
  nameLegal: string
  nameLimited: string
  reference?: string
  invoiceEmail: string
}

class Company {
  search(query: string, country: string, context = false, dropdown = false) {
    return $http.get(route('api.web.companies'), cleanup({ query, country, context, dropdown }))
  }

  setContext(id: number) {
    return $http.post(route('api.web.companies.context', { company: id }))
  }

  add(company: CompanyBasicResourceDto, redirect = false) {
    return $http.post(route('api.web.admin.companies.create'), cleanup({ ...company, redirect }))
  }

  index(options?: URLSearchParams) {
    return $http.get<CollectionResponse<CompanyResourceDto>>(route('api.web.admin.companies'), urlParams(options))
  }

  update(company: number, data: CompanyPayload) {
    return $http.put<ResourceResponse<CompanyResourceDto>>(
      route('api.web.admin.company.update', { company }),
      cleanup({
        name: data.name,
        name_short: data.nameShort,
        name_legal: data.nameLegal,
        name_limited: data.nameLimited,
        reference: data.reference,
        invoice_email: data.invoiceEmail,
      }),
    )
  }
}

export default Company
