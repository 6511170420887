import { CollectionResponse } from '@/munio/api/data/common'
import { GateUserResourceDto } from '@/munio/api/data/GateUserResource.dto'

export default class Search {
  constructor(private zoneId: number) {}

  get params() {
    return { zone: this.zoneId }
  }

  admins(options: any) {
    return window.Munio.$http.get(
      Munio.route('api.web.access.zone.search.admins', this.params),
      window.Munio.urlParams(window.cleanup(options)),
    )
  }

  companies(options: any) {
    return window.Munio.$http.get(
      Munio.route('api.web.access.zone.search.companies', this.params),
      window.Munio.urlParams(window.cleanup(options)),
    )
  }

  users(options: any) {
    return window.Munio.$http.get<CollectionResponse<GateUserResourceDto>>(
      Munio.route('api.web.access.zone.search.users', this.params),
      window.Munio.urlParams(window.cleanup(options)),
    )
  }
}
