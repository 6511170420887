<template>
  <div v-if="canEnroll">
    <div class="modal-body flex justify-between items-center">
      <div class="flex-grow">
        <div v-if="course.description.length">
          <h4 class="mdl-subheader">{{ trans('Description') }}</h4>
          <span class="whitespace-pre-wrap">{{ course.description }}</span>
        </div>
      </div>

      <div class="spacer"></div>

      <div class="flex justify-end flex-grow items-baseline">
        <mdl-button raised primary :disabled="loading" @click="$emit('enroll', userCourse)">
          {{ trans('Enroll') }}
        </mdl-button>
      </div>
    </div>
  </div>

  <div v-else>
    <div class="modal-body flex flex-col">
      <template v-if="!userCourse.course.isAdminResponsible">
        <div class="flex justify-end flex-grow items-baseline">
          <mdl-button v-if="canUnenroll" danger :disabled="loading" @click="$emit('unenroll', userCourse)">
            {{ trans('Unenroll') }}
          </mdl-button>

          <mdl-button v-if="userCourse.isRestartable" :disabled="loading" outlined @click="$emit('restart')">
            {{ trans('Restart') }}
          </mdl-button>
        </div>
      </template>

      <div v-if="course.description.length" class="flex-grow">
        <h4 class="mdl-subheader">{{ trans('Description') }}</h4>
        <span class="whitespace-pre-wrap">{{ course.description }}</span>
      </div>
    </div>

    <info v-if="userCourse.course.isAdminResponsible" :header="trans('Admin task')">{{
      trans('This task needs to be completed by an administrator')
    }}</info>

    <div class="modal-body modal-body--tinted">
      <template v-if="incompletePrerequisites.length">
        <h4 class="mdl-subheader">{{ trans('Prerequisites') }}</h4>
        <div>
          <div class="course-nodes">
            <Prerequisite
              v-for="prerequisite in incompletePrerequisites"
              :key="prerequisite.courseId"
              :prerequisite="prerequisite"
            />
          </div>
        </div>
      </template>
      <h4 class="mdl-subheader">{{ trans('Completion') }}</h4>
      <div>
        <ContentMisc
          :user-course="userCourse"
          :loading="loading || incompletePrerequisites.length > 0"
          :on-upload="onUpload"
          :on-setdate="onSetdate"
          :on-approve="onApprove"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ContentMisc from '@/munio/vue/components/UserCourse/Misc.vue'
import Prerequisite from './Prerequisite.vue'
import Info from './Info.vue'

export default {
  components: {
    ContentMisc,
    Info,
    Prerequisite,
  },

  props: {
    loading: Boolean,
    userCourse: { type: Object, required: true },
    onUpload: { type: Function, default: () => {} },
    onSetdate: { type: Function, default: () => {} },
    onApprove: { type: Function, default: () => {} },
  },

  computed: {
    canUnenroll() {
      if (!can('munio')) {
        return false
      }

      return this.userCourse.isEnrolled && this.userCourse.isUnenrollable && !this.userCourse.course.isAdminResponsible
    },

    canEnroll() {
      return !this.userCourse.isEnrolled && this.userCourse.canSelfEnroll
    },

    course() {
      if (!this.userCourse) {
        return
      }

      return this.userCourse.course
    },

    incompletePrerequisites() {
      return this.userCourse.prerequisites.filter((prerequisite) => !prerequisite.isCompleted)
    },
  },
}
</script>
